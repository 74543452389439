import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { useSupabase } from "../../../hooks/useSupabase";
import { useCanEdit } from "../../../hooks/userCanEdit";
import { Tab, TabGroup } from "../../../shared/Library/Tab";
import { AssetDbType, FileDbType, UploadDbType } from "../../../types";
import { UPLOAD_CATEGORIES } from "../../Episode/components/types";

export const Page = () => {
  const { episodeId } = useParams();

  const supabase = useSupabase();

  const [upload, setUpload] = useState<UploadDbType>();
  const [assets, setAssets] = useState<AssetDbType[]>([]);
  const [files, setFiles] = useState<FileDbType[]>([]);

  const canEdit = useCanEdit({ episode: upload });

  useEffect(() => {
    if (!episodeId) return;
  }, [episodeId]);

  useEffect(() => {
    supabase
      .from("Upload")
      .select(`*, Asset(*), File(*)`) // add podcast
      .eq("id", episodeId as string)
      .limit(1)
      .single()
      .then((res) => {
        setUpload(res.data as UploadDbType);
        setAssets(res.data?.Asset as AssetDbType[]);
        setFiles(res.data?.File as FileDbType[]);
      });
  }, [episodeId, supabase]);

  // listen to db changes on selectedEpisode
  const displayPlatforms = assets
    .map((a) => a.asset_id as string)
    .filter((asset_id) => Boolean(asset_id))
    .map((asset_id) => {
      return UPLOAD_CATEGORIES[asset_id.split("_")[0]];
    })
    .filter((platform) => platform !== undefined)
    // keep only unique records
    .filter(
      (platform, index, self) =>
        self.findIndex((p) => p?.value === platform?.value) === index
    );

  // now flatten all the resources and add platform component to each
  const flattenedAssets = displayPlatforms
    .map((platform) => {
      return platform?.resources.map((resource) => {
        // find resource.dbId in asset_id in assets
        if (!assets.find((a) => a.asset_id === resource.dbId)) return undefined;
        return {
          ...resource,
          component: platform.component,
        };
      });
    })
    .flat()
    .filter((resource) => resource !== undefined) as {
    component: (props: any) => JSX.Element;
    dbId: string;
    label: string;
    value: string;
    hasFilters: boolean;
  }[];

  // if (
  //   !upload?.type?.includes("text") &&
  //   assets.find((a) => a.asset_id === "transcript") === undefined
  // )
  //   flattenedAssets.unshift({
  //     component: UPLOAD_CATEGORIES["transcript"].component,
  //     dbId: UPLOAD_CATEGORIES["transcript"].resources[0].dbId,
  //     label: UPLOAD_CATEGORIES["transcript"].resources[0].label,
  //     value: UPLOAD_CATEGORIES["transcript"].resources[0].value,
  //     hasFilters: false,
  //   });

  const trim = (title: string | undefined) => {
    if (!title) return "";
    if (title.length > 50) {
      return title.slice(0, 50) + "...";
    }
    return title;
  };

  // sort all flattenedAssets for the transcript to be always at the beginning
  flattenedAssets.sort((a, b) => {
    if (a.dbId === "transcript") return -1;
    if (b.dbId === "transcript") return 1;
    return 0;
  });

  return (
    <div className="flex flex-col justify-start items-center w-full py-4 bg-[#fbfbfb] ">
      <div className="w-full max-w-[994px] mt-14 py-8 px-12 bg-white rounded-xl">
        <div className="flex justify-between items-center">
          <div
            className="w-full font-bold flex justify-start items-center space-x-2 cursor-pointer"
            onClick={() => {
              window.location.href = "https://www.unifire.ai";
            }}
          >
            <Logo className="w-[32px] h-[32px] mb-1" />
            <p className="text-20 font-extrabold">Unifire</p>
          </div>

          <Link
            to="/signup"
            className="border font-medium py-2 px-11 min-w-max bg-uf-yellow-1 text-[#202020] text-14 border-uf-yellow-1 rounded-lg "
          >
            Sign Up
          </Link>
        </div>

        <p className="text-gray-700 text-lg leading-6 mt-10">
          This content was created with the help of{" "}
          <a className="underline text-blue-700" href="https://www.unifire.ai">
            Unifire.ai
          </a>
          , sign up to upload, collaborate and work with your own content.
        </p>

        <div className="min-h-screen space-y-8 mt-8 bg-white/40 rounded-lg no-scrollbar">
          <div className="w-full h-full">
            <p className="text-18 font-semibold text-uf-black-1">
              {trim(upload?.title ?? "")}
            </p>

            <div className="mt-10">
              {upload && (
                <div className="w-full">
                  <TabGroup sticky maxColumns={5}>
                    {flattenedAssets.map(
                      ({ component, dbId, label, value, hasFilters }) => (
                        <Tab
                          key={dbId}
                          name={label}
                          assetId={dbId}
                          onClick={() => {}}
                          content={() =>
                            component({
                              canEdit,
                              subCategory: dbId,
                              upload: upload,
                              selectedEditState: "UNTTAGED",
                              setArchivedPostCount: () => {},
                              setFavorizedPostCount: () => {},
                              setUntaggedPostCount: () => {},
                              assets,
                              files,
                            })
                          }
                        />
                      )
                    )}
                  </TabGroup>
                </div>
              )}

              {/* <div className="w-full flex justify-end items-center space-x-4">
          {isSaved ? (
            <div className=" flex items-center space-x-2">
              <span className="text-[#46D5B3] text-12">Changes saved</span>
              <CloudSavedSvg />
            </div>
          ) : (
            <div className=" flex items-center space-x-2">
              <span className="text-[#CAC8CD] text-12">Saving...</span>
              <CloudNotSavedSvg />
            </div>
          )}

         
        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;

// export default () => <div />;
