export const YoutubeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4461_11245)">
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#FF0000"
      />
      <path
        d="M13.2723 6.23828H6.72761C6.46606 6.23828 6.20708 6.28982 5.96546 6.38995C5.72384 6.49008 5.50432 6.63683 5.31944 6.82184C5.13456 7.00684 4.98795 7.22645 4.88798 7.46814C4.78801 7.70983 4.73664 7.96884 4.73682 8.23039V11.7699C4.73664 12.0314 4.78801 12.2904 4.88798 12.5321C4.98795 12.7738 5.13456 12.9934 5.31944 13.1784C5.50432 13.3634 5.72384 13.5102 5.96546 13.6103C6.20708 13.7104 6.46606 13.762 6.72761 13.762H13.2723C13.5339 13.762 13.7929 13.7104 14.0345 13.6103C14.2761 13.5102 14.4956 13.3634 14.6805 13.1784C14.8654 12.9934 15.012 12.7738 15.112 12.5321C15.2119 12.2904 15.2633 12.0314 15.2631 11.7699V8.23039C15.2633 7.96884 15.2119 7.70983 15.112 7.46814C15.012 7.22645 14.8654 7.00684 14.6805 6.82184C14.4956 6.63683 14.2761 6.49008 14.0345 6.38995C13.7929 6.28982 13.5339 6.23828 13.2723 6.23828ZM8.61313 11.6133V8.38697L11.3868 10.0001L8.61313 11.6133Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4461_11245">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
