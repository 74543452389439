export const CheckmarkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 6.86647L3.33995 10.4138C4.15171 11.5256 5.81789 11.504 6.60056 10.3715L13.25 0.75"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
