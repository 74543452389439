export const LinkedinIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4461_11414)">
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#0B69C7"
      />
      <path
        d="M7.76307 6.36447C7.76333 6.63471 7.68342 6.89896 7.53344 7.12376C7.38347 7.34857 7.17018 7.52383 6.92056 7.62736C6.67094 7.7309 6.39621 7.75806 6.13115 7.7054C5.8661 7.65274 5.62261 7.52264 5.43153 7.33155C5.24044 7.14046 5.11033 6.89698 5.05767 6.63192C5.00502 6.36686 5.03217 6.09214 5.13571 5.84252C5.23925 5.5929 5.41451 5.3796 5.63931 5.22963C5.86412 5.07965 6.12836 4.99974 6.3986 5C6.76037 5.00035 7.10723 5.14422 7.36304 5.40003C7.61886 5.65584 7.76273 6.0027 7.76307 6.36447Z"
        fill="white"
      />
      <path
        d="M7.11704 8.29883H5.6802C5.49344 8.29883 5.34204 8.45023 5.34204 8.63699V14.662C5.34204 14.8487 5.49344 15.0001 5.6802 15.0001H7.11704C7.3038 15.0001 7.4552 14.8487 7.4552 14.662V8.63699C7.4552 8.45023 7.3038 8.29883 7.11704 8.29883Z"
        fill="white"
      />
      <path
        d="M14.967 11.7726V14.6897C14.967 14.772 14.9343 14.851 14.8761 14.9092C14.8178 14.9675 14.7389 15.0002 14.6565 15.0002H13.1144C13.032 15.0002 12.9531 14.9675 12.8948 14.9092C12.8366 14.851 12.8039 14.772 12.8039 14.6897V11.8634C12.8039 11.441 12.9262 10.0212 11.7012 10.0212C10.7512 10.0212 10.5578 10.9976 10.517 11.4357V14.6962C10.5153 14.777 10.4822 14.8539 10.4247 14.9107C10.3672 14.9674 10.2899 14.9995 10.2091 15.0002H8.71702C8.6762 15.0004 8.63574 14.9925 8.59798 14.9769C8.56023 14.9614 8.52593 14.9385 8.49706 14.9096C8.46819 14.8808 8.44532 14.8465 8.42978 14.8087C8.41424 14.771 8.40632 14.7305 8.4065 14.6897V8.61072C8.40632 8.56983 8.41423 8.52931 8.42976 8.49149C8.44528 8.45366 8.46813 8.41927 8.49698 8.3903C8.52583 8.36132 8.56012 8.33833 8.59789 8.32264C8.63565 8.30696 8.67613 8.29888 8.71702 8.29888H10.2091C10.2505 8.29818 10.2916 8.30573 10.3301 8.32109C10.3685 8.33645 10.4035 8.35931 10.433 8.38833C10.4626 8.41736 10.486 8.45198 10.502 8.49016C10.518 8.52834 10.5262 8.56932 10.5262 8.61072V9.13704C10.8789 8.61072 11.4025 8.2002 12.517 8.2002C14.9815 8.19888 14.967 10.5055 14.967 11.7726Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4461_11414">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
