// YoutubeUrlUploader.tsx

import { AxiosResponse } from "axios";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import uuid4 from "uuid4";
import * as Yup from "yup";
import { useLogsnag } from "../../../../../hooks/logsnag"; // Adjust the path as necessary
import { useAnalytics } from "../../../../../hooks/useAnalytics";
import { useRemainingCredits } from "../../../../../hooks/useRemainingCredits";
import { useSupabase } from "../../../../../hooks/useSupabase";
import { useWorkspace } from "../../../../../hooks/useWorkspace";
import { useRole } from "../../../../../hooks/userCanEdit";
import {
  convertMillisecondsToMinutes,
  embedText,
  sleep,
} from "../../../../../utils";
import { getFileTypeAnalytics } from "../../../../../utils/common";
import { youtube } from "../../../../UploadContent/util";
import { ReactComponent as LinkIcon } from "../assets/link.svg"; // Adjust the path as necessary
import { UploadProgressModal } from "./ManualUploadModal";

// Regular expression to match YouTube video URLs that are not playlists
const youtubeVideoUrlRegex =
  /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(?!.*&list=).*/;

// Yup validation schema
const validationSchema = Yup.object().shape({
  youtubeUrl: Yup.string()
    .required("YouTube URL is required")
    .matches(
      youtubeVideoUrlRegex,
      "Please enter a valid YouTube video URL that is not a playlist"
    )
    .test("is-not-playlist", "Playlists are not allowed", (value) => {
      return value ? !/&list=/.test(value) : true;
    }),
});

const YoutubeLink = ({
  projectId,
  onUploadIdDecided,
  onUploaded,
  language,
}: {
  projectId: string;
  onUploadIdDecided: (_: string, isText: boolean) => void;
  onUploaded: () => void;
  language: string;
}) => {
  const logsnag = useLogsnag();
  const supabase = useSupabase(); // Use this context if needed for metadata or other Supabase interactions
  const [progress, setProgress] = useState(0);
  const totalDuration = 10000; // 10 seconds in milliseconds
  const startTime = Date.now();

  const [startUpload, setStartUpload] = useState(false);
  const ownerGeneralLimits = useRemainingCredits()?.ownerGeneralLimits;

  const formikRef = useRef();

  const [show, setShow] = useState(false);

  const role = useRole();
  const workspace = useWorkspace();
  const { newUploadStarted, newUploadSuccessful, uploadFailed } =
    useAnalytics();

  const handleSubmit = (values: { youtubeUrl: string }) => {
    if (!values.youtubeUrl) return;
    else handlePrivateUpload(values.youtubeUrl);
  };
  const handlePrivateUpload = async (youtubeUrl: string) => {
    // upload
    setShow(true);
    setStartUpload(true);

    const session = await supabase.auth.getSession();

    const startWaitduration = Date.now();

    if (!projectId) return;

    const uploadId = uuid4();
    const fileId = uuid4();

    newUploadStarted({
      mediaType: getFileTypeAnalytics("video/mp4") as any,
      uploadMethod: "manual",
      userRole: role as any,
      workspaceId: workspace?.id as string,
    });

    const result = (await youtube({
      supabase,
      url: youtubeUrl,
      language,
    }).catch((e) => {
      alert(
        "Youtube rejected the download of your file, please use the manual upload feature or paste your text."
      );
      uploadFailed({
        uploadLength: result.data?.duration,
        uploadMethod: "youtube",
        message: "Failed to get video duration and title",
      });
      setShow(false);
      setStartUpload(false);
      return;
    })) as {
      data: {
        authorized: boolean;
        duration: number;
        transcript: string;
        title: string;
      };
    };

    if (
      ownerGeneralLimits.audioHourLimit <
      (result as AxiosResponse).data?.minutes
    ) {
      uploadFailed({
        uploadLength: (result as AxiosResponse).data?.minutes,
        uploadMethod: "youtube",
        message: "Audio duration exceeds limit",
      });
      const hours = ownerGeneralLimits.audioHourLimit / 60;
      alert(
        `Your plan only allows for ${hours} hour${
          hours > 1 ? "s" : ""
        } per upload. Please upgrade to upload longer files.`
      );
      setShow(false);
      setStartUpload(false);
      return;
    }

    logsnag.publish({
      channel: "content",
      description: "User started a new upload",
      event: "Uploading...",
      icon: "⬆",
      notify: true,
      tags: {
        userid: session?.data?.session?.user.id ?? "",
        email: session?.data?.session?.user.email ?? "",
      },
    });
    const userId = session?.data?.session?.user.id ?? "";

    // submit record
    const upload = await supabase.from("Upload").insert({
      id: uploadId,
      project_id: projectId,
      status: "edit",
      title: result.data.title,
      workspace_id: workspace?.id,
      user_id: userId,
      lang_tag: language,
    });

    const insertedFile = await supabase.from("File").insert({
      id: fileId,
      duration: -1,
      mime: "text/plain",
      status: "READY",
      title: result.data.title,
      upload_id: uploadId,
      workspace_id: workspace?.id,
      text_content: result.data.transcript,
    });
    console.log("insertedFile ", insertedFile);
    if (insertedFile.error || upload.error) {
      alert("An error occured, please try again");
      return;
    }

    // await embedText({
    //   uploadId,
    //   supabase,
    //   langTag: language,
    // }).catch((e) => {
    //   alert("An error has occured, please try again");
    //   window.location.reload();
    // });

    onUploadIdDecided(uploadId, true);

    const endDateDuration = Date.now();
    newUploadSuccessful({
      fileType: "video/mp4",
      uploadMethod: "youtube",
      uploadWaitDuration: convertMillisecondsToMinutes(
        endDateDuration - startWaitduration
      ),
      userRole: role as any,
      workspaceId: workspace?.id as string,
      uploadId,
      uploadLength: (result as AxiosResponse).data?.minutes,
      ownerId: workspace?.owner_id as string,
    });

    logsnag.publish({
      channel: "content",
      description: "User finished upload",
      event: "Upload finished",
      icon: "✅",
      notify: true,
      tags: {
        userid: session?.data?.session?.user.id ?? "",
        email: session?.data?.session?.user.email ?? "",
      },
    });
    await sleep(4000);

    setShow(false);
    onUploaded();
  };

  // progress simulation
  useEffect(() => {
    if (!startUpload) return;
    let isCancelled = false;

    const updateProgress = () => {
      if (isCancelled) {
        return;
      }

      const elapsedTime = Date.now() - startTime;
      if (elapsedTime >= totalDuration) {
        setProgress(100);
        return;
      }

      // Calculate the current progress based on the elapsed time
      const currentProgress = (elapsedTime / totalDuration) * 100;

      // Randomly add some progress to simulate non-linear behavior
      const randomProgress = Math.random() * 5; // up to 5% random progress
      const newProgress = Math.floor(
        Math.min(currentProgress + randomProgress, 100)
      );
      setProgress(newProgress);

      // Schedule the next update
      const randomInterval = Math.random() * 1000 + 500; // between 0.5 and 1.5 seconds
      setTimeout(updateProgress, randomInterval);
    };

    // Start the loading simulation
    setTimeout(updateProgress, 0);

    return () => {
      isCancelled = true;
    };
  }, [startUpload]);

  return (
    <>
      <div className="w-full min-h-[250px]">
        <Formik
          initialValues={{ youtubeUrl: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formikRef as any}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-full">
                <div className="w-full border border-gray-600/20 rounded-lg flex justify-center items-center">
                  <div className="px-3 flex justify-center items-center border-r border-r-gray-600/20">
                    <LinkIcon className="scale-75" />
                  </div>
                  <input
                    className="w-full py-[7px] outline-none px-3 bg-transparent text-14"
                    placeholder="Drop a video link (YouTube)"
                    onChange={(e) =>
                      setFieldValue("youtubeUrl", e.target.value)
                    }
                  />
                  <button
                    className="rounded-lg px-2 md:min-w-fit md:px-10 py-[7px] bg-[#FF5698] text-14 font-semibold text-white"
                    type="submit"
                  >
                    Write Content
                  </button>
                </div>
                <ErrorMessage
                  name="youtubeUrl"
                  component="div"
                  className="text-red-500 text-10"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="flex justify-between items-center w-full mt-5">
        <button
          className="text-14 font-medium"
          onClick={() => {
            window.location.reload();
          }}
        >
          Cancel
        </button>
        <button
          className="bg-uf-yellow-1 rounded-lg text-14 font-medium text-[#202020] py-2 px-4 focus:outline-none focus:shadow-outline disabled:bg-gray-100"
          // onClick={() => onUploaded()}
          disabled
        >
          Upload Source Material
        </button>
      </div>
      <UploadProgressModal
        setShow={setShow}
        show={show}
        fileUploadProgress={progress}
      />
    </>
  );
};

export default YoutubeLink;
