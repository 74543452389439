import { Editor } from "@tiptap/react";
import axios, { AxiosError } from "axios";
import classNames from "classnames";
import { AssemblyTurnByTurn } from "../../../Episode/components/EpisodePage";
import { SpeakerLabelType } from "../types";
import { RedoItem } from "./MenuItems/RedoItem";
import ReplaceItem from "./MenuItems/ReplaceItem";
import SpeakerLabelItem from "./MenuItems/SpeakerLabelItem";
import { UndoItem } from "./MenuItems/UndoItem";

export const EditorMenu = ({
  editor,
  speakers,
  onSpeakersUpdated,
  hideGeneration,
}: // name,
// color,
{
  editor: Editor | null;
  speakers: SpeakerLabelType[];
  onSpeakersUpdated: (speakers: SpeakerLabelType[]) => void;
  hideGeneration?: boolean;
  // name: string;
  // color: string;
}) => {
  return (
    <div className="mt-6 flex justify-between items-center px-3 md:px-7 py-3.5 rounded-md text-12">
      <div className="flex justify-between items-center space-x-8">
        <ReplaceItem editor={editor} />
        <SpeakerLabelItem
          speakers={speakers}
          onSpeakersUpdated={onSpeakersUpdated}
        />
        <UndoItem editor={editor} />
        <RedoItem editor={editor} />
      </div>

      <div
        className={classNames("flex justify-between items-center space-x-8 ", {
          hidden: hideGeneration,
        })}
      >
        {/* <ApproveItem onSubmit={handleSubmit} /> */}
      </div>
      {/* <div
        className={classNames(" flex justify-end items-end", {
          hidden: !hideGeneration,
        })}
      >
        <Avatars name={name} color={color} />
      </div> */}
    </div>
  );
};

export const getConversationData = (
  editor: Editor,
  speakers: SpeakerLabelType[]
) => {
  const html = editor.getHTML();
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const conversationTexts = doc.querySelectorAll("conversation-text");

  const conversationData = Array.from(conversationTexts).map(
    (conversationText) => {
      const start = conversationText.getAttribute("timeStart");
      const end = conversationText.getAttribute("timeEnd");
      const speakerValue = parseInt(
        `${conversationText.getAttribute("speakerValue")}`,
        10
      );

      const text = conversationText.textContent;
      return {
        start: parseInt(start as string, 10),
        end: parseInt(end as string, 10),
        speaker: speakerValue,
        text: text as string,
      } as AssemblyTurnByTurn[number];
    }
  );

  return conversationData;
};

export const submitTranscript = async ({
  uploadId,
  supabase,
}: {
  uploadId: any;
  supabase: any;
}) => {
  const body = { uploadId: uploadId };

  const session = await supabase.auth.getSession();

  return await axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/submit-script`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};
