import { LinkIcon, UploadIcon } from "@iconicicons/react";
import { useState } from "react";
import { Tab, TabGroup } from "../../../../../shared/Library/Tab";
import { SelectLanguage } from "../../../../Dashboard/components/CreateProject";
import FilesUpload from "./FilesUpload";
import YoutubeLink from "./YoutubeLink";
import { DownArrow } from "../../../../../shared/Icons/DownArrow";
import { BackIcon } from "../../../../../shared/Icons/Back";
import PasteText from "./PasteText";
import { FileIcon } from "../../../../../shared/Icons/File";

export function UploadPage({
  isPublic,
  onUploaded,
  projectId,
  onUploadIdDecided,
  onUploadProgress,
}: {
  isPublic?: boolean;
  onUploaded: () => void;
  onUploadIdDecided: (_: string, isText: boolean) => void;
  projectId: string;
  onUploadProgress?: (progress: number) => void;
}) {
  // language
  const [language, setLanguage] = useState("en");

  return (
    <div className="absolute inset-0 flex justify-center items-start z-10 bg-white overflow-x-hidden">
      <div className="flex flex-col justify-center items-center ">
        <div className="h-10 w-1" />
        <div className="flex justify-center items-center text-sm leading-5 font-medium text-[#6C6C89] space-x-2">
          <div className="flex flex-col justify-center items-center">
            <span className="">Upload Content</span>
            <div className="h-1 w-[110%] rounded bg-uf-green-1" />
          </div>

          <div>
            <DownArrow className="-rotate-90" />
          </div>

          <div className="flex flex-col justify-center items-center">
            <span className="">Generate Content</span>
            <div className="h-1 w-[110%] rounded bg-[#D9D9D9]" />
          </div>
        </div>

        <button
          className="absolute top-10 left-10"
          onClick={() => {
            window.location.reload();
          }}
        >
          <BackIcon width={24} height={24} />
        </button>

        <div className="mt-6 w-full max-w-[829px] min-h-[719px] flex flex-col justify-between items-center p-10 border border-[#D9D9D9]/5 rounded-lg shadow">
          <div className="w-full space-y-[50px]">
            {/* text section */}
            <div className="w-full text-center space-y-[10px]">
              <p className="text-[#202020] text-xl leading-8 font-semibold">
                Upload Source Material
              </p>
              <p className="text-lg leading-6 font-normal text-[#6C6C89]">
                Source materials is content that Unifire repurposes and extracts
                information from. Upload text, audio or a video recording{" "}
              </p>
            </div>

            <SelectLanguage
              isOnboarding
              defaultLanguage="en"
              handleLangChange={(lang) => {
                setLanguage(lang);
              }}
            />

            <div className=" w-full">
              <TabGroup maxColumns={3}>
                {/* <Tab
              name="Enter Text"
              icon={FileIcon}
              content={() => <PasteBox />}
            /> */}
                <Tab
                  name="Paste Text"
                  assetId="paste-text"
                  icon={FileIcon}
                  content={() => (
                    <PasteText
                      projectId={projectId}
                      onUploadIdDecided={onUploadIdDecided}
                      onUploaded={onUploaded}
                      isPublic={isPublic}
                      language={language}
                    />
                  )}
                />
                <Tab
                  name="Upload File"
                  assetId="upload-file"
                  icon={UploadIcon}
                  content={() => (
                    <FilesUpload
                      projectId={projectId}
                      onUploadIdDecided={onUploadIdDecided}
                      onUploaded={onUploaded}
                      isPublic={isPublic}
                      onUploadProgress={onUploadProgress}
                      language={language}
                    />
                  )}
                />
                <Tab
                  name="Drop a Link"
                  assetId="drop-link"
                  icon={LinkIcon}
                  content={() => (
                    <YoutubeLink
                      projectId={projectId}
                      onUploadIdDecided={onUploadIdDecided}
                      onUploaded={onUploaded}
                      language={language}
                    />
                  )}
                />
              </TabGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadPage;
