import { Socket } from "socket.io-client";
import { ApplePodcastIcon } from "../../../shared/Icons/Social/ApplePodcastIcon";
import { ArticleIcon } from "../../../shared/Icons/Social/ArticleIcon";
import { EbookIcon } from "../../../shared/Icons/Social/EbookIcon";
import { EducationIcon } from "../../../shared/Icons/Social/EducationIcon";
import { LinkedinIcon } from "../../../shared/Icons/Social/LinkedinIcon";
import { MailIcon } from "../../../shared/Icons/Social/MailIcon";
import { MediumIcon } from "../../../shared/Icons/Social/MediumIcon";
import { TwitterIcon } from "../../../shared/Icons/Social/TwitterIcon";
import { YoutubeIcon } from "../../../shared/Icons/Social/YoutubeIcon";
import { ZoomIcon } from "../../../shared/Icons/Social/ZoomIcon";
import {
  AssetDbType,
  FileDbType,
  UploadWithSocialPostsAndProject,
} from "../../../types";
import BlogPostResources from "./BlogResources";
import EbookResources from "./EbookResources";
import EducationResources from "./EducationResources";
import LinkedinResources from "./LinkedinResources";
import MeetingResources from "./MeetingResources";
import { NewsletterResources } from "./NewsletterResources";
import { PodcastResources } from "./PodcastResources";
// import { TranscriptResources } from "./TranscriptResources";
import { TwitterResources } from "./TwitterResources";
import { YoutubeResources } from "./YoutubeResources";
import { TranscriptResources } from "./TranscriptResources";

export const ASSETS_IDS = {
  TRANSCRIPT: "transcript",

  PODCAST_TITLES: "podcast_titles",
  PODCAST_SUMMARY: "podcast_summaries",
  PODCAST_KEYWORDS: "podcast_keywords",
  PODCAST_TIMESTAMPS: "podcast_timestamps",
  PODCAST_QUOTES: "podcast_quotes",
  PODCAST_KEYTAKEAWAYS: "podcast_keytakeaways",
  PODCAST_MENTIONEDTOPICS: "podcast_mentionedtopics",

  BLOG_POST: "blog_posts",
  BLOG_OUTLINE: "blog_outlines",
  // linkedin
  LINKEDIN_POSTS: "linkedin_posts",
  LINKEDIN_CAROUSELS: "linkedin_carousels",

  // twitter
  TWITTER_TWEETS: "twitter_tweets",
  TWITTER_THREADS: "twitter_threads",
  TWITTER_LONGFORM: "twitter_longform",

  YOUTUBE_TITLES: "youtube_titles",
  YOUTUBE_TAGS: "youtube_tags",
  YOUTUBE_DESCRIPTION: "youtube_descriptions",
  // YOUTUBE_POSTS: "youtube_posts",
  YOUTUBE_TIMESTAMPS: "youtube_timestamps",

  NEWSLETTER_POSTS: "newsletter_posts",

  MEETING_SUMMARY: "meeting_summaries",
  MEETING_FAQ: "meeting_faqs",
  MEETING_REPORT: "meeting_reports",
  MEETING_MEMO: "meeting_memos",
  MEETING_TRAINING: "meeting_trainings",

  // education
  EDUCATION_CHECKLIST: "education_checklists",
  EDUCATION_WORKSHEET: "education_worksheets",
  EDUCATION_GLOSSARY: "education_glossaries",
  EDUCATION_FLASHCARDS: "education_flashcards",
  EDUCATION_DISCUSSION: "education_discussions",
  EDUCATION_CASESTUDY: "education_casestudies",
  EDUCATION_FOLLOWUP: "education_followups",

  // ebooks
  // EBOOKS_APPENDICES: "ebook_appendices",
  // EBOOKS_GLOSSARY: "ebook_glossaries",
  EBOOKS_TITLES: "ebook_titles",
  // EBOOKS_FOREWORD: "ebook_forewords",
  // EBOOKS_TABLE_OF_CONTENTS: "ebook_table_of_contents",
  // EBOOKS_CHAPTER_OUTLINE: "ebook_chapter_outlines",
  // EBOOKS_ENTIRE_OUTLINE: "ebook_entire_outlines",
  EBOOKS_CHAPTER: "ebook_chapters",
  EBOOKS_ENTIRE_PRODUCTION: "ebook_entire_productions",
};

export const UPLOAD_CATEGORIES: {
  [key: string]: {
    label: string;
    value: string;
    icon: JSX.Element;
    component: (props: {
      upload: UploadWithSocialPostsAndProject;
      subCategory: string;
      canEdit: boolean;
      editorName?: string;
      socket: Socket;
      assets: AssetDbType[];
      files: FileDbType[];
    }) => JSX.Element;
    resources: Array<{
      label: string;
      value: string;
      dbId: string;
      hasFilters: boolean;
    }>;
  };
} = {
  transcript: {
    label: "Transcript",
    value: "transcript",
    icon: <ArticleIcon className="cursor-default !w-5 !h-5" />,
    component: ({ canEdit, upload, editorName, files }) => (
      <TranscriptResources
        canEdit={canEdit}
        upload={upload}
        editorName={editorName ?? "Anon"}
        files={files}
      />
    ),
    resources: [
      // {
      //  label: "Timeline",
      //  value: "timeline",
      //  dbId: undefined,
      // },
      {
        label: "Transcript",
        value: ASSETS_IDS.TRANSCRIPT,
        dbId: ASSETS_IDS.TRANSCRIPT,
        hasFilters: false,
      },
    ],
  },
  podcast: {
    label: "Podcast",
    value: "podcast",
    icon: <ApplePodcastIcon className="cursor-default !w-5 !h-5" />,
    component: ({
      canEdit,
      subCategory,
      upload,
      socket,
      assets,
    }: {
      upload: UploadWithSocialPostsAndProject;
      canEdit: boolean;
      subCategory: string;
      socket: Socket;
      assets: AssetDbType[];
    }) => (
      <PodcastResources
        upload={upload}
        selectedAssetId={subCategory}
        canEdit={canEdit ?? false}
        socket={socket}
        assets={assets}
      />
    ),
    resources: [
      {
        label: "Summary",
        value: "summary",
        dbId: ASSETS_IDS.PODCAST_SUMMARY,
        hasFilters: false,
      },
      {
        label: "Keywords",
        value: "keywords",
        dbId: ASSETS_IDS.PODCAST_KEYWORDS,
        hasFilters: false,
      },
      {
        label: "Titles",
        value: "titles",
        dbId: ASSETS_IDS.PODCAST_TITLES,
        hasFilters: false,
      },
      {
        label: "Quotes",
        value: "quotes",
        dbId: ASSETS_IDS.PODCAST_QUOTES,
        hasFilters: false,
      },
      {
        label: "Timestamps",
        value: "timestamps",
        dbId: ASSETS_IDS.PODCAST_TIMESTAMPS,
        hasFilters: false,
      },
      {
        label: "Podcast Newsletter",
        value: "keytakeaways",
        dbId: ASSETS_IDS.PODCAST_KEYTAKEAWAYS,
        hasFilters: true,
      },
      {
        label: "Mentioned Resources",
        value: "mentionedtopics",
        dbId: ASSETS_IDS.PODCAST_MENTIONEDTOPICS,
        hasFilters: false,
      },
    ],
  },
  ebook: {
    label: "Ebook",
    value: "ebook",
    icon: <EbookIcon className="cursor-default !w-5 !h-5" />,
    component: ({
      canEdit,
      subCategory,
      upload,
      socket,
      assets,
    }: {
      upload: UploadWithSocialPostsAndProject;
      canEdit: boolean;
      subCategory: string;
      socket: Socket;
      assets: AssetDbType[];
    }) => (
      <EbookResources
        upload={upload}
        selectedAssetId={subCategory}
        canEdit={canEdit ?? false}
        socket={socket}
        assets={assets}
      />
    ),
    resources: [
      // {
      //   label: "Appendices",
      //   value: "appendices",
      //   dbId: ASSETS_IDS.EBOOKS_APPENDICES,
      //   hasFilters: false,
      // },
      // {
      //   label: "Glossary",
      //   value: "glossary",
      //   dbId: ASSETS_IDS.EBOOKS_GLOSSARY,
      //   hasFilters: false,
      // },
      {
        label: "Titles",
        value: "titles",
        dbId: ASSETS_IDS.EBOOKS_TITLES,
        hasFilters: false,
      },
      // {
      //   label: "Foreword",
      //   value: "foreword",
      //   dbId: ASSETS_IDS.EBOOKS_FOREWORD,
      //   hasFilters: false,
      // },
      // {
      //   label: "Table of Contents",
      //   value: "table of contents",
      //   dbId: ASSETS_IDS.EBOOKS_TABLE_OF_CONTENTS,
      //   hasFilters: false,
      // },
      {
        label: "Chapter",
        value: "chapters",
        dbId: ASSETS_IDS.EBOOKS_CHAPTER,
        hasFilters: false,
      },
      // {
      //   label: "Chapter Outline",
      //   value: "chapters_outline",
      //   dbId: ASSETS_IDS.EBOOKS_CHAPTER_OUTLINE,
      //   hasFilters: false,
      // },
      {
        label: "Full E-book",
        value: "entire production",
        dbId: ASSETS_IDS.EBOOKS_ENTIRE_PRODUCTION,
        hasFilters: false,
      },
    ],
  },
  blog: {
    label: "Blog Post",
    value: "blog",
    icon: <MediumIcon className="cursor-default !w-5 !h-5" />,
    component: ({ canEdit, subCategory, upload, socket, assets }) => (
      <BlogPostResources
        upload={upload}
        selectedAssetId={subCategory}
        canEdit={canEdit ?? false}
        socket={socket}
        assets={assets}
      />
    ),
    resources: [
      {
        label: "Blog",
        value: "post",
        dbId: ASSETS_IDS.BLOG_POST,
        hasFilters: true,
      },
      {
        label: "Blog Outline",
        value: "outline",
        dbId: ASSETS_IDS.BLOG_OUTLINE,
        hasFilters: true,
      },
    ],
  },
  linkedin: {
    label: "Linkedin",
    value: "linkedin",
    icon: <LinkedinIcon className="cursor-default !w-5 !h-5" />,
    component: ({ upload, subCategory, canEdit, socket, assets }) => (
      <LinkedinResources
        upload={upload}
        selectedSubCategory={subCategory}
        canEdit={canEdit ?? false}
        socket={socket}
        assets={assets}
      />
    ),
    resources: [
      {
        label: "LinkedIn Posts",
        value: "posts",
        dbId: ASSETS_IDS.LINKEDIN_POSTS,
        hasFilters: true,
      },
      {
        label: "Carousels Post",
        value: "carousels",
        dbId: ASSETS_IDS.LINKEDIN_CAROUSELS,
        hasFilters: true,
      },
    ],
  },
  twitter: {
    label: "Twitter",
    value: "twitter",
    icon: <TwitterIcon className="cursor-default !w-5 !h-5" />,
    component: ({ upload, subCategory, canEdit, socket, assets }) => (
      <TwitterResources
        episode={upload}
        selectedSubCategory={subCategory}
        canEdit={canEdit}
        upload={upload}
        socket={socket}
        assets={assets}
      />
    ),
    resources: [
      {
        label: "Tweets",
        value: "tweets",
        dbId: ASSETS_IDS.TWITTER_TWEETS,
        hasFilters: true,
      },
      // {
      //  label: "Threads",
      //  value: "threads",
      //  dbId: ASSETS_IDS.TWITTER_THREADS,
      // },
      {
        label: "Long-Form Tweet",
        value: "longform tweet",
        dbId: ASSETS_IDS.TWITTER_LONGFORM,
        hasFilters: true,
      },
    ],
  },
  // tags, description, posts, titles
  youtube: {
    label: "Youtube",
    value: "youtube",
    icon: <YoutubeIcon className="cursor-default !w-5 !h-5" />,
    component: ({ upload, subCategory, canEdit, socket, assets }) => (
      <YoutubeResources
        upload={upload}
        selectedAssetId={subCategory}
        canEdit={canEdit ?? false}
        socket={socket}
        assets={assets}
      />
    ),
    resources: [
      {
        label: "YouTube Titles",
        value: "titles",
        dbId: ASSETS_IDS.YOUTUBE_TITLES,
        hasFilters: false,
      },
      {
        label: "YouTube Tags",
        value: "tags",
        dbId: ASSETS_IDS.YOUTUBE_TAGS,
        hasFilters: false,
      },
      {
        label: "Video Description",
        value: "description",
        dbId: ASSETS_IDS.YOUTUBE_DESCRIPTION,
        hasFilters: false,
      },
      // {
      //   label: "Community Posts",
      //   value: "posts",
      //   dbId: ASSETS_IDS.YOUTUBE_POSTS,
      //   hasFilters: true,
      // },
      {
        label: "Timestamps",
        value: "timestamps",
        dbId: ASSETS_IDS.YOUTUBE_TIMESTAMPS,
        hasFilters: false,
      },
    ],
  },
  newsletter: {
    label: "Newsletter Posts",
    value: "newsletter",
    icon: <MailIcon className="cursor-default !w-5 !h-5" />,
    component: ({ upload, subCategory, canEdit, socket, assets }) => (
      <NewsletterResources
        upload={upload}
        selectedAssetId={subCategory}
        canEdit={canEdit ?? false}
        socket={socket}
        assets={assets}
      />
    ),
    resources: [
      {
        label: "Newsletter",
        value: "newsletter",
        dbId: ASSETS_IDS.NEWSLETTER_POSTS,
        hasFilters: true,
      },
    ],
  },
  meeting: {
    label: "Meeting",
    value: "meeting",
    icon: <ZoomIcon className="cursor-default !w-5 !h-5" />,
    component: ({ upload, subCategory, canEdit, socket, assets }) => (
      <MeetingResources
        upload={upload}
        selectedAssetId={subCategory}
        canEdit={canEdit ?? false}
        socket={socket}
        assets={assets}
      />
    ),
    resources: [
      {
        label: "Meeting Summary",
        value: "summary",
        dbId: ASSETS_IDS.MEETING_SUMMARY,
        hasFilters: false,
      },
      {
        label: "Meeting FAQ",
        value: "faq",
        dbId: ASSETS_IDS.MEETING_FAQ,
        hasFilters: false,
      },
      {
        label: "Meeting Report",
        value: "report",
        dbId: ASSETS_IDS.MEETING_REPORT,
        hasFilters: true,
      },
      {
        label: "Follow-up Memo",
        value: "memo",
        dbId: ASSETS_IDS.MEETING_MEMO,
        hasFilters: true,
      },
      // {
      //   label: "Training",
      //   value: "training",
      //   dbId: ASSETS_IDS.MEETING_TRAINING,
      //   hasFilters: true,
      // },
    ],
  },
  // education
  education: {
    label: "Education",
    value: "education",
    icon: <EducationIcon className="cursor-default !w-5 !h-5" />,
    component: ({ upload, subCategory, canEdit, socket, assets }) => (
      <EducationResources
        upload={upload}
        canEdit={canEdit ?? false}
        selectedAssetId={subCategory}
        socket={socket}
        assets={assets}
      />
    ),
    resources: [
      {
        label: "Lesson Checklist",
        value: "checklist",
        dbId: ASSETS_IDS.EDUCATION_CHECKLIST,
        hasFilters: true,
      },
      {
        label: "Worksheet",
        value: "worksheet",
        dbId: ASSETS_IDS.EDUCATION_WORKSHEET,
        hasFilters: true,
      },
      {
        label: "Terms Glossary",
        value: "glossary",
        dbId: ASSETS_IDS.EDUCATION_GLOSSARY,
        hasFilters: true,
      },
      {
        label: "Flashcards",
        value: "flashcards",
        dbId: ASSETS_IDS.EDUCATION_FLASHCARDS,
        hasFilters: true,
      },
      {
        label: "Discussion Prompt",
        value: "discussion prompt",
        dbId: ASSETS_IDS.EDUCATION_DISCUSSION,
        hasFilters: true,
      },
      {
        label: "Case Study Exercise",
        value: "case study",
        dbId: ASSETS_IDS.EDUCATION_CASESTUDY,
        hasFilters: true,
      },
      {
        label: "Lecture Follow Up Email",
        value: "follow-up",
        dbId: ASSETS_IDS.EDUCATION_FOLLOWUP,
        hasFilters: true,
      },
    ],
  },
};

export const ASSETID_PLATFORM_MAPPING = Object.values(UPLOAD_CATEGORIES).reduce(
  (acc, curr) => {
    curr.resources.forEach((resource) => {
      acc.push({
        label: resource.label,
        value: resource.dbId,
      });
    });
    return acc;
  },
  [] as { label: string; value: string }[]
);
