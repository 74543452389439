import HCaptcha from "@hcaptcha/react-hcaptcha";
import axios, { AxiosError } from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useLogsnag } from "../../../hooks/logsnag";
import { useInviteIdEmail } from "../../../hooks/useAuth";
import { useNavigateWithParams } from "../../../hooks/useNavigateWithParams";
import { useSupabase } from "../../../hooks/useSupabase";
import {
  isDisposable,
  signout,
  signup,
  verifyEmail,
} from "../../../utils/auth";
import max from "..//assets/max.png";
import { ReactComponent as HeartSvg } from "../assets/heart.svg";

export const signupValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Required")
    .test(
      "isDisposable",
      "Invalid email address",
      async (value) => !(await isDisposable(value))
    ),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  password: Yup.string().required("Required").min(6),
  ltdCode: Yup.string().required("Required"),
  // terms
  checkTerms: Yup.boolean().oneOf([true], "Must Accept Terms and Conditions"),
  captchaToken: Yup.string().required("Please complete the captcha"),
});

export const StackSocialSignupPage = () => {
  const navigateWithParams = useNavigateWithParams();
  const params = useSearchParams();
  const supabase = useSupabase();

  const inviteEmail = useInviteIdEmail();

  const logsnag = useLogsnag();

  const captcha = useRef(null);

  useEffect(() => {
    signout(supabase);
  }, []);

  // axios
  const checkLtd = async (ltdCode: string) => {
    const body = {
      code: ltdCode,
    };

    const resp = await axios
      .post(`${process.env.REACT_APP_TRANSCRIPTION_URL}/check-ltd`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((error: AxiosError) => {
        throw error;
      });
    return resp.data?.valid as Boolean;
  };

  const localSignup = async ({
    email,
    password,
    firstName,
    lastName,
    captchaToken,
    ltdCode,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    captchaToken: string;
    ltdCode: string;
  }) => {
    // fetch if code exists in the LtdCode table
    const isCouponValid = await checkLtd(ltdCode);

    if (!isCouponValid) {
      alert("Invalid StackSocial Code, please try again");
      return;
    }

    const { error } = await signup({
      supabase,
      full_name: firstName + " " + lastName,
      email: email ?? "",
      password: password ?? "",
      captchaToken,
      captcha,
      ltdCode: ltdCode,
    });

    if (error) {
      alert(error?.cause ?? error?.message);
      return;
    }

    navigateWithParams("/login");
  };

  return (
    <div className="h-screen w-full bg-white font-poppins">
      <div className="flex justify-between items-center w-full h-full ">
        <div className="w-3/6 h-full relative hidden md:block">
          <div
            className="w-full h-full"
            style={{
              backgroundImage: `url(${max})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <div className="absolute top-16 text-white px-12 space-y-10">
            <p className="text-4xl leading-10 font-semibold ">Hi LTD Hunter!</p>

            <p className="text-xl leading-9 font-medium">
              Please share your name, last name <br /> and StackSocial Code to
              sign into <br />
              Unifire for lifetime access. <br /> <br />
              After your submission, you’ll <br />
              get immediate access to your account.
              <br /> <br />
              Welcome onboard 👋
              <br /> <br />
              Max & Yassine
            </p>
          </div>
        </div>
        {/* <p className="absolute top-12 right-12">
          Already have an account?{" "}
          <a
            onClick={() => navigateWithParams("/login")}
            className="text-teal-700 hover:text-teal-600 font-medium cursor-pointer"
          >
            Sign in
          </a>
        </p> */}
        <div className="w-full flex items-center justify-center ">
          <div className="w-[80%] md:w-[60%] lg:w-[60%] h-full">
            <div className="w-full text-[20px] sm:text-[30px] lg:text-[50px] font-bold mb-[120px] flex justify-around items-center space-x-6 font-inter">
              <span className="">Unifire</span>

              <div className="col-span-2">
                <HeartSvg className="w-full" />
              </div>
              <div className="">
                <span className="">StackSocial</span>
              </div>
            </div>

            <Formik
              initialValues={{
                email: inviteEmail ?? "",
                firstName: "",
                lastName: "",
                password: "",
                checkTerms: false,
                captchaToken: "",
                ltdCode: "",
              }}
              enableReinitialize
              validationSchema={signupValidationSchema}
              onSubmit={localSignup}
            >
              {({ errors, touched, ...props }) => (
                <Form>
                  {/* Email input */}
                  <div>
                    <div
                      className={`mt-6 relative ${
                        errors.email && touched.email && "border-red-500"
                      }`}
                    >
                      {inviteEmail ? (
                        <Field
                          type="email"
                          id="email_floating_outlined"
                          name="email"
                          className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm  text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                          placeholder=" "
                          defaultValue={inviteEmail}
                          value={inviteEmail}
                          onChange={props.handleChange}
                          disabled
                          focused
                        />
                      ) : (
                        <>
                          <Field
                            type="email"
                            id="email_floating_outlined"
                            name="email"
                            className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                            placeholder=" "
                          />
                        </>
                      )}
                      <label
                        htmlFor="email_floating_outlined"
                        className="hover:cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 bg-white peer-focus:bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Email Address
                      </label>
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* First name input */}
                  <div className="mt-6 flex justify-between items-center w-full space-x-4">
                    <div className="w-full">
                      <div
                        className={`w-full relative ${
                          errors.firstName &&
                          touched.firstName &&
                          "border-red-500"
                        }`}
                      >
                        <Field
                          type="text"
                          id="firstName_floating_outlined"
                          name="firstName"
                          className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                          placeholder=" "
                        />
                        <label
                          htmlFor="firstName_floating_outlined"
                          className="hover:cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 bg-white peer-focus:bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          First Name
                        </label>
                      </div>
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    {/* Last name input */}
                    <div className="w-full">
                      <div
                        className={`w-full relative ${
                          errors.lastName &&
                          touched.lastName &&
                          "border-red-500"
                        }`}
                      >
                        <Field
                          type="text"
                          id="lastName_floating_outlined"
                          name="lastName"
                          className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                          placeholder=" "
                        />
                        <label
                          htmlFor="lastName_floating_outlined"
                          className="hover:cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 bg-white peer-focus:bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          Last Name
                        </label>
                      </div>
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>

                  {/* Password input */}
                  <div>
                    <div
                      className={`mt-6 relative ${
                        errors.password && touched.password && "border-red-500"
                      }`}
                    >
                      <Field
                        type="password"
                        id="password_floating_outlined"
                        name="password"
                        className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                        placeholder=" "
                      />
                      <label
                        htmlFor="password_floating_outlined"
                        className="hover:cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 bg-white peer-focus:bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Password
                      </label>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div>
                    <div
                      className={`mt-6 relative ${
                        errors.ltdCode && touched.ltdCode && "border-red-500"
                      }`}
                    >
                      <Field
                        id="password_floating_outlined"
                        name="ltdCode"
                        className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                        placeholder=" "
                      />
                      <label
                        htmlFor="ltdCode_floating_outlined"
                        className="hover:cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 bg-white peer-focus:bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        StackSocial Code
                      </label>
                    </div>
                    <ErrorMessage
                      name="ltdCode"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* checkmark for terms of service */}
                  <div className="mt-6 flex items-start">
                    <Field
                      type="checkbox"
                      id="terms"
                      name="checkTerms"
                      className="mt-1 peer h-4 w-4 cursor-pointer border border-gray-300 rounded-md checked:bg-teal-600 checked:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent focus:ring-blue-500"
                    />
                    <label
                      htmlFor="terms"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      I agree to the{" "}
                      <a
                        className="text-[#009580] "
                        href="https://www.unifire.ai/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Unifire Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        className="text-[#009580]"
                        href="https://www.unifire.ai/terms-of-service"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Service.
                      </a>
                    </label>
                  </div>
                  <ErrorMessage
                    name="checkTerms"
                    component="div"
                    className="text-red-500 text-sm"
                  />

                  <div className="w-full flex justify-center items-center">
                    <div>
                      <div className="mt-6 relative">
                        <HCaptcha
                          ref={captcha}
                          sitekey={process.env.REACT_APP_CAPTCHA_KEY as string}
                          onVerify={(token) => {
                            props.setFieldValue("captchaToken", token);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="captchaToken"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>

                  {/* Submit button */}
                  <button
                    type="submit"
                    className="w-full block bg-uf-yellow-1 text-[#121217] rounded-xl px-4 py-3 mt-8 text-sm leading-5 font-semibold"
                  >
                    Let me in for life!
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StackSocialSignupPage;
