export const SmallBinIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.75 3.75L3.59115 13.4233C3.68102 14.4568 4.54622 15.25 5.58363 15.25H10.4164C11.4538 15.25 12.319 14.4568 12.4088 13.4233L13.25 3.75M5.75 3.5V2.75C5.75 1.64543 6.64543 0.75 7.75 0.75H8.25C9.35457 0.75 10.25 1.64543 10.25 2.75V3.5M1 3.75H15"
      stroke="#F53D6B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
