import { Route, Routes } from "react-router-dom";
import { OnboardingPage } from "./components/OnboardingPage";

export const Onboarding = () => {
  return (
    <Routes>
      <Route path="/onboarding" element={<OnboardingPage />} />
    </Routes>
  );
};

export default Onboarding;
