import { AxiosError } from "axios";
import classNames from "classnames";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSupabase } from "../../../../../hooks/useSupabase";
import { LibraryModal } from "../../../../../shared/Library/Modal";
import GenerateAssetsFlow from "../../../../GenerateAssetsFlow";
import { submitAssets } from "../../../../EditableTranscript/utils";
import { TemplateDbType } from "../../../../../types";

export const GenerateMoreButton = ({
  assetId,
  disable,
}: {
  assetId: string | undefined;
  disable: boolean;
}) => {
  const [show, setShow] = useState(false);

  const { id: uploadId } = useParams<{ id: string }>();
  // supabase
  const supabase = useSupabase();

  const handleSubmit = async (
    customInstruction: string,
    selectedTemplates: TemplateDbType[]
  ) => {
    if (!uploadId || !assetId) {
      return;
    }
    const templateIds = selectedTemplates.map((t) => t.id);
    try {
      await submitAssets({
        episodeId: uploadId,
        assetId,
        customInstruction,
        supabase,
        templateIds,
      });
      window.location.reload();
      setShow(false);
      // setStep(3);
    } catch (error: any) {
      alert((error as AxiosError)?.message);
    }
  };

  return (
    <>
      {/* <button
        className={classNames(
          "text-sm leading-5 font-medium text-[#121217] py-[8px] px-[54px] rounded-lg bg-uf-yellow-1 disabled:bg-gray-100 w-full text-center md:min-w-[200px] disabled:text-white disabled:cursor-not-allowed transition-all duration-300"
        )}
        disabled={disable}
        onClick={() => {
          setShow(true);
        }}
      >
        Generate More
      </button> */}

      <LibraryModal setShow={setShow} show={show}>
        <GenerateAssetsFlow
          assetIds={[assetId as string]}
          uploadId={uploadId as string}
          noDbRecordCreation
          onConfirmAdditionalGenerations={(asset, selectedTemplates) => {
            handleSubmit(asset[0].instructions || "", selectedTemplates);
          }}
        />
      </LibraryModal>
    </>
  );
};
