import { createClient } from "@supabase/supabase-js";
import React, { ReactElement } from "react";
import { Database } from "../types/database.types";

const supabase = createClient<Database>(
  process.env.REACT_APP_SUPABASE_URL as string,
  process.env.REACT_APP_SUPABASE_SECRET as string,
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
    },
  }
);
export const SupabaseContext = React.createContext(supabase);

// Provider component that wraps your app and makes an API call to fetch remaining credits
export const SupabaseProvider = ({ children }: { children: ReactElement }) => {
  return (
    <SupabaseContext.Provider value={supabase}>
      {children}
    </SupabaseContext.Provider>
  );
};
