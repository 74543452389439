import { useState } from "react";
import { LibraryModal } from "../../../shared/Library/Modal";

export const CancellationFeedbackModal = ({
  isOpen,
  onClose,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { reason: string; otherReason: string }) => void;
}) => {
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");

  const handleSubmit = () => {
    if (
      reason?.toLowerCase() === "other" &&
      !Boolean(otherReason.trim()?.length < 5)
    ) {
      alert("Please specify a valid reason for cancellation");
      return;
    }
    onSubmit({ reason, otherReason });
    onClose();
  };

  return (
    <LibraryModal show={isOpen} setShow={onClose}>
      <div className="p-6">
        <h2 className="text-xl font-semibold mb-4">
          We're sorry to see you go
        </h2>
        <p className="mb-4">Please tell us why you're cancelling:</p>
        <div className="space-y-2">
          {[
            "Expensive",
            "Not enough Generations",
            "Did not find what I was looking for",
            "Bad quality output",
            "Other",
          ].map((option) => (
            <div key={option} className="flex items-center">
              <input
                type="radio"
                id={option}
                name="cancellationReason"
                value={option}
                checked={reason === option}
                onChange={() => setReason(option)}
                className="mr-2"
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </div>
        {reason === "Other" && (
          <textarea
            className="w-full mt-2 p-2 border rounded"
            placeholder="Please specify..."
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
          />
        )}
        <div className="mt-6 flex justify-end">
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Submit and Cancel
          </button>
        </div>
      </div>
    </LibraryModal>
  );
};
