import classNames from "classnames";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as Yup from "yup";
import { useSupabase } from "../../../hooks/useSupabase";
import { ArrowRightIcon } from "../../../shared/Icons/ArrowRight";
import { AvatarIcon } from "../../../shared/Icons/Avatar";
import { CheckmarkIcon } from "../../../shared/Icons/Checkmark";
import LoadingLottie from "../../../shared/Lottie/LoadingLottie";
import { isDisposable, verifyEmail } from "../../../utils/auth";
import { sendInviteMember } from "../../UploadContent/util";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Required")
    .test(
      "isDisposable",
      "Invalid email address",
      async (value) => !(await isDisposable(value))
    ),
});

export const InviteMemberField = ({
  shouldInvite,
  createdWorkspaceId,
  onDone,
  disabled,
}: {
  shouldInvite: boolean;
  createdWorkspaceId: string | undefined;
  onDone: (didActuallySendEmail?: boolean) => void;
  disabled?: boolean;
}) => {
  const [email, setEmail] = useState("");
  const [didActuallyInvite, setDidActuallyInvite] = useState(false);
  const [status, setStatus] = useState<"idle" | "loading" | "success">("idle");
  const supabase = useSupabase();

  const onInvite = async () => {
    try {
      await schema.validate({ email });
      setStatus("loading");
      // Simulate an API call
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      setDidActuallyInvite(true);
      await sendInviteMember({
        email: email.toLowerCase(),
        role: "admin",
        workspaceId: createdWorkspaceId as string,
        supabase: supabase,
      });
      setStatus("success");
    } catch (error) {}
  };

  useEffect(() => {
    if (shouldInvite && createdWorkspaceId) {
      onInvite().then(() => {
        setTimeout(() => {
          onDone(didActuallyInvite);
        }, 5000);
      });
      return;
    }

    onDone(didActuallyInvite);
  }, [shouldInvite, createdWorkspaceId]);

  return (
    <div>
      <span className="text-14 font-medium">Invite member</span>
      <div
        className={`mt-2 flex items-center max-h-[38px] overflow-clipborder border rounded-lg overflow-hidden ${
          status !== "idle" && "cursor-not-allowed"
        }`}
      >
        <div
          className={classNames(
            "flex justify-start items-center space-x-2 pl-2 w-full",
            {
              "bg-gray-100 pointer-events-none": disabled,
            }
          )}
        >
          <AvatarIcon className="w-6 h-6" color="#8A8AA3" />
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            formNoValidate
            placeholder="my@email.com"
            className={classNames(
              "appearance-none text-14 border-none placeholder:text-[#6C6C89] rounded-none w-full py-2.5 leading-tight focus:outline-none focus:shadow-outline bg-transparent",
              {
                "text-[#121217]": status !== "success",
                "text-[#46D5B3]": status === "success",
                "bg-gray-100": disabled,
              }
            )}
            disabled={status !== "idle" || disabled}
          />
        </div>

        <div
          className={classNames(
            `border-l border-l-[#EBEBEF] text-[#121217] px-4 py-2 w-[97px] text-center text-sm leading-5 font-semibold transition ease-in-out duration-150`,
            {
              "cursor-pointer bg-gray-100": disabled,
            }
          )}
        >
          {status === "loading" ? (
            <div className="-m-5">
              <Lottie
                options={{
                  animationData: LoadingLottie,
                  autoplay: true,
                  loop: true,
                }}
              />
            </div>
          ) : status === "success" ? (
            <div className="flex justify-start items-center space-x-1 text-[#46D5B3] w-full">
              <div>
                {" "}
                <CheckmarkIcon className="w-3 h-3" color="#46D5B3" />{" "}
              </div>
              <span className="text-[#46D5B3">Success</span>
            </div>
          ) : (
            <div
              className={classNames(
                "flex justify-center items-center space-x-2"
              )}
            >
              <div>
                {" "}
                <ArrowRightIcon className="w-6 h-6" color="#8A8AA3" />
              </div>
              <span className="text-[#121217] font-medium text-14">Invite</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
