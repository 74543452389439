export const GermanFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_31873)">
      <path
        d="M0.746277 16.1753C2.44179 20.7448 6.84031 24.0014 11.9999 24.0014C17.1595 24.0014 21.558 20.7448 23.2535 16.1753L11.9999 15.1318L0.746277 16.1753Z"
        fill="#FFDA44"
      />
      <path
        d="M11.9999 0.000976562C6.84031 0.000976562 2.44179 3.25748 0.746277 7.82709L11.9999 8.87052L23.2535 7.82704C21.558 3.25748 17.1595 0.000976562 11.9999 0.000976562Z"
        fill="black"
      />
      <path
        d="M0.746452 7.82617C0.264108 9.12616 6.10352e-05 10.5322 6.10352e-05 12.0001C6.10352e-05 13.4679 0.264108 14.874 0.746452 16.174H23.2537C23.7361 14.874 24.0001 13.4679 24.0001 12.0001C24.0001 10.5322 23.7361 9.12616 23.2537 7.82617H0.746452Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_31873">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
