import { SupabaseClient } from "@supabase/supabase-js";
import axios from "axios";
import { AssetDbType, FileDbType, UploadDbType } from "../../../types";
import { Database } from "../../../types/database.types";

export const pollUploadWithId = async (
  supabase: SupabaseClient<Database>,
  uploadId: string,
  setData: (
    ep: UploadDbType,
    assets: AssetDbType[],
    files: FileDbType[]
  ) => void
) => {
  const upload = await supabase
    .from("Upload")
    .select(`*, Asset(*), File(*)`) // add podcast
    .eq("id", uploadId)
    .limit(1)
    .single();

  if (upload.error) {
    setTimeout(() => pollUploadWithId(supabase, uploadId, setData), 500);
  } else if (upload.data?.status === "transcribing") {
    setData(
      upload.data as UploadDbType,
      upload.data?.Asset as AssetDbType[],
      upload.data.File
    );
    setTimeout(() => pollUploadWithId(supabase, uploadId, setData), 1000);
  } else if (upload.data?.status === "writing") {
    setData(
      upload.data as UploadDbType,
      upload.data?.Asset as AssetDbType[],
      upload.data.File
    );
    setTimeout(() => pollUploadWithId(supabase, uploadId, setData), 1000);
  } else {
    setData(
      upload.data as UploadDbType,
      upload.data?.Asset as AssetDbType[],
      upload.data.File
    );
  }
};

export const submitAssets = async ({
  episodeId,
  assetId,
  customInstruction,
  supabase,
  outlineId,
  templateIds,
}: {
  episodeId: string;
  assetId: string;
  customInstruction: string;
  supabase: any;
  outlineId?: string;
  templateIds: string[];
}) => {
  // construct a body object
  const body = {
    episodeId,
    assetId,
    customInstruction,
    outlineId,
    templateIds,
  };

  const session = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/rewrite-assets`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};
