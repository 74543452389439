import Skeleton from "react-loading-skeleton";

export const SkeletonEpisodes = ({ number = 10 }: { number?: number }) => {
  // generate dummy array
  const arr = Array.from({ length: number }, (_, i) => i + 1);
  // console.log("arr ", arr);
  return (
    <>
      {arr.map((_, i) => (
        <button
          key={i}
          className="w-full rounded-lg overflow-clip flex bg-white "
          onClick={() => {}}
        >
          <div className="w-full flex justify-between items-center px-3 font-medium text-14 h-9 ">
            <p className="text-center align-middle break-all p-4 text-[#202020] w-full ">
              <Skeleton className="w-full h-9 " />
            </p>

            <span
              className={` px-4 py-2 text-uf-slate-1 w-full max-w-[100px] `}
            >
              <Skeleton className="w-full h-9 " />
            </span>
          </div>
        </button>
      ))}
    </>
  );
};
