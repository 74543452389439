import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSupabase } from "../../../hooks/useSupabase";
import { confirmResetPwd, isPasswordValid } from "../../../utils/auth";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, "Password must be at least 5 characters")
    .required("Required"),
  confirmedPassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Required"),
});

export const ResetPwdPage = () => {
  const navigate = useNavigate();
  const supabase = useSupabase();
  const updateUser = async (
    { password }: { password: string },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    const err = await confirmResetPwd(supabase, password);
    if (err.error?.message) {
      alert(err.error.message);
      setSubmitting(false);
      return;
    }
    navigate("/dashboard/overview");
  };
  return (
    <div>
      <section className="flex flex-col h-screen items-center">
        <div
          className=" w-full md:max-w-md lg:max-w-full md:mx-auto  md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12
      flex items-center justify-center"
        >
          <div className="w-full h-100">
            <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
              Reset Password
            </h1>

            <Formik
              initialValues={{
                password: "",
                confirmedPassword: "",
              }}
              enableReinitialize
              validationSchema={loginValidationSchema}
              onSubmit={(data, { setSubmitting }) =>
                updateUser(data, setSubmitting)
              }
            >
              {({ errors, touched, isSubmitting, ...props }) => (
                <Form>
                  <div className="mt-12">
                    <div className="mt-4">
                      <label className="block text-gray-700">
                        New password
                      </label>
                      <input
                        type="text"
                        name="password"
                        min={2}
                        placeholder="New Password"
                        className="w-full px-4 py-3 rounded-lg  mt-2 border focus:border-blue-500
              focus:bg-white focus:outline-none"
                        required
                        onChange={(e) => {
                          props.setFieldValue(
                            "password",
                            e.currentTarget.value
                          );
                        }}
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div className="mt-8">
                      <label className="block text-gray-700">
                        Confirm password
                      </label>
                      <input
                        type="text"
                        name="confirmedPassword"
                        min={2}
                        placeholder="Confirm Password"
                        className="w-full px-4 py-3 rounded-lg  mt-2 border focus:border-blue-500
              focus:bg-white focus:outline-none"
                        required
                        onChange={(e) => {
                          props.setFieldValue(
                            "confirmedPassword",
                            e.currentTarget.value
                          );
                        }}
                      />
                      <ErrorMessage
                        name="confirmedPassword"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="w-full block bg-uf-yellow-1 text-gray-700 font-semibold rounded-lg
            px-4 py-3 mt-8"
                    disabled={isSubmitting}
                  >
                    Reset password
                  </button>
                </Form>
              )}
            </Formik>

            <hr className="my-6 border-gray-300 w-full" />

            <p className="mt-8">
              Remember your password?{" "}
              <a href="/login" className="text-teal-600 font-semibold">
                Back to login
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ResetPwdPage;
