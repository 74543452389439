import { useNavigate } from "react-router-dom";
import { BackIcon } from "../../../shared/Icons/Back";
import ChatInterface from "./ChatInterface";

export const TemplateBuilder = () => {
  const navigate = useNavigate();
  return (
    <div className="animate-slide-in px-10">
      <div className="w-full flex justify-center items-center mt-8">
        <div className="max-w-[192px] flex flex-col justify-center space-y-1 w-full">
          <p className="text-sm text-[#6C6C89] font-medium text-center">
            Generate new template
          </p>
          <div className="w-full h-1 rounded-lg bg-uf-green-1" />
        </div>
      </div>
      <button className="mt-3" onClick={() => navigate("/dashboard/templates")}>
        <BackIcon />
      </button>
      <div className="">
        <ChatInterface />
      </div>
    </div>
  );
};
