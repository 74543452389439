import GenerateAssetsFlow from "../../../GenerateAssetsFlow";
import { AssetProgressType } from "../../../GenerateAssetsFlow/components/GenerateAssetsFlow";

export const GenerateAssetsPage = ({
  selectedAssetIds,
  uploadId,
  onConfirmAssets,
}: {
  selectedAssetIds: string[];
  uploadId: string | undefined;
  onConfirmAssets: (
    assets: AssetProgressType[],
    skipTranscript: boolean
  ) => void;
}) => {
  //

  return (
    <div className="absolute inset-0 flex justify-center items-start z-10 bg-white overflow-x-hidden">
      <div className="flex flex-col justify-center items-center pt-5">
        {/* <button className="absolute top-10 left-10">
          <BackIcon width={24} height={24} />
        </button> */}
        <GenerateAssetsFlow
          onConfirmAssets={(assets, skipTranscript) =>
            onConfirmAssets(assets, skipTranscript)
          }
          assetIds={selectedAssetIds}
          uploadId={uploadId as string}
        />
      </div>
    </div>
  );
};
