import classNames from "classnames";
import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import * as Yup from "yup";
import { useSupabase } from "../../../hooks/useSupabase";
import { Workspace } from "../../../hooks/useWorkspace";
import { ReactComponent as ArrowDownSvg } from "../../Dashboard/assets/arrow-down.svg";
import { LoadingScreen } from "../../Episode/components/FlowComponents/LoadingAsset/LoadingScreen";
import { InviteMemberField } from "./InviteMemberField";
import { useRemainingCredits } from "../../../hooks/useRemainingCredits";

const validationSchema = Yup.object().shape({
  memberEmail: Yup.string().email("Invalid email").required("Required"),
});

const SelectRole = ({
  handleRoleChange,
  currentRole,
}: {
  handleRoleChange: (role: string) => void;
  currentRole: string;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-28 text-start">
      <div>
        <button
          type="button"
          className={classNames(
            "transition-all overflow-hidden ease flex w-full justify-around items-center gap-x-1.5 rounded-lg bg-white px-3 py-2 border border-1 border-gray-300/60 text-sm text-gray-900  ",
            {
              "delay-200 duration-100": !isOpen,
              "border-b-0 rounded-b-none duration-100": isOpen,
            }
          )}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          {currentRole}{" "}
          <ArrowDownSvg
            className={classNames({
              "transform rotate-180": isOpen,
            })}
          />
        </button>
      </div>
      <div
        className={classNames(
          "transition-all duration-300 ease z-10 w-full origin-top-right rounded-2xl bg-white focus:outline-none",
          {
            "h-[0px] overflow-hidden rounded-t-none": !isOpen,
            "h-[80px] overflow-auto border border-1 border-t-0 border-gray-300/60 rounded-t-none":
              isOpen,
          }
        )}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
      >
        <div className="py-1 " role="none">
          <button
            type="button"
            className="w-full text-start px-4 py-2 text-14 hover:bg-gray-100 focus:bg-gray-100"
            role="menuitem"
            onClick={() => {
              handleRoleChange("admin");
              setIsOpen(false);
            }}
          >
            admin
          </button>

          <button
            type="button"
            className="w-full text-start px-4 py-2 text-14 hover:bg-gray-100 focus:bg-gray-100"
            role="menuitem"
            onClick={() => {
              handleRoleChange("reader");
              setIsOpen(false);
            }}
          >
            reader
          </button>

          <button
            type="button"
            className="w-full text-start px-4 py-2 text-14 hover:bg-gray-100 focus:bg-gray-100"
            role="menuitem"
            onClick={() => {
              handleRoleChange("editor");
              setIsOpen(false);
            }}
          >
            editor
          </button>
        </div>
      </div>
    </div>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "6px",
    zIndex: 51,
  },
};

Modal.setAppElement("#root");
const InviteMemeberModal = ({
  workspace,
  setShowSharingForm,
  showSharingForm,
  onInviteSent,
  userId,
}: {
  workspace: Workspace;
  setShowSharingForm: React.Dispatch<React.SetStateAction<boolean>>;
  showSharingForm: boolean;
  onInviteSent: () => void;
  userId: string;
}) => {
  const supabase = useSupabase();

  const [progressTitle, setProgressTitle] = useState("");
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  const [selectedRole, setSelectedRole] = useState("reader");

  const [inviteOneDone, setInviteOneDone] = useState(false);
  const [inviteTwoDone, setInviteTwoDone] = useState(false);
  const [inviteThreeDone, setInviteThreeDone] = useState(false);
  const [inviteFourDone, setInviteFourDone] = useState(false);
  const [shouldInvite, setShouldInvite] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const ownerGeneralLimits = useRemainingCredits()?.ownerGeneralLimits;

  const finalizeModal = () => {
    setTimeout(() => {
      if (inviteOneDone && inviteTwoDone && inviteThreeDone && inviteFourDone) {
        setShowSharingForm(false);
        window.location.reload();
      }
    }, 0); // force next rendering cycle since we check the state right after assigning it
  };

  // create an array from allowed to not allowed, aka true to false, demending on how many invites the user has left
  const allowedToInvite = Array.from(
    {
      length:
        ownerGeneralLimits?.teamMembers.find(
          (teamMember) => teamMember.workspaceId === workspace.id
        )?.remainingMembers || 0,
    },
    (_, i) => i < ownerGeneralLimits?.teamMembers.length
  );

  return (
    <>
      <Modal
        isOpen={showSharingForm}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setShowSharingForm(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className="relative max-w-[300px] md:min-w-[909px] min-h-[300px] md:px-8 pb-20 pt-10"
          role="document"
        >
          {showLoadingScreen ? (
            <LoadingScreen displayText={progressTitle} />
          ) : (
            <>
              <div className="text-center space-y-3">
                <p className="block w-full text-xl leading-8 text-gray-900 font-semibold">
                  Invite Team Members
                </p>
                <p className="text-lg leading-6 text-[#6C6C89]">
                  Collaborate on your content with <br />
                  as many team members as you need
                </p>
              </div>

              <div className="mt-[55px]">
                <div className="mt-9 space-y-5">
                  <InviteMemberField
                    shouldInvite={shouldInvite}
                    createdWorkspaceId={workspace.id}
                    disabled={!allowedToInvite?.[0]}
                    onDone={() => {
                      setInviteOneDone(true);
                      finalizeModal();
                    }}
                  />

                  <InviteMemberField
                    shouldInvite={shouldInvite}
                    createdWorkspaceId={workspace.id}
                    disabled={!allowedToInvite?.[1]}
                    onDone={() => {
                      setInviteTwoDone(true);
                      finalizeModal();
                    }}
                  />

                  <InviteMemberField
                    shouldInvite={shouldInvite}
                    createdWorkspaceId={workspace.id}
                    disabled={!allowedToInvite?.[2]}
                    onDone={() => {
                      setInviteThreeDone(true);
                      finalizeModal();
                    }}
                  />

                  <InviteMemberField
                    shouldInvite={shouldInvite}
                    createdWorkspaceId={workspace.id}
                    disabled={!allowedToInvite?.[3]}
                    onDone={() => {
                      setInviteFourDone(true);
                      finalizeModal();
                    }}
                  />
                </div>
              </div>

              <div className=" mt-20 w-full flex justify-end items-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="max-w-[254px] disabled:bg-gray-100 bg-uf-yellow-1 py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline w-full text-xs leading-6 font-semibold text-gray-800"
                  onClick={() => {
                    setShouldInvite(true);
                    setIsSubmitting(true);
                  }}
                >
                  Invite Team Members
                </button>
              </div>
            </>
          )}
        </div>
        <Tooltip
          style={{ backgroundColor: "#31d0AA", color: "#222" }}
          place="bottom"
          isOpen={openTooltip}
          variant="success"
          anchorSelect=".my-share-element"
          id="registerTipTwo"
          afterShow={() => {
            setTimeout(() => {
              setOpenTooltip(false);
            }, 1000);
          }}
        >
          Copied!
        </Tooltip>
      </Modal>
    </>
  );
};

export default InviteMemeberModal;
