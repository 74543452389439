import "react-dropzone-uploader/dist/styles.css";

import SeatsPricingToolTip from "../../Pricing/components/SeatsPricingToolTip";

export const WPPremiumPlan = ({ isMonthly }: { isMonthly: boolean }) => {
  const displayPrice = isMonthly ? 28 : 23;

  return (
    <>
      <div className="relative w-full h-[778px] overflow-hidden bg-white rounded-md border border-black p-6 justify-self-center">
        <span className="absolute top-2 right-2 bg-[#17663A]/5 text-[#17663A] border border-[#17663A]/30 font-semibold text-12 px-2.5 py-0.5 rounded">
          Most popular
        </span>{" "}
        <div className="text-16 font-inter font-semibold ">Premium</div>
        <p className="mt-2 text-14 font-medium text-[#84818A] h-[63px]">
          For content teams in need with the most advanced AI models,
          transcription quality and collaboration.
        </p>
        <div className="mt-7">
          <p className="text-[#84818A] text-16 font-semibold ">
            <span className="text-[#000000] text-4xl leading-10 font-semibold">
              €{displayPrice}
            </span>
            <span className="text-[#84818A] font-semibold text-16">/mo</span>
          </p>
          <div className="mt-4">
            <SeatsPricingToolTip price={displayPrice} />
          </div>

          <div className="h-[100px]" />

          <div className="mt-6 flex justify-center">
            <button
              className={`w-full py-2 font-semibold text-14 rounded-lg focus:outline-none focus:shadow-outline-orange bg-uf-yellow-1 text-[#202020]`}
              onClick={async () => {
                //    hreft to app.unifire.ai/signup
                window.parent.location.href = "https://app.unifire.ai/signup";
              }}
            >
              Start your 14-day trial
            </button>
          </div>
        </div>
        <hr className="h-px bg-[#DCDBDD] mt-[36px] mb-[26px]" />
        <div className="text-gray-800 space-y-5">
          <p className="text-14 text-[#202020] font-semibold">
            Everything in Free Forever, plus:
          </p>

          <p className="text-14 font-medium text-[#42474B]">
            Highest Quality Transcription
          </p>
          <p className="text-14 font-medium text-[#42474B]">
            Highest AI Model Quality
          </p>
          <p className="text-14 font-medium text-[#42474B]">
            10h Upload length / upload
          </p>
          <p className="text-14 font-medium text-[#42474B]">
            1 Million Characters uploaded / month, e.g. transcripts, documents,
            audio & video
          </p>

          <p className="text-14 font-medium text-[#42474B]">
            Real-time Collaboration & Editing
          </p>
        </div>
      </div>
    </>
  );
};

export default WPPremiumPlan;
