export const ApplePodcastIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4461_11390)">
      <path
        d="M15.8984 20H4.10156C1.83996 20 0 18.16 0 15.8984V4.10156C0 1.83996 1.83996 0 4.10156 0H15.8984C18.16 0 20 1.83996 20 4.10156V15.8984C20 18.16 18.16 20 15.8984 20Z"
        fill="#DD86F2"
      />
      <path
        d="M15.8984 0H10V20H15.8984C18.16 20 20 18.16 20 15.8984V4.10156C20 1.83996 18.16 0 15.8984 0Z"
        fill="#CD67E5"
      />
      <path
        d="M12.053 13.3022L11.6908 16.2001C11.6175 16.7865 11.119 17.2266 10.528 17.2266H9.47203C8.88102 17.2266 8.3825 16.7865 8.30922 16.2L7.94699 13.3022C7.79258 12.0673 8.75547 10.9766 10 10.9766C9.03074 10.9766 8.24219 10.188 8.24219 9.21875C8.24219 8.24949 9.03074 7.46094 10 7.46094C10.9693 7.46094 11.7578 8.24949 11.7578 9.21875C11.7578 10.188 10.9693 10.9766 10 10.9766C11.2445 10.9766 12.2074 12.0673 12.053 13.3022ZM10 6.28906C8.16918 6.28906 6.67969 7.77855 6.67969 9.60938C6.67969 10.2693 6.87398 10.8843 7.20723 11.4017C6.9677 11.8087 6.8202 12.2633 6.77457 12.7326C5.99094 11.9236 5.50781 10.822 5.50781 9.60938C5.50781 7.13238 7.52301 5.11719 10 5.11719C12.477 5.11719 14.4922 7.13238 14.4922 9.60938C14.4922 10.822 14.0091 11.9236 13.2255 12.7327C13.1798 12.2633 13.0323 11.8088 12.7928 11.4017C13.126 10.8843 13.3203 10.2693 13.3203 9.60938C13.3203 7.77855 11.8308 6.28906 10 6.28906ZM12.9234 15.7878L13.1037 14.3449C14.6446 13.3316 15.6641 11.5875 15.6641 9.60938C15.6641 6.48621 13.1232 3.94531 10 3.94531C6.87684 3.94531 4.33594 6.48621 4.33594 9.60938C4.33594 11.5875 5.35543 13.3316 6.89629 14.3449L7.07664 15.7878C4.76578 14.69 3.16406 12.3334 3.16406 9.60938C3.16406 5.84004 6.23066 2.77344 10 2.77344C13.7693 2.77344 16.8359 5.84004 16.8359 9.60938C16.8359 12.3334 15.2342 14.69 12.9234 15.7878Z"
        fill="white"
      />
      <path
        d="M10 10.9766V17.2266H10.528C11.119 17.2266 11.6175 16.7865 11.6908 16.2L12.053 13.3022C12.2074 12.0673 11.2445 10.9766 10 10.9766Z"
        fill="#F2F2F2"
      />
      <path
        d="M11.7578 9.21875C11.7578 8.24949 10.9693 7.46094 10 7.46094V10.9766C10.9693 10.9766 11.7578 10.188 11.7578 9.21875Z"
        fill="#F2F2F2"
      />
      <path
        d="M10 2.77344V3.94531C13.1232 3.94531 15.6641 6.48621 15.6641 9.60938C15.6641 11.5875 14.6446 13.3316 13.1037 14.3449L12.9234 15.7878C15.2342 14.69 16.8359 12.3334 16.8359 9.60938C16.8359 5.84004 13.7693 2.77344 10 2.77344Z"
        fill="#F2F2F2"
      />
      <path
        d="M13.2255 12.7327C14.0091 11.9236 14.4922 10.822 14.4922 9.60938C14.4922 7.13238 12.477 5.11719 10 5.11719V6.28906C11.8308 6.28906 13.3203 7.77855 13.3203 9.60938C13.3203 10.2693 13.126 10.8843 12.7928 11.4017C13.0323 11.8087 13.1798 12.2633 13.2255 12.7327Z"
        fill="#F2F2F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_4461_11390">
        <rect width="20" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
