import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useSupabase } from "../../hooks/useSupabase";
import GenerationFlowManager from "../DashboardOverview";
import Episode from "../Episode";
import Pricing from "../Pricing";
import Project from "../Project";
import Settings from "../Settings";
import TeamPage from "../Team";
import { TemplateBuilder } from "../Templates/components/TemplateBuilder";
import TemplateDetails from "../Templates/components/TemplateDetails";
import { TemplatesOverview } from "../Templates/components/TemplatesOverview";
import Senja from "../Testimonial";
import DashboardPage from "./components/DashboardPage";

export const Dashboard = () => {
  // identify user here too
  const { identifyUser } = useAnalytics();
  const navigate = useNavigate();
  const supabase = useSupabase();
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (!session) return;

      supabase
        .from("UserProfile")
        .select("*, Billing(*)")
        .eq("id", session.user.id)
        .limit(1)
        .single()
        .then(({ data, error }) => {
          if (error) {
            console.error(error);
            return;
          }
          if (data) {
            identifyUser(data.id);
            // redirect to trial if not confirmed
            // only do this if the url doesn't already have /trial

            if (
              data.Billing?.trial_confirmed === false &&
              !window.location.href.includes("/share") &&
              !window.location.href.includes("/trial")
            )
              window.location.href = "/trial";
          }
        });
    });
  }, [supabase, identifyUser, supabase.auth]);

  return (
    <Routes>
      <Route path="dashboard" element={<DashboardPage />}>
        <Route path="overview" element={<GenerationFlowManager />} />
        <Route path="project/:id" element={<Project />} />
        <Route path="project/:projectId/generation/:id" element={<Episode />} />
        <Route path="billing" element={<Pricing />} />
        {/* settings */}
        <Route path="settings" element={<Settings />} />
        <Route path="team" element={<TeamPage />} />
        <Route path="testimonial" element={<Senja />} />

        <Route path="template/create" element={<TemplateBuilder />} />
        <Route path="templates" element={<TemplatesOverview />} />
        <Route path="template/:id" element={<TemplateDetails />} />
        {/* <Route
          path="project/:projectId/transcript/:id"
          element={<EditableTranscript />}
        /> */}

        {/* <Route path="onboarding" element={<OnboardingPage />} /> */}
      </Route>
    </Routes>
  );
};

export default Dashboard;
