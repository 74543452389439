import React from "react";
import { Route, Routes } from "react-router-dom";
import WPPricingPage from "./components/WPPricingPage";

export const WPPricing = () => {
  return (
    <Routes>
      <Route path="/public/pricing" element={<WPPricingPage />} />
    </Routes>
  );
};

export default WPPricing;
