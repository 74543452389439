export const PortugueseFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_32487)">
      <path
        d="M-0.000396729 12.0007C-0.000396729 17.1602 3.2561 21.5587 7.82562 23.2542L8.86915 12.0006L7.82562 0.74707C3.2561 2.44263 -0.000396729 6.8411 -0.000396729 12.0007Z"
        fill="#6DA544"
      />
      <path
        d="M24.0001 12C24.0001 5.37262 18.6275 0 12.0001 0C10.5323 0 9.12617 0.264047 7.82614 0.746391V23.2536C9.12617 23.736 10.5323 24 12.0001 24C18.6275 24 24.0001 18.6274 24.0001 12Z"
        fill="#D80027"
      />
      <path
        d="M7.82623 16.1749C10.1314 16.1749 12.0001 14.3062 12.0001 12.001C12.0001 9.69586 10.1314 7.82715 7.82623 7.82715C5.52106 7.82715 3.65234 9.69586 3.65234 12.001C3.65234 14.3062 5.52106 16.1749 7.82623 16.1749Z"
        fill="#FFDA44"
      />
      <path
        d="M5.47775 9.91309V12.5218C5.47775 13.8184 6.52888 14.8696 7.82558 14.8696C9.12229 14.8696 10.1734 13.8185 10.1734 12.5218V9.91309H5.47775Z"
        fill="#D80027"
      />
      <path
        d="M7.82623 13.3036C7.3947 13.3036 7.04361 12.9525 7.04361 12.521V11.4775H8.60881V12.521C8.60881 12.9525 8.25772 13.3036 7.82623 13.3036Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_32487">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
