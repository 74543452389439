import { ErrorMessage, Field, Form, Formik } from "formik";
import { useRef, useState } from "react";
import * as Yup from "yup";
import { useNavigateWithParams } from "../../../hooks/useNavigateWithParams";
import { useSupabase } from "../../../hooks/useSupabase";
import Notification from "../../../ui/Notification";
import { sleep } from "../../../utils";
import { sendResetPwd } from "../../../utils/auth";
import portraitImg from "../../Login/assets/portraitImg.jpeg";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  captchaToken: Yup.string().required("Please complete the captcha"),
});

export const ResetPwdPage = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const navigateWithParams = useNavigateWithParams();
  const supabase = useSupabase();
  const captcha = useRef(null);

  const successFlow = async () => {
    setShowSuccess(true);
    await sleep(4000);
    setShowSuccess(false);
  };

  const sendResetEmail = async (
    {
      email,
      captchaToken,
    }: {
      email: string;
      captchaToken: string;
    },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(true);
    const resp = await sendResetPwd(supabase, email, captchaToken);
    if (resp.error?.message) {
      alert(resp.error.message);
      setSubmitting(false);
      return;
    }
    (captcha?.current as any)?.resetCaptcha();
    await successFlow();
  };

  return (
    <div className="h-screen w-full bg-white font-poppins">
      <div className="flex justify-between items-center w-full h-full ">
        <div className="w-3/6 h-full relative hidden md:block">
          <div
            className="w-full h-full"
            style={{
              backgroundImage: `url(${portraitImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <span className="absolute bottom-24 text-white text-24 font-medium px-12 italic">
            "I went from posting inconsistently to now creating 40+ Social posts
            a week with Unifire" -
            <span className="not-italic">Max Gutsche</span>
          </span>
        </div>
        <p className="absolute top-12 right-12">
          Remember your password?{" "}
          <a
            onClick={() => navigateWithParams("/login")}
            className="text-teal-700 hover:text-teal-600 font-medium"
          >
            Sign in
          </a>
        </p>
        <div className="w-full flex items-center justify-center ">
          <div className="w-[80%] md:w-[60%] lg:w-[40%] h-full">
            <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
              Reset your password
            </h1>

            <Formik
              initialValues={{
                email: "",
                firstName: "",
                lastName: "",
                password: "",
                captchaToken: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting }) =>
                sendResetEmail(data, setSubmitting)
              }
            >
              {({ errors, touched, isSubmitting, ...props }) => (
                <Form>
                  {/* Email input */}
                  <div>
                    <div
                      className={`mt-6 relative ${
                        errors.email && touched.email && "border-red-500"
                      }`}
                    >
                      <Field
                        type="email"
                        id="email_floating_outlined"
                        name="email"
                        className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                        placeholder=" "
                      />
                      <label
                        htmlFor="email_floating_outlined"
                        className="hover:cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 bg-white peer-focus:bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Email Address
                      </label>
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="w-full flex justify-center items-center">
                    <div>
                      <div className="mt-6 relative">
                        <HCaptcha
                          ref={captcha}
                          sitekey={process.env.REACT_APP_CAPTCHA_KEY as string}
                          onVerify={(token) => {
                            props.setFieldValue("captchaToken", token);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="captchaToken"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="mt-6 w-full block bg-teal-600 hover:bg-teal-500 text-white font-semibold rounded-xl px-4 py-3"
                    disabled={isSubmitting}
                  >
                    Reset Password
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Notification
        title={"Email sent, please check your mailbox"}
        display={showSuccess}
        onConfirm={() => {}}
        type="casual"
      />
    </div>
  );
};
