import { useEffect, useMemo, useState } from "react";

export const usePagination = <T>({
  data: items,
  itemsPerPage = 10,
}: {
  data: T[];
  itemsPerPage: number;
}) => {
  const [itemOffset, setItemOffset] = useState(0);

  // const [items, setItems] = useState(data);

  const [forcedPage, setForcedPage] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)

  const stats = useMemo(() => {
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil((items?.length ?? 0) / itemsPerPage);

    return { endOffset, currentItems, pageCount };
  }, [items, itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % (items?.length ?? 0);

    setItemOffset(newOffset);
    setForcedPage(event.selected);
  };

  const resetOffset = () => setItemOffset(0);
  const refreshItems = (items: T[], reset: boolean = false) => {
    // setItems(items);

    if (stats.currentItems.length === 0 && stats.pageCount >= 1) {
      handlePageClick({ selected: stats.pageCount - 1 });
      setForcedPage(stats.pageCount - 1);
    }
  };

  return {
    ...stats,
    handlePageClick,
    resetOffset,
    refreshItems,
    forcedPage,
  };
};
