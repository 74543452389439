import { useState } from "react";
import { InfoIcon } from "../../../shared/Icons/Info";

const SeatsPricingToolTip = ({ price }: { price: number }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div className="flex flex-col items-start">
      <div className="flex items-center mb-2 relative">
        <span className="text-sm leading-5 font-medium text-[#121217] mr-1">
          Per user / month
        </span>
        <div
          className="relative"
          onMouseEnter={() => setIsTooltipVisible(true)}
          onMouseLeave={() => setIsTooltipVisible(false)}
        >
          <div className="w-4 h-4 cursor-pointer">
            <InfoIcon className="text-[#7047EB]" />
          </div>
          {isTooltipVisible && (
            <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-2 w-64">
              <div className="w-3 h-3 bg-[#7047EB] transform rotate-45 absolute left-1/2 -translate-x-1/2 -top-1.5"></div>
              <p className="bg-[#7047EB] text-white text-xs font-normal p-3 rounded-lg shadow-lg text-center">
                Additional seats can be added for just €{price}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SeatsPricingToolTip;
