import Lottie from "react-lottie";
import classNames from "classnames";
import LoadingLottie from "../../../../../shared/Lottie/LoadingLottie";

export const LoadingScreen = ({
  displayText,
  isThemeWhite = false,
  isRelative = false,
  className,
}: {
  displayText: string;
  isThemeWhite?: boolean;
  isRelative?: boolean;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
}) => {
  return (
    <div
      className={classNames(
        "rounded-lg overflow-clip h-full w-full",
        className,
        {
          "absolute top-0 bottom-0 left-0 right-0 ": !isRelative,
        }
      )}
    >
      <div
        className={classNames(
          "flex flex-col justify-center items-center w-full h-full",
          {
            "bg-black": !isThemeWhite,
            "bg-white py-10":
              isThemeWhite &&
              !window?.location?.pathname?.includes("transcript"),
          }
        )}
      >
        <div className="w-28 h-28">
          <Lottie
            options={{
              animationData: LoadingLottie,
              loop: true,
            }}
          />
        </div>
        <div
          className={classNames(
            "mt-3 font-semibold font-inter sm:text-xs md:text-16 px-8 text-center",
            {
              "text-black": isThemeWhite,
              "text-gray-200": !isThemeWhite,
            }
          )}
        >
          {displayText}
        </div>
      </div>
    </div>
  );
};
