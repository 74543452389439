export const TurkishFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_31126)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#D80027"
      />
      <path
        d="M11.5087 9.80469L12.4934 11.1615L14.0879 10.6445L13.1017 12.0001L14.0862 13.3568L12.4922 12.8378L11.5061 14.1935L11.5071 12.5172L9.91312 11.9982L11.5077 11.4811L11.5087 9.80469Z"
        fill="#F0F0F0"
      />
      <path
        d="M8.8218 15.3904C6.94886 15.3904 5.43049 13.8721 5.43049 11.9991C5.43049 10.1262 6.94886 8.60782 8.8218 8.60782C9.40577 8.60782 9.95524 8.75552 10.435 9.01545C9.68238 8.27941 8.65315 7.8252 7.51746 7.8252C5.21224 7.8252 3.34357 9.69391 3.34357 11.9991C3.34357 14.3043 5.21229 16.173 7.51746 16.173C8.65324 16.173 9.68243 15.7187 10.435 14.9827C9.95524 15.2427 9.40577 15.3904 8.8218 15.3904Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_31126">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
