import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { UploadDbType } from "../../types";
import { NavigationArrowIcon } from "../Icons/NavigationArrow";
import { useLocation, useNavigate } from "react-router-dom";

// Updated Tab component
export const Tab = ({
  name,
  assetId,
  upload,
  isActive,
  isDisabled,
  onClick,
  icon: Icon,
  content: Content,
  onTabSwitch,
}: {
  name: string;
  assetId: string;
  isDisabled?: boolean;
  isActive?: boolean;
  upload?: UploadDbType;
  onClick?: () => void;
  onTabSwitch?: () => void;
  icon?: any;
  content?: any;
}) => {
  return (
    <button
      className={classNames(
        "inline-block w-full hover:bg-[#F7F7F8] py-4 text-14 min-w-[160px] ",
        {
          "border-b-2 text-[#7047EB] border-[#7047EB]": isActive,
          "text-[#6C6C89]": !isActive,
          "cursor-not-allowed text-[#6C6C89]/40": isDisabled,
        }
      )}
      onClick={() => {
        onClick?.();
        onTabSwitch?.();
      }}
      disabled={isDisabled}
    >
      <div className="flex items-center justify-center space-x-1">
        {Icon && <Icon className="max-h-5 max-w-5" />} <span>{name}</span>
      </div>
    </button>
  );
};
export const TabGroup = ({
  children,
  maxColumns,
  sticky = false,
  defaultTabId,
}: {
  children: any;
  maxColumns: number;
  sticky?: boolean;
  defaultTabId?: string | null;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const tabGroupRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabFromQuery = searchParams.get("tab");
    let tabToSet;

    if (tabFromQuery) {
      tabToSet = tabFromQuery;
    } else if (defaultTabId) {
      tabToSet = defaultTabId;
    } else {
      tabToSet = children[0].props.assetId;
    }

    setActiveTab(tabToSet);

    // Always update the URL, even if the tab hasn't changed
    if (tabToSet !== tabFromQuery) {
      searchParams.set("tab", tabToSet);
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [location.search, defaultTabId, children, navigate, location.pathname]);

  const handleClick = (assetId: string) => {
    setActiveTab(assetId);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", assetId);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const tabs = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      isActive: child.props.assetId === activeTab,
      onTabSwitch: () => handleClick(child.props.assetId),
    })
  );

  const activeContent = activeTab
    ? (
        React.Children.toArray(children).find(
          (child: any) => child.props.assetId === activeTab
        ) as any
      )?.props.content
    : null;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const isPinned = entry.intersectionRatio < 1;
        if (tabGroupRef.current) {
          tabGroupRef.current.classList.toggle("is-pinned", isPinned);
        }
      },
      { threshold: [1] }
    );

    if (tabGroupRef.current) {
      observer.observe(tabGroupRef.current);
    }

    return () => {
      if (tabGroupRef.current) {
        observer.unobserve(tabGroupRef.current);
      }
    };
  }, []); // Empty dependency array means this effect runs once on mount

  const checkScrollButtons = () => {
    if (tabGroupRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabGroupRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const scrollTabs = (direction: "left" | "right") => {
    if (tabGroupRef.current) {
      const scrollAmount =
        direction === "left"
          ? -tabGroupRef.current.offsetWidth
          : tabGroupRef.current.offsetWidth;
      tabGroupRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const tabContainer = tabGroupRef.current;
    if (tabContainer) {
      setIsScrollable(tabContainer.scrollWidth > tabContainer.clientWidth);
      checkScrollButtons();
      tabContainer.addEventListener("scroll", checkScrollButtons);
    }
    return () => {
      if (tabContainer) {
        tabContainer.removeEventListener("scroll", checkScrollButtons);
      }
    };
  }, []); // Run this effect on mount to set up event listeners

  return (
    <>
      <div
        className={classNames(
          `relative flex justify-start items-center rounded-lg border border-[#D1D1DB] w-full`,
          {
            "sticky top-[-1px] bg-white z-10": sticky,
          }
        )}
      >
        <div
          ref={tabGroupRef}
          className="flex justify-start items-center overflow-x-auto w-full"
        >
          {isScrollable && showLeftArrow && (
            <button
              className="absolute left-0 inset-y-auto py-3 pl-3 bg-white z-20"
              onClick={() => scrollTabs("left")}
            >
              <NavigationArrowIcon width={24} />
            </button>
          )}
          {tabs}
          {isScrollable && showRightArrow && (
            <button
              className="absolute right-0 inset-y-auto py-3 pr-3 bg-white z-20 rotate-180"
              onClick={() => scrollTabs("right")}
            >
              <NavigationArrowIcon width={24} />
            </button>
          )}
        </div>
      </div>

      <div className="pt-8">{activeContent ? activeContent() : null}</div>
    </>
  );
};
