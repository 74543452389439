import { SupabaseClient } from "@supabase/supabase-js";
import { Database } from "../../types/database.types";

export const getAssetTemplatesByUploadId = async ({
  uploadId,
  supabase,
  onlySelectedAssets,
}: {
  uploadId: string;
  supabase: SupabaseClient<Database>;
  onlySelectedAssets: string[];
}) => {
  for (let index = 0; index < 10; index++) {
    const resp = await supabase
      .from("AssetTemplate")
      .select("*, Template(*)")
      .eq("upload_id", uploadId)
      .in("asset_id", onlySelectedAssets);

    if (Boolean(resp.error)) {
      if (index === 9) {
        alert("Failed to fetch, try again please.");
      }
      continue;
    } else {
      return resp.data;
    }
  }
};

export const getAvailableTemplatesByWorkspaceId = async ({
  workspaceId,
  supabase,
}: {
  workspaceId: string;
  supabase: SupabaseClient<Database>;
}) => {
  for (let index = 0; index < 10; index++) {
    const resp = await supabase
      .from("Template")
      .select("*")
      .eq("workspace_id", workspaceId);

    if (Boolean(resp.error)) {
      if (index === 9) {
        alert("Failed to fetch, try again please.");
      }
      continue;
    } else {
      return resp.data;
    }
  }
};
