import { LogSnag } from "logsnag";
import { useMemo } from "react";

export const useLogsnag = () => {
  const logsnag = useMemo(() => {
    const logsnag = new LogSnag({
      token: process.env.REACT_APP_LOGSNAG as string,
      project: "unifire",
    });
    return logsnag;
  }, [process.env.REACT_APP_LOGSNAG]);
  return logsnag;
};
