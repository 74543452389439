import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export const ProgressBar = ({
  fileUploadProgress,
  onDone,
}: {
  fileUploadProgress: number;
  onDone?: () => void;
}) => {
  const [progress, setProgress] = useState(0);
  const totalDuration = 40000; // 10 seconds in milliseconds
  const startTime = Date.now();

  useEffect(() => {
    let isCancelled = false;

    const updateProgress = () => {
      if (isCancelled) {
        return;
      }

      const elapsedTime = Date.now() - startTime;
      if (elapsedTime >= totalDuration) {
        setProgress(100);
        return;
      }

      // Calculate the current progress based on the elapsed time
      const currentProgress = (elapsedTime / totalDuration) * 100;

      // Randomly add some progress to simulate non-linear behavior
      const randomProgress = Math.random() * 5; // up to 5% random progress
      const newProgress = Math.floor(
        Math.min(currentProgress + randomProgress, 100)
      );
      setProgress(newProgress);

      // Schedule the next update
      const randomInterval = Math.random() * 1000 + 500; // between 0.5 and 1.5 seconds
      setTimeout(updateProgress, randomInterval);
    };

    // Start the loading simulation
    setTimeout(updateProgress, 0);

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    // ondone
    if (fileUploadProgress === -1 && progress === 100) {
      onDone?.();
    } else if (fileUploadProgress !== -1 && fileUploadProgress === 100) {
      onDone?.();
    }
  }, [progress, fileUploadProgress]);

  return (
    <CircularProgressbar
      value={fileUploadProgress !== -1 ? fileUploadProgress : progress}
      text={`${fileUploadProgress !== -1 ? fileUploadProgress : progress}%`}
      styles={buildStyles({
        pathColor: "#2DCA72",
        textColor: "#121217",
        trailColor: "#E5E4E2",
      })}
    />
  );
};
