import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { UploadDbType } from "../../types";
import { DownArrow } from "../Icons/DownArrow";
import classNames from "classnames";

export const Dropdown = ({
  title,
  uploads,
  projectId,
  isPublic,
}: {
  title: string;
  uploads: UploadDbType[];
  projectId: string;
  isPublic?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(isPublic);

  const sortedUploads = useMemo(() => {
    return uploads.sort((a, b) => {
      return (
        new Date(b.created_at as string).getTime() -
        new Date(a.created_at as string).getTime()
      );
    });
  }, [uploads]);

  return (
    <div className="relative overflow-hidden">
      <div
        className={classNames(
          "transition-all overflow-hidden ease flex w-full justify-start items-center py-1 text-14 text-[#121217]",
          {
            "duration-300": !isOpen,
            "border-b-0 duration-300": isOpen,
          }
        )}
      >
        <button className="pr-1 hover:shadow">
          <DownArrow
            className={`w-5 h-5 transition-all ${
              isOpen ? "transform" : "transform -rotate-90"
            }`}
            aria-hidden="true"
            onClick={() => setIsOpen(!isOpen)}
          />
        </button>

        <Link
          className="w-full text-start"
          to={`/dashboard/project/${projectId}`}
        >
          {title}
        </Link>
      </div>
      <div
        className={classNames(
          "transition-all ease z-10 w-full origin-top-right focus:outline-none pl-4",
          {
            "max-h-0 overflow-hidden duration-300": !isOpen,
            "max-h-[1000px] duration-500 overflow-y-scroll no-scrollbar":
              isOpen,
          }
        )}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
      >
        {" "}
        <ul
          className="py-1 text-14 text-[#121217]"
          aria-labelledby="dropdownDefault"
        >
          {Boolean(sortedUploads.length) &&
            sortedUploads.map((upload) => (
              <li
                key={upload.id}
                className={classNames({
                  "pointer-events-none": upload.status === "failed",
                })}
              >
                <Link
                  className={classNames(
                    "block px-4 py-2 hover:bg-gray-100 truncate",
                    {
                      "pointer-events-none text-gray-500":
                        upload.status === "failed",
                    }
                  )}
                  to={`/dashboard/project/${projectId}/generation/${upload.id}`}
                >
                  {upload.title}
                </Link>
              </li>
            ))}

          {!Boolean(sortedUploads.length) && (
            <li>
              <div className="block px-4 truncate text-[#121217]/70">
                Nothing generated yet
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
