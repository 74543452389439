import React, { useEffect, useRef } from "react";
import { AssetProgressType } from "./GenerateAssetsFlow";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";

interface ProgressFlowProps {
  isLoading: boolean;
  items: AssetProgressType[];
  displaySuccess?: boolean;
  isHighlighted?: boolean;
  highlightIndex: number;
}

export const ProgressFlow: React.FC<ProgressFlowProps> = ({
  isLoading,
  items,
  displaySuccess,
  highlightIndex,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null); // Create a ref for the container

  useEffect(() => {
    // This function will be called whenever highlightIndex changes
    const currentElement = containerRef.current?.children[highlightIndex];
    if (currentElement) {
      currentElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [highlightIndex]); // Dependency array, re-run if highlightIndex changes

  if (isLoading) return <Skeleton className="w-full h-[20]" />;
  return (
    <div className="flex justify-center items-center w-full">
      <div
        ref={containerRef}
        className="flex justify-between items-start overflow-x-scroll space-x-[14px] max-w-max no-scrollbar w-full"
      >
        {items.map((item, index) => (
          <ProgressBar
            key={index}
            {...item}
            highlightIndex={highlightIndex}
            idx={index}
          />
        ))}
      </div>
    </div>
  );
};

const ProgressBar: React.FC<
  ProgressFlowProps["items"][number] & {
    displaySuccess?: boolean;
    highlightIndex?: number;
    idx: number;
  }
> = ({ label, status, displaySuccess, highlightIndex, idx }) => {
  const isHighlighted = (highlightIndex ?? -1) >= idx && status !== "skipped";

  return (
    <div className="min-w-[115px]">
      <div
        className={classNames("w-full h-[4px] rounded-lg ", {
          "bg-[#46D5B3]": isHighlighted,
          "bg-[#D9D9D9]": !isHighlighted,
        })}
      />
      <p className="mt-1 text-center text-14 text-[#84818A]">{label}</p>
    </div>
  );
};
