import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSupabase } from "../../../hooks/useSupabase";
import { useWorkspace } from "../../../hooks/useWorkspace";
import {
  AssetDbType,
  FileDbType,
  UploadDbType,
  UploadWithSocialPostsAndProject,
} from "../../../types";
import { pollUploadWithId } from "../../EditableTranscript/utils";
import { EpisodePage } from "./EpisodePage";
// import { EpisodeSidebar } from "./EpisodeSidebar";
import Lottie from "react-lottie";
import LoadingIntegrationLottie from "../assets/loading-assets.json";
import { EpisodeSidebar } from "./EpisodeSidebar";

export const EpisodePageManager = () => {
  const supabase = useSupabase();
  const { projectId, id } = useParams<{ projectId: string; id: string }>();
  const workspace = useWorkspace();
  const [upload, setUpload] = useState<UploadDbType>();
  const [assets, setAssets] = useState<AssetDbType[]>([]);
  const [files, setFiles] = useState<FileDbType[]>();

  const navigate = useNavigate();

  // listen to url changes and set th upload as undefined if the id changes
  useEffect(() => {
    setUpload(undefined);
  }, [id]);

  // // episode launch from storage using createEpisodeFromPublicUpload
  // useEffect(() => {
  //   if (!projectId || !workspace?.id) {
  //     return;
  //   }
  //   if (didLaunchPublicEpisode.current) {
  //     return;
  //   }
  //   didLaunchPublicEpisode.current = true;

  //   createEpisodeFromPublicUpload({
  //     supabase,
  //     workspaceId: workspace?.id,
  //     projectId: projectId!,
  //   }).then((res) => {
  //     setReload(true);
  //   });
  // }, [didLaunchPublicEpisode, projectId, workspace?.id, supabase]);

  // polling mechanism to check if the episode is ready
  useEffect(() => {
    if (!id) return;

    pollUploadWithId(supabase, id, (upload, assets, files) => {
      if (upload.status === "failed") {
        alert(
          "Sorry, something went wrong with the transcription. Please try again."
        );
        navigate(`/dashboard/overview`);
      }
      setUpload(upload as UploadDbType);
      setAssets(assets);
      setFiles(files);
    });
  }, [id, navigate, projectId, supabase]);

  // here we set a listener for UPDATES to the assets

  useEffect(() => {
    if (!workspace?.id) return;
    const sub = supabase
      .channel(`changes`)
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "Asset",
          filter: `upload_id=eq.${id}`,
        },
        (response) => {
          const assetId = response?.new?.asset_id;
          if (!assetId) return;
          // if not an array of data, return, because this is only for linkedin and twitter arrays. Rest can be handled through YJS
          if (!Array.isArray(response?.new?.data)) return;

          const newAssets = [...assets];
          if (!newAssets?.length) return;

          const index = newAssets?.findIndex(
            (asset) => asset.asset_id === assetId
          );
          if (index === -1) return;
          if (!newAssets[index]) return;
          (newAssets[index] as any).data = response?.new?.data;
          // setUserProfile(newProfile);
          setAssets(newAssets as AssetDbType[]);
        }
      )
      .subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [supabase, workspace, id, assets]);

  if (!upload || !Boolean(assets?.length) || !files) {
    return (
      <div className="flex flex-col items-center justify-center mt-20 md:mt-40">
        <Lottie
          width="200px"
          height="200px"
          options={{
            animationData: LoadingIntegrationLottie,
            loop: true,
          }}
        />
      </div>
    );
  }

  return (
    <div className="flex justify-start items-start">
      <EpisodePage upload={upload} assets={assets} files={files} />
      <EpisodeSidebar upload={upload} assets={assets} />
    </div>
  );
};
