import axios from "axios";

export const getCardValidationLink = async ({
  supabase,
}: {
  supabase: any;
}) => {
  // req.setRequestHeader("x-upsert", "true");
  // random string
  const body = {
    variantId: process.env.REACT_APP_TRIAL_VARIANT_ID as string,
  };

  const session = await supabase.auth.getSession();

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment-v2/new-subscription`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

export const getNewSubscriptionLink = async ({
  variantId,
  supabase,
}: {
  variantId: string;
  supabase: any;
}) => {
  // req.setRequestHeader("x-upsert", "true");
  // random string
  const body = {
    variantId,
  };

  const session = await supabase.auth.getSession();

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment-v2/new-subscription`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

export const upgradeDowngradeSubscription = async ({
  variantId,
  supabase,
}: {
  variantId: string;
  supabase: any;
}) => {
  // req.setRequestHeader("x-upsert", "true");
  // random string
  const body = {
    variantId,
  };

  const session = await supabase.auth.getSession();

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment-v2/update-subscription`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

export const cancelSubscription = async ({
  supabase,
  cancelationReason,
}: {
  supabase: any;
  cancelationReason: string;
}) => {
  // req.setRequestHeader("x-upsert", "true");
  // random string

  const session = await supabase.auth.getSession();

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment-v2/cancel-subscription`,
    {
      cancelationReason,
    },
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

export const resumeSubscription = async ({ supabase }: { supabase: any }) => {
  // req.setRequestHeader("x-upsert", "true");
  // random string

  const session = await supabase.auth.getSession();

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment-v2/resume-subscription`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

export const getCardUpdateLink = async ({ supabase }: { supabase: any }) => {
  // req.setRequestHeader("x-upsert", "true");
  // random string
  const body = {};

  const session = await supabase.auth.getSession();

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment-v2/update-payment-method`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

export const purchaseStaticMinutes = async ({
  minutes,
  supabase,
}: {
  minutes: number;
  supabase: any;
}) => {
  // req.setRequestHeader("x-upsert", "true");
  // random string
  const body = {
    minutes,
  };

  const session = await supabase.auth.getSession();

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment-v2/get-static-minutes`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

export const getPaddleDiscount = async ({
  credits,
  supabase,
}: {
  credits: number;
  supabase: any;
}) => {
  // req.setRequestHeader("x-upsert", "true");
  // random string
  const body = {
    credits,
  };

  const session = await supabase.auth.getSession();

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment-v2/get-discount`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

// ltd

export const getLtdLink = async ({ supabase }: { supabase: any }) => {
  // req.setRequestHeader("x-upsert", "true");
  // random string
  const body = {};

  const session = await supabase.auth.getSession();

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment-v2/get-ltd`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};
