import axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import { useAuthUserId } from "../hooks/useAuth";
import { useSupabase } from "../hooks/useSupabase";
import { useWorkspace } from "../hooks/useWorkspace";
import { useLocation } from "react-router-dom";
import { BillingDbType, UserProfileDbType } from "../types";

// Create a context
export const RemainingCreditsContext = React.createContext<
  | {
      ownerCredits: number;
      ownerGeneralLimits: GeneralLimits;
      ownerTotalCredits: number;
      ownerCycleEndDate: string;
      authProfile: UserProfileDbType | undefined;
      remainingInpCharacters: number;
      isLoading: boolean;
    }
  | undefined
>(undefined);

type GeneralLimits = {
  remainingWorkspaces: number;
  audioHourLimit: number;
  remainingTeamMembers: number;
  maxInpCharacters: number;
  planType: "LTD" | "FREE" | BillingDbType["plan_name"];
  subscriptionIntervalType?: "MONTHLY" | "YEARLY" | "LTD";
  teamMembers: {
    workspaceId: string;
    remainingMembers: number;
  }[];
};

// Provider component that wraps your app and makes an API call to fetch remaining credits
export const RemainingCreditsProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [ownerCredits, setOwnerCredits] = useState<number>(-1);
  const [ownerCycleDate, setOwnerCycleDate] = useState({
    cycleStartDate: "",
    cycleEndDate: "",
  });
  const [ownerGeneralLimits, setOwnerGeneralLimits] = useState<GeneralLimits>({
    remainingWorkspaces: 0,
    audioHourLimit: 0,
    teamMembers: [],
    remainingTeamMembers: 0,
    maxInpCharacters: 0,
    planType: "FREE",
  });
  // remainingInpCharacters
  const [remainingInpCharacters, setRemainingInpCharacters] =
    useState<number>(0);
  const [ownerTotalCredits, setOwnerTotalCredits] = useState<number>(-1);
  const supabase = useSupabase();
  const workspace = useWorkspace();
  const authUserId = useAuthUserId();
  const location = useLocation();
  const [authProfile, setAuthProfile] = useState<UserProfileDbType>();

  // isLoading
  const [isLoading, setIsLoading] = useState(true);

  const isPublicPage =
    location.pathname.includes("public") || location.pathname.includes("share");

  useEffect(() => {
    if (isPublicPage) return;
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      const userId = session?.user?.id;

      for (let i = 0; i < 5; i++) {
        const { data, error } = await supabase
          .from("UserProfile")
          .select("*")
          .eq("id", userId as string)
          .limit(1)
          .maybeSingle();

        // show alert if exhausted retries
        if (i === 4) {
          console.error("Failed to fetch profile");
          break;
        }
        if (error) {
          console.error(error);
          continue;
        }
        setAuthProfile(data as UserProfileDbType);
      }
    });
  }, [supabase, isPublicPage]);

  useEffect(() => {
    if (isPublicPage) {
      setIsLoading(false);
      return;
    }
    // for workspace owner
    const refreshCredits = () => {
      getremainingCredits({
        supabase,
        workspaceOwnerId: workspace?.owner_id as string,
      })
        .then((res) => {
          if (!res) return;
          setOwnerCredits(res.remainingCredits);
          setRemainingInpCharacters(res.remainingInpCharacters);
          setOwnerGeneralLimits(res);
          setOwnerTotalCredits(res.remainingCredits + res.consumedCredits);
          setOwnerCycleDate({
            cycleStartDate: res.cycleStartDate,
            cycleEndDate: res.cycleEndDate,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          // if url has dashboard
          if (window.location.pathname.includes("dashboard")) {
            window.location.reload();
          }
        });
    };
    refreshCredits();

    // const interval = setInterval(refreshCredits, 30000);
    // return () => clearInterval(interval);
  }, [workspace?.owner_id, supabase, authUserId, isPublicPage]);

  return (
    <RemainingCreditsContext.Provider
      value={{
        ownerCredits,
        ownerGeneralLimits,
        ownerTotalCredits,
        ownerCycleEndDate: ownerCycleDate.cycleEndDate,
        authProfile,
        remainingInpCharacters,
        isLoading,
      }}
    >
      {children}
    </RemainingCreditsContext.Provider>
  );
};

const getremainingCredits = async ({
  workspaceOwnerId,
  supabase,
}: {
  workspaceOwnerId?: string;
  supabase: any;
}): Promise<{
  ownerId: string;
  ownerName: string;
  consumedCredits: number;
  remainingCredits: number;
  cycleStartDate: string;
  cycleEndDate: string;
  remainingInpCharacters: number;
  assetsBreakdown:
    | {
        id: string;
        credits: number;
      }[]
    | [];

  // general limits
  remainingWorkspaces: number;
  audioHourLimit: number;
  totalRemainingMembers: number;
  remainingTeamMembers: number;
  maxInpCharacters: number;
  planType: BillingDbType["plan_name"] | "LTD" | "FREE";
  teamMembers: {
    workspaceId: string;
    remainingMembers: number;
  }[];
}> => {
  // construct a body object
  const params = {
    workspaceOwnerId,
  };

  const session = await supabase.auth.getSession();

  // req.setRequestHeader("x-upsert", "true");
  // random string

  return (
    await axios.get(
      `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment/remaining-credits`,
      {
        headers: {
          "Content-Type": "application/json",
          // @ts-ignore
          ...supabase.headers,
          authorization: `Bearer ${session.data.session.access_token}`,
        },
        params,
      }
    )
  ).data;
};
