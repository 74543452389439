import Skeleton from "react-loading-skeleton";

export const MembersSkeleton = ({ number = 10 }: { number?: number }) => {
  // generate dummy array
  const arr = Array.from({ length: number }, (_, i) => i + 1);

  return (
    <>
      {arr?.map((_, i) => (
        <tr key={i} className="">
          <td className="border border-x-0 px-4 text-14 font-bold py-5 truncate">
            <Skeleton className="w-full h-full min-w-full" />
          </td>
          <td className="border border-x-0 px-4 text-14">
            <Skeleton className="w-full h-full" />
          </td>
          <td className="border border-x-0 px-4  text-14 ">
            <Skeleton className="w-full h-full" />
          </td>
          <td className="border border-x-0 px-4 text-14 ">
            <span className="text-green-700 font-semibold">
              <Skeleton className="w-full h-full" />
            </span>
          </td>
        </tr>
      ))}
    </>
  );
};
