import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { useOutsideTap } from "../../../hooks/outsideTap";
import { useSupabase } from "../../../hooks/useSupabase";
import { useWorkspace } from "../../../hooks/useWorkspace";
import { BuildingIcon } from "../../../shared/Icons/Building";
import { WhiteFlagIcon } from "../../../shared/Icons/Flag/WhiteFlag";
import { HouseIcon } from "../../../shared/Icons/House";
import { MedalIcon } from "../../../shared/Icons/Medal";
import { PhoneIcon } from "../../../shared/Icons/Phone";
import { SpeechIcon } from "../../../shared/Icons/Speech";
import { TeamIcon } from "../../../shared/Icons/Team";
import Dropdown from "../../../shared/Library/Dropdown";
import {
  AssetDbType,
  BillingDbType,
  ProjectWithUploads,
  UploadDbType,
} from "../../../types";
import { Database } from "../../../types/database.types";
import { ReactComponent as HamburgerIcon } from "../assets/hamburger.svg";
import { CreateProject } from "./CreateProject";
import NavLink from "./NavLink";
import WorkspaceDropDown from "./WorkspaceDropDown";

import { GearIcon } from "../../../shared/Icons/Gear";
import { FileIcon } from "../../../shared/Icons/File";
import { StarIcon } from "../../../shared/Icons/Star";
import { DollarIcon } from "../../../shared/Icons/Dollar";
import { TemplatesIcon } from "../../../shared/Icons/Templates";

type ProfileTableType = Database["public"]["Tables"]["UserProfile"]["Row"];
const Sidebar = ({ isPublic = false }: { isPublic?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef() as any;
  useOutsideTap(ref, () => setIsOpen(false));

  const supabase = useSupabase();

  const [projectCrossUpload, setProjectCrossUpload] = useState<
    ProjectWithUploads[]
  >([]);

  const [currentProfile, setCurrentProfile] = useState<
    ProfileTableType & { Billing: BillingDbType }
  >();
  const workspace = useWorkspace();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setTimeout(async () => {
      supabase
        .from("UserProfile")
        .select("*, Billing(*)")
        .eq("id", (await supabase.auth.getUser()).data.user?.id as string)
        .limit(1)
        .single()
        .then(({ data }) => {
          setCurrentProfile(data as typeof currentProfile);
        });
    }, 0);
  }, [supabase]);

  const onDropdownOpen = () => setDropdownOpen(true);
  const onDropdownClose = () => setDropdownOpen(false);

  useEffect(() => {
    if (!workspace?.id) return;
    refreshProjects();
  }, [supabase, workspace]);

  function refreshProjects() {
    supabase
      .from("Project")
      .select(
        "id, title, Upload(id, title, status, project_id, created_at, deleted, is_complete)"
      )
      .eq("workspace_id", workspace?.id as string)
      .order("created_at", { ascending: false })
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          // filter ones that aren t ready
          const uploadsWithAssets = data.map((project) => {
            return {
              ...project,
              Upload: (project?.Upload as UploadDbType[])
                .filter((upload) => upload.deleted !== true)
                .filter((upload) => upload.status !== "failed")
                .filter((upload) => upload.is_complete === true)
                .sort((a, b) => {
                  return (
                    new Date(b?.created_at ?? "").getTime() -
                    new Date(a?.created_at ?? "").getTime()
                  );
                }),
            };
          });
          setProjectCrossUpload(uploadsWithAssets as ProjectWithUploads[]);
        }
      });
  }

  useEffect(() => {
    if (!workspace?.id) return;
    const sub = supabase
      .channel(`changes`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "Project",
          filter: `workspace_id=eq.${workspace.id}`,
        },
        () => {
          refreshProjects();
        }
      )
      .subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [supabase, workspace]);

  return (
    <>
      <button
        className="fixed md:hidden ml-4 mt-5 z-10 bg-white rounded-full p-3"
        onClick={() => setIsOpen(!isOpen)}
      >
        <HamburgerIcon width={24} height={24} />
      </button>
      <div
        className={`
        z-[1000] bg-white md:z-0
        h-full
        w-80 
        pr-3.5 pl-5 
        pb-24
        fixed
        overflow-auto
        no-scrollbar
        md:static
        md:translate-x-0
        mix-blend-normal
        
        ${
          isOpen
            ? "translate-x-0 duration-150"
            : "-translate-x-full duration-150"
        }

        ${isPublic ? "pointer-events-none" : ""}
     `}
        ref={ref}
      >
        <div className="w-full ">
          <Link className="" to="/dashboard/overview">
            <div className="w-full font-bold flex justify-start items-center space-x-2 mt-5">
              <Logo className="w-[32px] h-[32px] mb-1" />
              <p className="text-20 font-extrabold">Unifire</p>
            </div>
          </Link>

          <div className="w-full space-y-4 mt-14">
            <WorkspaceDropDown
              dropdownOpen={onDropdownOpen}
              dropdownClose={onDropdownClose}
              disable={isPublic}
            />
          </div>

          {/* create a new project */}
          <div className="my-[22px]">
            <CreateProject
              minimalistUI={true}
              onCreated={() => refreshProjects()}
            />
          </div>

          <div className="w-full space-y-3">
            <NavLink
              path="/dashboard/overview"
              title="Generate Content"
              icon={<HouseIcon />}
            />
            <NavLink
              path="/dashboard/templates"
              title="Templates"
              icon={<TemplatesIcon />}
            />
            <NavLink path="/dashboard/team" title="Team" icon={<TeamIcon />} />
            <NavLink
              path="/dashboard/billing"
              title="Plans & Billing"
              icon={<BuildingIcon />}
            />
            <NavLink
              path="/dashboard/settings"
              title="Settings"
              icon={<GearIcon />}
            />
          </div>

          <div className="w-full mt-12">
            <div className="flex items-center justify-between">
              <span className="text-12 text-[#6C6C89]"> Projects</span>

              <CreateProject
                minimalistUI={false}
                onCreated={() => refreshProjects()}
              />
            </div>
            <div className="w-full space-y-1 mt-2">
              {!isPublic &&
                projectCrossUpload.map((project) => (
                  <Dropdown
                    title={project.title as string}
                    uploads={project.Upload}
                    projectId={project.id as string}
                  />
                ))}
              {!isPublic && !Boolean(projectCrossUpload.length) && (
                <div className="space-y-2">
                  {["", "", "", "", "", "", ""].map((idx) => (
                    <div key={idx}>
                      <Skeleton className="w-full" height={20} />
                    </div>
                  ))}
                </div>
              )}
              {isPublic && (
                <Dropdown
                  isPublic
                  title="Default project"
                  uploads={
                    [
                      {
                        id: "aih",
                        title: "My SEO Assets",
                        created_at: "",
                      },
                      {
                        id: "alineig",
                        title: "Research",
                        created_at: "",
                      },
                      {
                        id: "alinealigneiaig",
                        title: "Company Training",
                        created_at: "",
                      },
                    ] as UploadDbType[]
                  }
                  projectId=""
                />
              )}
            </div>
          </div>

          {/* Resources */}
          <div className="w-full mt-12">
            <span className="text-12 text-[#6C6C89]">Resources</span>

            <div
              className={classNames("w-full space-y-3 mt-2", {
                "pointer-events-none": isPublic,
              })}
            >
              <NavLink
                path="https://unifire.tolt.io/login"
                blank
                title="Affiliate (30% Lifetime)"
                icon={<DollarIcon />}
              />
              <NavLink
                path="https://join.slack.com/t/unifireai/shared_invite/zt-2h15huhnm-JKxQLa33LKWC0R2_bCKxWg"
                blank
                title="Join our Slack community"
                icon={<SpeechIcon />}
              />
              <NavLink
                path="https://tidycal.com/unifire/onboarding"
                blank
                title="Schedule onboarding call"
                icon={<PhoneIcon />}
              />
              <NavLink
                path="https://unifireai.featurebase.app/?b=65119e8e21058ed04c02558e&s=65119e8e21058ed04c025592"
                blank
                title="Feature Requests"
                icon={<WhiteFlagIcon />}
              />
              <NavLink
                path="https://www.unifire.ai/help/"
                blank
                title="Knowledge Base"
                disabled
                icon={<FileIcon />}
              />
              <NavLink
                path="https://www.unifire.ai"
                blank
                title="Review us"
                icon={<StarIcon />}
              />
              <NavLink
                path="/dashboard/testimonial"
                title="Write us a testimonial ❤️"
                icon={<MedalIcon />}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={` w-[220px] mt-10 z-[10001] absolute bottom-0 pb-3 break-words transition-all bg-white ml-5  ${
          (dropdownOpen || !isOpen) && "invisible"
        } ${isPublic && "pointer-events-none"}`}
      >
        <Link to="/dashboard/billing">
          <div className="flex justify-between items-center">
            <p className="text-14 text-[#121217]">
              {currentProfile?.Billing?.subscription_status === "active" ||
              Boolean(currentProfile?.Billing?.appsumo_license) ||
              Boolean(currentProfile?.Billing?.ltd_id)
                ? "Scale"
                : "Free"}{" "}
              Plan
              <br />
              <span className="text-12 text-[#6C6C89]">999 Generations</span>
            </p>

            <button className="text-14 font-medium bg-[#F53D6B] px-2 py-1 rounded-lg text-white">
              Upgrade
            </button>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Sidebar;
