import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";
import * as Yup from "yup";
import { useLogsnag } from "../../../hooks/logsnag";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { useSupabase } from "../../../hooks/useSupabase";
import { useWorkspace } from "../../../hooks/useWorkspace";
import { useRole } from "../../../hooks/userCanEdit";
import { DownArrow } from "../../../shared/Icons/DownArrow";
import { AmericanFlag } from "../../../shared/Icons/Flag/AmericanFlag";
import { FinishFlag } from "../../../shared/Icons/Flag/FinishFlag";
import { FrenchFlag } from "../../../shared/Icons/Flag/FrenchFlag";
import { GermanFlag } from "../../../shared/Icons/Flag/GermanFlag";
import { ItalianFlag } from "../../../shared/Icons/Flag/ItalianFlag";
import { NetherlandsFlag } from "../../../shared/Icons/Flag/NetherlandsFlag";
import { PolishFlag } from "../../../shared/Icons/Flag/PolishFlag";
import { PortugueseFlag } from "../../../shared/Icons/Flag/PortugeseFlag";
import { RussianFlag } from "../../../shared/Icons/Flag/RussianFlag";
import { SpanishFlag } from "../../../shared/Icons/Flag/SpanishFlag";
import { TurkishFlag } from "../../../shared/Icons/Flag/TurkishFlag";
import { PlusIcon } from "../../../shared/Icons/Plus";
import { HindiFlag } from "../../../shared/Icons/Flag/HindiFlag";
import { JapaneseFlag } from "../../../shared/Icons/Flag/JapaneseFlag";
import { KoreanFlag } from "../../../shared/Icons/Flag/KoreanFlag";
import { VietnameseFlag } from "../../../shared/Icons/Flag/VietnameseFlag";
import { ChineseFlag } from "../../../shared/Icons/Flag/ChineseFlag";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  langTag: Yup.string().required("Language is required"),
});

//en, es, fr, de, it, pt, nl, fi, pl, ru, tr,
export const supportedLanguages = [
  {
    label: "English",
    value: "en",
    icon: <AmericanFlag />,
  },
  {
    label: "Spanish",
    value: "es",
    icon: <SpanishFlag />,
  },
  {
    label: "French",
    value: "fr",
    icon: <FrenchFlag />,
  },
  {
    label: "German",
    value: "de",
    icon: <GermanFlag />,
  },
  {
    label: "Italian",
    value: "it",
    icon: <ItalianFlag />,
  },
  {
    label: "Portuguese",
    value: "pt",
    icon: <PortugueseFlag />,
  },
  {
    label: "Dutch",
    value: "nl",
    icon: <NetherlandsFlag />,
  },
  {
    label: "Finnish",
    value: "fi",
    icon: <FinishFlag />,
  },
  {
    label: "Polish",
    value: "pl",
    icon: <PolishFlag />,
  },
  {
    label: "Russian",
    value: "ru",
    icon: <RussianFlag />,
  },
  {
    label: "Turkish",
    value: "tr",
    icon: <TurkishFlag />,
  },
  {
    label: "Chinese",
    value: "zh",
    icon: <ChineseFlag />,
  },
  {
    label: "Hindi",
    value: "hi",
    icon: <HindiFlag />,
  },
  {
    label: "Japanese",
    value: "ja",
    icon: <JapaneseFlag />,
  },
  {
    label: "Korean",
    value: "ko",
    icon: <KoreanFlag />,
  },
  {
    label: "Vietnamese",
    value: "vi",
    icon: <VietnameseFlag />,
  },
];

export const SelectLanguage = ({
  handleLangChange,
  defaultLanguage,
  isOnboarding = false,
  inlineSlide = false,
  title = "Upload Language",
  titleStyle = "text-14 font-medium mb-2 text-[#121217]",
}: {
  handleLangChange: (langTag: string) => void;
  defaultLanguage: string;
  isOnboarding?: boolean;
  inlineSlide?: boolean;
  title?: string;
  titleStyle?: string;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const [language, setLanguage] = useState(defaultLanguage);

  const languageDisplay = supportedLanguages.find(
    (lang) => lang.value === language
  );

  const supportedFilteredLanguages = supportedLanguages.filter(
    (lang) => lang.value !== languageDisplay?.value
  );

  return (
    <div className="text-start relative z-10">
      <p className={titleStyle}>{title}</p>
      <div className="">
        <button
          type="button"
          className={classNames(
            "transition-all overflow-hidden ease flex w-full justify-between items-center rounded-xl bg-white px-4 py-2.5 border border-1 border-gray-300/60 text-sm text-gray-900  ",
            {
              "delay-200 duration-100": !isOpen,
              "border-b-0 rounded-b-none duration-100": isOpen,
            }
          )}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center justify-between space-x-2">
            <div className="w-6 h-6">{languageDisplay?.icon}</div>
            <span>{languageDisplay?.label} </span>
          </div>

          <DownArrow
            className={classNames({
              "transform rotate-180": isOpen,
            })}
          />
        </button>
      </div>
      <div
        className={classNames(
          "transition-all duration-300 ease z-10 w-full origin-top-right rounded-2xl bg-white focus:outline-none",
          {
            absolute: !inlineSlide,
            "h-[0px] overflow-hidden rounded-t-none": !isOpen,
            "h-[250px] overflow-auto border border-1 border-t-0 border-gray-300/60 rounded-t-none":
              isOpen && !inlineSlide,
            // "h-[250px] overflow-auto border border-1 border-t-0 border-gray-300/60 rounded-t-none":
            //   isOpen && inlineSlide,
            //height
          }
        )}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
      >
        {supportedFilteredLanguages.map(({ label, value, icon }) => (
          <div className="py-1 " role="none" key={value}>
            <button
              type="button"
              className="w-full text-start px-4 py-2 text-14 hover:bg-gray-100 focus:bg-gray-100"
              role="menuitem"
              onClick={() => {
                handleLangChange(value);
                setIsOpen(false);
                setLanguage(value);
              }}
            >
              <div className="flex items-center justify-start space-x-2">
                <div className="w-6 h-6 rounded-full overflow-clip !object-cover">
                  {icon}
                </div>
                <span>{label} </span>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export const CreateProject = ({
  onCreated,
  minimalistUI,
}: {
  onCreated: () => void;
  minimalistUI: boolean;
}) => {
  const [showCreateProject, setShowCreateProject] = useState(false);

  const workspace = useWorkspace();
  const supabase = useSupabase();
  const logsnag = useLogsnag();
  const role = useRole();
  const { newProjectCreated } = useAnalytics();

  const UI = !minimalistUI ? (
    <div className="flex justify-end items-center">
      <button className="px-3 py-2" onClick={() => setShowCreateProject(true)}>
        <PlusIcon color="#6C6C89" />
      </button>
    </div>
  ) : (
    <div
      className="flex items-center justify-between w-full px-2 py-1.5 cursor-pointer border border-[#D1D1DB] rounded-lg shadow"
      onClick={() => setShowCreateProject(true)}
    >
      <p className="text-center w-full text-14 font-medium text-[#121217] truncate">
        Create New Project
      </p>
    </div>
  );
  const handleSubmit = async ({
    title,
    langTag,
  }: {
    title: string;
    langTag: string;
  }) => {
    const session = await supabase.auth.getSession();

    if (!workspace?.id) return;

    // submit record
    const { data: project } = await supabase
      .from("Project")
      .insert({
        title,
        user_id: session?.data?.session?.user.id,
        workspace_id: workspace?.id,
        lang_tag: langTag,
        selected_assets: {},
      })
      .select("*")
      .single();

    logsnag
      .publish({
        channel: "content",
        event: "new_podcast",
        description: "User started a new podcast series",
        icon: "🎥",
        notify: true,
        tags: {
          userid: session?.data?.session?.user.id ?? "",
        },
      })
      .catch((err) => {});

    setShowCreateProject(false);

    newProjectCreated({
      role: role as any,
      projectId: project?.id as string,
    });
    onCreated();
  };
  return (
    <>
      {UI}
      {/* invite members */}
      {showCreateProject && (
        <Modal
          isOpen={showCreateProject}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => setShowCreateProject(false)}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "6px",
              zIndex: 51,
            },
          }}
          contentLabel="Example Modal"
        >
          <Formik
            initialValues={{
              title: "",
              langTag: "en",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div
                  className="max-w-[300px] min-h-[250px] md:min-w-[909px] md:min-h-[420px] px-20 py-12 "
                  role="document"
                >
                  <div className="w-full">
                    <div className="">
                      <p className="block font-semibold w-full text-center text-xl leading-8">
                        New Project
                      </p>
                      <p className="w-full text-center font-normal mt-2 text-lg leading-6">
                        Create a place for all your generated
                        <br /> content
                      </p>
                      <div className="mt-16">
                        <span className="text-14 text-[#121217] font-medium">
                          Name your project
                        </span>
                        <Field
                          type="text"
                          name="title"
                          id="title"
                          placeholder="Name your channel"
                          maxLength={150}
                          className="appearance-none mt-2 border border-gray-300/60 rounded-xl text-gray-900 w-full p-3 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>{" "}
                      <SelectLanguage
                        titleStyle="text-14 font-medium mt-[46px] text-[#121217] mb-2"
                        defaultLanguage="en"
                        handleLangChange={(lang) =>
                          setFieldValue("langTag", lang)
                        }
                      />
                    </div>

                    <div className="mt-40 w-full flex justify-end items-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="max-w-[254px] disabled:bg-gray-500 bg-uf-yellow-1 text-14 font-semibold text-[#202020] py-3 px-4 rounded-xl focus:outline-none focus:shadow-outline w-full"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
