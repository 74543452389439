import { Editor } from "@tiptap/react";
import { ReactComponent as RewindSvg } from "../../../assets/rewind.svg";

export const UndoItem = ({ editor }: { editor: Editor | null }) => {
  const undo = () => editor?.commands.undo();
  return (
    <button
      className="flex justify-between items-center space-x-2 "
      onClick={undo}
    >
      <RewindSvg className="rotate-180" />
      <p className=" font-medium text-[#84818A]">Undo</p>
    </button>
  );
};
