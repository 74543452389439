import { Editor } from "@tiptap/react";
import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";
import { ReactComponent as MagnifierSvg } from "../../../assets/magnifier.svg";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "6px",
    zIndex: 51,
  },
};

export const ReplaceItem = ({ editor }: { editor: Editor | null }) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <button
        className="flex justify-between items-center space-x-2"
        onClick={() => {
          setShow(!show);
        }}
      >
        <MagnifierSvg />
        <p className=" font-medium text-[#42474B]">Find and replace</p>
      </button>
      {show && <ReplaceModal setShow={setShow} show={show} editor={editor} />}
    </div>
  );
};

let searchTerm = "";
let replaceTerm = "";
const ReplaceModal = ({
  setShow,
  show,
  editor,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  editor: Editor | null;
}) => {
  const replace = () => editor?.commands.replace();
  const replaceAll = () => editor?.commands.replaceAll();

  const updateSearchTerm = (e: any = "") => {
    const val = e ? e.target.value : e;
    searchTerm = val;
    editor?.commands.setSearchTerm(val);
  };

  const updateReplaceTerm = (e: any = "") => {
    const val = e ? e.target?.value : e;
    replaceTerm = val;
    editor?.commands.setReplaceTerm(val);
  };

  const clearAndClose = () => {
    searchTerm = "";
    replaceTerm = "";

    updateSearchTerm();
    updateReplaceTerm();

    setShow(false);
  };

  const resultCount = editor?.extensionManager.extensions.find(
    (ext) => ext.name === "search"
  )?.options?.results?.length;

  return (
    <div className="w-full h-full ">
      <Modal
        isOpen={show}
        onRequestClose={clearAndClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className=" max-w-[300px] min-h-[250px] md:min-w-[650px] md:min-h-[300px] px-8 "
          role="document"
        >
          <div className="mt-4">
            <p className="block font-semibold mb-2 w-full text-center text-16 text-[#202020]">
              Find and Replace
            </p>
            <p className="text-[#202020] text-16 text-center">
              Find errors and easily fix them
            </p>

            <div className="mt-16 ">
              <span className="text-12 font-medium">Search term</span>
              <div className="relative">
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Gogle..."
                  className="appearance-none mt-1.5 border border-gray-400/30 rounded-xl text-gray-900 w-full p-3 leading-tight focus:outline-none focus:shadow-outline"
                  onInput={updateSearchTerm}
                />
                <p className="absolute bottom-3.5 right-3 text-12 text-[#84818A] font-medium">
                  count: {resultCount}
                </p>
              </div>
            </div>

            <div className="mt-10 ">
              <span className="text-12 font-medium">Replace with</span>
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Google..."
                className="appearance-none mt-1.5 border border-gray-400/30 rounded-xl text-gray-900 w-full p-3 leading-tight focus:outline-none focus:shadow-outline"
                onInput={updateReplaceTerm}
                onKeyPress={(e) => e.key === "Enter" && replace()}
              />
            </div>
          </div>

          <div className="mt-20 w-full flex justify-end items-center">
            <div className="flex justify-between items-center space-x-9">
              <button
                type="submit"
                className="border border-uf-yellow-1 text-12 font-semibold text-[#202020] py-2.5 px-14 rounded-lg focus:outline-none focus:shadow-outline disabled:cursor-not-allowed"
                onClick={() => replaceAll()}
                disabled={!resultCount}
              >
                Replace all
              </button>

              <button
                type="submit"
                className="  bg-uf-yellow-1 text-12 font-semibold text-[#202020] py-2.5 px-14 rounded-lg focus:outline-none focus:shadow-outline disabled:cursor-not-allowed"
                onClick={() => replace()}
                disabled={!resultCount}
              >
                Replace
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReplaceItem;
