import { Socket } from "socket.io-client";
import {
  AssetDbType,
  UploadWithSocialPostsAndProject,
} from "../../../../types";
import { AssetPaginator } from "../AssetPaginator";
import { ASSETS_IDS } from "../types";

export const EducationResources = ({
  upload,
  selectedAssetId,
  canEdit,
  socket,
  assets,
}: {
  upload: UploadWithSocialPostsAndProject;
  selectedAssetId: string;
  canEdit: boolean;
  socket: Socket;
  assets: AssetDbType[];
}): JSX.Element => {
  return (
    <>
      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.EDUCATION_CASESTUDY
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.EDUCATION_CHECKLIST
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.EDUCATION_DISCUSSION
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.EDUCATION_FLASHCARDS
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.EDUCATION_FOLLOWUP
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.EDUCATION_GLOSSARY
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.EDUCATION_WORKSHEET
          ) as AssetDbType
        }
      />
    </>
  );
};

export default EducationResources;
