import { Route, Routes } from "react-router-dom";
import Page from "./components/Page";

export const PublicPage = () => (
  <Routes>
    <Route path="/share/generation/:episodeId" element={<Page />} />
  </Routes>
);

export default PublicPage;
