import Lottie from "react-lottie";
import LoadingLottie from "../../../shared/Lottie/LoadingLottie";

export const FullScreenLoading = () => {
  return (
    <div className="absolute inset-0 overflow-clip h-full w-full bg-opacity-10 z-[2000]">
      <div className="flex flex-col justify-center items-center w-full h-full bg-gray-600/75">
        <div className="w-28 h-28">
          <Lottie
            options={{
              animationData: LoadingLottie,
              loop: true,
            }}
          />
        </div>
        <div className="mt-0 ml-4 text-gray-200 font-semibold font-inter text-16  px-8 text-center">
          Approving...
        </div>
      </div>
    </div>
  );
};
