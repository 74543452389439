export const CopyIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    {...props}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66665 15.3333C2.37798 15.3333 1.33331 14.2886 1.33331 12.9999V3.99992C1.33331 2.52716 2.52722 1.33325 3.99998 1.33325H13C14.2886 1.33325 15.3333 2.37792 15.3333 3.66659M9.33331 20.6666H18C19.4727 20.6666 20.6666 19.4727 20.6666 17.9999V9.33325C20.6666 7.86049 19.4727 6.66658 18 6.66658H9.33331C7.86055 6.66658 6.66665 7.86049 6.66665 9.33325V17.9999C6.66665 19.4727 7.86055 20.6666 9.33331 20.6666Z"
      stroke="#8A8AA3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
