import React, { useState } from "react";
import { LibraryModal } from "../../../shared/Library/Modal";
import { useSupabase } from "../../../hooks/useSupabase";
import { InvitedMember, WorkSpaceMember } from "./TeamPage";
import { removeSeat } from "../utils";

interface DeleteUserModalProps {
  userName: string;
  onDelete: () => void;
  onCancel: () => void;
  isWithinFreeLimit: boolean;
  deleteTarget: WorkSpaceMember | InvitedMember;
  seatAmount: number;
  hasActiveSeats: boolean;
  priceId: string;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  userName,
  onDelete,
  onCancel,
  isWithinFreeLimit,
  deleteTarget,
  seatAmount,
  hasActiveSeats,
  priceId,
  showModal,
  setShowModal,
}) => {
  const supabase = useSupabase();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDelete = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // call api to update subscription
      if (hasActiveSeats) {
        await removeSeat({
          priceId,
          supabase,
        });
      }

      // delete from DB first
      const isInvite = "invitee_email" in deleteTarget;
      if (isInvite) {
        const { error } = await supabase
          .from("Invite")
          .delete()
          .eq("id", deleteTarget.id);
        if (error) {
          alert("Error deleting invite, please try again");
          return;
        }
      } else {
        const { error } = await supabase
          .from("Member")
          .delete()
          .eq("id", deleteTarget.id);
        if (error) {
          alert("Error deleting member, please try again");
          return;
        }
      }

      window.location.reload();
      onDelete();
      setShowModal(false);
    } catch (err) {
      setError("An error occurred while deleting the user. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    onCancel();
    setShowModal(false);
  };

  const message = `You are about to delete ${userName}'s seat. ${
    // if has active seats then remove
    hasActiveSeats && !isWithinFreeLimit
      ? `This will reduce your bill by €${seatAmount}.`
      : ""
  }`;

  return (
    <LibraryModal setShow={setShowModal} show={showModal}>
      <div className="px-6 py-10 lg:min-w-[500px] flex flex-col justify-center items-center">
        <div className="w-full flex flex-col justify-center items-center text-center">
          <p className="text-xl leading-7 font-semibold text-[#121217]">
            Delete {userName}'s account
          </p>
          <p className="text-base leading-6 font-medium text-[#6C6C89] mt-3.5">
            Please be certain! There's no going back
          </p>
        </div>
        <div className="w-full my-[68px]">
          <p className="text-lg leading-6 font-medium text-[#121217] text-center">
            {message}
          </p>

          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
              role="alert"
            >
              <span className="block text-sm">{error}</span>
            </div>
          )}
        </div>

        <div className="w-full flex justify-between items-center space-x-5">
          <button
            onClick={handleCancel}
            className="py-2 px-4 rounded-lg text-sm leading-5 font-medium text-black w-[48%] bg-white transition duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleDelete}
            disabled={isLoading}
            className={`py-2 px-4 rounded-lg text-base leading-6 font-medium text-white w-full max-w-[327px] ${
              isLoading ? "bg-red-300 cursor-not-allowed" : "bg-[#D50B3E]"
            } text-white transition duration-200`}
          >
            {isLoading ? "Deleting..." : "Delete User"}
          </button>
        </div>
      </div>
    </LibraryModal>
  );
};

export default DeleteUserModal;
