import axios from "axios";
import { ASSETS_IDS } from "../../Episode/components/types";
import { capitalizeFirst } from "../../../utils/common";

export const PLATFORM_COLORS: {
  [key: string]: { background: string; text: string; hover: string };
} = {
  transcript: { background: "#F3F4F6", text: "#4B5563", hover: "#E5E7EB" },
  podcast: { background: "#D1FAE5", text: "#065F46", hover: "#A7F3D0" },
  blog: { background: "#E5E7EB", text: "#1F2937", hover: "#D1D5DB" },
  linkedin: { background: "#DBEAFE", text: "#1E40AF", hover: "#BFDBFE" },
  twitter: { background: "#E0F2FE", text: "#0284C7", hover: "#BAE6FD" },
  youtube: { background: "#FEE2E2", text: "#B91C1C", hover: "#FECACA" },
  newsletter: { background: "#FEF3C7", text: "#92400E", hover: "#FDE68A" },
  meeting: { background: "#E0E7FF", text: "#3730A3", hover: "#C7D2FE" },
  education: { background: "#F3E8FF", text: "#6B21A8", hover: "#E9D5FF" },
  ebook: { background: "#FCE7F3", text: "#9D174D", hover: "#FBCFE8" },
};

export const ASSET_ID_TO_TEMPLATE_PILL_LABEL = (): {
  [key: string]: string;
} => ({
  [ASSETS_IDS.TRANSCRIPT]: "Transcript",
  [ASSETS_IDS.PODCAST_TITLES]: "Podcast Titles",
  [ASSETS_IDS.PODCAST_SUMMARY]: "Podcast Summary",
  [ASSETS_IDS.PODCAST_KEYWORDS]: "Podcast Keywords",
  [ASSETS_IDS.PODCAST_TIMESTAMPS]: "Podcast Timestamps",
  [ASSETS_IDS.PODCAST_QUOTES]: "Podcast Quotes",
  [ASSETS_IDS.PODCAST_KEYTAKEAWAYS]: "Podcast Key Takeaways",
  [ASSETS_IDS.PODCAST_MENTIONEDTOPICS]: "Podcast Mentioned Topics",
  [ASSETS_IDS.BLOG_POST]: "Blog Post",
  [ASSETS_IDS.BLOG_OUTLINE]: "Blog Outline",
  [ASSETS_IDS.LINKEDIN_POSTS]: "LinkedIn Posts",
  [ASSETS_IDS.LINKEDIN_CAROUSELS]: "LinkedIn Carousels",
  [ASSETS_IDS.TWITTER_TWEETS]: "Twitter Tweets",
  [ASSETS_IDS.TWITTER_THREADS]: "Twitter Threads",
  [ASSETS_IDS.TWITTER_LONGFORM]: "Twitter Longform",
  [ASSETS_IDS.YOUTUBE_TITLES]: "YouTube Titles",
  [ASSETS_IDS.YOUTUBE_TAGS]: "YouTube Tags",
  [ASSETS_IDS.YOUTUBE_DESCRIPTION]: "YouTube Description",
  [ASSETS_IDS.YOUTUBE_TIMESTAMPS]: "YouTube Timestamps",
  [ASSETS_IDS.NEWSLETTER_POSTS]: "Newsletter Posts",
  [ASSETS_IDS.MEETING_SUMMARY]: "Meeting Summary",
  [ASSETS_IDS.MEETING_FAQ]: "Meeting FAQ",
  [ASSETS_IDS.MEETING_REPORT]: "Meeting Report",
  [ASSETS_IDS.MEETING_MEMO]: "Meeting Memo",
  [ASSETS_IDS.MEETING_TRAINING]: "Meeting Training",
  [ASSETS_IDS.EDUCATION_CHECKLIST]: "Education Checklist",
  [ASSETS_IDS.EDUCATION_WORKSHEET]: "Education Worksheet",
  [ASSETS_IDS.EDUCATION_GLOSSARY]: "Education Glossary",
  [ASSETS_IDS.EDUCATION_FLASHCARDS]: "Education Flashcards",
  [ASSETS_IDS.EDUCATION_DISCUSSION]: "Education Discussion",
  [ASSETS_IDS.EDUCATION_CASESTUDY]: "Education Case Study",
  [ASSETS_IDS.EDUCATION_FOLLOWUP]: "Education Follow-up",
  [ASSETS_IDS.EBOOKS_TITLES]: "E-Book Titles",
  [ASSETS_IDS.EBOOKS_CHAPTER]: "E-Book Chapter",
  [ASSETS_IDS.EBOOKS_ENTIRE_PRODUCTION]: "Full E-Book",
});

export const TEMPLATE_ASSETS_IDS = () => {
  //  remove transcript, blog outline, blog post, ebook chapter, entire ebook from the final object
  let chatAssetIds = {};

  const keysToRemove = [
    ASSETS_IDS.TRANSCRIPT,
    ASSETS_IDS.BLOG_OUTLINE,
    ASSETS_IDS.BLOG_POST,
    ASSETS_IDS.EBOOKS_CHAPTER,
    ASSETS_IDS.EBOOKS_ENTIRE_PRODUCTION,
    ASSETS_IDS.EBOOKS_TITLES,
    ASSETS_IDS.TWITTER_THREADS,
  ];

  // Remove specified keys
  Object.keys(ASSETS_IDS).forEach((key) => {
    if (
      !keysToRemove.includes(
        ASSETS_IDS[key as keyof typeof ASSETS_IDS].toLowerCase()
      )
    ) {
      Object.assign(chatAssetIds, {
        [key]: ASSETS_IDS[key as keyof typeof ASSETS_IDS],
      });
    }
  });

  return chatAssetIds as Record<string, string>;
};

export const TEMPLATE_PLATFORMS = () => [
  ...new Set(
    Object.values(TEMPLATE_ASSETS_IDS())
      .filter(Boolean)
      .map((key) => capitalizeFirst(key.split("_")[0]))
  ),
];

export function addAlpha(color: string, opacity: number) {
  // coerce values so it is between 0 and 1.
  var _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export const getTemplateFromLLM = async ({
  assetId,
  goal,
  examples,
  supabase,
}: {
  assetId: string;
  goal: string;
  examples: string;
  supabase: any;
}) => {
  const body = {
    assetId,
    goal,
    examples,
  };

  const session = await supabase.auth.getSession();

  const data = await axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/build-template`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );

  return data.data as { template: string; title: string; overview: string };
};

export const getPlatformColorByAssetId = (assetId: string) => {
  const platform = Object.keys(PLATFORM_COLORS).find((key) =>
    assetId.toLowerCase().includes(key)
  );
  return (
    PLATFORM_COLORS[platform as keyof typeof PLATFORM_COLORS] || {
      background: "#DBEAFE",
      text: "#1E40AF",
      hover: "#BFDBFE",
    }
  );
};
