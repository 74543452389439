import { Editor } from "@tiptap/react";
import { ReactComponent as RewindSvg } from "../../../assets/rewind.svg";

export const RedoItem = ({ editor }: { editor: Editor | null }) => {
  const redo = () => editor?.commands.redo();
  return (
    <button
      className="flex justify-between items-center space-x-2"
      onClick={redo}
    >
      <RewindSvg />
      <p className=" font-medium text-[#84818A]">Redo</p>
    </button>
  );
};
