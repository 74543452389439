import { useCallback, useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

export const useNavigateWithParams = () => {
  const navigate = useNavigate();
  const params = useSearchParams();

  const [urlParams, setUrlParams] = useState({});

  useEffect(() => {
    const paramsObject = {};
    params[0].forEach((value, key) =>
      Object.assign(paramsObject, { [key]: value })
    );

    setUrlParams(paramsObject);
  }, [navigate, params[0]]);

  return useCallback(
    (path: string) =>
      navigate({
        pathname: path,
        search: createSearchParams(urlParams).toString(),
      }),
    [urlParams]
  );
};
