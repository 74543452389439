export const PADDLE_MAP = window.location.hostname?.includes("app.unifire.ai")
  ? {
      month: {
        small: {
          planPriceId: "pri_01j4w0g4vw10eejddej8db0775",
          seatPriceId: "pri_01j4w0s9x6pwv6zgzs1q8cxk85",
          amount: 16,
        },
        medium: {
          planPriceId: "pri_01j4w0vh0r42xdhzdhdc7fma11",
          seatPriceId: "pri_01j4w0warfabp6hf908kcg7ppk",
          amount: 28,
        },
        large: {
          planPriceId: "pri_01j5434jya9hjy2z2j51fb6514",
          seatPriceId: "pri_01j5435kym548bnv8ca3z5rrn1",
          amount: 199,
        },
      },

      year: {
        small: {
          planPriceId: "pri_01j542rfmwbpatbz0m6jj6vkwm",
          seatPriceId: "pri_01j542t2pec23x1tmpangmerwr",
          amount: 160,
        },
        medium: {
          planPriceId: "pri_01j542y4eprmbmmsxargak9k54",
          seatPriceId: "pri_01j542zqdfen5taa3atjtrdpy5",
          amount: 280,
        },
        large: {
          planPriceId: "pri_01j5438s13487v383ccdd5z6tq",
          seatPriceId: "pri_01j5439rn2r0g3yaz22k2wycbx",
          amount: 1990,
        },
      },
    }
  : {
      month: {
        small: {
          planPriceId: "pri_01j5423630mc439eh3efgfkwfd",
          seatPriceId: "pri_01j5425hmm9f12b2ac104n9geq",
          amount: 16,
        },

        medium: {
          planPriceId: "pri_01j5446q383y6za2b33a8vp0tg",
          seatPriceId: "pri_01j54482ryryhqwdpkwmm7gyre",
          amount: 28,
        },

        large: {
          planPriceId: "",
          seatPriceId: "",
          amount: 199,
        },
      },

      year: {
        small: {
          planPriceId: "",
          seatPriceId: "",
          amount: 160,
        },

        medium: {
          planPriceId: "",
          seatPriceId: "",
          amount: 280,
        },

        large: {
          planPriceId: "",
          seatPriceId: "",
          amount: 1990,
        },
      },
    };
