"use client";

import { TiptapCollabProvider } from "@hocuspocus/provider";
import "iframe-resizer/js/iframeResizer.contentWindow";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { Doc as YDoc } from "yjs";
import { BlockEditor } from "../../components/BlockEditor";
import { getEditorAuthTokens } from "../api/auth";

export interface AiState {
  isAiLoading: boolean;
  aiError?: string | null;
}

const useDarkmode = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    // typeof window !== "undefined"
    //   ? window.matchMedia("(prefers-color-scheme: dark)").matches
    //   : false
    false
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = () => setIsDarkMode(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleChange);
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", isDarkMode);
  }, [isDarkMode]);

  const toggleDarkMode = useCallback(
    () => setIsDarkMode((isDark) => !isDark),
    []
  );
  const lightMode = useCallback(() => setIsDarkMode(false), []);
  const darkMode = useCallback(() => setIsDarkMode(true), []);

  return {
    isDarkMode,
    toggleDarkMode,
    lightMode,
    darkMode,
  };
};

export const CollaborativeEditor = ({
  params,
  content,
  onChange,
}: {
  params: {
    uploadId: string;
    assetId: string;
    postId: string;
    userId: string | undefined;
    aiToken: string;
    collabToken: string;
    fullName: string;
    convertToken: string;
  };
  onChange: (value: string) => void;
  content: string;
}) => {
  // const { isDarkMode, darkMode, lightMode } = useDarkmode();
  const [provider, setProvider] = useState<TiptapCollabProvider | null>(null);

  // get("noCollab") as string) !== 1;

  const { uploadId, assetId, aiToken, collabToken, fullName, convertToken } =
    params;

  const ydoc = useMemo(() => new YDoc(), []);

  useLayoutEffect(() => {
    if (collabToken) {
      setProvider(
        new TiptapCollabProvider({
          name: `${uploadId}/${assetId}/${params.postId}`,
          appId: process.env.REACT_APP_TIPTAP_APP_ID ?? "",
          token: collabToken,
          document: ydoc,
        })
      );
    }
  }, [setProvider, collabToken, ydoc, uploadId, assetId]);

  if (!collabToken || !provider || !aiToken || !convertToken) {
    return <></>;
  }

  // const DarkModeSwitcher = createPortal(
  //   <Surface className="flex items-center gap-1 fixed bottom-6 right-6 z-[99999] p-1">
  //     <Toolbar.Button onClick={lightMode} active={!isDarkMode}>
  //       <Icon name="Sun" />
  //     </Toolbar.Button>
  //     <Toolbar.Button onClick={darkMode} active={isDarkMode}>
  //       <Icon name="Moon" />
  //     </Toolbar.Button>
  //   </Surface>,
  //   document.body
  // );

  return (
    <>
      {/* {DarkModeSwitcher} */}
      <BlockEditor
        aiToken={aiToken}
        convertToken={convertToken}
        hasCollab={true}
        ydoc={ydoc}
        provider={provider}
        onChange={onChange}
        content={content}
        fullName={fullName}
      />
    </>
  );
};

export default Document;
