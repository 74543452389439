export const EducationIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4461_11342)">
      <path
        d="M19.3953 5.07266C18.8707 3.13984 16.8605 1.12969 14.9277 0.605078C13.743 0.309375 12.1551 0.00507812 10 0C7.84531 0.00546875 6.25742 0.309375 5.07266 0.605078C3.13984 1.13008 1.12969 3.13984 0.605078 5.07266C0.309375 6.25742 0.00546875 7.84531 0 10C0.00546875 12.1551 0.309375 13.743 0.605078 14.9277C1.12969 16.8605 3.13984 18.8703 5.07266 19.3953C6.25742 19.691 7.84609 19.9949 10 20.0004C12.1551 19.9949 13.743 19.691 14.9277 19.3953C16.8605 18.8703 18.8707 16.8605 19.3953 14.9277C19.691 13.743 19.9949 12.1543 20.0004 10C19.9949 7.84531 19.691 6.25742 19.3953 5.07266Z"
        fill="#E6F1F4"
      />
      <path
        d="M15.8828 13.3949V9.57812L15.1562 9.82812V13.3949C15.3971 13.3584 15.642 13.3584 15.8828 13.3949Z"
        fill="url(#paint0_linear_4461_11342)"
      />
      <path
        d="M15.8828 13.3957L15.8914 13.3976C15.9661 13.4189 16.0338 13.4596 16.0876 13.5156C16.1414 13.5716 16.1794 13.6409 16.1977 13.7164C16.2113 13.7855 16.2277 14.1851 16.2332 14.5511C16.231 14.6163 16.2089 14.6792 16.1701 14.7315C16.1312 14.7839 16.0773 14.8231 16.0156 14.8441C15.6906 14.9312 15.3484 14.9312 15.0234 14.8441C14.9615 14.8233 14.9074 14.7841 14.8683 14.7318C14.8292 14.6794 14.807 14.6164 14.8047 14.5511C14.8102 14.1859 14.8266 13.7855 14.8402 13.7164C14.8586 13.6409 14.8966 13.5717 14.9504 13.5157C15.0042 13.4597 15.0718 13.4189 15.1465 13.3976L15.1551 13.3957C15.3963 13.359 15.6416 13.359 15.8828 13.3957Z"
        fill="url(#paint1_linear_4461_11342)"
      />
      <path
        d="M15.1563 9.82842C13.7177 10.337 12.3276 10.9035 10.986 11.528C10.6772 11.6718 10.3408 11.7463 10.0002 11.7463C9.65966 11.7463 9.32321 11.6718 9.01448 11.528C7.36459 10.7624 5.67209 10.0922 3.94534 9.52061C3.2594 9.29678 3.13518 8.4167 3.77229 8.03115C5.42722 7.03587 7.13654 6.13394 8.89221 5.32959C9.2404 5.17222 9.61812 5.09082 10.0002 5.09082C10.3823 5.09082 10.76 5.17222 11.1082 5.32959C12.8639 6.13394 14.5732 7.03587 16.2282 8.03115C16.8653 8.4167 16.741 9.29561 16.0543 9.52061L15.8828 9.57842L15.1563 9.82842Z"
        fill="url(#paint2_linear_4461_11342)"
      />
      <path
        d="M10.986 11.5285C10.6773 11.6723 10.3409 11.7468 10.0003 11.7468C9.65973 11.7468 9.32329 11.6723 9.01455 11.5285C7.88799 11.0051 6.72575 10.521 5.52783 10.0762C5.56025 10.9035 5.65713 12.6027 5.70635 13.2309C5.73604 13.5898 6.00635 14.0051 6.33994 14.1531C7.49569 14.6523 8.74134 14.9099 10.0003 14.9099C11.2592 14.9099 12.5049 14.6523 13.6606 14.1531C13.9942 14.0051 14.2646 13.5898 14.2942 13.2309C14.3435 12.6031 14.4403 10.9047 14.4728 10.077C13.2759 10.5207 12.1136 11.0046 10.986 11.5285Z"
        fill="url(#paint3_linear_4461_11342)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4461_11342"
        x1="16.6555"
        y1="12.6223"
        x2="14.5086"
        y2="10.4758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FE7838" />
        <stop offset="0.43" stop-color="#FE7636" />
        <stop offset="0.97" stop-color="#FFAD8A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4461_11342"
        x1="17.3574"
        y1="16.0058"
        x2="14.8727"
        y2="13.5207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF6224" />
        <stop offset="0.54" stop-color="#FF6224" />
        <stop offset="1" stop-color="#FFAD8A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4461_11342"
        x1="14.8516"
        y1="13.5901"
        x2="6.55862"
        y2="5.29717"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#34344F" />
        <stop offset="0.26" stop-color="#3B3B57" />
        <stop offset="0.66" stop-color="#50506D" />
        <stop offset="1" stop-color="#666684" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4461_11342"
        x1="13.0728"
        y1="14.8227"
        x2="2.98643"
        y2="4.73633"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#26263D" />
        <stop offset="0.21" stop-color="#2D2D45" />
        <stop offset="0.52" stop-color="#41415B" />
        <stop offset="0.91" stop-color="#61617E" />
        <stop offset="0.97" stop-color="#666684" />
      </linearGradient>
      <clipPath id="clip0_4461_11342">
        <rect width="20" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
