import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";
import { ReactComponent as MicSvg } from "../../../assets/mic.svg";
import { SpeakerLabelType } from "../../types";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "6px",
    zIndex: 51,
  },
};

export const SpeakerLabel = ({
  speakers,
  onSpeakersUpdated,
}: {
  speakers: SpeakerLabelType[];
  onSpeakersUpdated: (speakers: SpeakerLabelType[]) => void;
}) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <button
        className="flex justify-between items-center space-x-2"
        onClick={() => {
          setShow(!show);
        }}
      >
        <MicSvg />
        <p className=" font-medium text-[#42474B]">Edit Speaker Labels</p>
      </button>
      {show && (
        <ReplaceModal
          setShow={setShow}
          show={show}
          speakers={speakers}
          onSpeakersUpdated={onSpeakersUpdated}
        />
      )}
    </div>
  );
};

const ReplaceModal = ({
  setShow,
  show,
  speakers,
  onSpeakersUpdated,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  speakers: SpeakerLabelType[];
  onSpeakersUpdated: (speakers: SpeakerLabelType[]) => void;
}) => {
  const [localSpeakers, setLocalSpeakers] = useState(speakers);

  const updateSpecificSpeakerLabel = ({
    value,
    label,
  }: {
    value: number;
    label: string;
  }) => {
    const newSpeakers = localSpeakers.map((s) => {
      if (s.value === value) {
        return { ...s, label };
      }
      return s;
    });
    setLocalSpeakers(newSpeakers);
  };

  // if the label is empty, make it equal the value
  const handleSubmit = () => {
    const newSpeakers = localSpeakers.map((s) => {
      if (!s.label) {
        return { ...s, label: s.value.toString() };
      }

      return s;
    });
    onSpeakersUpdated(newSpeakers);
    setShow(false);
  };

  return (
    <div className="w-full h-full ">
      <Modal
        isOpen={show}
        onRequestClose={() => setShow(!show)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className=" max-w-[300px] min-h-[250px] md:min-w-[650px] md:min-h-[300px] px-8 "
          role="document"
        >
          <div className="mt-4">
            <p className="block font-semibold mb-2 w-full text-center text-16 text-[#202020]">
              Edit Speaker Labels
            </p>
            <p className="text-[#202020] text-16 text-center">
              Give your speakers real-names
            </p>

            <div className="mt-10 space-y-5 ">
              {localSpeakers.map(({ value }, idx) => (
                <div key={idx}>
                  <span className="text-12 font-medium">
                    Speaker: {value + 1}
                  </span>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Name"
                    className="appearance-none mt-1.5 border border-gray-400/30 rounded-xl text-gray-900 w-full p-3 leading-tight focus:outline-none focus:shadow-outline"
                    defaultValue={
                      speakers.find((s) => s.value === value)?.label
                    }
                    onChange={(e) => {
                      if (!e.currentTarget.value) {
                        updateSpecificSpeakerLabel({
                          value,
                          label: speakers.find((s) => s.value === value)
                            ?.label as string,
                        });
                      } else {
                        updateSpecificSpeakerLabel({
                          value,
                          label: e.currentTarget.value,
                        });
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="mt-20 w-full flex justify-end items-center">
            <button
              type="submit"
              className="bg-uf-yellow-1 text-12 font-semibold text-[#202020] py-2.5 px-14 rounded-lg focus:outline-none focus:shadow-outline disabled:cursor-not-allowed w-full"
              onClick={handleSubmit}
            >
              Update Speakers
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SpeakerLabel;
