import { AxiosError } from "axios";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useParams } from "react-router";
import WarningLottie from "../../../../../assets/lotties/warning.json";
import WritingLottie from "../../../../../assets/lotties/writing.json";
import { LibraryModal } from "../../../../../shared/Library/Modal";
import GenerateAssetsFlow from "../../../../GenerateAssetsFlow";
import { useSupabase } from "../../../../../hooks/useSupabase";
import LoadingLottie from "../../../../../shared/Lottie/LoadingLottie";
import { submitAssets } from "../../../../EditableTranscript/utils";
import { getAssetTemplatesByUploadId } from "../../../../../api/db/query";

export const RecoverSkippedAsset = ({
  assetId,
  className,
}: {
  assetId: string;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
}) => {
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1); // 1 is default message step, 2 is loading, 3 is writing.

  const [stepState, setStepState] = useState<{
    lottie: any;
    text: string;
    buttonText: string;
  }>({
    lottie: WarningLottie,
    text: "You skipped this asset, do you want to generate it?",
    buttonText: "Write content",
  });

  const { id: uploadId } = useParams<{ id: string }>();
  // supabase
  const supabase = useSupabase();

  const handleSubmit = async (customInstruction: string) => {
    try {
      setStep(2);

      const templateIds =
        (
          await getAssetTemplatesByUploadId({
            uploadId: uploadId as string,
            supabase,
            onlySelectedAssets: [assetId],
          })
        )
          ?.filter((t) => t.asset_id === assetId)
          .map((t) => t.template_id as string) ?? [];
      await submitAssets({
        episodeId: uploadId as string,
        assetId,
        supabase,
        customInstruction,
        templateIds,
      });
      // setStep(3);
    } catch (error: any) {
      setStep(1);
      alert((error as AxiosError)?.message);
    }
  };

  // step-state changer
  useEffect(() => {
    if (step === 1) {
      setStepState({
        lottie: WarningLottie,
        text: "You skipped this asset, do you want to generate it?",
        buttonText: "Write content",
      });
    } else if (step === 2) {
      setStepState({
        lottie: LoadingLottie,
        text: "Submitting your request...",
        buttonText: "",
      });
    } else if (step === 3) {
      setStepState({
        lottie: WritingLottie,
        text: "Request successful, We are writing your assets...",
        buttonText: "",
      });
    }
  }, [step]);

  return (
    <div
      className={classNames(
        "rounded-lg overflow-clip h-full w-full py-10",
        className
      )}
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="pointer-events-none">
          <Lottie
            width={step === 1 ? 42 : 80}
            height={step === 1 ? 42 : 80}
            options={{
              loop: step !== 1,
              autoplay: true,
              animationData: stepState.lottie,
            }}
          />
        </div>
        <div
          className={classNames(
            "font-semibold font-inter text-14 px-8 text-center",
            {
              "mt-3 ": step === 1,
            }
          )}
        >
          <p>{stepState.text}</p>
          {Boolean(stepState.buttonText) && (
            <button
              className="px-10 py-2 bg-uf-yellow-1 rounded-lg mt-7 text-14"
              onClick={() => setShow(true)}
            >
              {stepState.buttonText}
            </button>
          )}
        </div>
      </div>

      <LibraryModal setShow={setShow} show={show}>
        <GenerateAssetsFlow
          noDbRecordCreation
          assetIds={[assetId]}
          uploadId={uploadId as string}
          onConfirmAdditionalGenerations={(asset) => {
            handleSubmit(asset[0].instructions || "");
          }}
        />
      </LibraryModal>
    </div>
  );
};
