export const BuildingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 6.75C5 5.23122 6.23122 4 7.75 4H16.25C17.7688 4 19 5.23122 19 6.75V18.5H19.25C19.6642 18.5 20 18.8358 20 19.25C20 19.6642 19.6642 20 19.25 20H18.25H14.25H9.75H5.75H4.75C4.33579 20 4 19.6642 4 19.25C4 18.8358 4.33579 18.5 4.75 18.5H5V6.75ZM10.5 18.5H13.5V15.75C13.5 15.0596 12.9404 14.5 12.25 14.5H11.75C11.0596 14.5 10.5 15.0596 10.5 15.75V18.5ZM15 18.5V15.75C15 14.2312 13.7688 13 12.25 13H11.75C10.2312 13 9 14.2312 9 15.75V18.5H6.5V6.75C6.5 6.05964 7.05964 5.5 7.75 5.5H16.25C16.9404 5.5 17.5 6.05964 17.5 6.75V18.5H15ZM9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10ZM14 9C13.4477 9 13 9.44772 13 10C13 10.5523 13.4477 11 14 11C14.5523 11 15 10.5523 15 10C15 9.44772 14.5523 9 14 9Z"
      fill="#8A8AA3"
    />
  </svg>
);
