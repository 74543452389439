export const CheckButton = ({
  isChecked,
  onClick,
  label,
}: {
  isChecked: boolean;
  onClick: () => void;
  label: string;
}) => {
  // yellow background cirlce with a check mark white

  return (
    <div className="w-auto inline-block">
      <button
        className={` border-[#D1D1DB] flex justify-center items-center cursor-pointer rounded-[7px] pl-3 pr-4 border space-x-2`}
        onClick={onClick}
      >
        <input
          className="!accent-[#7047EB]  w-4 h-4"
          type="checkbox"
          checked={isChecked}
        />
        <span className="text-14 font-medium text-[#121217] px-1 py-[7px]">
          {label}
        </span>{" "}
      </button>
    </div>
  );
};
