export const SpanishFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_31106)">
      <path
        d="M-3.05176e-05 11.9996C-3.05176e-05 13.4674 0.264016 14.8735 0.74636 16.1735L12 17.217L23.2536 16.1735C23.7359 14.8735 24 13.4674 24 11.9996C24 10.5318 23.7359 9.12569 23.2536 7.82571L12 6.78223L0.74636 7.82571C0.264016 9.12569 -3.05176e-05 10.5318 -3.05176e-05 11.9996Z"
        fill="#FFDA44"
      />
      <path
        d="M23.2551 7.82611C21.5596 3.25655 17.1611 0 12.0015 0C6.84192 0 2.44341 3.25655 0.747894 7.82611H23.2551Z"
        fill="#D80027"
      />
      <path
        d="M0.746918 16.1748C2.44243 20.7444 6.84095 24.0009 12.0005 24.0009C17.1601 24.0009 21.5586 20.7444 23.2541 16.1748H0.746918Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_31106">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
