import { useContext } from "react";
import { RemainingCreditsContext } from "../contexts/RemainingCreditsProvider";

// Custom hook to use the remainingCredits
export const useRemainingCredits = () => {
  const context = useContext(RemainingCreditsContext);
  if (context === undefined) {
    throw new Error(
      "useRemainingCredits must be used within a RemainingCreditsProvider"
    );
  }
  return context;
};
