export const NetherlandsFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_31056)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.0002 -0.000976562C6.8406 -0.000976562 2.44208 3.25552 0.746567 7.82513H23.2538C21.5583 3.25552 17.1598 -0.000976562 12.0002 -0.000976562Z"
        fill="#A2001D"
      />
      <path
        d="M12.0002 23.9999C17.1598 23.9999 21.5583 20.7434 23.2538 16.1738H0.746582C2.4421 20.7434 6.84061 23.9999 12.0002 23.9999Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_31056">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
