import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { useRole } from "../../../hooks/userCanEdit";
import { useSupabase } from "../../../hooks/useSupabase";
import { useWorkspace } from "../../../hooks/useWorkspace";
import { AssetDbType, UploadDbType } from "../../../types";
import { sleep } from "../../../utils";
import { getFileTypeAnalytics } from "../../../utils/common";
import { submitTranscript } from "../../EditableTranscript/components/EditableTranscriptEditor/EditorMenu";
import { FullScreenLoading } from "../../EditableTranscript/components/FullscreenLoading";
import { submitAssets } from "../../EditableTranscript/utils";
import { GenerateMoreButton } from "./FlowComponents/GenerateMore/GenerateMoreButton";
import { ASSETS_IDS } from "./types";

export const EpisodeSidebar = ({
  upload,
  assets,
}: {
  upload?: UploadDbType;
  assets: AssetDbType[];
}) => {
  const [startWritingAssets, setStartWritingAssets] = useState(false);
  const [selectedOutlineId, setSelectedOutlineId] = useState<string | null>(
    null
  );
  const [selectedAssetId, setSelectedAssetId] = useState<
    (typeof ASSETS_IDS)[keyof typeof ASSETS_IDS] | null
  >(null);

  const supabase = useSupabase();

  const [loading, setLoading] = useState(false);
  const { id: uploadId, projectId } = useParams<{
    id: string;
    projectId: string;
  }>();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setSelectedOutlineId(searchParams.get("post"));
    setSelectedAssetId(
      searchParams.get("tab") as (typeof ASSETS_IDS)[keyof typeof ASSETS_IDS]
    );
  }, [location]);

  const handleSubmitOutlineForBlog = async () => {
    if (!uploadId || !selectedOutlineId) {
      alert("Error, please reload the page");
      return;
    }

    try {
      setLoading(true);
      await submitAssets({
        episodeId: uploadId,
        assetId: ASSETS_IDS.BLOG_POST,
        customInstruction: "",
        supabase,
        outlineId: selectedOutlineId,
        templateIds: [],
      });
      window.location.href = `/dashboard/project/${projectId}/generation/${uploadId}?asset=blog_post`;
    } catch (error: any) {
      alert((error as AxiosError)?.message);
      setLoading(false);
    }
  };

  const handleSubmitTranscript = async () => {
    setLoading(true);

    await submitTranscript({
      uploadId: uploadId as string,
      supabase,
    })
      .then((response) => {
        window.location.reload();
      })
      .catch(async (error: AxiosError) => {
        setStartWritingAssets(false);
        const message =
          (error.response?.data as any)?.message || "An error occurred";
        if (!message.includes("submitted")) {
          for (let i = 0; i < 3; i++) {
            const { error } = await supabase
              .from("Upload")
              .update({ status: "failed" })
              .eq("id", uploadId as string);
            if (!error) {
              break;
            }
            await sleep(1000);
          }
          alert((error.response?.data as any)?.message || "An error occurred");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="max-w-[300px]">
      <div className="fixed top-0 max-w-[241px]">
        {upload?.status === "edit" && (
          <div className="mt-3">
            <button
              className="text-start rounded-xl border border-[#FFE476] pt-[7px] pb-[12px] px-4 disabled:bg-gray-100 disabled:border-gray-100 shadow"
              onClick={() => {
                setStartWritingAssets(true);
                handleSubmitTranscript();
              }}
              disabled={startWritingAssets}
            >
              <span className="text-xs leading-5 font-medium text-[#6C6C89]">
                Next Step
              </span>

              <span className="block mt-[3px] text-sm leading-5 font-semibold text-[#121217]">
                Approve your transcript to generate content
              </span>
            </button>
          </div>
        )}
        {upload?.status !== "transcribing" && upload?.status !== "edit" && (
          <GenerateMoreButton
            assetId={
              location.search.includes("asset=")
                ? (new URLSearchParams(location.search).get("asset") as string)
                : ""
            }
            disable={
              location.search.includes("asset=transcript") ||
              assets.find(
                (a) =>
                  a.asset_id ===
                  new URLSearchParams(location.search).get("asset")
              )?.status === "failed" ||
              assets.find(
                (a) =>
                  a.asset_id ===
                  new URLSearchParams(location.search).get("asset")
              )?.status === "writing"
            }
          />
        )}

        {selectedOutlineId && selectedAssetId === ASSETS_IDS.BLOG_OUTLINE && (
          <div className="mt-3">
            <button
              className="text-start rounded-xl border border-[#FFE476] pt-[7px] pb-[12px] px-4 disabled:bg-gray-100 disabled:border-gray-100 shadow"
              onClick={handleSubmitOutlineForBlog}
              disabled={
                location.search.includes("asset=transcript") ||
                assets.find(
                  (a) =>
                    a.asset_id ===
                    new URLSearchParams(location.search).get("asset")
                )?.status === "failed" ||
                assets.find(
                  (a) =>
                    a.asset_id ===
                    new URLSearchParams(location.search).get("asset")
                )?.status === "writing"
              }
            >
              <span className="text-xs leading-5 font-medium text-[#6C6C89]">
                Next Step
              </span>

              <span className="block mt-[3px] text-sm leading-5 font-semibold text-[#121217]">
                Click here to generate a blog post from your outline
              </span>
            </button>
          </div>
        )}
      </div>
      {loading && <FullScreenLoading />}
    </div>
  );
};
