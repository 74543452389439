import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "6px",
    zIndex: 51,
  },
};

export const PLAN_TITLE_MAP = {
  [process.env.REACT_APP_P1 as string]:
    process.env.REACT_APP_P1_TITLE + " Monthly",
  [process.env.REACT_APP_P1_YEAR as string]:
    process.env.REACT_APP_P1_TITLE + " Yearly",

  [process.env.REACT_APP_P2 as string]:
    process.env.REACT_APP_P2_TITLE + " Monthly",
  [process.env.REACT_APP_P2_YEAR as string]:
    process.env.REACT_APP_P2_TITLE + " Yearly",

  [process.env.REACT_APP_P3 as string]:
    process.env.REACT_APP_P3_TITLE + " Monthly",
  [process.env.REACT_APP_P3_YEAR as string]:
    process.env.REACT_APP_P3_TITLE + " Yearly",

  [process.env.REACT_APP_LEMON_VARIANT_ENTERPRISE_YEARLY as string]:
    "Custom Plan Yearly",
  [process.env.REACT_APP_LEMON_VARIANT_ENTERPRISE_MONTHLY as string]:
    "Custom Plan Monthly",
};

const getActionDescription = (
  planId: string,
  planLabel: "TO_CANCEL" | "TO_CHANGE_PLAN"
) => {
  const ACTION_DESCRIPTION_MAP = {
    TO_CANCEL: <span>Are you sure you want to cancel your subscription?</span>,
    TO_CHANGE_PLAN: (
      <span>
        You are about to change your plan to{" "}
        <span className="font-semibold">{PLAN_TITLE_MAP[planId]}</span> <br />
        <br />
        <span className="font-semibold">Do you confirm?</span>
      </span>
    ),
  };

  return ACTION_DESCRIPTION_MAP[planLabel];
};

Modal.setAppElement("#root");
const ConfirmationModal = ({
  show,
  onCancel,
  onConfirm,
  setShow,
  variantId,
  action,
  downgrade = false,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
  variantId: string;
  action: "TO_CANCEL" | "TO_CHANGE_PLAN";
  downgrade?: boolean;
}) => {
  const description = getActionDescription(variantId, action);
  return (
    <>
      <Modal
        isOpen={show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setShow(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className="relative max-w-[300px] min-h-[300px] md:min-w-[600px] md:min-h-[350px] px-8 pb-8 pt-4 rounded-xl overflow-hidden flex justify-center items-center items-center "
          role="document"
        >
          <div className="w-full h-full  space-y-24 flex flex-col justify-between items-center whitespace-pre-line">
            <p className="text-20 font-semibold text-uf-slate-1 ">
              {action === "TO_CANCEL" ? "Cancel Subscription" : "Change Plan"}
            </p>

            <p className="text-16 text-uf-slate-1 text-center fon">
              {description}
            </p>

            {action === "TO_CANCEL" && (
              <div className="flex justify-between w-full px-10">
                <button
                  type="button"
                  className="px-8 py-2 text-14  bg-white border border-black/20 text-[#202020] rounded-md font-medium"
                  onClick={onConfirm}
                >
                  {downgrade ? "Downgrade" : "Cancel"} subscription
                </button>
                <button
                  type="button"
                  className="px-8 py-2 text-14 rounded-md bg-uf-yellow-1 text-black font-medium"
                  onClick={onCancel}
                >
                  Dismiss
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
