import { useEffect, useRef, useState } from "react";

import {
  getAssetTemplatesByUploadId,
  getAvailableTemplatesByWorkspaceId,
} from "../../../api/db/query";
import { useAuthUserId } from "../../../hooks/useAuth";
import { useRemainingCredits } from "../../../hooks/useRemainingCredits";
import { useSupabase } from "../../../hooks/useSupabase";
import { useWorkspace } from "../../../hooks/useWorkspace";
import {
  AssetDbType,
  AssetTemplateDbType,
  TemplateDbType,
} from "../../../types";
import { getPublicCreditsBreakdown } from "../../../utils/payment";
import { ASSETID_PLATFORM_MAPPING } from "../../Episode/components/types";
import { AssetInstructions } from "./AssetInstructions";
import { ProgressFlow } from "./ProgressFlow";
import { TEMPLATE_ASSETS_IDS } from "../../Templates/utils";

export type AssetProgressType = (typeof ASSETID_PLATFORM_MAPPING)[number] & {
  status: "confirmed" | "skipped" | "not_reached";
  generations: number;
  instructions: string;
  credits: number;
  templates: TemplateDbType[];
};

export const GenerateAssetsFlow = ({
  isPublic,
  noDbRecordCreation,
  assetIds = [],
  onConfirmAssets,
  onConfirmAdditionalGenerations,
  uploadId,
}:
  | {
      noDbRecordCreation?: false | undefined;
      isPublic?: boolean;
      assetIds: string[];
      onConfirmAssets: (
        _: AssetProgressType[],
        skipTranscript: boolean
      ) => void;
      onConfirmAdditionalGenerations?: (
        _: AssetProgressType[],
        selectedTemplates: TemplateDbType[],
        skipTranscript: boolean
      ) => void;
      uploadId: string;
    }
  | {
      noDbRecordCreation: true;
      isPublic?: boolean;
      assetIds: string[];
      onConfirmAdditionalGenerations: (
        _: AssetProgressType[],
        selectedTemplates: TemplateDbType[],
        skipTranscript: boolean
      ) => void;
      onConfirmAssets?: () => void;
      uploadId: string;
    }) => {
  const supabase = useSupabase();
  const workspace = useWorkspace();
  const { ownerCredits: remainingCredits } = useRemainingCredits();

  const [uploadAssetList, setUploadAssetList] = useState<AssetProgressType[]>();
  // current asset
  const [currentAsset, setCurrentAsset] = useState<AssetProgressType>();
  // current templates
  const [availableTemplates, setAvailableTemplates] =
    useState<TemplateDbType[]>();

  const [showSuccessScreen, setshowSuccessScreen] = useState(false);

  const didTrigger = useRef(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const userId = useAuthUserId();

  const [defaultSkipTranscriptEditing, setDefaultSkipTranscriptEditing] =
    useState<boolean>();

  const [selectedTemplates, setSelectedTemplates] =
    useState<TemplateDbType[]>();

  const multiSelectAssetIds = [
    TEMPLATE_ASSETS_IDS().TWITTER_TWEETS,
    TEMPLATE_ASSETS_IDS().TWITTER_LONGFORM,
    // TEMPLATE_ASSETS_IDS().YOUTUBE_POSTS,
    TEMPLATE_ASSETS_IDS().LINKEDIN_POSTS,
  ];

  const isReady =
    defaultSkipTranscriptEditing !== undefined &&
    availableTemplates !== undefined &&
    selectedTemplates !== undefined &&
    uploadAssetList !== undefined &&
    currentAsset !== undefined;

  useEffect(() => {
    if (didTrigger.current) {
      return;
    } else didTrigger.current = true;

    if (!workspace?.id) return;

    supabase
      .from("Upload")
      .select("*")
      .eq("workspace_id", workspace.id)
      .order("created_at", { ascending: false })
      .in("status", ["ready", "failed", "edit", "writing"])
      .limit(1)
      .maybeSingle()
      .then(({ data, error }) => {
        setDefaultSkipTranscriptEditing(
          data?.skip_transcript_editing === false ? false : true // keep like this to account for the  null case forwarding to true
        );
      });

    Promise.all([
      getAvailableTemplatesByWorkspaceId({
        workspaceId: workspace.id,
        supabase,
      }),
      getAssetTemplatesByUploadId({
        uploadId,
        supabase,
        onlySelectedAssets: assetIds,
      }),
    ]).then(([allTemplatesData, assetTemplatesData]) => {
      setAvailableTemplates(allTemplatesData as TemplateDbType[]);

      const assetTemplates =
        assetTemplatesData?.map((d) => d.Template as TemplateDbType) ?? [];
      // now instead of getting all templates, lets get only the one for the assetIds of this flow. Since the same flow can
      // be triggered for the "generate more button", we don't want to get the full ids for all assets when it's the "generate more for 1 asset" case
      const previouslySelectedAssetTemplates = assetTemplates.filter(
        (template) => assetIds.includes(template?.asset_id as string)
      );
      const alwaysDefaultAssetTemplates = (allTemplatesData as TemplateDbType[])
        .filter((template) => assetIds.includes(template?.asset_id as string))
        .filter((template) => template.is_enabled);

      const defaultSelectedTemplates = [
        ...previouslySelectedAssetTemplates,
        ...alwaysDefaultAssetTemplates,
      ].filter(
        (template, index, self) =>
          index ===
          self.findIndex(
            (t) => t.asset_id === template.asset_id && t.id === template.id
          )
      );
      // now group the default selected templates by asset_id
      const groupedSelectedTempaltes = defaultSelectedTemplates.reduce(
        (acc, curr) => {
          if (!acc[curr?.asset_id ?? ""]) {
            acc[curr?.asset_id ?? ""] = [];
          }
          acc[curr?.asset_id ?? ""].push(curr);
          return acc;
        },
        {} as { [key: string]: TemplateDbType[] }
      );

      // now filter down the selected templates to 1 if it s not a multi select asset
      const selectedTemplates = Object.keys(groupedSelectedTempaltes).map(
        (key) => {
          if (multiSelectAssetIds.includes(key)) {
            return groupedSelectedTempaltes[key];
          } else {
            return [groupedSelectedTempaltes[key][0]];
          }
        }
      );

      setSelectedTemplates(selectedTemplates.flat());
    });

    const creditBreakdown = getPublicCreditsBreakdown({
      assetIds,
    });

    creditBreakdown.then((res) => {
      //if result doesn thave key assetsBreakdown wrap everything in it
      Object.assign(res, {
        assetsBreakdown: res.assetsBreakdown || res,
      });
      const assetsCredits = res.assetsBreakdown.map((a) => {
        return {
          id: a.id,
          credits: a.credits,
        };
      });
      const localUploadAssetList = assetsCredits.map(({ id }) => {
        return {
          label: ASSETID_PLATFORM_MAPPING.find((asset) => asset.value === id)
            ?.label,
          value: id,
          status: "not_reached",
          instructions: "",
          credits: assetsCredits.find((asset) => asset.id === id)?.credits || 0,
        } as AssetProgressType;
      });
      if (localUploadAssetList.length)
        setCurrentAsset(localUploadAssetList[0] as AssetProgressType);
      setUploadAssetList(localUploadAssetList);
    });
  }, [supabase, workspace?.id, assetIds, workspace, didTrigger, isPublic]);

  const updateUploadDb = async (
    assetsInput: AssetProgressType[],
    selectedTemplates: TemplateDbType[],
    skipTranscriptEditing: boolean
  ) => {
    // const assets = sanitizeAssets(assetsInput);
    const assets = assetsInput;
    // if is public, just call onConfirmAssets return
    if (noDbRecordCreation) {
      onConfirmAdditionalGenerations(
        assets as AssetProgressType[],
        selectedTemplates as TemplateDbType[],
        skipTranscriptEditing
      );
      return;
    }

    if (!workspace?.id || !uploadId) return;

    setIsSubmitting(true);

    // now prepare the assets progress status. {asset_id: {status: 'writing', generations: 1}}
    const assetsProgress = assets.reduce((acc, curr) => {
      acc[curr.value as string] = {
        status: curr.status === "skipped" ? "skipped" : "writing",
        generations: curr.status === "skipped" ? 0 : 1,
        credits: curr.credits,
        instructions: curr.instructions,
      };
      return acc;
    }, {} as { [key: string]: any });

    for (let index = 0; index < 6; index++) {
      const data = await supabase
        .from("Upload")
        .update({
          skip_transcript_editing: skipTranscriptEditing,
          user_id: userId,
        })
        .eq("id", uploadId as string);
      if (Boolean(data.error)) {
        if (index === 5) {
          alert(
            "Could not submit the upload, please make sure you have a stable internet connection and try again."
          );
          window.location.reload();
        }
        continue;
      } else {
        break;
      }
    }
    const assetIds = Object.keys(assetsProgress);
    let dbAssets: AssetDbType[] = [];
    for (let index = 0; index < 6; index++) {
      const resp = await supabase
        .from("Asset")
        .insert(
          assetIds.map((asset) => ({
            upload_id: uploadId,
            status: assetsProgress[asset].status,
            instructions: assetsProgress[asset].instructions,
            generations: assetsProgress[asset].generations,
            credits: assetsProgress[asset].credits,
            workspace_id: workspace.id,
            asset_id: asset,
            data: [],
          }))
        )
        .select("*");

      if (Boolean(resp.error)) {
        if (index === 5) {
          alert(
            "Could not submit the upload, please make sure you have a stable internet connection and try again."
          );
          window.location.reload();
        }
        continue;
      } else {
        dbAssets = resp.data as AssetDbType[];
        break;
      }
    }

    // asset_id: string | null
    //       created_at: string
    //       id: string
    //       upload_id: string | null
    //       workspace_id: string | null

    // get the assets from the AssetTemplate and remove them from the list so they aren't added again

    const assetTemplatesToInsert: AssetTemplateDbType[] = [];

    assets
      .filter((asset) => asset.templates.length)
      .forEach((asset) => {
        const assetObj = dbAssets.find(
          (dbAsset) => dbAsset.asset_id === asset.value
        );
        const assetId = assetObj?.asset_id;
        const assetRecordId = assetObj?.id;

        asset.templates.forEach((template) => {
          assetTemplatesToInsert.push({
            asset_id: assetId as string,
            asset_record_id: assetRecordId as string,
            template_id: template.id,
            upload_id: uploadId,
            workspace_id: workspace?.id,
          } as AssetTemplateDbType);
        });
      });

    for (let index = 0; index < 6; index++) {
      const data = await supabase
        .from("AssetTemplate")
        .insert(assetTemplatesToInsert);

      if (Boolean(data.error)) {
        if (index === 5) {
          alert(
            "Could not submit the upload, please make sure you have a stable internet connection and try again."
          );
          window.location.reload();
        }
        continue;
      } else {
        break;
      }
    }

    for (let index = 0; index < 6; index++) {
      const data = await supabase
        .from("Upload")
        .update({
          is_complete: true,
        })
        .eq("id", uploadId as string);
      if (Boolean(data.error)) {
        if (index === 5) {
          alert(
            "Could not submit the upload, please make sure you have a stable internet connection and try again."
          );
          window.location.reload();
        }
        continue;
      } else {
        break;
      }
    }

    setIsSubmitting(false);
    onConfirmAssets(assets as AssetProgressType[], skipTranscriptEditing);
  };

  //   on confirm, mark the next asset if any as confirmed then move on to the next
  const onConfirm = (
    instructions: string,
    templates: TemplateDbType[],
    skipTranscriptEditing: boolean
  ) => {
    if (!currentAsset) return;
    if (!uploadAssetList) return;

    const assetsCopy = [...uploadAssetList];
    // find current index, then update next asset as confirmed already
    const currentIdx = uploadAssetList
      .map((asset) => asset.value)
      .indexOf(currentAsset.value);

    assetsCopy[currentIdx].status = "confirmed";
    assetsCopy[currentIdx].instructions = instructions;
    assetsCopy[currentIdx].templates = templates;

    const currentSelectedTemplates = [...(selectedTemplates ?? [])];

    // remove the templates for the current assetId
    const newTemplates = currentSelectedTemplates.filter(
      (template) => template.asset_id !== currentAsset.value
    );

    // add the new templates
    newTemplates.push(...templates);

    setSelectedTemplates(newTemplates);
    // if (assetsCopy[currentIdx + 1]) {
    //   assetsCopy[currentIdx + 1].status = "confirmed";
    // }

    setUploadAssetList(assetsCopy as AssetProgressType[]);

    if (currentIdx < assetsCopy.length - 1) {
      setCurrentAsset(assetsCopy[currentIdx + 1] as AssetProgressType);
    } else {
      setshowSuccessScreen(true);

      updateUploadDb(
        assetsCopy as AssetProgressType[],
        newTemplates,
        skipTranscriptEditing
      );
    }
  };

  const onSkip = (skipTranscriptEditing: boolean) => {
    if (!currentAsset) return;
    if (!uploadAssetList) return;

    const assetsCopy = [...uploadAssetList];
    // find current index, then update next asset as confirmed already
    const currentIdx = uploadAssetList
      .map((asset) => asset.value)
      .indexOf(currentAsset.value);

    assetsCopy[currentIdx].status = "skipped";
    assetsCopy[currentIdx].instructions = "";

    // remove the templates for the current assetId
    const newTemplates =
      selectedTemplates?.filter(
        (template) => template.asset_id !== currentAsset.value
      ) ?? [];

    setSelectedTemplates(newTemplates);

    setUploadAssetList(assetsCopy as AssetProgressType[]);

    if (currentIdx < assetsCopy.length - 1) {
      setCurrentAsset(assetsCopy[currentIdx + 1] as AssetProgressType);
    } else {
      // if there is at least 1 confirmed item then call confirm
      if (assetsCopy.find((asset) => asset.status === "confirmed")) {
        updateUploadDb(
          assetsCopy as AssetProgressType[],
          newTemplates,
          skipTranscriptEditing
        );
      }
      // otherwise reset the modal and close it
      else {
        setUploadAssetList([]);
        setshowSuccessScreen(true);
        // reload the page to restart the process
        alert(
          "You skipped all assets. Please select at least 1 asset to generate"
        );
        window.location.reload();
      }
    }
  };

  // remaining assets in flow: If an asset is marked as writing, then deduct it from the remaining credits
  const consumedCreditsInFlow = uploadAssetList
    ?.filter((asset) => asset.status === "confirmed")
    .reduce((acc, asset) => acc + asset.credits, 0);

  const remainingCreditsInFlow =
    (remainingCredits ?? 0) - (consumedCreditsInFlow ?? 0);

  return (
    <div
      className="w-full h-full mt-[20px] rounded-[18px]"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className="max-w-[800px] md:min-w-[800px] px-3 md:px-12 flex flex-col justify-center items-center "
        role="document"
      >
        <div className="w-full">
          <ProgressFlow
            items={uploadAssetList ?? []}
            displaySuccess={showSuccessScreen}
            highlightIndex={
              uploadAssetList?.findIndex(
                (asset) => asset.value === currentAsset?.value
              ) ?? 0
            }
            isLoading={!isReady}
          />
        </div>

        <AssetInstructions
          assetObject={currentAsset}
          onConfirm={onConfirm}
          onSkip={onSkip}
          isLoading={!isReady}
          isSubmitting={isSubmitting}
          remainingCredits={remainingCreditsInFlow}
          assetCost={currentAsset?.credits || 0}
          numberOfAssets={uploadAssetList?.length ?? 0}
          defaultSkipTranscriptEditing={defaultSkipTranscriptEditing ?? true}
          assetTemplates={availableTemplates?.filter(
            (template) => template.asset_id === currentAsset?.value
          )}
          defaultSelectedTemplates={selectedTemplates?.filter(
            (template) => template.asset_id === currentAsset?.value
          )}
        />
      </div>
    </div>
  );
};
