export const BinIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3106 8.69531C15.0001 8.69531 14.7485 8.94692 14.7485 9.25737V19.8803C14.7485 20.1905 15.0001 20.4423 15.3106 20.4423C15.621 20.4423 15.8727 20.1905 15.8727 19.8803V9.25737C15.8727 8.94692 15.621 8.69531 15.3106 8.69531Z"
      fill="#737178"
    />
    <path
      d="M8.67827 8.69531C8.36782 8.69531 8.11621 8.94692 8.11621 9.25737V19.8803C8.11621 20.1905 8.36782 20.4423 8.67827 20.4423C8.98872 20.4423 9.24033 20.1905 9.24033 19.8803V9.25737C9.24033 8.94692 8.98872 8.69531 8.67827 8.69531Z"
      fill="#737178"
    />
    <path
      d="M3.84439 7.14503V20.993C3.84439 21.8115 4.14452 22.5801 4.66881 23.1317C5.19069 23.6847 5.91698 23.9987 6.67708 24H17.3114C18.0717 23.9987 18.798 23.6847 19.3197 23.1317C19.844 22.5801 20.1441 21.8115 20.1441 20.993V7.14503C21.1863 6.86839 21.8617 5.86152 21.7223 4.79207C21.5826 3.72284 20.6717 2.923 19.5932 2.92278H16.7155V2.22021C16.7188 1.62939 16.4852 1.06206 16.067 0.644685C15.6487 0.227532 15.0805 -0.00475635 14.4897 7.385e-05H9.49879C8.90797 -0.00475635 8.33976 0.227532 7.92151 0.644685C7.50326 1.06206 7.26965 1.62939 7.27295 2.22021V2.92278H4.39525C3.3168 2.923 2.40587 3.72284 2.26623 4.79207C2.12681 5.86152 2.80216 6.86839 3.84439 7.14503ZM17.3114 22.8759H6.67708C5.71609 22.8759 4.9685 22.0504 4.9685 20.993V7.19443H19.02V20.993C19.02 22.0504 18.2724 22.8759 17.3114 22.8759ZM8.39707 2.22021C8.39333 1.92754 8.50838 1.64585 8.71608 1.43925C8.92356 1.23265 9.2059 1.11914 9.49879 1.12419H14.4897C14.7826 1.11914 15.0649 1.23265 15.2724 1.43925C15.4801 1.64563 15.5952 1.92754 15.5914 2.22021V2.92278H8.39707V2.22021ZM4.39525 4.0469H19.5932C20.152 4.0469 20.6049 4.49984 20.6049 5.05861C20.6049 5.61737 20.152 6.07031 19.5932 6.07031H4.39525C3.83648 6.07031 3.38354 5.61737 3.38354 5.05861C3.38354 4.49984 3.83648 4.0469 4.39525 4.0469Z"
      fill="#737178"
    />
    <path
      d="M11.9942 8.69531C11.6837 8.69531 11.4321 8.94692 11.4321 9.25737V19.8803C11.4321 20.1905 11.6837 20.4423 11.9942 20.4423C12.3046 20.4423 12.5562 20.1905 12.5562 19.8803V9.25737C12.5562 8.94692 12.3046 8.69531 11.9942 8.69531Z"
      fill="#737178"
    />
  </svg>
);
