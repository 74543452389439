import classNames from "classnames";
import { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import { useSupabase } from "../../../hooks/useSupabase";
import { useWorkspace } from "../../../hooks/useWorkspace";
import { DownArrow } from "../../../shared/Icons/DownArrow";
import { ProjectDbType } from "../../../types";

export const SelectProject = ({
  handleProjectChange,
  defaultProjectId,
  isPublic,
}: {
  handleProjectChange: (id: string) => void;
  defaultProjectId?: string;
  isPublic?: boolean;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const [projects, setProjects] = useState<ProjectDbType[]>();
  const workspace = useWorkspace();
  const supabase = useSupabase();
  const [projectId, setProjectId] = useState(defaultProjectId ?? "");

  const projectTitleDisplay = projects?.find(({ id }) => id === projectId);
  const filteredProjects = projects?.filter(({ id }) => id !== projectId);

  useEffect(() => {
    if (isPublic) return;
    supabase
      .from("Project")
      .select("id, title")
      .eq("workspace_id", workspace?.id as string)
      .order("created_at", { ascending: false })
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        } else {
          setProjects(data as ProjectDbType[]);
          if (projectId === "") setProjectId(data[0].id);
        }
      });
  }, [supabase, workspace, projectId, isPublic]);

  useEffect(() => {
    handleProjectChange(projectId);
  }, [projectId]);

  return (
    <div className="relative transition-all">
      <div className="">
        <button
          type="button"
          className={classNames(
            "min-w-[120px] outline-none transition-all overflow-hidden ease flex w-full justify-between items-center rounded-xl pr-2 pl-4 py-0.5 text-sm text-[#7047EB] disabled:cursor-not-allowed",
            {
              "delay-200 duration-100": !isOpen,
              "border-b-0 rounded-b-none duration-100": isOpen,
            }
          )}
          disabled={!Boolean(filteredProjects?.length)}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center justify-between space-x-2 font-medium text-12 text-[#7047EB] underline">
            <span>{projectTitleDisplay?.title ?? "Default"} </span>
          </div>

          <DownArrow
            className={classNames({
              "transform rotate-180": isOpen,
            })}
          />
        </button>
      </div>
      <div
        className={classNames(
          "shadow z-10 w-full origin-top-right rounded-2xl bg-white focus:outline-none min-w-[148px]",
          {
            absolute: true,
            "max-h-0 overflow-hidden": !isOpen,
            "max-h-[200px] overflow-auto": isOpen,
          }
        )}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
      >
        {filteredProjects?.map(({ id, title }) => (
          <div className="py-1 " role="none" key={id}>
            <button
              type="button"
              className="w-full text-start px-4 py-2 text-14 font-normal text-[#121217] hover:bg-gray-100 focus:bg-gray-100"
              role="menuitem"
              onClick={() => {
                setIsOpen(false);
                setProjectId(id);
              }}
            >
              <div className="flex items-center justify-start space-x-2">
                <span className="w-full truncate">{title} </span>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
