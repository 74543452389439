import axios, { AxiosError } from "axios";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useParams } from "react-router";
import RedCrossLottie from "../../../../../assets/lotties/red-cross.json";
import WritingLottie from "../../../../../assets/lotties/writing.json";
import { useSupabase } from "../../../../../hooks/useSupabase";
import LoadingLottie from "../../../../../shared/Lottie/LoadingLottie";
import { submitAssets } from "../../../../EditableTranscript/utils";
import { getAssetTemplatesByUploadId } from "../../../../../api/db/query";

export const regenerateFailedResources = async ({
  episodeId,
  assetIds,
  supabase,
  templateIds,
}: {
  episodeId: string;
  assetIds: string[];
  supabase: any;
  templateIds: string[];
}) => {
  // construct a body object
  const body = {
    episodeId,
    assetIds,
    templateIds,
  };

  const session = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/rewrite-assets`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

export const RecoverFailedAssets = ({
  assetId,
  className,
}: {
  assetId: string;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
}) => {
  const [step, setStep] = useState(1); // 1 is default message step, 2 is loading, 3 is writing.
  const [stepState, setStepState] = useState<{
    lottie: any;
    text: string;
    buttonText: string;
  }>({
    lottie: RedCrossLottie,
    text: "This asset failed, retry now for free!",
    buttonText: "Retry for free",
  });

  const { id: uploadId } = useParams<{ id: string }>();
  // supabase
  const supabase = useSupabase();

  const handleSubmit = async () => {
    try {
      setStep(2);

      const templateIds =
        (
          await getAssetTemplatesByUploadId({
            uploadId: uploadId as string,
            supabase,
            onlySelectedAssets: [assetId],
          })
        )
          ?.filter((t) => t.asset_id === assetId)
          .map((t) => t.template_id as string) ?? [];

      await submitAssets({
        episodeId: uploadId as string,
        assetId,
        supabase,
        customInstruction: "",
        templateIds,
      });
      // setStep(3);
    } catch (error) {
      setStep(1);
      alert((error as AxiosError)?.message);
    }
  };

  // step-state changer
  useEffect(() => {
    if (step === 1) {
      setStepState({
        lottie: RedCrossLottie,
        text: "This asset failed, retry now for free!",
        buttonText: "Retry for free",
      });
    } else if (step === 2) {
      setStepState({
        lottie: LoadingLottie,
        text: "Submitting your request...",
        buttonText: "",
      });
    } else if (step === 3) {
      setStepState({
        lottie: WritingLottie,
        text: "Request successful, We are writing your assets...",
        buttonText: "",
      });
    }
  }, [step]);

  return (
    <div
      className={classNames(
        "rounded-lg overflow-clip h-full w-full py-10",
        className
      )}
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <Lottie
          width={step === 1 ? 42 : 80}
          height={step === 1 ? 42 : 80}
          options={{
            loop: step !== 1,
            autoplay: true,
            animationData: stepState.lottie,
          }}
        />
        <div
          className={classNames(
            "font-semibold font-inter text-14 px-8 text-center",
            {
              "mt-3": step === 1,
            }
          )}
        >
          <p>{stepState.text}</p>

          {Boolean(stepState.buttonText) && (
            <button
              className="px-10 py-2 bg-uf-yellow-1 rounded-lg mt-7 text-14"
              onClick={() => handleSubmit()}
              disabled={step !== 1}
            >
              {stepState.buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
