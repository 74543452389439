import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { RemainingCreditsProvider } from "./contexts/RemainingCreditsProvider";
import { SupabaseProvider } from "./contexts/SupabaseProvider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { PaddleProvider } from "./contexts/PaddleProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={{
          api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        }}
      >
        <SupabaseProvider>
          <PaddleProvider>
            <RemainingCreditsProvider>
              <App />
            </RemainingCreditsProvider>
          </PaddleProvider>
        </SupabaseProvider>
      </PostHogProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
