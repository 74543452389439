import { Routes, Route } from "react-router-dom";
import TrialPage from "./components/TrialPage";

export const Waitlist = () => {
  return (
    <Routes>
      <Route path="/trial" element={<TrialPage />} />
    </Routes>
  );
};

export default Waitlist;
