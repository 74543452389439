import { BackIcon } from "../../../shared/Icons/Back";

export const ArrowButton = ({
  isChecked,
  onClick,
  label,
}: {
  isChecked: boolean;
  onClick: () => void;
  label: string;
}) => {
  // yellow background cirlce with a check mark white

  return (
    <div className="w-auto inline-block">
      <button
        className={` border-[#D1D1DB] flex justify-center items-center cursor-pointer rounded-[7px] ${
          isChecked ? "border-2 border-uf-yellow-1" : "border"
        }`}
        onClick={onClick}
      >
        <span className="border-r text-14 font-medium text-[#121217] px-4 py-[7px]">
          {label}
        </span>{" "}
        <div className="px-3 py-[7px]">
          <BackIcon className="rotate-180" width={48} />
        </div>
      </button>
    </div>
  );
};
