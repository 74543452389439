export const SendChatIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.56322 7.88589C2.02122 8.11339 1.6848 8.51838 1.67972 9.09172C1.67622 9.49255 1.94672 10.0309 2.48872 10.2509C2.71197 10.3417 5.84655 10.7634 5.84655 10.7634C5.84655 10.7634 6.67622 13.3859 6.97522 14.3076C7.0618 14.5742 7.11114 14.7051 7.30114 14.8792C7.62347 15.1742 8.16839 15.0817 8.40405 14.8451C9.02755 14.2201 10.0131 13.2559 10.0131 13.2559L10.4278 13.5934C10.4278 13.5934 12.2696 15.0626 13.2763 15.7542C13.8691 16.1617 14.2805 16.5867 14.9476 16.5892C15.2875 16.5909 15.8326 16.4217 16.1929 16.0092C16.431 15.7367 16.5837 15.3009 16.6428 14.9101C16.7771 14.0225 18.3531 4.42589 18.3464 4.09006C18.3357 3.55423 17.885 3.25255 17.5103 3.25589C17.275 3.25839 17.0811 3.32673 16.6496 3.4584C13.3117 4.47756 2.7843 7.79339 2.56322 7.88589ZM15.0131 5.75589C15.0131 5.75589 10.6147 9.58423 8.85714 11.3459C8.29405 11.9101 8.2543 12.8784 8.2543 12.8784L7.34581 9.97172L15.0131 5.75589Z"
      fill="currentColor"
    />
  </svg>
);
