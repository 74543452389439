import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactTextareaAutosize from "react-textarea-autosize";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { useSupabase } from "../../../hooks/useSupabase";
import { useWorkspace } from "../../../hooks/useWorkspace";
import { SendChatIcon } from "../../../shared/Icons/SendChat";
import {
  ASSET_ID_TO_TEMPLATE_PILL_LABEL,
  getTemplateFromLLM,
  PLATFORM_COLORS,
  TEMPLATE_ASSETS_IDS,
} from "../utils";

interface StandardFlowData {
  goal: string;
  example: string;
  title: string;
  description: string;
}

interface EbookFlowData {
  topic: string;
  chapters: number;
  audience: string;
  tone: string;
}

interface EbookChapterFlowData {
  topic: string;
  position: string;
  keyPoints: string;
}

interface BlogPostFlowData {
  topic: string;
  audience: string;
  tone: string;
  length: string;
}

// Union type for all possible flow data
type FlowData =
  | StandardFlowData
  | EbookFlowData
  | EbookChapterFlowData
  | BlogPostFlowData;

type MessageType =
  | "unifire"
  | "user"
  | "pill"
  | "template"
  | "waiting"
  | "prompt"
  | "title"
  | "description";

interface Message {
  type: MessageType;
  content: string | JSX.Element;
  response?: string;
}

const STANDARD_FLOW: Flow = {
  example: {
    message: (
      <>
        Great! Now the most important part: Provide a perfect example of the
        final content.{" "}
        {/* <a className=" text-[#3487EB]" target="_blank" href="#">
          Here are examples
        </a>{" "}
        on how to do that. */}
      </>
    ),
    input: true,
    nextStep: "goal",
  },
  goal: {
    message: (
      <>
        Excellent! Let’s define the goal of the template.{" "}
        {/* <a className=" text-[#3487EB]" target="_blank" href="#">
          Use our guide{" "}
        </a>{" "}
        with examples.{" "} */}
      </>
    ),
    input: true,
    nextStep: "api",
  },
  api: {
    message: <>Your template is ready! give it a title and a description</>,
    input: true,
    nextStep: "title",
  },
  title: {
    message: <>Finally, choose a title to remember this template</>,
    input: true,
    nextStep: "description",
  },
  description: {
    message: <>Finally, add a brief description for your template</>,
    input: true,
    nextStep: null,
  },
};

interface Step {
  message: JSX.Element;
  options?: string[];
  input?: boolean;
  nextStep: string | null;
}

interface Flow {
  [key: string]: Step;
}

const ChatInterface: React.FC = () => {
  const posthog = useAnalytics();
  const supabase = useSupabase();
  const workspace = useWorkspace();

  const navigate = useNavigate();
  const [messages, setMessages] = useState<Message[]>([]);
  const [userInput, setUserInput] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [shareWithCommunity, setShareWithCommunity] = useState(false);
  const [currentStep, setCurrentStep] = useState("example");
  const [currentFlow, setCurrentFlow] = useState<Flow | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [editablePrompt, setEditablePrompt] = useState("");
  const [isPublishing, setIsPublishing] = useState(false);

  const [templateTitle, setTemplateTitle] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (!currentFlow) {
      setMessages([
        {
          type: "unifire",
          content:
            "Hi, let's start by selecting a type of template you want to generate. What should it be?",
        },
      ]);
    }
  }, [currentFlow]);

  // Function to extract user input based on the current flow
  const extractUserInput = (messages: Message[], flow: Flow): FlowData => {
    const userMessages = messages.filter((m) => m.type === "user");

    return {
      example: userMessages[0]?.content || "",
      goal: userMessages[1]?.content || "",
      title: userMessages[2]?.content || "", // Update this line
      description: userMessages[3]?.content || "", // Update this line
    } as StandardFlowData;
  };

  const handleTemplateSelection = (assetId: string) => {
    setSelectedAssetId(assetId);
    const newFlow = STANDARD_FLOW;
    setCurrentFlow(newFlow);
    setCurrentStep("example");
    setMessages((prev) => [
      ...prev,
      { type: "pill", content: assetId },
      {
        type: "unifire",
        content: newFlow.example.message,
      },
    ]);
  };

  const callTemplateAPI = async ({
    assetId,
    examples,
    goal,
  }: {
    assetId: string;
    goal: string;
    examples: string;
  }) => {
    setIsLoading(true);
    const data = await getTemplateFromLLM({
      assetId,
      goal,
      examples,
      supabase,
    });
    setIsLoading(false);

    return data;
  };

  const handleSendMessage = async () => {
    if (userInput.trim() && currentFlow) {
      const newMessages: Message[] = [
        ...messages,
        { type: "user", content: userInput },
      ];

      const currentStepData = currentFlow[currentStep];
      if (currentStepData.nextStep) {
        const nextStepData = currentFlow[currentStepData.nextStep];

        if (currentStepData.nextStep === "api") {
          newMessages.push({
            type: "waiting",
            content: "Analyzing your template...",
          });

          setMessages(newMessages);
          setUserInput("");

          const flowData = extractUserInput(
            newMessages,
            currentFlow
          ) as StandardFlowData;
          const templateResponse = await callTemplateAPI({
            assetId: selectedAssetId,
            examples: flowData.example,
            goal: flowData.goal,
          });

          setMessages((prev) => [
            ...prev.filter((m) => m.type !== "waiting"),
            {
              type: "unifire",
              content: nextStepData.message,
            },
            {
              type: "title",
              content: templateResponse.title,
            },
            {
              type: "description",
              content: templateResponse.overview,
            },
          ]);

          setPrompt(templateResponse.template);
          setEditablePrompt(templateResponse.template);
          setTemplateTitle(templateResponse.title);
          setTemplateDescription(templateResponse.overview);
          setCurrentStep("description");
          return;
        }

        newMessages.push({
          type: "unifire",
          content: nextStepData.message,
        });
        setCurrentStep(currentStepData.nextStep);
      }

      setMessages(newMessages);
      setUserInput("");
    }
  };

  const handlePublish = async () => {
    setMessages((prev) => [
      ...prev,
      {
        type: "waiting",
        content: "Publishing your prompt...",
      },
    ]);

    setIsPublishing(true);

    try {
      const flowData = extractUserInput(
        messages,
        currentFlow!
      ) as StandardFlowData;

      // Create the template record
      const { data, error } = await supabase
        .from("Template")
        .insert({
          title: templateTitle,
          prompt: editablePrompt,
          is_public: shareWithCommunity,
          is_enabled: true,
          overview: templateDescription, // Use the new description here
          asset_id: selectedAssetId,
          workspace_id: workspace?.id,
          goal: flowData.goal,
          examples: flowData.example,
        })
        .select()
        .single();

      if (error) throw error;

      posthog?.createdTemplate({
        assetType: selectedAssetId,
        isUnifireTemplate: false,
        platformType: selectedAssetId.split("_")[0].toLowerCase(),
        templateTitle: templateTitle,
      });
      setMessages((prev) => [
        ...prev.filter((m) => m.type !== "waiting"),
        {
          type: "unifire",
          content: "Your prompt has been published successfully!",
        },
      ]);

      // Countdown and redirect
      for (let i = 3; i > 0; i--) {
        setMessages((prev) => [
          ...prev,
          {
            type: "unifire",
            content: `You will be redirected in ${i} second${
              i !== 1 ? "s" : ""
            }...`,
          },
        ]);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      // Call function after countdown
      redirectAfterPublish(data.id as string);
    } catch (error) {
      console.error("Error publishing template:", error);
      setMessages((prev) => [
        ...prev.filter((m) => m.type !== "waiting"),
        {
          type: "unifire",
          content:
            "There was an error publishing your prompt. Please try again.",
        },
      ]);
    } finally {
      setIsPublishing(false);
    }
  };
  const redirectAfterPublish = (id: string) => {
    navigate(`/dashboard/template/${id}`);
    // Add your redirect logic here
  };

  const getPlatformColorByAssetId = (assetId: string) => {
    return (
      PLATFORM_COLORS[
        assetId?.split("_")[0] as keyof typeof PLATFORM_COLORS
      ] || {
        background: "#DBEAFE",
        text: "#1E40AF",
        hover: "#BFDBFE",
      }
    );
  };

  const renderMessage = (message: Message, index: number) => {
    switch (message.type) {
      case "unifire":
        return (
          <div
            className="px-4 pt-3 pb-8 rounded-lg opacity-0 animate-message-appear bg-gray-50 space-y-1.5"
            style={{
              animationDelay: `${index * 100}ms`,
            }}
          >
            <p className=" text-[#FF5698] text-xs font-medium">Unifire AI</p>
            <p className="text-sm font-medium text-[#121217]">
              {message.content}
            </p>
          </div>
        );
      case "user":
        return (
          <div
            className="flex justify-end opacity-0 animate-message-appear"
            style={{ animationDelay: `${index * 100}ms` }}
          >
            <div
              className="p-3 rounded-lg max-w-[80%]"
              style={{ backgroundColor: "#F0FAFF" }}
            >
              <p
                className="text-xs font-medium text-right text-md text-[#121217]"
                style={{ color: "#00ACFF" }}
              >
                You
              </p>
              <p className="text-right text-sm font-medium text-[#121217]">
                {message.content}
              </p>
            </div>
          </div>
        );
      case "pill":
        const color = getPlatformColorByAssetId(message.content as string);
        return (
          <div
            className="flex justify-end opacity-0 animate-message-appear"
            style={{ animationDelay: `${index * 100}ms` }}
          >
            <span
              className="px-4 py-2 rounded-full text-sm font-medium text-[#121217]"
              style={{
                backgroundColor: color.background,
                color: color.text,
                borderWidth: "0.5px",
                borderColor: color.text,
              }}
            >
              {(message.content as string)?.replace(/_/g, " ")}
            </span>
          </div>
        );
      case "template":
        return (
          <div
            className="mt-4 p-4 rounded-lg opacity-0 animate-message-appear"
            style={{
              animationDelay: `${index * 100}ms`,
              backgroundColor: "#FCE7F3",
            }}
          >
            <p
              className="text-xs font-medium text-[#121217]"
              style={{ color: "#9D174D" }}
            >
              Template Description
            </p>
            <p className="mt-2 text-sm font-medium text-[#121217]">
              {message.content}
            </p>
          </div>
        );
      case "waiting":
        return (
          <div
            className="mt-3 p-3 rounded-lg opacity-0 animate-message-appear flex items-center"
            style={{
              animationDelay: `${index * 100}ms`,
              backgroundColor: "#FEF3C7",
            }}
          >
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-yellow-700 mr-3"></div>
            <p className="text-sm font-medium text-[#92400E]">
              {message.content}
            </p>
          </div>
        );
      case "prompt":
        return (
          <div
            className="mt-4 p-4 rounded-lg opacity-0 animate-message-appear bg-gray-50"
            style={{
              animationDelay: `${index * 100}ms`,
            }}
          >
            <p
              className="mb-2 text-xs font-medium text-[#121217]"
              style={{ color: "#0284C7" }}
            >
              Generated Template
            </p>
            <ReactTextareaAutosize
              value={editablePrompt}
              onChange={(e) => setEditablePrompt(e.target.value)}
              className="p-2 w-full rounded-lg focus:outline-none resize-none text-sm disabled:bg-transparent "
              style={{
                borderColor: "#D1D5DB",
              }}
              rows={1}
            />
          </div>
        );
      //  just like the prompt
      case "title":
        return (
          <div
            className="mt-4 p-4 rounded-lg opacity-0 animate-message-appear bg-gray-50"
            style={{
              animationDelay: `${index * 100}ms`,
            }}
          >
            <p
              className="mb-2 text-xs font-medium text-[#121217]"
              style={{ color: "#0284C7" }}
            >
              Template Title
            </p>
            <ReactTextareaAutosize
              value={templateTitle}
              onChange={(e) => setTemplateTitle(e.target.value)}
              className="p-2 w-full rounded-lg focus:outline-none resize-none text-sm disabled:bg-transparent "
              style={{
                borderColor: "#D1D5DB",
              }}
              rows={1}
            />
          </div>
        );
      case "description":
        return (
          <div
            className="mt-4 p-4 rounded-lg opacity-0 animate-message-appear bg-gray-50"
            style={{
              animationDelay: `${index * 100}ms`,
            }}
          >
            <p
              className="mb-2 text-xs font-medium text-[#121217]"
              style={{ color: "#0284C7" }}
            >
              Template Description
            </p>
            <ReactTextareaAutosize
              value={templateDescription}
              onChange={(e) => setTemplateDescription(e.target.value)}
              className="p-2 w-full rounded-lg focus:outline-none resize-none text-sm disabled:bg-transparent "
              style={{
                borderColor: "#D1D5DB",
              }}
              rows={1}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-[726px] mx-auto whitespace-pre-line h-full">
      <div className="rounded-lg mb-4">
        <div className="space-y-4 h-[75vh] max-h-[1000px] overflow-y-auto">
          {messages.map((message, index) => renderMessage(message, index))}
          <div ref={messagesEndRef} />
          {!currentFlow && !prompt && (
            <div className="flex flex-wrap gap-2 mt-4 max-h-80 overflow-y-auto">
              {Object.values(TEMPLATE_ASSETS_IDS()).map((value, index) => {
                const color = getPlatformColorByAssetId(value as string);
                return (
                  <button
                    key={value}
                    onClick={() => handleTemplateSelection(value as string)}
                    className="px-3 py-1 rounded-full transition-colors duration-200 opacity-0 animate-message-appear text-14"
                    style={{
                      backgroundColor: color.background,
                      color: color.text,
                      animationDelay: `${(index + 1) * 50}ms`,
                      accentColor: color.text,
                      borderColor: color.text,
                      borderWidth: "0.5px",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = color.hover)
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = color.background)
                    }
                  >
                    {ASSET_ID_TO_TEMPLATE_PILL_LABEL()[value as string]}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div
        className="rounded-xl shadow-md pl-4 pr-3"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        {Boolean(templateTitle) && Boolean(templateDescription) ? (
          <div className="text-center" style={{ color: "#4B5563" }}></div>
        ) : (
          <div
            className={classNames("flex justify-start items-center space-x-3", {
              "opacity-50 cursor-not-allowed":
                isLoading ||
                (!!prompt &&
                  currentStep !== "title" &&
                  currentStep !== "description") ||
                !currentFlow,
            })}
          >
            <ReactTextareaAutosize
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              className="w-full rounded-lg focus:outline-none resize-none py-3.5 text-sm disabled:bg-transparent"
              placeholder={
                currentStep === "title"
                  ? "Enter a title for your template"
                  : currentStep === "description"
                  ? "Enter a brief description for your template"
                  : "Type your message here..."
              }
              rows={1}
              disabled={
                isLoading ||
                (!!prompt &&
                  currentStep !== "title" &&
                  currentStep !== "description") ||
                !currentFlow
              }
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
            />
            <button
              onClick={handleSendMessage}
              className="px-3 py-2 rounded-lg transition-colors duration-200 text-[#F53D6B]"
              disabled={isLoading || !currentFlow}
            >
              <SendChatIcon />
            </button>
          </div>
        )}
      </div>
      {/* <div className="flex justify-between items-start space-x-2 mt-10">
        <div>
          <span className="text-14 font-medium text-[#121217]">
            Share with the community
          </span>
          <p className="text-14 text-[#6C6C89] max-w-lg">
            Share this template with other Unifire users so they can benefit
            from your creation. They will find it in Community Templates
          </p>
        </div>

        <Switch
          checked={shareWithCommunity}
          onChange={() => setShareWithCommunity(!shareWithCommunity)}
        />
      </div> */}

      {Boolean(templateTitle) && Boolean(templateDescription) && (
        <div className="my-5 flex justify-end items-center">
          <button
            onClick={handlePublish}
            className={classNames(
              "px-16 py-2 rounded-lg transition-colors duration-200 flex items-center text-14 text-[#202020] font-medium bg-uf-yellow-1",
              {
                "text-[#92400E]": isPublishing,
              }
            )}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = "#FFE476")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = "#FFE476")
            }
            disabled={isPublishing}
          >
            {isPublishing && (
              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-yellow-700 mr-2"></div>
            )}
            {isPublishing ? "Publishing..." : "Publish"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatInterface;
