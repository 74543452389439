import "intro.js/introjs.css";
import { useEffect, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { useCanEdit } from "../../../hooks/userCanEdit";
import { Tab, TabGroup } from "../../../shared/Library/Tab";
import {
  AssetDbType,
  FileDbType,
  UploadDbType,
  UploadWithSocialPostsAndProject,
} from "../../../types";
import { ReactComponent as AllSvg } from "../assets/all.svg";
import { ReactComponent as BinSvg } from "../assets/bin.svg";

import { Socket } from "socket.io-client";
import { getSocket } from "../../../io";
import { ReactComponent as SaveSvg } from "../assets/saved-filled.svg";
import ShareForm from "./ShareForm";
import { UPLOAD_CATEGORIES } from "./types";

export type SocialPostStructure = {
  id: string;
  post: string;
  state: "UNTAGGED" | "SAVED" | "ARCHIVED";
  isMinized?: boolean;
};

export type AssemblyTurnByTurn = {
  speaker: number;
  start: number;
  end: number;
  text: string;
}[];

export type AssemblyChapters = {
  summary: string;
  headline: string;
  gist: string;
  start: number;
  end: number;
}[];

export type StorageState = {
  state: "SAVED" | "SAVING" | "IDLE";
};

export const EditStateComponent = ({
  tag,
  onClick,
  isSelected,
  isBin,
  isSaved,
  isUnread,
}: {
  tag: string;
  onClick: () => void;
  isSelected: boolean;
  isBin?: boolean;
  isSaved?: boolean;
  isUnread?: boolean;
}) => {
  return (
    <div
      className={`space-x-1 text-[12px] max-w-[130px] py-2 w-full cursor-pointer flex flex-row items-center justify-center ${
        isSelected
          ? "order border-uf-green-1/0 text-[#009580]"
          : "border border-uf-green-1/0 text-black/50 "
      } rounded-full text-sm font-medium  `}
      onClick={() => onClick()}
    >
      {isBin && <BinSvg className="w-4 h-4" />}
      {isSaved && <SaveSvg className="w-4 h-4" />}
      {isUnread && <AllSvg className="w-4 h-4" />}
      <span> {tag}</span>
    </div>
  );
};

export const EpisodePage = ({
  upload,
  assets,
  files,
}: {
  upload?: UploadDbType;
  assets: AssetDbType[];
  files: FileDbType[];
  collabToken?: string | null;
  aiToken?: string | null;
}) => {
  const [showSharingForm, setShowSharingForm] = useState(false);
  const [socket, setSocket] = useState<Socket>();

  const displayPlatforms = assets
    .map((a) => a.asset_id as string)
    .filter((asset_id) => Boolean(asset_id))
    .map((asset_id) => {
      return UPLOAD_CATEGORIES[asset_id.split("_")[0]];
    })
    .filter((platform) => platform !== undefined)
    // keep only unique records
    .filter(
      (platform, index, self) =>
        self.findIndex((p) => p?.value === platform?.value) === index
    );

  // now flatten all the resources and add platform component to each
  const flattenedAssets = displayPlatforms
    .map((platform) => {
      return platform?.resources.map((resource) => {
        // find resource.dbId in asset_id in assets
        if (!assets.find((a) => a.asset_id === resource.dbId)) return undefined;
        return {
          ...resource,
          component: platform.component,
        };
      });
    })
    .flat()
    .filter((resource) => resource !== undefined) as {
    component: (props: any) => JSX.Element;
    dbId: string;
    label: string;
    value: string;
    hasFilters: boolean;
  }[];

  const defaultAssetId =
    upload?.status === "edit"
      ? undefined
      : // else check if has transcript skipped, then show second asset
      upload?.status === "transcribing"
      ? upload.skip_transcript_editing
        ? flattenedAssets[1]?.dbId
        : flattenedAssets[0]?.dbId
      : new URLSearchParams(window.location.search).get("asset") ||
        flattenedAssets[0]?.dbId === "transcript"
      ? flattenedAssets[1]?.dbId
      : flattenedAssets[0]?.dbId;

  // if an upload is not plain text and does not have a transcript, add a transcript (backward compatibility)
  // if (
  //   !upload?.type?.includes("text") &&
  //   assets.find((a) => a.asset_id === "transcript") === undefined
  // )
  //   flattenedAssets.unshift({
  //     component: UPLOAD_CATEGORIES["transcript"].component,
  //     dbId: UPLOAD_CATEGORIES["transcript"].resources[0].dbId,
  //     label: UPLOAD_CATEGORIES["transcript"].resources[0].label,
  //     value: UPLOAD_CATEGORIES["transcript"].resources[0].value,
  //     hasFilters: false,
  //   });
  // sort all flattenedAssets for the transcript to be always at the beginning
  flattenedAssets.sort((a, b) => {
    if (a.dbId === "transcript") return -1;
    if (b.dbId === "transcript") return 1;
    return 0;
  });

  const canEdit = useCanEdit({ episode: upload });

  useEffect(() => {
    const s = getSocket(upload?.room_secret as string);
    if (upload?.room_secret && s.connected === false) {
      s.connect();
    }

    setSocket(s);
    return () => {
      s.disconnect();
    };
  }, [upload?.room_secret]);

  return (
    <div
      className="w-full h-full animate-slide-in max-w-[900px] pb-20"
      id="root-episode"
    >
      {/* <div
        className={classNames(
          "w-full flex justify-end items-center pr-4 my-5",
          {
            hidden: upload?.status === "edit",
          }
        )}
      >
        <button
          className="border font-medium py-1 px-4 bg-uf-yellow-1 text-[#202020] text-14 border-uf-yellow-1 rounded-lg "
          onClick={async () => {
            setShowSharingForm((prev) => !prev);
          }}
          hidden={role !== "owner" && role !== "admin"}
        >
          Share assets
        </button>
      </div> */}

      <div className="mt-5 mx-auto px-4">
        {upload && Boolean(flattenedAssets.length) && (
          <div className="px-4 w-full">
            <TabGroup sticky maxColumns={5} defaultTabId={defaultAssetId}>
              {flattenedAssets.map(
                ({ component, dbId, label, value, hasFilters }) => (
                  <Tab
                    key={dbId}
                    assetId={dbId}
                    name={label}
                    isDisabled={
                      (upload.status === "transcribing" ||
                        upload.status === "edit") &&
                      dbId !== UPLOAD_CATEGORIES["transcript"].resources[0].dbId
                    }
                    onClick={() => {}}
                    content={() =>
                      component({
                        canEdit,
                        subCategory: dbId,
                        upload: upload,
                        socket: socket,
                        assets,
                        files,
                      })
                    }
                  />
                )
              )}
            </TabGroup>
          </div>
        )}

        {/* <div className="w-full flex justify-end items-center space-x-4">
          {isSaved ? (
            <div className=" flex items-center space-x-2">
              <span className="text-[#46D5B3] text-12">Changes saved</span>
              <CloudSavedSvg />
            </div>
          ) : (
            <div className=" flex items-center space-x-2">
              <span className="text-[#CAC8CD] text-12">Saving...</span>
              <CloudNotSavedSvg />
            </div>
          )}

         
        </div> */}
      </div>

      {showSharingForm && upload && (
        <ShareForm
          episode={upload}
          setShowSharingForm={setShowSharingForm}
          showSharingForm={showSharingForm}
        />
      )}
    </div>
  );
};
