import { Node } from "@tiptap/core";
import {
  EditorContent,
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAnalytics } from "../../../../hooks/useAnalytics";
import { useRole } from "../../../../hooks/userCanEdit";
import { useWorkspace } from "../../../../hooks/useWorkspace";
import { AssemblyTurnByTurn } from "../../../Episode/components/EpisodePage";
import { SpeakerLabelType } from "../types";
import { SearchNReplace } from "./SearchReplaceExtension";
import { useSupabase } from "../../../../hooks/useSupabase";
import { isEditorEmpty } from "../../../../shared/LiveEditor/util";
import { EditorMenu, getConversationData } from "./EditorMenu";

const ConversationText = (uniqueSpeakers: any) =>
  Node.create({
    name: "conversationText",

    group: "block",

    content: "text*",

    addAttributes() {
      return {
        timeStart: {
          default: "",
        },
        timeEnd: {
          default: "",
        },
        speaker: {
          default: {
            label: "0",
            value: 0,
          },
        },
        speakerLabel: {
          default: "",
        },
        speakerValue: {
          default: 0,
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: "conversation-text",
        },
      ];
    },

    renderHTML({ node, HTMLAttributes }) {
      return ["conversation-text", mergeAttributes(HTMLAttributes), 0];
    },

    addNodeView() {
      return ReactNodeViewRenderer((props: any) => (
        <ConversationComponent {...props} speakers={uniqueSpeakers} />
      ));
    },
  });

const ConversationComponent = ({ node, updateAttributes, extension }: any) => {
  const { timeStart, timeEnd, speakerLabel, speakerValue } = node.attrs;

  // seconds to format HH:MM:SS
  const timeStartFormatted = new Date(timeStart).toISOString().substr(11, 8);

  return (
    <NodeViewWrapper>
      <div className="flex justify-start items-start mb-5 space-x-4 justify-items-stretch">
        <div
          className="min-w-[150px] font-semibold text-14 text-[#202020]"
          contentEditable="false"
        >
          {timeStartFormatted} {speakerLabel}
        </div>
        <NodeViewContent as="div" />
      </div>
    </NodeViewWrapper>
  );
};

export const TrasncriptEditor = ({
  content,
  episodeId,
  fileId,
  onEditorReady,
  hideGeneration,
  readOnly,
  speakers,
}: {
  content: AssemblyTurnByTurn;
  episodeId: string;
  fileId: string;
  onEditorReady: () => void;
  hideGeneration?: boolean;
  readOnly?: boolean;
  speakers: SpeakerLabelType[];
}) => {
  // const [doc, setDoc] = useState<Y.Doc>();
  // const [provider, setProvider] = useState<any>();

  // useEffect(() => {
  //   if (status === "connected") onEditorReady();
  // }, [status, onEditorReady]);

  // useEffect(() => {
  //   onEditorReady();
  // }, []);

  // Set up Liveblocks Yjs provider
  // useEffect(() => {
  //   const yDoc = new Y.Doc();

  //   const yProvider = new LiveblocksProvider(room, yDoc);

  //   setDoc(yDoc);
  //   setProvider(yProvider);

  //   return () => {
  //     yDoc?.destroy();
  //     yProvider?.destroy();
  //   };
  // }, [room]);

  // if (!doc || !provider) {
  //   return null;
  // }

  return (
    <Editor
      // doc={doc}
      // provider={provider}
      speakers={speakers}
      content={content}
      episodeId={episodeId}
      hideGeneration={hideGeneration}
      fileId={fileId}
    />
  );
};

type EditorProps = {
  // doc: Y.Doc;
  // provider: any;
  content: AssemblyTurnByTurn;
  speakers: SpeakerLabelType[];
  episodeId: string;
  fileId: string;
  hideGeneration?: boolean;
};
const Editor = ({
  content,
  speakers,
  episodeId,
  fileId,
  hideGeneration,
}: // doc,
// provider,
EditorProps) => {
  const [defaultContentIsSet, setDefaultContentIsSet] = useState(false);

  const workspace = useWorkspace();
  const userRole = useRole();
  const didSendAnalytics = useRef(false);
  const supabase = useSupabase();

  const { transcriptEdited } = useAnalytics();

  const [uniqueSpeakers, setUniqueSpeakers] =
    useState<SpeakerLabelType[]>(speakers);

  const defaultContent = useMemo(() => {
    return (
      content
        .map(
          ({ start, end, text, speaker }) =>
            `<conversation-text timeStart="${start}" timeEnd="${end}" speakerLabel="${
              uniqueSpeakers.find((s) => s.value === speaker)?.label
            }" speakerValue="${
              uniqueSpeakers.find((s) => s.value === speaker)?.value
            }"
          >${text}</conversation-text>`
        )
        // replace all new lines
        .map((line) => line.replace(/\n/g, ""))
        .join("")
    );
  }, [content]);

  // const {
  //   info: { name, color },
  // } = useSelf((self) => self.presence) as {
  //   info: { name: string; color: string };
  // };

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        // The Collaboration extension comes with its own history handling
        // history: false,
      }),
      ConversationText(uniqueSpeakers),
      SearchNReplace,
      // Register the document with Tiptap
      // Collaboration.configure({
      //   document: doc,
      // }),
      // // // Attach provider and user info
      // CollaborationCursor.configure({
      //   provider: provider,
      //   user: {
      //     name,
      //     color,
      //   },
      // }),
    ],
    editorProps: {
      attributes: {
        class: `text-editor__editor focus:outline-none editor  `,
      },
      handleDOMEvents: {
        keydown(view, event) {
          if (!view?.state) return false;

          // analytics
          if (!didSendAnalytics.current) {
            transcriptEdited({
              uploadId: episodeId,
              workspaceId: workspace?.id as string,
              userRole: userRole as any,
            });
            didSendAnalytics.current = true;
          }

          // if enter return
          if (event.key === "Enter" || event.key === "Tab") {
            event.preventDefault();
            return true;
          }

          if (event.key === "Backspace") {
            const { selection } = view?.state;

            const parentPos = selection.$from.parentOffset;
            const node = selection.$from.node(parentPos);
            const text = node.textBetween(
              selection.$from.parentOffset,
              selection.$to.parentOffset
            );

            if (
              node &&
              selection.$from.textOffset === 0 &&
              selection.$from.parentOffset <= 0 &&
              text.length === 0
            ) {
              event.preventDefault();
              return true;
            }

            // if multiple nodes are selected ignore the selection
            if (
              selection.$from.parent.attrs?.timeStart !==
              selection.$to.parent.attrs?.timeStart
            ) {
              event.preventDefault();
              return true;
            }
          }

          return false;
        },
      },
    },
  });

  useEffect(() => {
    // if (shouldSetDefault === false) return;
    editor?.state.doc.descendants((node, position) => {
      if (node.attrs.speakerValue >= 0) {
        // get new speaker from unique speakers
        const newSpeaker = uniqueSpeakers.find(
          (s) => s.value === node.attrs.speakerValue
        ) as SpeakerLabelType;

        const tr = editor.state.tr;
        tr.setNodeMarkup(position, undefined, {
          ...node.attrs,
          speakerLabel: newSpeaker.label,
        });

        editor.view.dispatch(tr);
      }
    });
  }, [uniqueSpeakers]);

  useEffect(() => {
    if (!editor) return;
    // // once the editor is synced and has the final content, start the checks...
    // provider.on("sync", (isSynced: boolean) => {
    //   // check if the editor already has content
    //   // const isPopulated = editor?.state.doc.textContent.length > 0;
    const isPopulated = !isEditorEmpty(editor);

    // if it's not populated after done syncing, add default content
    if (!isPopulated) {
      editor?.commands.setContent(defaultContent);
      setDefaultContentIsSet(true);
    }

    //   // // The following code clears the history. Hopefully without side effects.
    //   // const newEditorState = EditorState.create({
    //   //   doc: editor.state.doc,
    //   //   plugins: editor.state.plugins,
    //   //   schema: editor.state.schema,
    //   // });
    //   // editor.view.updateState(newEditorState);
    // });

    // return () => {
    //   provider.off("sync", (sync: any) => {
    //   });
    // };
  }, [editor]);

  // update database
  useEffect(() => {
    if (!defaultContentIsSet || !editor) return;
    const isEmpty = isEditorEmpty(editor);

    if (isEmpty) return;

    const delayDebounceFn = setTimeout(async () => {
      const newScript = getConversationData(editor, uniqueSpeakers);
      if (!newScript || !Boolean(newScript.length)) return;

      await supabase
        .from("File")
        .update({
          edited_script: newScript,
        })
        .eq("id", fileId as string);
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [
    supabase,
    editor,
    editor?.state.doc,
    fileId,
    workspace?.id,
    uniqueSpeakers,
    defaultContentIsSet,
  ]);

  return (
    <div className="space-y-3">
      <>
        <>
          {/* <div
            className={classNames("w-full flex justify-end items-end", {
              hidden: hideGeneration,
            })}
          >
            <Avatars name={name} color={color} />
          </div> */}
          <div className="sticky bg-white z-10 top-[55px]">
            <EditorMenu
              editor={editor}
              speakers={uniqueSpeakers}
              hideGeneration={hideGeneration}
              // name={name}
              // color={color}
              onSpeakersUpdated={(updatedSpeakers) => {
                setUniqueSpeakers(updatedSpeakers);
                supabase
                  .from("File")
                  .update({
                    speaker_mapping: updatedSpeakers,
                  })
                  .eq("id", fileId)
                  .then(({ error }) => {
                    if (error) {
                      console.error("error updating database", error);
                    }
                  });
              }}
            />
          </div>

          <div className="bg-white mt-5 rounded-md pt-6 pb-1 px-3 md:px-7">
            <EditorContent
              editor={editor}
              className={
                "no-scrollbar border-none editorContainer w-full prose "
              }
            />
          </div>
        </>
      </>
    </div>
  );
};
