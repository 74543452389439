export const FileIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 9.25H14.25V5M10.25 15.25H14.75M10.25 12.25H14.75M16.75 19.25H8.25C7.14543 19.25 6.25 18.3546 6.25 17.25V6.75C6.25 5.64543 7.14543 4.75 8.25 4.75H14.5L18.75 9V17.25C18.75 18.3546 17.8546 19.25 16.75 19.25Z"
      stroke="#8A8AA3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
