import React, { useState, useEffect } from "react";

const WaitlistPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-white flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 shadow-lg p-8 rounded-lg">
        <>
          <div className="text-center mt-6">
            <h1 className="text-xl font-semibold text-slate-900 dark:text-gray-700">
              You are on the Waitlist
            </h1>
            <p className="text-gray-600 dark:text-gray-400 mt-2">
              Thank you for joining the waitlist! We'll get back to you soon
              with an email
            </p>
          </div>
          <div className="text-center mt-6">
            <div
              className="animate-pulse transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110
                bg-green-300 dark:bg-green-800 p-4 py-2 font-semibold text-white rounded-lg shadow-md hover:bg-green-500 dark:hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
            >
              Waiting for Access
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default WaitlistPage;
