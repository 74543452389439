import HCaptcha from "@hcaptcha/react-hcaptcha";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useCallback, useRef } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useLogsnag } from "../../../hooks/logsnag";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { useInviteIdEmail } from "../../../hooks/useAuth";
import { useNavigateWithParams } from "../../../hooks/useNavigateWithParams";
import { useSupabase } from "../../../hooks/useSupabase";
import { isDisposable, login, socialSignin } from "../../../utils/auth";
import GoogleSigninSvg from "../assets/google.png";
import portraitImg from "../assets/portraitImg.jpeg";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Required")
    .test(
      "isDisposable",
      "Invalid email address",
      async (value) => !(await isDisposable(value))
    ),
  password: Yup.string().required("Required"),
  captchaToken: Yup.string().required("Please complete the captcha"),
});

export const LoginPage = () => {
  // watch

  const captcha = useRef(null);

  const inviteEmail = useInviteIdEmail();
  const navigateWithParams = useNavigateWithParams();
  const params = useSearchParams();
  const logsnag = useLogsnag();
  const supabase = useSupabase();

  const { loginSelected, loginSuccessful } = useAnalytics();

  const authGoogle = useCallback(async () => {
    logsnag.publish({
      channel: "auth",
      description: "User is logging in with Google",
      event: "Log in with Google",
      icon: "🚀",
      notify: true,
    });
    const currentUrl = window.location.origin;

    const paramsObject = {};
    params[0].forEach((value, key) =>
      Object.assign(paramsObject, { [key]: value })
    );

    loginSelected("google");

    socialSignin(
      supabase,
      currentUrl,
      createSearchParams(paramsObject).toString(),
      inviteEmail
    ).then(() => {
      loginSuccessful("google");
    });
  }, [supabase, logsnag, params[0]]);

  // const logSnag = useLogsnag();

  // <p className="mt-8">
  //             New user?{" "}
  //             <a
  //               href="/signup"
  //               className="text-blue-500 hover:text-blue-700 font-semibold"
  //             >
  //               Create an account
  //             </a>
  //           </p>

  const authenticate = async ({
    email,
    password,
    captchaToken,
  }: {
    email: string;
    password: string;
    captchaToken: string;
  }) => {
    // logsnag.publish({
    //   channel: "auth",
    //   description: "User is logging in natively",
    //   event: "Native login",
    //   icon: "🎉",
    //   notify: true,
    //   tags: {
    //     email: email ?? "",
    //   },
    // });
    loginSelected("email");
    const { data, error } = await login(
      supabase,
      email ?? "",
      password ?? "",
      captchaToken,
      captcha
    );
    if (error) {
      alert(error?.cause ?? error?.message);
      return;
    }

    loginSuccessful("email");
  };

  return (
    <div className="h-screen w-full bg-white font-poppins">
      <div className="flex justify-between items-center w-full h-full ">
        <div className="w-3/6 h-full relative hidden md:block">
          <div
            className="w-full h-full"
            style={{
              backgroundImage: `url(${portraitImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <span className="absolute bottom-24 text-white text-24 font-medium px-12 italic">
            "I went from posting inconsistently to now creating 40+ Social posts
            a week with Unifire" -
            <span className="not-italic">Max Gutsche</span>
          </span>
        </div>
        <p className="absolute top-12 right-12">
          New user? {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={(e) => {
              navigateWithParams("/signup");
            }}
            className="text-teal-700 hover:text-teal-600 font-medium cursor-pointer"
          >
            Create an account
          </a>
        </p>
        <div className="w-full flex items-center justify-center ">
          <div className="w-[80%] md:w-[60%] lg:w-[40%] h-full">
            <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
              Sign in to Unifire
            </h1>

            <div className="mt-8 w-full flex justify-center items-center">
              <button
                type="button"
                className="flex items-center justify-between  text-white w-full rounded-xl focus:outline-none font-semibold scale-75" //px-6 py-3 bg-[#3487EB]
                onClick={authGoogle}
              >
                <img src={GoogleSigninSvg} />

                {/* <div className="w-full flex justify-center items-center">
                  <p>Sign in with Google</p>
                </div> */}
              </button>
            </div>

            <div className="flex items-center text-gray-500 mt-6">
              <div className="flex-1 bg-gray-400 bg-opacity-30 h-0.5"></div>
              <span className="mx-2">or</span>
              <div className="flex-1 bg-gray-400 bg-opacity-30 h-0.5"></div>
            </div>

            <Formik
              initialValues={{
                email: inviteEmail ?? "",
                firstName: "",
                lastName: "",
                password: "",
                captchaToken: "",
              }}
              enableReinitialize
              validationSchema={loginValidationSchema}
              onSubmit={authenticate}
            >
              {({ errors, touched, ...props }) => (
                <Form>
                  {/* Email input */}
                  <div>
                    <div
                      className={`mt-6 relative ${
                        errors.email && touched.email && "border-red-500"
                      }`}
                    >
                      {inviteEmail ? (
                        <Field
                          type="email"
                          id="email_floating_outlined"
                          name="email"
                          className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                          placeholder=" "
                          defaultValue={inviteEmail}
                          value={inviteEmail}
                          disabled
                          focused
                        />
                      ) : (
                        <Field
                          type="email"
                          id="email_floating_outlined"
                          name="email"
                          className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                          placeholder=" "
                        />
                      )}

                      <label
                        htmlFor="email_floating_outlined"
                        className="hover:cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 bg-white peer-focus:bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Email Address
                      </label>
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Password input */}
                  <div>
                    <div
                      className={`mt-6 relative ${
                        errors.password && touched.password && "border-red-500"
                      }`}
                    >
                      <Field
                        type="password"
                        id="password_floating_outlined"
                        name="password"
                        className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                        placeholder=" "
                      />
                      <label
                        htmlFor="password_floating_outlined"
                        className="hover:cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 bg-white peer-focus:bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Password
                      </label>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="w-full flex justify-center items-center">
                    <div>
                      <div className="mt-6 relative">
                        <HCaptcha
                          ref={captcha}
                          sitekey={process.env.REACT_APP_CAPTCHA_KEY as string}
                          onVerify={(token) => {
                            props.setFieldValue("captchaToken", token);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="captchaToken"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>

                  <div className="w-full flex justify-between items-center mt-6">
                    <a
                      onClick={() => navigateWithParams("/send-reset")}
                      className="text-14 hover:text-blue-700 focus:text-blue-700 cursor-pointer"
                    >
                      Forgot Password?
                    </a>
                    <button
                      type="submit"
                      className="w-40 block bg-teal-600 hover:bg-teal-500 text-white font-semibold rounded-xl px-4 py-3"
                    >
                      Sign In
                    </button>
                  </div>

                  <div className="relative">
                    <p className="text-[#84818A] absolute top-16 text-12">
                      Protected by hCAPTCHA and subject to the{" "}
                      <a
                        className="text-[#009580] "
                        href="https://www.unifire.ai/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Unifire Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        className="text-[#009580]"
                        href="https://www.unifire.ai/terms-of-service"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Service.
                      </a>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
