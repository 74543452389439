// TemplateSelector.tsx
import React from "react";
import { LibraryModal } from "../../../shared/Library/Modal";
import { TemplateDbType } from "../../../types";
import TemplatePicker from "../../Templates/components/TemplatePicker";
import { TEMPLATE_ASSETS_IDS } from "../../Templates/utils";

interface TemplateSelectorProps {
  selectedTemplates: TemplateDbType[];
  assetTemplates: TemplateDbType[] | undefined;
  assetLabel: string | undefined;
  onSelectTemplates: (templates: TemplateDbType[]) => void;
  onRemoveTemplate: (templateId: string) => void;
  assetId: string;
  isMultiSelect: boolean;
}

export const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  selectedTemplates,
  assetTemplates,
  assetLabel,
  onSelectTemplates,
  onRemoveTemplate,
  assetId,
  isMultiSelect,
}) => {
  const [showTemplatePicker, setShowTemplatePicker] = React.useState(false);

  const handleTemplateSelection = (templates: TemplateDbType[]) => {
    onSelectTemplates(templates);
    setShowTemplatePicker(false);
  };

  return (
    <div className="space-y-2 mt-12">
      <p className="text-14 font-medium text-[#121217]">
        Select Content Templates{" "}
        <span className="text-14 font-regular text-[#6C6C89]">(optional)</span>
      </p>

      {selectedTemplates.length > 0 ? (
        <div className="flex flex-wrap gap-2">
          {selectedTemplates.map((template) => (
            <button
              key={template.id}
              className="inline-flex items-center px-3 py-1 rounded-full bg-gray-100 text-sm max-w-[240px] "
            >
              <p className="w-full truncate text-ellipsis">{template.title}</p>
              <span
                className="ml-2 text-gray-500 hover:text-gray-700"
                onClick={() => onRemoveTemplate(template.id)}
              >
                ×
              </span>
            </button>
          ))}
          <button
            onClick={() => setShowTemplatePicker(true)}
            className="inline-flex items-center px-3 py-1 rounded-full bg-uf-yellow-1 text-[#121217] text-14"
          >
            + Add template
          </button>
        </div>
      ) : (
        <button
          onClick={() => setShowTemplatePicker(true)}
          className="w-full px-4 py-2 bg-uf-yellow-1 rounded-lg transition duration-200 text-[#202020] text-14 font-medium"
        >
          Select templates
        </button>
      )}
      <LibraryModal show={showTemplatePicker} setShow={setShowTemplatePicker}>
        <TemplatePicker
          platformName={assetLabel || "Content"}
          templates={assetTemplates!}
          onSelectTemplates={handleTemplateSelection}
          onCancel={() => setShowTemplatePicker(false)}
          initialSelectedTemplates={selectedTemplates}
          assetId={assetId}
          isMultiSelect={isMultiSelect}
        />
      </LibraryModal>
    </div>
  );
};
