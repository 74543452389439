import { SupabaseClient } from "@supabase/supabase-js";
import { removeWokspace } from "../hooks/useWorkspace";
import axios from "axios";
// import { useOnboarding } from "../hooks/useOnboarded";
const DisposableEmails: string = require("./disposable_email_blocklist.txt");

let blocklist: string[];
export const isDisposable = async (email: string) => {
  if (!blocklist) {
    blocklist = (await (await fetch(DisposableEmails)).text()).split("\n");
  }

  return blocklist.includes(email.split("@")[1]);
};

export const login = async (
  supabase: SupabaseClient,
  email: string,
  password: string,
  captchaToken: string,
  captcha: any
) => {
  captcha?.current?.resetCaptcha();
  const resp = await supabase.auth.signInWithPassword({
    email,
    password,
    options: {
      captchaToken,
    },
  });
  return resp;
};
export const socialSignin = async (
  supabase: SupabaseClient,
  url: string,
  urlParams: string,
  login_hint?: string
) => {
  return await supabase.auth.signInWithOAuth({
    provider: "google",
    options: {
      scopes: "profile email",
      redirectTo: `${url}/login?${urlParams}`,
      queryParams: {
        ...(login_hint && { login_hint: login_hint }),
      },
    },
  });
};

export const signup = async ({
  supabase,
  full_name,
  email,
  password,
  captchaToken,
  captcha,
  ltdCode,
}: {
  supabase: SupabaseClient;
  full_name: string;
  email: string;
  password: string;
  captchaToken: string;
  captcha: any;
  ltdCode?: string;
}) => {
  captcha?.current?.resetCaptcha();

  try {
    const result = await verifyEmail(email);
    if (result.status !== "valid" || result.is_disposable) {
      return {
        error: {
          message: "Invalid email address",
          cause: "Invalid email address",
        },
      };
    }
  } catch (error) {
    //  if server error, be optimistic and let it pass
  }

  return await supabase.auth.signUp({
    options: {
      captchaToken,
      data: {
        full_name,
        native: true,
        ltd_code: ltdCode,
      },
    },
    email,
    password,
  });
};

export const sendResetPwd = async (
  supabase: SupabaseClient,
  email: string,
  captchaToken: string
) => {
  return await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: `${window.location.origin}/reset`,
    captchaToken,
  });
};

export const confirmResetPwd = async (
  supabase: SupabaseClient,
  newPd: string
) => {
  return await supabase.auth.updateUser({
    password: newPd,
  });
};

export const signout = async (supabase: SupabaseClient) => {
  removeWokspace();
  return await supabase?.auth?.signOut();
};

// write an email validation function
export const isEmailValid = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email) ? true : false;
};

// write a password validation function where the password is at least 5 characters long
export const isPasswordValid = (password: string) => {
  return password.length >= 6 ? true : false;
};

// write a username validation function where the username is at least 3 characters long
export const isUsernameValid = (username: string) => {
  return username.length >= 1 ? true : false;
};

export const verifyEmail = async (email: string) => {
  try {
    const response = await axios.get(
      `https://emailverifier.reoon.com/api/v1/verify?email=${email}&key=${process.env.REACT_APP_EMAIL_VERIFICATION_KEY}&mode=quick`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying email:", error);
    throw error;
  }
};
