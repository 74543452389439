export const ChineseFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_31581)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#D80027"
      />
      <path
        d="M6.56737 7.30078L7.60331 10.4883H10.9549L8.2455 12.4617L9.28143 15.6492L6.56737 13.6805L3.85331 15.6492L4.89393 12.4617L2.17987 10.4883H5.53143L6.56737 7.30078Z"
        fill="#FFDA44"
      />
      <path
        d="M14.2262 18.5848L13.434 17.6098L12.2622 18.0645L12.9418 17.0098L12.1497 16.0301L13.3637 16.3535L14.0481 15.2988L14.1137 16.5551L15.3325 16.8785L14.1559 17.3285L14.2262 18.5848Z"
        fill="#FFDA44"
      />
      <path
        d="M15.8018 15.7254L16.1768 14.5254L15.1502 13.7988L16.4065 13.7801L16.7768 12.5801L17.1846 13.7707L18.4408 13.7566L17.433 14.5066L17.8361 15.6973L16.8096 14.9707L15.8018 15.7254Z"
        fill="#FFDA44"
      />
      <path
        d="M17.9254 8.80625L17.3723 9.93594L18.2723 10.8125L17.0301 10.6344L16.477 11.7594L16.2613 10.5219L15.0145 10.3438L16.1301 9.75781L15.9145 8.51562L16.8145 9.39219L17.9254 8.80625Z"
        fill="#FFDA44"
      />
      <path
        d="M14.2592 5.38477L14.1654 6.63633L15.3326 7.10977L14.1092 7.40977L14.0201 8.66602L13.3592 7.59727L12.1357 7.89727L12.9467 6.93633L12.2811 5.87227L13.4482 6.3457L14.2592 5.38477Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_31581">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
