import React from "react";
import { CenterDashboard } from "./CenterDashboard";
import Sidebar from "./Sidebar";
import { useRemainingCredits } from "../../../hooks/useRemainingCredits";
import Lottie from "react-lottie";
import LoadingIntegrationLottie from "../../Episode/assets/loading-assets.json";

export const DashboardPage = () => {
  const { isLoading } = useRemainingCredits();
  return (
    <div className="md:flex md:flex-row md:justify-start md:items-start h-screen w-screen p-0 border-none">
      <Sidebar />
      {isLoading ? (
        <div className="h-full w-full relative">
          <div className="absolute inset-0 flex flex-col items-center justify-center ">
            <Lottie
              width="200px"
              height="200px"
              options={{
                animationData: LoadingIntegrationLottie,
                loop: true,
              }}
            />
          </div>
        </div>
      ) : (
        <CenterDashboard />
      )}
    </div>
  );
};
export default DashboardPage;
