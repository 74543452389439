import { ReactComponent as TutorialSvg } from "../assets/tutorial.svg";
import { ReactComponent as CloseTutorialSvg } from "../assets/close-tutorial.svg";
import classNames from "classnames";
import { useState } from "react";
import { LibraryModal } from "../../../shared/Library/Modal";
export const Tutorial = () => {
  const [isHidden, setIsHidden] = useState(
    localStorage.getItem("hideTutorial")
  );
  const [show, setShow] = useState(false);
  return (
    <div
      className={classNames(
        "mt-5 flex flex-col sm:flex-row justify-between items-start min-h-[192px] bg-[#FFDA85] space-x-10 rounded-xl cursor-pointer",
        {
          hidden: isHidden === "true",
        }
      )}
      onClick={() => {
        setShow(true);
      }}
    >
      <div className="w-full pt-6 pl-7">
        <p className="text-2xl leading-10 font-bold text-[#121217]">
          Understand how to use Unifire in <br /> under 3 mins
        </p>
        <p className="mt-2 text-base leading-8 font-medium text-[#121217]">
          Content Repurposing in 3 easy steps
        </p>
      </div>

      <div className="relative p-0.5">
        <TutorialSvg />
        <button
          className="absolute p-2 right-0 top-0"
          onClick={() => {
            localStorage.setItem("hideTutorial", "true");
            setIsHidden("true");
          }}
        >
          <CloseTutorialSvg />
        </button>
      </div>

      <LibraryModal setShow={setShow} show={show}>
        <div className="lg:w-[1100px] aspect-video">
          <iframe
            className="absolute top-0 left-0 right-0 w-full h-full !min-w-full !min-h-full"
            src="https://www.youtube.com/embed/mDOU5bTpceI"
            title="How To Use Unifire"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </LibraryModal>
    </div>
  );
};
