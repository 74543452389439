import FlipMove from "react-flip-move";
import LinkedinCard from "./LinkedinResources/LinkedinCard";
import { ASSETS_IDS } from "./types";
import TwitterCard from "./TwitterResources/TwitterCard";
import { ReadOnlyEditor } from "../../../shared/LiveEditor/components/ReadOnlyEditor";
import { AssetDbType } from "../../../types";

export const CardLoadingLiveFeed = ({
  text,
  asset,
}: {
  text: string;
  asset: AssetDbType;
}) => {
  const posts = text?.split("===").filter((t) => t.trim().length > 0);
  return (
    <div className="w-full h-full whitespace-pre-line">
      <FlipMove
        typeName="div"
        className="w-full mt-5 grid lg:gap-x-3 grid-cols-1 lg:grid-cols-2 gap-y-12 place-items-start"
      >
        {posts?.map((t, i) => (
          <div key={i} className="w-full">
            {asset.asset_id === ASSETS_IDS.LINKEDIN_POSTS && (
              <LinkedinCard
                disabled
                canEdit={false}
                position={i}
                postObject={{ id: `${i}`, post: t }}
                role="reader"
                upload={{} as any}
                asset={asset}
              />
            )}
            {asset.asset_id === ASSETS_IDS.TWITTER_TWEETS && (
              <TwitterCard
                disabled
                canEdit={false}
                position={i}
                postObject={{ id: `${i}`, post: t }}
                role="reader"
                upload={{} as any}
                asset={asset}
              />
            )}
            {asset.asset_id === ASSETS_IDS.TWITTER_LONGFORM && (
              <p className="h-full w-full align-top text-start no-scrollbar">
                <ReadOnlyEditor content={t} />
              </p>
            )}
            {/* {assetId === ASSETS_IDS.YOUTUBE_POSTS && (
              <p className="h-full w-full align-top text-start no-scrollbar">
                <ReadOnlyEditor content={t} />
              </p>
            )} */}
          </div>
        ))}
      </FlipMove>
    </div>
  );
};
