import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogsnag } from "../../../hooks/logsnag";
import { useSupabase } from "../../../hooks/useSupabase";
import { IconStack } from "../../../shared/Library/IconStack";
import RemoveUpload from "../../Common/RemoveUpload";
import { UPLOAD_CATEGORIES } from "../../Episode/components/types";
import { EpisodeJoinSocialPostType } from "./ProjectEpisodes";

export const ProjectSingleEpisode = ({
  remoteEpisode,
  assetIds,
  role,
  reload,
}: {
  remoteEpisode: EpisodeJoinSocialPostType;
  assetIds: string[];
  role: string | undefined;
  reload: () => void;
}): JSX.Element => {
  const supabase = useSupabase();
  const logsnag = useLogsnag();
  const navigate = useNavigate();

  const [localEpisode, setLocalEpisode] = useState(remoteEpisode);

  const { id, status, project_id, title, created_at } = localEpisode;

  // animated 3 dots

  const [animatedDots, setAnimatedDots] = useState("");
  const [, setAnimatedDotsCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedDotsCount((prev) => {
        setAnimatedDots(".".repeat(prev));
        if (prev === 3) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // ping episode table if status is not ready
  useEffect(() => {
    if (status === "ready" || status === "failed") return;

    const interval = setInterval(async () => {
      const { data, error } = await supabase
        .from("Upload")
        .select(`id, status, project_id, title, created_at`)
        .eq("id", id)
        .single();

      if (data && localEpisode.status !== data.status) {
        setLocalEpisode(data as EpisodeJoinSocialPostType);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [id, status, supabase]);

  const onUploadClick = async () => {
    navigate("/dashboard/project/" + project_id + "/generation/" + id);

    const email = (await supabase.auth.getUser())?.data.user?.email ?? "";

    logsnag.publish({
      channel: "content",
      description: "Checking Episode " + id,
      event: "Onboarding",
      icon: "📄",
      notify: true,
      tags: {
        email: email,
      },
    });
  };
  const generatedPlatformsIcons = assetIds
    .map((platform) => platform?.split("_")[0])
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((platform) => UPLOAD_CATEGORIES[platform].icon);

  return (
    <button
      className={classNames(`w-full text-slate-900 rounded-lg shadow`, {
        "cursor-default": status === "failed",
      })}
      key={id}
      onKeyUp={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={async (e) => {
        if (status === "failed") return;
        onUploadClick();
      }}
    >
      <div className="w-full bg-opacity-75 flex justify-between items-center md:px-3 text-14 font-medium h-[64px]">
        <div className="flex justify-start items-center">
          <div className="w-24">
            <IconStack icons={generatedPlatformsIcons} />
          </div>

          <p className="text-start truncate text-gray-700 text-sm leading-6 w-[247px]">
            {title}
          </p>
        </div>
        <div className="flex justify-between items-center space-x-5 w-1/3 ">
          <span className="text-xs leading-4 font-normal flex justify-center items-center text-black">
            {moment(created_at).fromNow()}
          </span>
          <span
            className={classNames(
              `font-medium  flex justify-center items-center text-xs leading-4 w-[110px]`,
              {
                "text-orange-700": status === "transcribing",
                "text-indigo-700": status === "edit",
                "text-sky-700": status === "writing",
                "text-green-700": status === "ready",
                "text-gray-700": status === "failed",
              }
            )}
          >
            {status === "transcribing" && `transcribing${animatedDots}`}
            {status === "edit" && "Ready to Generate"}
            {status === "writing" && `writing${animatedDots}`}
            {status === "ready" && "ready"}
            {status === "failed" && "failed"}
          </span>
        </div>
        <div className="hidden md:block ">
          <RemoveUpload
            onConfirm={async () => {
              await supabase
                .from("Upload")
                .update({ deleted: true })
                .eq("id", id);
              reload();
            }}
            role={role as string}
          />
        </div>
      </div>
    </button>
  );
};
