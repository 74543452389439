import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useNavigateWithParams } from "./useNavigateWithParams";
import { useSwitchWorkspace } from "./useWorkspace";
// import { useOnboarding } from "../hooks/useOnboarded";
import { AuthSession } from "@supabase/supabase-js";
import { useAnalytics } from "./useAnalytics";
import { useSupabase } from "./useSupabase";
import { submitAppsumoCode } from "../api/appsumo";

export const useRedirectOnAuthEvent = async () => {
  const supabase = useSupabase();

  const { identifyUser } = useAnalytics();
  const location = useLocation();
  const navigateWithParams = useNavigateWithParams();
  const navigate = useNavigate();

  const switchWorkspace = useSwitchWorkspace();

  const didTriggerListener = useRef(false);

  const params = useSearchParams();
  const invite_id = params[0].get("invite");
  const appsumoCode = params[0].get("code");

  const acceptInviteIfAny = async (session: AuthSession) => {
    const { data: invite, error } = await supabase
      .from("Invite")
      .select("*")
      .eq("id", invite_id as string)
      .limit(1)
      .maybeSingle();

    if (!invite) return;

    const { workspace_id, invitee_email, role } = invite;

    if (invitee_email?.toLowerCase() !== session?.user.email?.toLowerCase()) {
      return;
    }
    await Promise.all([
      await supabase
        .from("Member")
        .insert({
          role: role as string,
          user_id: session?.user.id,
          workspace_id,
        })
        .then((resp) => {}),
      await supabase
        .from("Invite")
        .delete()
        .eq("id", invite_id as string)
        .then((resp) => {}),
      await supabase
        .from("UserProfile")
        .update({
          trial_confirmed: true,
          onboarded: true,
          should_discount_subscription: false,
        })
        .eq("id", session?.user.id)
        .then((resp) => {}),
    ]);

    await switchWorkspace(workspace_id as string, true);
  };

  // router.post('/appsumo/coupon', async function (req: Request, res: Response) {
  //   const { code } = req.body;

  //   try {
  //     if (!code) {
  //       res.status(400).send({
  //         message: 'params missing',
  //       });
  //       return;
  //     }

  //     const response = await getAppsumoLicense(code);
  //     res.status(200).send(response);
  //   } catch (error: any) {
  //     Sentry.captureException(error);
  //     res.status(500).send({
  //       message: error?.message,
  //     });
  //   }
  // });
  // submitAppsumoCode

  const attachAppsumoLicenseIfAny = async (userId: string) => {
    // submit license
    await submitAppsumoCode({
      code: appsumoCode as string,
      supabase,
      userId,
    }).catch((error) => {
      console.log("error", error);
    });
  };

  const shouldOnboard = async (userId: string) => {
    const { data } = await supabase
      .from("UserProfile")
      .select("*")
      .eq("id", userId)
      .limit(1)
      .single();
    if (data?.onboarded === false) return true;
  };

  useEffect(() => {
    if (location.pathname === "/") navigateWithParams("/login");
  }, [location]);

  useEffect(() => {
    // first time ever
    supabase.auth.getSession().then(({ data: { session } }) => {
      redirectOnSessionChange(session);
    });
  }, [supabase]);

  useEffect(() => {
    // for updates
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      redirectOnSessionChange(session);
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [supabase, location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function redirectOnSessionChange(session: AuthSession | null) {
    ////// Public
    //if lands on empty page
    if (location.pathname === "/") navigateWithParams("/login");
    // if on public urls, don't do anything
    if (
      location.pathname.includes("trial") ||
      location.pathname.includes("share") ||
      location.pathname.includes("public") ||
      location.pathname.includes("reset") ||
      location.pathname.includes("stacksocial")
    )
      return;

    ////// Unauthenticated
    // if user is not logged in and tries to access dashboard
    if (session === null && location.pathname.includes("dashboard")) {
      navigateWithParams("/login");
    }

    // if user is not logged in and tries to access reset password
    if (session === null && location.pathname.includes("reset")) {
      navigateWithParams("/login");
    }

    // just to make sure
    if (!session) return;

    ////// Authenticated
    // identify logged in user
    if (session?.user.id) identifyUser(session?.user.id);

    // if url contains ?code=some_code, call the appsumo endpoint to get the license and attach it to the user
    if (appsumoCode)
      await attachAppsumoLicenseIfAny(session?.user.id as string);

    // if url contains ?invite=invite_id, accept the invitation in the database on the invite table and don't redirect
    if (invite_id) await acceptInviteIfAny(session as AuthSession);
    // instead if there is a force redirect to the public episode
    // now that everything's checked, check if the user is onboarded and redirect accordingly
    else if (await shouldOnboard(session?.user.id as string))
      !location.pathname.includes("onboarding") &&
        navigateWithParams("/onboarding");
    else if (!location.pathname.includes("dashboard")) {
      navigateWithParams("/dashboard/overview");
    }
  }
};

export const useAuthUserId = () => {
  const [id, setId] = useState<string | undefined>(undefined);
  const supabase = useSupabase();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setId(session?.user?.id);
    });
  }, [supabase]);

  return useMemo(() => id, [id]);
};

export const useInviteIdEmail = () => {
  const [email, setEmail] = useState<string>();
  const params = useSearchParams();
  const supabase = useSupabase();

  useEffect(() => {
    const invite_id = params[0].get("invite");

    if (!invite_id) return;

    supabase
      .from("Invite")
      .select("*")
      .eq("id", invite_id)
      .limit(1)
      .maybeSingle()
      .then(({ data }) => {
        setEmail(data?.invitee_email ?? undefined);
      });
  }, [params[0], supabase]);

  return useMemo(() => email, [email]);
};
