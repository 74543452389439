import { SupabaseClient } from "@supabase/supabase-js";
import axios from "axios";
import * as tus from "tus-js-client";
import { Database } from "../../../types/database.types";

export const resumableUpload = async ({
  file,
  mimeType,
  supabase,
  progress,
  projectId,
  uploadId,
  fileId,
}: {
  file: File;
  mimeType: string;
  supabase: SupabaseClient<Database>;
  projectId: string;
  fileId: string;
  uploadId: string;
  progress: (percentage: string) => void;
}) => {
  return new Promise(async (resolve, reject) => {
    const accessToken = (await (supabase as any).auth.getSession()).data.session
      .access_token;

    const bucketName = "media";

    const path = `${projectId}/${uploadId}/${fileId}`;

    var upload = new tus.Upload(file, {
      endpoint: `${
        (supabase as any).supabaseUrl
      }/storage/v1/upload/resumable?t=${new Date().getTime()}`,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      headers: {
        authorization: `Bearer ${accessToken}`,
        "x-upsert": "true", // optionally set upsert to true to overwrite existing files
      },

      uploadDataDuringCreation: true,
      metadata: {
        bucketName,
        objectName: path,
        contentType: mimeType,
        cacheControl: "3600",
      },
      chunkSize: 6 * 1024 * 1024, // NOTE: it must be set to 6MB (for now) do not change it
      onError: function (error) {
        alert("Upload failed, please reload the page and retry ");
        reject(error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        progress(percentage);
      },
      onSuccess: async function () {
        // add file to the table

        resolve({});
      },
    });

    // Check if there are any previous uploads to continue.
    return upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }
      // Start the upload
      upload.start();
    });
  });
};

export const youtube = async ({
  url,
  supabase,
  language,
}: {
  url: string;
  supabase: any;
  language: string;
}) => {
  // construct a body object
  const body = {
    url,
    language,
  };

  const session = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/transcribe/youtube`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

// const { url, accessToken, projectId, mimeType, title } = req.body;
export const transcribeGoogle = async ({
  url,
  accessToken,
  projectId,
  mimeType,
  title,
  supabase,
  fileId,
  redirectUri,
  duration,
}: {
  url: string;
  accessToken: string;
  projectId: string;
  mimeType: string;
  title: string;
  supabase: SupabaseClient<Database>;
  fileId: string;
  redirectUri: string;
  duration: number;
}): Promise<boolean> => {
  // construct a body object
  const body = {
    url,
    accessToken,
    projectId,
    mimeType,
    title,
    fileId,
    redirectUri,
    fileDurationMinutes: duration,
  };

  const session: any = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string
  let abortController = new AbortController();
  const timeout = setTimeout(() => {
    abortController.abort();
  }, 3600000);

  let source = axios.CancelToken.source();
  setTimeout(() => {
    source.cancel();
  }, 3600000);

  const res = await axios
    .post(
      `${process.env.REACT_APP_TRANSCRIPTION_URL}/transcribe/google`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          // @ts-ignore
          ...supabase.headers,
          authorization: `Bearer ${session.data.session.access_token}`,
        },
        // timeout 1 hour
        timeout: 3600000,
        signal: abortController.signal,
        cancelToken: source.token,
      }
    )
    .then((response) => {
      clearTimeout(timeout);
      return response;
    })
    .catch((error) => {
      // console.log(error);
      clearTimeout(timeout);
      return error;
    });

  // get the result then poll the supabase backend for the status
  // console.log("res ", res);
  if (res instanceof Error) return false;

  while (true) {
    // console.log("polling");

    const { data, error } = await supabase
      .from("Upload")
      .select("*")
      .eq("id", res.data.temporaryUploadId)
      .limit(1)
      .maybeSingle();

    // console.log("data ", data);

    if (!data?.id) {
      // has been rejected and removed
      // console.log("rejected");
      return false;
    }

    if (data?.status === "transcribing") {
      return true;
    }

    if (error) {
      return false;
    }

    await new Promise((r) => setTimeout(r, 1000 * 3));
  }
};

export const getZoomUser = async ({ supabase }: { supabase: any }) => {
  const session = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string

  return axios.get(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/integrations/zoom/user`,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

export const getZoomRecordingList = async ({
  supabase,
  page_number,
  zoom_user_id,
}: {
  supabase: any;
  page_number: number;
  zoom_user_id: string;
}) => {
  const query = {
    page_number,
    zoom_user_id,
  };

  const session = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string

  return axios.get(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/integrations/zoom/recordings`,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
      params: query,
    }
  );
};

export const transcribeZoomRecording = async ({
  title,
  projectId,
  zoomFileUrl,
  passcode,
  mimeType,
  supabase,
}: {
  title: string;
  projectId: string;
  zoomFileUrl: string;
  passcode: string;
  mimeType: string;
  supabase: any;
}) => {
  // construct a body object
  const body = {
    title,
    projectId,
    zoomFileUrl,
    passcode,
    mimeType,
  };

  const session = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/integrations/zoom/transcribe`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

export const transcribe = async ({
  fileId,
  uploadId,
  supabase,
}: {
  uploadId: string;
  fileId: string;
  supabase: any;
}) => {
  // construct a body object
  const body = {
    fileId,
    uploadId,
  };

  const session = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/transcribe`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

export const callStripe = async (body: Record<string, any>, supabase: any) => {
  const session = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string

  return axios.post(`${process.env.REACT_APP_TRANSCRIPTION_URL}/stripe`, body, {
    headers: {
      "Content-Type": "application/json",
      // @ts-ignore
      ...supabase.headers,
      authorization: `Bearer ${session.data.session.access_token}`,
    },
  });
};

export const sendEmails = async ({
  episodeId,
  emails,
  episodeTitle,
  podcastTitle,
  sourceEmail,
  supabase,
}: {
  episodeId: string;
  emails: string[];
  episodeTitle: string;
  podcastTitle: string;
  sourceEmail: string;
  supabase: SupabaseClient<Database>;
}) => {
  // construct a body object
  const body = {
    episodeId,
    emails,
    episodeTitle,
    podcastTitle,
    sourceEmail,
  };

  const session = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string

  return axios.post(`${process.env.REACT_APP_TRANSCRIPTION_URL}/invite`, body, {
    headers: {
      "Content-Type": "application/json",
      // @ts-ignore
      ...supabase.headers,
      authorization: `Bearer ${session?.data?.session?.access_token}`,
    },
  });
};

export const sendInviteMember = async ({
  email,
  workspaceId,
  role,
  supabase,
}: {
  email: string;
  workspaceId: string;
  role: string;
  supabase: SupabaseClient<Database>;
}) => {
  // construct a body object
  const body = {
    email,
    workspaceId,
    role,
  };

  const session = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/invite_member`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session?.data?.session?.access_token}`,
      },
    }
  );
};

export const toHHMMSS = (millisec: number): string => {
  const seconds = Math.floor((millisec / 1000) % 60);
  const minutes = Math.floor((millisec / (1000 * 60)) % 60);
  const hours = Math.floor(millisec / (1000 * 60 * 60));

  const pad = (n: number) => (n < 10 ? "0" + n : n);

  return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
};

const RESULTS_PER_SEARCH = 10;
const getPagination = (page: number, size: number) => {
  const limit = size ? +size : 3;
  const from = page ? page * limit : 0;
  const to = page ? from + size - 1 : size - 1;

  return { from, to };
};

const clamp = (num: number, min: number, max = Infinity) =>
  Math.max(min, Math.min(num, max));

export const getPaginatedEpisodes = async (
  supabase: SupabaseClient<Database>,
  { query: { page, podcastId } }: { query: { page: number; podcastId: string } }
) => {
  const { from, to } = getPagination(page, RESULTS_PER_SEARCH);
  const { data, count } = await supabase
    .from("Upload")
    .select(
      `id, title, status, project_id, created_at, is_complete, Asset(*)`,
      {
        count: "exact",
      }
    )
    .eq("project_id", podcastId)
    .neq("deleted", true)
    .eq("is_complete", true)
    .neq("status", "failed")
    .order("created_at", { ascending: false })
    .range(from, to);

  return {
    props: {
      data: data?.sort((a, b) => {
        return (
          new Date(b.created_at ?? "").getTime() -
          new Date(a.created_at ?? "").getTime()
        );
      }),
      pageCount: clamp(
        Math.ceil((count as number) / RESULTS_PER_SEARCH),
        1,
        1000
      ),
      page: +page,
    },
  };
};

export const getPaginatedTones = async (
  supabase: SupabaseClient<Database>,
  {
    query: { page, workspaceId },
  }: { query: { page: number; workspaceId: string } }
) => {
  const { from, to } = getPagination(page, 500);
  const { data, count, error } = await supabase
    .from("Tone")
    .select(`*`, {
      count: "exact",
    })
    .eq("workspace_id", workspaceId)
    .order("created_at", { ascending: false })
    .range(from, to)
    // filter out projects ids if they are null
    .filter("project_ids", "not.is", null);

  return {
    props: {
      data: data?.sort((a, b) => {
        return (
          new Date(b.created_at ?? "").getTime() -
          new Date(a.created_at ?? "").getTime()
        );
      }),
      pageCount: clamp(
        Math.ceil((count as number) / RESULTS_PER_SEARCH),
        1,
        1000
      ),
      page: +page,
    },
  };
};

export const removeHtmlTags = (str: string) => {
  return str.replace(/<\/?[^>]+(>|$)/g, "");
};
