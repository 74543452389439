import { useContext, useEffect, useMemo, useState } from "react";

import { TiptapCollabProvider, WebSocketStatus } from "@hocuspocus/provider";
import Ai from "@tiptap-pro/extension-ai";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { Editor, useEditor } from "@tiptap/react";
import type { Doc as YDoc } from "yjs";

import { isEditorEmpty } from "../../../../shared/LiveEditor/util";
import { EditorUser } from "../components/BlockEditor/types";
import { EditorContext } from "../context/EditorContext";
import { ExtensionKit } from "../extensions/extension-kit";
import { userColors } from "../lib/constants";
import { randomElement } from "../lib/utils";
import { useSidebar } from "./useSidebar";
import Export from "@tiptap-pro/extension-export";

export const TIPTAP_AI_APP_ID = process.env.REACT_APP_TIPTAP_AI_APP_ID;
const TIPTAP_AI_BASE_URL =
  process.env.NEXT_PUBLIC_TIPTAP_AI_BASE_URL || "https://api.tiptap.dev/v1/ai";

declare global {
  interface Window {
    editor: Editor | null;
  }
}

export const useBlockEditor = ({
  aiToken,
  convertToken,
  ydoc,
  provider,
  fullName,
  content,
  onChange,
}: {
  aiToken: string;
  convertToken: string;
  ydoc: YDoc;
  provider?: TiptapCollabProvider | null | undefined;
  fullName: string;
  onChange: (value: string) => void;
  content: string;
}) => {
  const leftSidebar = useSidebar();
  const [collabState, setCollabState] = useState<WebSocketStatus>(
    provider ? WebSocketStatus.Connecting : WebSocketStatus.Disconnected
  );
  const [isLoading, setIsLoading] = useState(true);
  const { setIsAiLoading, setAiError } = useContext(EditorContext);

  const editor = useEditor(
    {
      immediatelyRender: false,
      autofocus: true,

      // onCreate: ({ editor }) => {
      //   if (provider) {
      //     provider.on("synced", () => {
      //       console.log("synced");
      //       console.log("editor.isEmpty ", editor.isEmpty);
      //       console.log("isEditorEmpty(editor) ", isEditorEmpty(editor));
      //       if (editor.isEmpty || isEditorEmpty(editor)) {
      //         editor.commands.setContent(content);
      //       }
      //       setIsLoading(false);
      //     });
      //   } else {
      //     editor.commands.setContent(content);
      //     // e
      //     editor?.commands.setTextSelection({ from: 0, to: 0 });
      //     // focus at the beginning too
      //     editor?.commands.focus("start", { scrollIntoView: false });
      //     setIsLoading(false);
      //   }
      // },
      onUpdate: ({ editor }) => {
        // if empty, don t store
        if (isEditorEmpty(editor)) return;

        onChange(editor.getHTML());
      },
      extensions: [
        ...ExtensionKit({
          provider,
        }),
        provider
          ? Collaboration.configure({
              document: ydoc,
            })
          : undefined,
        provider
          ? CollaborationCursor.configure({
              provider,
              user: {
                name: fullName,
                color: randomElement(userColors),
              },
            })
          : undefined,
        Ai.configure({
          appId: TIPTAP_AI_APP_ID,
          token: aiToken,
          baseUrl: TIPTAP_AI_BASE_URL,
          autocompletion: true,
          autocompletionOptions: {
            modelName: "gpt-4o-mini",
          },
          onLoading: () => {
            setIsAiLoading(true);
            setAiError(null);
          },
          onSuccess: () => {
            setIsAiLoading(false);
            setAiError(null);
          },
          onError: (error) => {
            setIsAiLoading(false);
            setAiError(error.message);
          },
        }),
        Export.configure({
          appId: TIPTAP_AI_APP_ID,
          token: convertToken,
        }),
      ].filter((e) => !!e) as any,
      editorProps: {
        attributes: {
          autocomplete: "off",
          autocorrect: "off",
          autocapitalize: "off",
          class: "min-h-full",
        },
      },
    },
    [ydoc, provider]
  );

  const users = useMemo(() => {
    if (!editor?.storage.collaborationCursor?.users) {
      return [];
    }

    // const uniqueUsersByName = new Map<string, EditorUser>();
    // editor.storage.collaborationCursor?.users.forEach((user: EditorUser) => {
    //   uniqueUsersByName.set(user.name, user);
    // });

    // const users = Array.from(uniqueUsersByName.values());

    return editor.storage.collaborationCursor?.users?.map(
      (user: EditorUser) => {
        const names = user.name?.split(" ");
        const firstName = names?.[0];
        const lastName = names?.[names.length - 1];
        const initials = `${firstName?.[0] || "?"}${lastName?.[0] || "?"}`;

        return { ...user, initials: initials.length ? initials : "?" };
      }
    );
  }, [editor?.storage.collaborationCursor?.users]);

  const characterCount = editor?.storage.characterCount || {
    characters: () => 0,
    words: () => 0,
  };

  useEffect(() => {
    provider?.on("status", (event: { status: WebSocketStatus }) => {
      setCollabState(event.status);
    });
  }, [provider]);

  // if yjs doc has content skip, otherwise write
  // useEffect(() => {
  //   if (!editor) return;
  //   // if (editor?.state.doc.textContent === undefined) return;

  //   // once the editor is synced and has the final content, start the checks...
  //   // provider.on("sync", (isSynced: boolean) => {
  //   // check if the editor already has content
  //   // const isPopulated = editor?.state.doc.textContent.length > 0;
  //   const isPopulated = !isEditorEmpty(editor);

  //   //if populated leave, otherwise write the default value

  //   if (isPopulated !== true) {
  //     editor?.commands.setContent(content);
  //   }
  //   // });
  //   // return () => {
  //   //   provider.off("sync", (sync: any) => {
  //   //     console.log("off sync, ", sync);
  //   //   });
  //   // };
  // }, [content, editor, editor?.state.doc.textContent]);

  // useEffect(() => {
  //   editor?.commands.setTextSelection({ from: 0, to: 0 });
  //   // focus at the beginning too
  //   editor?.commands.focus("start", { scrollIntoView: false });
  // }, [editor && editor]);

  // destroy previous editor
  useEffect(() => {
    return () => {
      (window as any)?.provider?.disconnect();
      window.editor?.destroy();
      window.editor = null;
    };
  }, []);
  (window as any).provider = provider;

  // if yjs doc has content skip, otherwise write
  useEffect(() => {
    if (!editor) return;
    // if (editor?.state.doc.textContent === undefined) return;

    // once the editor is synced and has the final content, start the checks...
    provider?.on("synced", (isSynced: boolean) => {
      // check if the editor already has content
      // const isPopulated = editor?.state.doc.textContent.length > 0;
      const isPopulated = !isEditorEmpty(editor);

      //if populated leave, otherwise write the default value

      if (isPopulated !== true) {
        editor?.commands.setContent(content);
      }

      setIsLoading(false);
    });
    return () => {
      provider?.off("sync", (sync: any) => {
        console.log("off sync, ", sync);
      });
    };
  }, [content, editor, editor?.state.doc.textContent, provider]);

  window.editor = editor;

  return { editor, users, characterCount, collabState, leftSidebar, isLoading };
};
