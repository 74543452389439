import classNames from "classnames";
import copy from "copy-to-clipboard";
import { useEffect, useRef, useState } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";
import { Tooltip } from "react-tooltip";
import { useAnalytics } from "../../../../hooks/useAnalytics";
import { useSupabase } from "../../../../hooks/useSupabase";
import { RoleType } from "../../../../hooks/userCanEdit";
import { AssetDbType, UploadDbType } from "../../../../types";
import { ReactComponent as BinSvg } from "../../assets/bin.svg";
import { ReactComponent as CopySvg } from "../../assets/copy.svg";
import { SocialPostStructure } from "../EpisodePage";
import { ASSETS_IDS } from "../types";

interface CardProps {
  postObject: { id: string; post: string };
  position: number;
  canEdit: boolean;
  upload: UploadDbType;
  role: RoleType;
  disabled?: boolean;
  asset: AssetDbType;
}

export const LinkedinCard = ({
  postObject: { id: postId, post },
  canEdit,
  position,
  role,
  upload,
  disabled,
  asset,
}: CardProps): JSX.Element => {
  const [editedText, setEditedText] = useState<string>();
  const [openTooltip, setOpenTooltip] = useState(false);
  const supabase = useSupabase();
  const { userEditedAsset } = useAnalytics();
  const didCallEditedAsset = useRef(false);

  useEffect(() => {
    if (editedText === undefined) return;

    const delayDebounceFn = setTimeout(() => {
      const filteredPosts = (
        asset.data as {
          id: string;
          post: string;
          status: string;
        }[]
      )?.map((post) => {
        if (post?.id === postId) {
          return {
            ...post,
            post: editedText,
          };
        }
        return post;
      });

      // filter localposts
      supabase
        .from("Asset")
        .update({
          data: filteredPosts,
        })
        .eq("asset_id", asset.asset_id as string)
        .eq("upload_id", upload.id)
        .then((resp) => {});

      // onSave({ id: postId, savedPost: editedText });
      if (didCallEditedAsset.current) return;
      didCallEditedAsset.current = true;

      userEditedAsset({
        assetType: ASSETS_IDS.LINKEDIN_POSTS,
        platformType: "linkedin",
        uploadId: upload.id,
        userRole: role,
        workspaceId: upload.workspace_id as string,
      });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [editedText, postId]);

  const archivePost = async (id: string) => {
    const filteredPosts = (
      asset.data as {
        id: string;
        post: string;
        status: string;
      }[]
    )?.map((post) => {
      if (post?.id === id) {
        return {
          ...post,
          state: "ARCHIVED" as SocialPostStructure["state"],
        };
      }
      return post;
    });

    // filter localposts
    supabase
      .from("Asset")
      .update({
        data: filteredPosts,
      })
      .eq("asset_id", asset.asset_id as string)
      .eq("upload_id", upload.id)
      .then((resp) => {});
  };

  const RemoveComponent = (): JSX.Element =>
    canEdit ? (
      <button
        className="p-1"
        title="Remove"
        onClick={() => {
          // onRemove(postId);
          archivePost(postId);
        }}
      >
        <BinSvg className="text-red-500" />
      </button>
    ) : (
      <></>
    );

  const CopyComponent = (): JSX.Element => (
    <button
      className="my-linkedin-anchor-element p-1"
      data-tip
      data-for="linkedinTip"
      title="Copy"
      onClick={() => {
        setOpenTooltip(true);
        copy(post.trim());
      }}
    >
      <CopySvg />
    </button>
  );

  const filterActions = () => {
    if (canEdit === null || canEdit === undefined) return [];
    return canEdit
      ? [<CopyComponent />, <RemoveComponent />]
      : [<CopyComponent />];
  };

  const actions = filterActions();

  return (
    <div
      className={classNames(
        "shadow bg-white rounded-xl py-7 transition-all duration-500 ease w-full h-full overflow-hidden border-none ",
        {
          "pointer-events-none": disabled,
        }
      )}
    >
      <div className="w-full flex justify-between items-center px-2 lg:px-8 mb-8 text-gray-600">
        <span className="font-semibold ">Post #{position}</span>

        <div className="flex flex-row justify-end my-2 space-x-6 items-center">
          {actions?.map((action, idx) => (
            <div key={idx}>{action}</div>
          ))}
        </div>
      </div>

      {canEdit && (
        <div className="w-full h-full ">
          <ReactTextareaAutosize
            defaultValue={post}
            onChange={(e) => {
              setEditedText(e.target.value);
            }}
            className="leading-7 w-full h-full !resize-none pb-14 px-4 lg:px-8 border-none active:border-none bg-transparent focus:border-none outline-none"
          />
        </div>
      )}

      {!canEdit && (
        <button
          className="h-full w-full cursor-pointer"
          disabled={!canEdit}
          onClick={() => {}}
        >
          <p className="text-gray-800 h-full w-full px-4 lg:px-7 align-top text-start leading-7">
            {post.trim()}
          </p>
        </button>
      )}

      <Tooltip
        isOpen={openTooltip}
        variant="success"
        anchorSelect=".my-linkedin-anchor-element"
        id="linkedinTip"
        place="top"
        afterShow={() => {
          setTimeout(() => {
            setOpenTooltip(false);
          }, 1000);
        }}
      >
        Copied!
      </Tooltip>
    </div>
  );
};

export default LinkedinCard;
