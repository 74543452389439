import uuid4 from "uuid4";
import { UploadDbType } from "../../../../../types";

export const uploadPastedText = async ({
  text,
  logsnag,
  language,
}: {
  text: string;
  logsnag: any;
  language: string;
}) => {
  const resourceId = uuid4();

  logsnag.publish({
    channel: "content",
    description: "PUBLIC UPLOAD - User started a new upload",
    event: "Uploading...",
    icon: "⬆",
    notify: true,
    tags: {
      source: "text",
      resourceId,
    },
  });

  logsnag.publish({
    channel: "content",
    description: "PUBLIC UPLOAD - User finished upload",
    event: "Upload finished",
    icon: "✅",
    notify: true,
    tags: {
      source: "text",
      resourceId,
    },
  });
};
