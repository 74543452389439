export const EbookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_custom)">
      <circle cx="10" cy="10" r="9.6" fill="#4A90E2" />
      <path
        d="M6 5 L14 5 L14 15 L6 15 C5 15 4.4 14.4 4.4 13.4 L4.4 6.6 C4.4 5.6 5 5 6 5 Z"
        fill="white"
      />
      <path
        d="M6 5 L13 5 L13 15 L6 15 C5 15 4.4 14.4 4.4 13.4 L4.4 6.6 C4.4 5.6 5 5 6 5 Z"
        fill="#E8F0FE"
      />
      <rect x="7" y="7" width="5" height="0.6" fill="#4A90E2" />
      <rect x="7" y="9" width="4" height="0.6" fill="#4A90E2" />
      <rect x="7" y="11" width="4.4" height="0.6" fill="#4A90E2" />
    </g>
    <defs>
      <clipPath id="clip0_custom">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
