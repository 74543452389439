import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { ClosedEye } from "../../../shared/Icons/ClosedEye";
import { OpenEye } from "../../../shared/Icons/OpenEye";

const ApiKeyLabel = ({
  integration,
  apiKey,
}: {
  apiKey: string;
  integration: "make" | "zapier";
}) => {
  const [showApiKey, setShowApiKey] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const toggleShowApiKey = () => {
    setShowApiKey(!showApiKey);
  };

  const handleApiKeyClick = async (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    event.currentTarget.select(); // Select the text
    navigator.clipboard.writeText(event.currentTarget.value); // Copy to clipboard
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 1000);
  };

  return (
    <div className="text-[#202020]">
      <div className="bg-white  rounded-lg flex flex-col space-y-3 max-w-lg mt-5">
        <label
          htmlFor="apiKey"
          className="block text-14 font-medium text-[#121217]"
        >
          {integration === "zapier" ? "Zapier API Key" : "Make API Key"}
          {/* <small className="ml-1 italic text-gray-500"></small> */}
        </label>
        <div className="flex items-center space-x-2">
          <input
            type={showApiKey ? "text" : "password"}
            value={apiKey || ""}
            data-tip
            data-for="api-tip"
            readOnly
            className="anchor-element flex-1 border rounded-lg px-2.5 py-1.5 transition focus:outline-none hover:cursor-pointer"
            onClick={handleApiKeyClick}
          />
          <button
            onClick={toggleShowApiKey}
            className="border rounded p-1 focus:outline-none hover:bg-gray-200 transition"
          >
            {showApiKey ? (
              <OpenEye /> // assuming you are using Material icons for eye closed
            ) : (
              <ClosedEye /> // assuming you are using Material icons for eye open
            )}
          </button>
        </div>
      </div>

      <Tooltip
        isOpen={openTooltip}
        variant="success"
        anchorSelect=".anchor-element"
        id="api-tip"
        place="bottom"
        afterShow={() => {
          setTimeout(() => {
            setOpenTooltip(false);
          }, 1000);
        }}
      >
        Copied!
      </Tooltip>
    </div>
  );
};

export default ApiKeyLabel;
