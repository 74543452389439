import { Socket } from "socket.io-client";
import {
  AssetDbType,
  UploadWithSocialPostsAndProject,
} from "../../../../types";
import { AssetPaginator } from "../AssetPaginator";
import { ASSETS_IDS } from "../types";

export const MeetingResources = ({
  upload,
  selectedAssetId,
  canEdit,
  socket,
  assets,
}: {
  upload: UploadWithSocialPostsAndProject;
  selectedAssetId: string;
  canEdit: boolean;
  socket: Socket;
  assets: AssetDbType[];
}): JSX.Element => {
  return (
    <>
      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.MEETING_FAQ
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.MEETING_SUMMARY
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.MEETING_MEMO
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.MEETING_REPORT
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.MEETING_TRAINING
          ) as AssetDbType
        }
      />
    </>
  );
};

export default MeetingResources;
