export const JapaneseFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_32049)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 17.2179C14.8815 17.2179 17.2174 14.882 17.2174 12.0006C17.2174 9.1191 14.8815 6.7832 12 6.7832C9.11855 6.7832 6.78265 9.1191 6.78265 12.0006C6.78265 14.882 9.11855 17.2179 12 17.2179Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_32049">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
