import classNames from "classnames";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation, useParams } from "react-router";
import { useLogsnag } from "../../../hooks/logsnag";
import { useAuthUserId } from "../../../hooks/useAuth";
import { useSupabase } from "../../../hooks/useSupabase";
import { useRole } from "../../../hooks/userCanEdit";
import { GearIcon } from "../../../shared/Icons/Gear";
import { LibraryModal } from "../../../shared/Library/Modal";
import {
  AssetDbType,
  ProjectDbType,
  UploadWithSocialPostsAndProject,
} from "../../../types";
import GenerationFlowManager from "../../DashboardOverview";
import SelectAssets from "../../DashboardOverview/components/SelectAssets";
import { getPaginatedEpisodes } from "../../UploadContent/util";
import { ProjectEpisodes } from "./ProjectEpisodes";
import { ProjectSettings } from "./ProjectSettings";
import { Database } from "../../../types/database.types";

type EpisodeJoinSocialPostType =
  Database["public"]["Tables"]["Upload"]["Row"] & {
    Asset: AssetDbType[];
  };

export const ProjectPage = () => {
  const { id } = useParams<{ id: string }>();
  const supabase = useSupabase();
  const [show, setShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<
    "uploads" | "settings" | "generation"
  >("uploads");
  const [project, setProject] = useState<ProjectDbType>();
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("upload");

  const [generate, setGenerate] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);

  const [refresh, setRefresh] = useState(false);
  // console.log("upload query", param);

  // useEffect(() => {
  //   if (param === "true") setSelectedCategory(categories[1]);
  // }, [param]);

  const userId = useAuthUserId();

  const role = useRole();

  const logsnag = useLogsnag();

  const [remoteEpisodes, setRemoteEpisodes] =
    useState<EpisodeJoinSocialPostType[]>();

  useEffect(() => {
    if (!id) return;
    supabase
      .from("Project")
      .select("*")
      .eq("id", id)
      .maybeSingle()
      .then(({ data }) => {
        data && setProject(data as ProjectDbType);
      });
  }, [id, supabase, userId]);

  useEffect(() => {
    // realtime
    const sub = supabase
      .channel(`changes`)
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "Project",
          filter: `id=eq.${id}`,
        },
        (payload) => {
          setProject(
            (project) =>
              ({
                ...project,
                ...payload.new,
              } as ProjectDbType)
          );
        }
      )
      .subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [id, supabase, userId]);

  useEffect(() => {
    if (!id) return;
    getPaginatedEpisodes(supabase, {
      query: { page: selectedPage, podcastId: id },
    }).then((res) => {
      setRemoteEpisodes(res.props.data as any[]);
      setPageCount(res.props.pageCount);
    });
  }, [project, supabase, show, selectedPage, userId, refresh]);

  // Invoke when user click to request another page.
  const handlePageClick = (page: number) => {
    setSelectedPage(page);
  };

  return (
    <div className="w-full h-full animate-slide-in text-14 px-4 max-w-5xl mt-5 no-scrollbar">
      {selectedCategory === "uploads" && (
        <>
          <div className="w-full flex justify-between items-center">
            <div className="text-14 font-semibold text-[#202020]">
              {project?.title ? (
                <div className="flex justify-start items-center space-x-2">
                  <p className="text-lg leading-6 font-semibold text-gray-900">
                    {project?.title}
                  </p>
                  <button
                    className="p-1.5"
                    onClick={() => {
                      setSelectedCategory("settings");
                    }}
                  >
                    <GearIcon color="#8A8AA3" />
                  </button>
                </div>
              ) : (
                <Skeleton className="min-w-[100px] h-4" />
              )}
            </div>
            <div
              className={classNames(
                "border border-uf-yellow-1 rounded-lg w-fit my-4 cursor-pointer visible opacity-100 transition-opacity ease-in-out delay-150 duration-300 flex justify-end items-center",
                {
                  " opacity-0 ": role !== "owner" && role !== "admin",
                }
              )}
              onClick={async () => {
                // dont  allow click if not admin or owner
                if (role !== "owner" && role !== "admin") return;
                setSelectedCategory("generation");
                setGenerate(true);
                logsnag.publish({
                  channel: "content",
                  description: "User opened new episode form",
                  event: "New episode form opened",
                  icon: "🎤",
                  notify: true,
                  tags: {
                    userid: (await supabase.auth.getUser()).data.user?.id ?? "",
                    email:
                      (await supabase.auth.getUser()).data.user?.email ?? "",
                  },
                });
              }}
            >
              <p className="text-14 text-[#202020] bg-uf-yellow-1 py-2 px-3.5 font-medium rounded-md">
                Generate Content
              </p>
            </div>
          </div>

          <ProjectEpisodes
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            remoteEpisodes={remoteEpisodes}
            handleRedirectToUpload={() => {
              // setSelectedCategory(categories[1]);
            }}
            reload={() => {
              setRefresh(!refresh);
            }}
          />
        </>
      )}

      {selectedCategory === "settings" && project && (
        <>
          <ProjectSettings
            project={project}
            onClickReturn={() => {
              setSelectedCategory("uploads");
            }}
          />
        </>
      )}

      {/* now if the flag "generate" is turned on, show the first step of the generation process as a modal
      and the rest as a full screen */}
      {selectedCategory === "generation" &&
        !Boolean(selectedAssetIds.length) && (
          <LibraryModal
            setShow={(bool) => {
              setGenerate(bool);
              setSelectedCategory("uploads");
              setSelectedAssetIds([]);
            }}
            show={generate}
          >
            <div className="w-full h-full space-y-3 min-h-[90vh]">
              <p className="text-lg leading-8 text-gray-900 font-semibold ml-5 ">
                Generate New
              </p>
              <SelectAssets
                hideShadow
                bgWhite
                onAssetsIntent={(assets) => {
                  setSelectedAssetIds(assets);
                }}
                onSelectProjectId={(id) => setSelectedProjectId(id)}
              />
            </div>
          </LibraryModal>
        )}
      {selectedCategory === "generation" &&
        Boolean(selectedAssetIds.length) && (
          <GenerationFlowManager
            defaultSelectedProjectId={project?.id}
            defaultSelectedAssetIds={selectedAssetIds}
            defaultStep="upload"
          />
        )}
    </div>
  );
};
