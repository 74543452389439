// this file is used to create utility function for payment like authorization

import axios from "axios";

export const getPublicCreditsBreakdown = async ({
  assetIds,
}: {
  assetIds: string[];
}): Promise<{
  assetsBreakdown:
    | {
        id: string;
        credits: number;
      }[]
    | [];
}> => {
  // construct a body object
  const params = {
    assetIds,
  };

  return (
    await axios.get(
      `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment/public-credits-breakdown`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        params,
      }
    )
  ).data;
};
