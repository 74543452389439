import classNames from "classnames";
import { useEffect, useState } from "react";
import FlipMove from "react-flip-move";
import { Socket } from "socket.io-client";
import { usePagination } from "../../../hooks/usePagination";
import { useRole } from "../../../hooks/userCanEdit";
import { AssetDbType, UploadDbType } from "../../../types";
import AssetCard from "./AssetCard";
import { AssetLoadingLiveFeed } from "./AssetLoadingLiveFeed";
import { CardLoadingLiveFeed } from "./CardLoadingLiveFeed";
import { SocialPostStructure } from "./EpisodePage";
import { LoadingScreen } from "./FlowComponents/LoadingAsset/LoadingScreen";
import { RecoverFailedAssets } from "./FlowComponents/RecoverFailedAsset/RecoverFailedAsset";
import { RecoverSkippedAsset } from "./FlowComponents/RecoverSkippedAsset/RecoverSkippedAsset";
import LinkedinCard from "./LinkedinResources/LinkedinCard";
import { RenderPagination } from "./RenderPagination";
import TwitterCard from "./TwitterResources/TwitterCard";
import { ASSETS_IDS } from "./types";

export type AssetPaginatorProps = {
  id: string;
  post: string;
  state: SocialPostStructure["state"];
  isMinimized?: boolean;
};

export const AssetPaginator = ({
  upload,
  asset,
  canEdit,
  selectedAssetId,
  itemsPerPage = 1,
  isShortForm = false,
  socket,
}: {
  upload: UploadDbType;
  asset?: AssetDbType;
  canEdit: boolean;
  selectedAssetId: string;
  itemsPerPage?: number;
  isShortForm?: boolean;
  socket: Socket;
}): JSX.Element => {
  const role = useRole();
  const posts = (
    asset?.data as {
      id: string;
      post: string;
      state: SocialPostStructure["state"];
      isArchived?: boolean;
    }[]
  )?.filter((post) => post.state !== "ARCHIVED");

  const status = asset?.status;

  useEffect(() => {
    // Create a socket connection
    // stop listen to any previous events and listne to the new one only
    if (
      status === "writing" &&
      selectedAssetId === asset?.asset_id &&
      socket &&
      !socket?.hasListeners(asset.asset_id)
    ) {
      // Listen for events and update the state
      socket.on(asset.asset_id, (data: string) => {
        setStreamedData(data);
      });
    }
  }, [asset?.asset_id, status, socket, selectedAssetId]);

  const {
    handlePageClick,
    currentItems,
    pageCount,
    refreshItems,
    resetOffset,
    forcedPage,
  } = usePagination({
    data: posts ?? [],
    itemsPerPage,
  });

  const [streamedData, setStreamedData] = useState<string>();

  useEffect(() => {
    refreshItems(posts ?? []);
  }, [posts]);

  // useEffect(() => {
  //   resetOffset();
  // }, []);
  useEffect(() => {
    if (status === "ready") {
      setStreamedData(undefined);
    }
  }, [status]);

  if (selectedAssetId !== asset?.asset_id) return <></>;
  if (status === "writing" && streamedData === undefined) {
    let waitingMessage = "Writing. Please wait 17 seconds...";
    if (asset.asset_id === ASSETS_IDS.BLOG_POST) {
      waitingMessage = "Writing. Please wait 30 seconds...";
    } else if (asset.asset_id === ASSETS_IDS.EBOOKS_CHAPTER) {
      waitingMessage = "Writing. Please wait 30 seconds...";
    } else if (asset.asset_id === ASSETS_IDS.EBOOKS_ENTIRE_PRODUCTION) {
      waitingMessage = "Writing. Please wait 1 - 2 minutes";
    }
    return (
      <LoadingScreen displayText={waitingMessage} isRelative isThemeWhite />
    );
  } else if (status === "writing" && streamedData !== undefined) {
    // if is linkedin, use LinkedinLoadingLiveFeed instead
    if (
      asset.asset_id === ASSETS_IDS.LINKEDIN_POSTS ||
      asset.asset_id === ASSETS_IDS.TWITTER_TWEETS ||
      asset.asset_id === ASSETS_IDS.TWITTER_LONGFORM
      // assetId === ASSETS_IDS.YOUTUBE_POSTS
    ) {
      return <CardLoadingLiveFeed text={streamedData} asset={asset} />;
    }

    return <AssetLoadingLiveFeed text={streamedData} />;
  }
  //failed <RecoverFailedAssets />
  if (status === "failed") {
    return <RecoverFailedAssets assetId={asset.asset_id} />;
  }

  // skipped RecoverSkippedAssets
  if (status === "skipped") {
    return <RecoverSkippedAsset assetId={asset.asset_id} />;
  }

  return (
    <div className="w-full h-full mt-5 whitespace-pre-line rounded-lg">
      <div className="w-full h-full whitespace-pre-line">
        <RenderPagination
          forcedPage={forcedPage}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
        />

        <FlipMove
          typeName="div"
          className={classNames("w-full ", {
            "flex flex-col justify-start items-stretch my-4": !isShortForm,
            "mt-5 grid lg:gap-x-3 grid-cols-1 lg:grid-cols-2 gap-y-12 place-items-start":
              isShortForm,
          })}
        >
          {currentItems?.map((postObject, idx) => (
            <div id={postObject.id} key={postObject.id} className="w-full">
              {asset.asset_id === ASSETS_IDS.LINKEDIN_POSTS ? (
                <LinkedinCard
                  canEdit={canEdit}
                  position={idx + 1}
                  postObject={postObject}
                  role={role}
                  upload={upload}
                  asset={asset}
                />
              ) : asset.asset_id === ASSETS_IDS.TWITTER_TWEETS ? (
                <TwitterCard
                  canEdit={canEdit}
                  position={idx + 1}
                  postObject={postObject}
                  role={role}
                  upload={upload}
                  asset={asset}
                />
              ) : (
                <AssetCard
                  upload={upload}
                  canEdit={canEdit}
                  post={postObject}
                  role={role}
                  asset={asset}
                  stream={false}
                />
              )}
            </div>
          ))}
        </FlipMove>

        <RenderPagination
          forcedPage={forcedPage}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
        />
      </div>
    </div>
  );
};
