import "react-dropzone-uploader/dist/styles.css";
import { CircleCheckmark } from "../../../shared/Icons/CircleCheckmark";

export const WPBusinessPlan = ({ isMonthly }: { isMonthly: boolean }) => {
  const displayPrice = isMonthly ? 199 : 165;

  return (
    <>
      <div className="h-full bg-white p-6 rounded-md border border-black">
        <div className="flex items-center">
          <h2 className="text-[#202020] font-semibold text-14 mr-2">
            Business
          </h2>
        </div>
        <p className="text-[#84818A] text-14 font-medium mt-2 h-[63px]">
          For businesses and agencies with the highest transcription and content
          quality needs and unlimited collaboration.
        </p>
        <div className="mt-7">
          <span className="text-[#000000] text-4xl leading-10 font-semibold">
            €{displayPrice}
          </span>
          <span className="text-[#84818A] font-semibold text-16">/mo</span>
        </div>
        <ul className="mt-8 space-y-2">
          {[
            "Unlimited content generation",
            "Unlimited uploads & transcriptions",
            "Unlimited team members",
            "Unlimited AI writer usage",
          ].map((feature, index) => (
            <li key={index} className="flex items-center space-x-2 ">
              <CircleCheckmark className="text-[#FF5698]" />
              <span className="relative text-sm leading-5 font-medium text-[#121217]">
                {" "}
                {feature}
                {index === 1 && <span className=" text-[#FF5698]">*</span>}
              </span>
              {/* asterics if index is 1 */}
            </li>
          ))}
        </ul>
        <button
          className="w-full bg-uf-yellow-1 text-sm leading-5 font-semibold text-[#202020] py-2 px-4 rounded-lg mt-8"
          onClick={async () => {
            window.parent.location.href = "https://app.unifire.ai/signup";
          }}
        >
          Start your 14-day trial
        </button>

        <hr className="h-px bg-[#DCDBDD] mt-[36px] mb-[26px]" />

        <div className="text-gray-800 space-y-5">
          <p className="text-14 text-[#202020] font-semibold">
            Everything in Premium, plus:
          </p>

          <p className="text-14 font-medium text-[#42474B]">
            5 Custom Content Templates per month
          </p>
          <p className="text-14 font-medium text-[#42474B]">Priority Support</p>
          <p className="text-14 font-medium text-[#42474B]">
            Priority AI Processing
          </p>
        </div>
      </div>
    </>
  );
};

export default WPBusinessPlan;
