import ReactSwitch from "react-switch";

export const Switch = ({
  checked,
  onChange,
  onColor = "#7047EB",
}: {
  checked: boolean;
  onChange: (checked: boolean) => void;
  onColor?: string;
}) => (
  <ReactSwitch
    height={16}
    width={30}
    onChange={onChange}
    checked={checked}
    checkedIcon={false}
    uncheckedIcon={false}
    onColor={onColor}
  />
);
