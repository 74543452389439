import classNames from "classnames";
import { useState } from "react";
import { ArrowButton } from "./ArrowButton";

// job title
// emoji
const menuItems = [
  { id: "student", label: "📖 Student" },
  { id: "youtuber", label: "📽️ YouTuber or Video Creator" },
  { id: "podcaster", label: "🎙 Podcaster" },
  { id: "blogger", label: "📩 Blogger" },
  { id: "marketing", label: "📈 Marketing Agency" },
  { id: "freelancer", label: "📈 Freelancer / Solopreneur" },
  {
    id: "socialMedia",
    label: "🖼️ Social Media / Content Marketer",
  },
  {
    id: "conference",
    label: "📆 Conference & Event Host",
  },
  {
    id: "educational",
    label: "🎓 Educational Provider",
  },
  {
    id: "other",
    label: "？Other",
  },
];
export const UserJob = ({
  onSelectedJob,
}: {
  onSelectedJob: (job: string) => void;
}) => {
  const [selectedJob, setSelectedJob] = useState("");
  return (
    <div>
      <p className="text-[#202020] font-semibold text-20 text-center">
        What describes you best?
      </p>
      <p className="text-[#202020] text-16 mt-4 text-center">
        We will tailor your experience and content assets to your <br />
        role
      </p>

      <div className="mt-[38px] max-w-[550px]">
        <div className="flex flex-row items-start flex-wrap justify-start space-x-[10px]">
          {menuItems.map(({ id, label }, idx) => (
            <div
              key={id}
              className={classNames({
                "mt-4": idx >= 2,
                "ml-2": idx < 2,
              })}
            >
              <ArrowButton
                label={label}
                isChecked={selectedJob === id}
                onClick={() => {
                  setSelectedJob(id);
                }}
              />
            </div>
          ))}

          <button
            className=" bg-uf-yellow-1 rounded-lg py-2.5 w-full text-14 font-semibold text-[#42474B] disabled:opacity-50 mt-36"
            disabled={!Boolean(selectedJob)}
            onClick={() => onSelectedJob(selectedJob)}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
