// payment-v2/add-seat

import axios from "axios";

// const { seats }: { seats: { price_id: string }[] } = req.body;
export const addSeat = async ({
  seats,
  supabase,
}: {
  seats: { price_id: string }[];
  supabase: any;
}) => {
  // req.setRequestHeader("x-upsert", "true");
  // random string
  const body = {
    seats,
  };

  const session = await supabase.auth.getSession();

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment-v2/add-seat`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};

// removePaddleSeats
// /payment-v2/remove-seat
//
export const removeSeat = async ({
  priceId,
  supabase,
}: {
  priceId: string;
  supabase: any;
}) => {
  const body = {
    price_id: priceId,
  };

  const session = await supabase.auth.getSession();

  return axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/payment-v2/remove-seat`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    }
  );
};
