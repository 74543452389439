import { useEffect, useMemo, useState } from "react";
import { Database } from "../types/database.types";
import { useAuthUserId } from "./useAuth";
import { useSupabase } from "./useSupabase";

export type Workspace = Database["public"]["Tables"]["Workspace"]["Row"] & {
  owner_name: string;
};

export const useWorkspace = () => {
  const [workspace, setWorkspace] = useState<Workspace | undefined | null>(
    JSON.parse(localStorage?.getItem("workspace") ?? "null")
  );
  const supabase = useSupabase();
  const userId = useAuthUserId();
  const switchWorkspace = useSwitchWorkspace();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeStorage = async () => {
    const localWorkspace = JSON.parse(
      localStorage?.getItem("workspace") ?? "null"
    );

    if (!userId) return;
    if (localWorkspace) {
      setWorkspace(localWorkspace);
      return;
    } else {
      const { data: newRemoteWorkspace } = await supabase
        .from("Workspace")
        .select(`*, UserProfile(*)`)
        .order("created_at", { ascending: false })
        .eq("owner_id", userId)
        .limit(1)
        .single();

      const newWorkspace = await switchWorkspace(
        newRemoteWorkspace?.id as string,
        false
      );

      if (newWorkspace) setWorkspace(newWorkspace as Workspace);
    }
  };

  useEffect(() => {
    window.addEventListener("storage", handleChangeStorage);
    handleChangeStorage();
    return () => window.removeEventListener("storage", handleChangeStorage);
  }, [supabase, userId]);

  return useMemo(
    () =>
      Boolean(workspace)
        ? Object.assign(
            {},
            { ...workspace, id: workspace?.id?.replaceAll("'", "") }
          )
        : ({} as Workspace | null | undefined),
    [workspace]
  );
};

export const useSwitchWorkspace = () => {
  const supabase = useSupabase();

  const refresh = async (workspaceId: string, reload?: boolean) => {
    if (!workspaceId) return;

    const { data: workspace, error } = await supabase
      .from("Workspace")
      .select(`*, UserProfile(full_name)`)
      .order("created_at", { ascending: false })
      .eq("id", workspaceId.replaceAll("'", ""))
      .limit(1)
      .single();

    if (!workspace?.id) return;

    const workspaceWithOwnerName = Object.assign(
      {},
      {
        ...workspace,
        owner_name: (workspace?.UserProfile as any)?.full_name ?? "",
        UserProfile: undefined,
      }
    );

    localStorage.setItem("workspace", JSON.stringify(workspaceWithOwnerName));

    // if (reload) window.location.reload();
    // assign url again without path
    if (reload)
      window.location.href = `${window.location.origin}/dashboard/overview`;

    return workspaceWithOwnerName;
  };

  return refresh;
};

export const removeWokspace = () => {
  localStorage.removeItem("workspace");
};
