export const FinishFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_31838)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.8986 10.4347H9.39154H9.3915V0.285156C8.28253 0.531062 7.23117 0.929641 6.26104 1.459V10.4346V10.4347H0.101761C0.0349644 10.9471 0.000183105 11.4694 0.000183105 11.9999C0.000183105 12.5304 0.0349644 13.0528 0.101761 13.5651H6.261H6.26104V22.5408C7.23117 23.0701 8.28253 23.4688 9.3915 23.7146V13.5653V13.5652H23.8986C23.9653 13.0528 24.0002 12.5304 24.0002 11.9999C24.0002 11.4694 23.9653 10.9471 23.8986 10.4347Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_31838">
        <rect width="24" height="24" rx="5" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
