import { useCallback } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PaginationArrow } from "../../../assets/pagination-arrow.svg";
import { useLogsnag } from "../../../hooks/logsnag";
import { useSupabase } from "../../../hooks/useSupabase";
import { useRole } from "../../../hooks/userCanEdit";
import { Database } from "../../../types/database.types";
import { ReactComponent as MagnifierSvg } from "../assets/empty-project-magnifier.svg";
import { ProjectSingleEpisode } from "./ProjectSingleEpisode";
import { SkeletonEpisodes } from "./SkeletonEpisodes";
import { AssetDbType } from "../../../types";

export type EpisodeJoinSocialPostType =
  Database["public"]["Tables"]["Upload"]["Row"] & {
    Asset: AssetDbType[];
  };

export type ProjectType = Database["public"]["Tables"]["Project"]["Row"];

export const ProjectEpisodes = ({
  remoteEpisodes,
  pageCount,
  handlePageClick,
  handleRedirectToUpload,
  reload,
}: {
  remoteEpisodes?: EpisodeJoinSocialPostType[];
  pageCount: number;
  handlePageClick: (selected: number) => void;
  handleRedirectToUpload: () => void;
  reload: () => void;
}) => {
  const navigate = useNavigate();
  const logsnag = useLogsnag();

  const role = useRole();

  const supabase = useSupabase();

  const onSave = useCallback(
    async (uploadId: string, newText: string) => {
      const resp = await supabase
        .from("Upload")
        .update({ title: newText })
        .eq("id", uploadId as string);
    },
    [supabase]
  );
  const truncateText = useCallback((text: string, length: number = 200) => {
    if (text.length > length) {
      return text.substring(0, length) + "...";
    } else {
      return text;
    }
  }, []);

  if (remoteEpisodes?.length === 0)
    return (
      <div className="w-full flex justify-center items-center mt-20">
        <div className="flex flex-col justify-center items-center space-y-8">
          <MagnifierSvg />
          <p className="text-14 font-semibold text-[#3487EB] ">
            Nothing here yet...start by uploading a video/audio content
          </p>
          {/* <button
            className="border border-uf-yellow-1 px-10 py-1 text-10 font-medium bg-uf-yellow-1  rounded-lg w-fit my-4 visible opacity-100 transition-opacity ease-in-out delay-150 duration-300 flex justify-end items-center disabled:cursor-not-allowed"
            disabled={role !== "owner" && role !== "admin" && role !== "editor"}
            onClick={() => {
              handleRedirectToUpload();
            }}
          >
            Start your first upload
          </button> */}
        </div>
      </div>
    );
  return (
    <div className="w-full mt-12">
      {
        <p className="text-sm leading-6 font-semibold text-gray-700">
          Recent Generations
        </p>
      }
      <div className="flex justify-end items-center w-full pt-10">
        <ReactPaginate
          className="flex justify-start items-center space-x-4 shadow py-3 px-4 rounded-xl "
          breakLabel="..."
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          nextLabel={
            <PaginationArrow className="w-6 h-6 drop-shadow rotate-0" />
          }
          // activeClassName="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white text-white rounded-full w-7 h-7 p-0"
          activeLinkClassName="bg-gray-600 text-white text-white rounded-full px-2"
          pageLinkClassName="px-2"
          onPageChange={(e) => {
            handlePageClick(e.selected);
          }}
          pageCount={pageCount}
          previousLabel={<PaginationArrow className="w-6 h-6 drop-shadow" />}
        />
      </div>
      <div className="space-y-8 mt-5">
        {remoteEpisodes?.map((ep, idx) => {
          return (
            <ProjectSingleEpisode
              key={ep.id}
              remoteEpisode={ep}
              role={role}
              reload={reload}
              assetIds={ep.Asset.map((asset) => asset.asset_id as string)}
            />
          );
        })}
      </div>

      <div className="w-full space-y-8 mt-5">
        {remoteEpisodes === undefined && <SkeletonEpisodes />}
      </div>

      <div className="flex justify-end items-center w-full pt-2 pb-10">
        <ReactPaginate
          className="flex justify-start items-center space-x-4 shadow py-3 px-4 rounded-xl "
          breakLabel="..."
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          nextLabel={
            <PaginationArrow className="w-6 h-6 drop-shadow rotate-0" />
          }
          // activeClassName="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white text-white rounded-full w-7 h-7 p-0"
          activeLinkClassName="bg-gray-600 text-white text-white rounded-full px-2"
          pageLinkClassName="px-2"
          onPageChange={(e) => {
            handlePageClick(e.selected);
          }}
          pageCount={pageCount}
          previousLabel={<PaginationArrow className="w-6 h-6 drop-shadow" />}
        />
      </div>
    </div>
  );
};
