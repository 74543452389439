import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/react";
import * as htmlparser from "htmlparser2";

export const capitalizeFirst = (text: string) => {
  const copyText = `${text}`;
  return (
    copyText.charAt(0).toUpperCase() + copyText.slice(1).replaceAll("_", " ")
  );
};

export const ytbInfluencerAttrMap = {
  "": "",
  channel_name: "Channel Name",
  link: "Link",
  country: "Country",
  language: "Language",
  subscribers: "Subscribers",
  avg_sub_per_month: "Avg Sub /Month",
  total_views: "Total Views",
  avg_views_per_video: "Average Views per video",
  total_videos: "Total Videos",
  category: "Category",
  keyword: "Keyword",
};

export const buttonClassnames = `
inline-block
px-8 py-2 
mb-4
font-bold 
leading-normal 
text-center 
text-white 
uppercase
align-middle
transition-all 
ease-in 
rounded-lg
shadow-xs 
cursor-pointer
bg-slate-900
text-xs tracking-tight-rem 
bg-150
bg-x-25
hover:-translate-y-px 
active:opacity-85 
hover:shadow-md`;

export const initSentry = () => {
  const dsn = process.env.REACT_APP_SENTRY_DSN;

  if (!dsn) return;

  try {
    Sentry.init({
      dsn,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.5,
      allowUrls: [/app.unifire\.ai/],
      release: process.env.NEXT_PUBLIC_RELEASE_VERSION,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("failed to initialize sentry:", error);
  }
};

type MimeTypeMap = {
  [type: string]: string[];
};

const acceptedFileTypes: MimeTypeMap = {
  "audio/*": [".mp3", ".wav", ".ogg", ".flac", ".m4a"],
  "video/*": [".mp4", ".mov", ".wmv", ".flv", ".webm", ".mpeg", ".mpg", ".m4v"],
};
export function getFileType(filename: string): string | null {
  const fileExtension = filename
    ?.slice(filename?.lastIndexOf("."))
    ?.toLowerCase();

  for (const mimeType in acceptedFileTypes) {
    if (acceptedFileTypes[mimeType]?.includes(fileExtension)) {
      return mimeType?.split("/")?.[0];
    }
  }

  return null;
}

// now use getFileType to return 3 enums: Audio, Video, Text
export const getFileTypeAnalytics = (filename: string) => {
  const fileType = getFileType(filename);
  if (fileType === "audio") return "audio";
  if (fileType === "video") return "video";
  return "text";
};

export const AVATAR_COLORS = [
  "#EF4444",
  "#F59E0B",
  "#10B981",
  "#3B82F6",
  "#6366F1",
  "#8B5CF6",
  "#EC4899",
  "#F43F5E",
  "#F97316",
  "#047857",
  "#065F46",
];

export function parseHtmlToString(htmlString: string) {
  let parsedText = "";
  const parser = new htmlparser.Parser(
    {
      ontext(text) {
        parsedText += text;
      },
    },
    { decodeEntities: true }
  );

  parser.write(htmlString);
  parser.end();

  // replace too many newlines with just one
  parsedText = parsedText.replace(/\n{3,}/g, "\n\n");

  // if it starts with "\n then replace it with new line only
  if (parsedText.startsWith('"\n')) {
    parsedText = parsedText.slice(2, parsedText.length);
  }

  // if it ends with "\n then replace it with new line only
  if (parsedText.endsWith('\n"')) {
    // remove the end part
    parsedText = parsedText.slice(0, parsedText.length - 2);
  }

  return parsedText;
}

export function parseHtmlToStringWithNewLines(htmlString: string) {
  let parsedText = "";
  const parser = new htmlparser.Parser(
    {
      ontext(text) {
        // Append text directly along with new lines
        parsedText += text;
      },
      onopentag(name) {
        // If a new block-level element opens, prepend a newline to separate content clearly
        if (["p", "div", "br", "hr", "ul", "ol", "li"].includes(name)) {
          parsedText += "\n";
        }
      },
      onclosetag(name) {
        // Add a newline after closing block-level elements to maintain readable formatting
        if (["p", "div", "br", "hr", "ul", "ol", "li"].includes(name)) {
          parsedText += "\n";
        }
      },
    },
    { decodeEntities: true }
  );

  parser.write(htmlString);
  parser.end();

  // Clean up newlines: replace three or more consecutive newlines with just two
  parsedText = parsedText.replace(/\n{3,}/g, "\n\n");

  // Trim leading and trailing quotation marks with newlines if they exist
  parsedText = parsedText.replace(/^"\n+/, "").replace(/\n+"$/, "");

  return parsedText;
}
