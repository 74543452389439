import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSupabase } from "../../../hooks/useSupabase";
import { GearIcon } from "../../../shared/Icons/Gear";
import { WarningIcon } from "../../../shared/Icons/Warning";
import { ProjectDbType } from "../../../types";
import { SelectLanguage } from "../../Dashboard/components/CreateProject";

export const ProjectSettings = ({
  project,
  onClickReturn,
}: {
  project: ProjectDbType;
  onClickReturn: () => void;
}) => {
  const [projectTitle, setProjectTitle] = useState("");
  const [langTag, setLangTag] = useState<string>();

  const supabase = useSupabase();

  const onSave = async () => {
    await supabase
      .from("Project")
      .update({ title: projectTitle || project.title, lang_tag: langTag })
      .eq("id", project.id);
  };
  useEffect(() => {
    if (!langTag && (projectTitle === undefined || projectTitle === "")) return;
    const delayDebounceFn = setTimeout(() => {
      onSave();
      // console.log("saving");
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [supabase, project.id, projectTitle, langTag]);

  const updateProjectLang = (lang: string) => {
    supabase
      .from("Project")
      .update({ lang_tag: lang })
      .eq("id", project.id)
      .then((e) => {});
  };

  return (
    <div className="px-4">
      <div className="flex justify-start items-center text-[#42474B] text-14 font-medium space-x-4">
        <button onClick={onClickReturn}>
          {project?.title ? (
            project?.title
          ) : (
            <Skeleton className="min-w-[100px] h-4" />
          )}
        </button>

        <span>/</span>

        <span className="cursor-pointer max-w-[100px] lg:max-w-[250px] truncate">
          {project?.title ? (
            "Project Settings"
          ) : (
            <Skeleton className="min-w-[100px] h-4" />
          )}
        </span>
      </div>

      <div className="flex justify-start items-center space-x-2 mt-[69px]">
        <p className="text-lg leading-6 font-semibold text-gray-900">
          Project Settings
        </p>
        <button
          className="p-1.5"
          onClick={() => {
            onClickReturn();
          }}
        >
          <GearIcon color="#8A8AA3" />
        </button>
      </div>

      <div className="mt-11">
        <span className="text-14 text-[#121217] font-medium ">
          Name your Project
        </span>
        <input
          type="text"
          name="title"
          id="title"
          placeholder="New Unicorns"
          className="appearance-none mt-3 border border-[#DCDBDD] rounded-xl text-14 text-gray-900 w-full px-4 py-3 leading-tight focus:outline-none focus:shadow-outline"
          defaultValue={project.title as string}
          onChange={(e) => setProjectTitle(e.target.value)}
        />
      </div>

      <div className="mt-10">
        <SelectLanguage
          isOnboarding
          defaultLanguage={project?.lang_tag ?? "en"}
          handleLangChange={(lang) => updateProjectLang(lang)}
          title="Project Language"
        />
      </div>

      <div className="space-y-7 bg-[#FBFCFC] rounded-xl px-6 py-5 mt-40 shadow">
        <div className="flex justify-start items-center">
          <WarningIcon />
          <span className="text-red-700 text-sm leading-6 font-semibold ml-2">
            Danger Zone - Project deletion
            <small className="ml-1 italic text-gray-500">(coming soon)</small>
          </span>
        </div>
        <p className="text-sm leading-5 font-medium text-[#6C6C89]">
          Once you delete your project, there is no going back. Please be
          certain.
        </p>

        <button
          className="text-14 text-white py-2 px-[22px] bg-red-700 rounded-md cursor-not-allowed"
          disabled
        >
          Delete project
        </button>
      </div>
    </div>
  );
};
