import { Socket } from "socket.io-client";
import { CanEditType } from "../../../../hooks/userCanEdit";
import {
  AssetDbType,
  UploadWithSocialPostsAndProject,
} from "../../../../types";
import { AssetPaginator } from "../AssetPaginator";
import { SocialPostStructure } from "../EpisodePage";
import { ASSETS_IDS } from "../types";

export type TwitterThread = {
  id: string;
  state: SocialPostStructure["state"];
  upload: UploadWithSocialPostsAndProject;
  isMinimized: boolean;
  tweets: {
    id: string;
    tweet: string;
    state: SocialPostStructure["state"];
    upload: UploadWithSocialPostsAndProject;
    isMinimized: boolean;
  }[];
  canEdit: CanEditType;
};
export type TwitterTweet = {
  id: string;
  post: string;
  state: SocialPostStructure["state"];
  upload: UploadWithSocialPostsAndProject;
  isMinimized?: boolean;
};

export const TwitterResources = ({
  episode,
  selectedSubCategory,
  canEdit,
  upload,
  socket,
  assets,
}: {
  episode: UploadWithSocialPostsAndProject;
  selectedSubCategory: string;
  upload: UploadWithSocialPostsAndProject;
  canEdit: boolean;
  socket: Socket;
  assets: AssetDbType[];
}): JSX.Element => {
  return (
    <>
      {/* <div className="w-full h-full mt-2 whitespace-pre-line rounded-lg">
        {selectedSubCategory === ASSETS_IDS.TWITTER_TWEETS && (
          <TweetsSection
            episode={episode}
            selectedSubCategory={selectedSubCategory}
            selectedEditState={selectedEditState}
            getArchivedPostsCount={getArchivedPostsCount}
            getFavorizedPostsCount={getFavorizedPostsCount}
            getUntaggedPostsCount={getUntaggedPostsCount}
            canEdit={canEdit}
            upload={upload}
          />
        )}

        {selectedSubCategory === ASSETS_IDS.TWITTER_THREADS && (
          <ThreadsSection
            episode={episode}
            getArchivedPostsCount={getArchivedPostsCount}
            getFavorizedPostsCount={getFavorizedPostsCount}
            getUntaggedPostsCount={getUntaggedPostsCount}
            selectedEditState={selectedEditState}
            selectedSubCategory={selectedSubCategory}
            canEdit={canEdit}
            upload={upload}
          />
        )}
      </div> */}
      <AssetPaginator
        canEdit={canEdit}
        upload={episode}
        selectedAssetId={selectedSubCategory}
        isShortForm
        itemsPerPage={6}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.TWITTER_TWEETS
          ) as AssetDbType
        }
      />
      <AssetPaginator
        canEdit={canEdit}
        upload={episode}
        selectedAssetId={selectedSubCategory}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.TWITTER_THREADS
          ) as AssetDbType
        }
      />
      <AssetPaginator
        canEdit={canEdit}
        upload={upload}
        selectedAssetId={selectedSubCategory}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.TWITTER_LONGFORM
          ) as AssetDbType
        }
      />
    </>
  );
};
