import { useState } from "react";
import { CheckButton } from "./CheckButton";

// job title
// emoji
const menuItems = [
  {
    id: "transcribe_audio_video_files",
    label: "💬 Transcribe audio and video files",
  },
  {
    id: "autogenerate_podcast_assets",
    label: "🎙️ Autogenerate podcast assets (show notes, time stamps...)",
  },
  {
    id: "extract_youtube_assets",
    label: "📹 Extract YouTube assets (descriptions, tags...)",
  },
  {
    id: "repurpose_content_into_social_posts",
    label: "🕊️ Repurpose existing content into social posts (X, LinkedIn...)",
  },
  {
    id: "convert_audio_video_into_text",
    label:
      "🎧 Convert audio & video content into long-form text (blog articles, e-books...)",
  },
  {
    id: "summarise_meetings",
    label: "👔 Summarise meetings (reports, summaries, action plans...)",
  },
  {
    id: "create_educational_resources",
    label:
      "🎓 Create educational resources from lectures and course recordings",
  },
];

export const UseCases = ({
  onSelectedUseCases,
}: {
  onSelectedUseCases: (usecases: string[]) => void;
}) => {
  const [selectedUseCases, setSelectedUseCases] = useState<string[]>([]);
  return (
    <div>
      <p className="text-[#202020] font-semibold text-20 text-center">
        How are you planning to use Unifire?
      </p>
      <p className="text-[#202020] text-16 mt-4 text-center">
        We will send you examples on how to achieve your goals <br />
        based on your selection
      </p>

      <div className="mt-[58px] max-w-[600px]">
        <div className="space-y-[15px]">
          {menuItems.map(({ id, label }, idx) => (
            <div key={id}>
              <CheckButton
                label={label}
                isChecked={selectedUseCases.includes(label)}
                onClick={() => {
                  setSelectedUseCases([...selectedUseCases, label]);
                }}
              />
            </div>
          ))}
        </div>
        <button
          className=" bg-uf-yellow-1 rounded-lg py-2.5 w-full text-14 font-semibold text-[#42474B] disabled:opacity-50 mt-32"
          disabled={!Boolean(selectedUseCases.length)}
          onClick={() => onSelectedUseCases(selectedUseCases)}
        >
          Continue
        </button>
      </div>
    </div>
  );
};
