import React, { useEffect, useState } from "react";
import { useSupabase } from "../../../hooks/useSupabase";
import { useWorkspace } from "../../../hooks/useWorkspace";
import ApiKeyLabel from "./ApiKeyLabel";

const MakeApiKey: React.FC = () => {
  const supabase = useSupabase();
  const workspace = useWorkspace();
  const [apiKey, setApiKey] = useState<string>();

  //useEffect
  useEffect(() => {
    supabase
      .from("Make")
      .select("*")
      .eq("workspace_id", workspace?.id as string)
      .limit(1)
      .single()
      .then(({ data, error }) => {
        if (data?.api_key) {
          setApiKey(data.api_key);
        }
      });
  }, [supabase, workspace?.id]);

  return <ApiKeyLabel integration="make" apiKey={apiKey ?? ""} />;
};

export default MakeApiKey;
