import React from "react";
import { ReactComponent as Spinner } from "../assets/spinner.svg";
// import Lottie from "lottie-react";
import CheckmarkLottie from "../assets/checkmark.json";
import Lottie from "react-lottie";
import LottieSpinner from "./LottieSpinner.json";

export const Notification = ({
  display,
  title,
  type,
  onConfirm,
}: {
  display: boolean;
  title: string;
  type: "casual" | "storing" | "success";
  onConfirm: () => void;
}) => {
  return (
    <div
      className={`fixed bottom-0 left-[55%] -translate-x-[55%] flex justify-center items-center z-50
      ${
        display
          ? "-translate-y-10 duration-300"
          : "translate-y-full duration-300"
      }
      `}
    >
      <div className="px-4 w-72 md:w-96 py-3 text-12 md:text-14 rounded-xl text-slate-900 bg-uf-yellow-1 shadow-xl font-bold">
        {/* {type === "casual" && (
          <div className="flex justify-end items-center w-4/12">
            <button
              className="border border-white px-2 rounded-md "
              onClick={onConfirm}
            >
              save
            </button>
          </div>
        )} */}

        {type === "casual" && <p className="w-full text-center">{title}</p>}

        {type === "storing" && (
          <div className="w-full flex justify-center items-center">
            <div className="flex justify-start items-center">
              <p className="text-center h-full">{title}</p>
              <Lottie
                width={30}
                height={30}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: LottieSpinner,
                }}
              />
            </div>
          </div>
        )}

        {type === "success" && (
          <>
            <p className="w-full text-start">{title}</p>
            <div className="flex justify-around items-center w-4/12">
              {/* <Lottie
                animationData={CheckmarkLottie}
                loop={true}
                className="w-12 h-12"
              /> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Notification;
