import { useEffect, useMemo, useState } from "react";
import { UploadDbType } from "../types";
import { useAuthUserId } from "./useAuth";
import { useSupabase } from "./useSupabase";
import { useWorkspace } from "./useWorkspace";

export type CanEditType =
  | {
      authorized: boolean;
      editorName: string;
    }
  | null
  | undefined;

export const useCanEdit = ({
  episode,
}: {
  episode: UploadDbType | undefined;
}): CanEditType => {
  const supabase = useSupabase();
  const [canEdit, setCanEdit] = useState<null | {
    authorized: boolean;
    editorName: string;
  }>(null);

  const workspace = useWorkspace();

  const userId = useAuthUserId();

  useEffect(() => {
    if (episode?.allow_edit === true && userId === undefined)
      return setCanEdit({ authorized: true, editorName: "Anon" });

    if (!episode || !workspace?.id || !userId) return;

    const userPromise = supabase
      .from("UserProfile")
      .select("*")
      .eq("id", userId)
      .single();

    const memebership = supabase
      .from("Member")
      .select("*")
      .eq("workspace_id", workspace?.id as string)
      .eq("user_id", userId)
      .single();

    const resultProm = Promise.all([userPromise, memebership]);

    resultProm.then(([{ data: user }, { data: membership }]) => {
      setCanEdit({
        authorized:
          membership?.role === "owner" ||
          membership?.role === "admin" ||
          membership?.role === "editor" ||
          episode?.allow_edit === true,
        editorName: user?.full_name ?? "Anon",
      });
    });
  }, [episode?.id, supabase, workspace?.id, userId]);

  return useMemo(() => canEdit, [canEdit]);
};

export type RoleType = undefined | "admin" | "owner" | "editor" | "reader";

export const useRole = (): RoleType => {
  const supabase = useSupabase();
  const [role, setRole] = useState<undefined | string>();

  const workspace = useWorkspace();

  const userId = useAuthUserId();

  useEffect(() => {
    if (!userId) return;
    supabase
      .from("Member")
      .select("*")
      .eq("workspace_id", workspace?.id as string)
      .eq("user_id", userId)
      .single()
      .then(({ data }) => {
        setRole(data?.role);
      });
  }, [supabase, workspace?.id, userId]);

  return useMemo(() => role as RoleType, [role]);
};
