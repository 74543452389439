import classNames from "classnames";
import { Link } from "react-router-dom";
import { useRemainingCredits } from "../../hooks/useRemainingCredits";
import { ReactComponent as WarningSvg } from "./assets/warning.svg";

export const BillinInfoBanner = () => {
  const { remainingInpCharacters, ownerGeneralLimits } = useRemainingCredits();

  const displayBanner =
    ownerGeneralLimits.planType !== "LTD" && remainingInpCharacters <= 0;

  return (
    <div
      className={classNames(
        "transition-all pointer-events-none opacity-0 w-full h-[45px] flex justify-start items-center bg-uf-yellow-1/30 max-w-5xl sticky top-[5px] overflow-hidden z-10",
        {
          "opacity-100 pointer-events-auto": displayBanner,
        }
      )}
    >
      <div className="h-full w-[6px] bg-uf-yellow-1" />

      <div className="w-full h-full flex justify-start items-center">
        <WarningSvg className="w-8 h-8 mx-4" />

        <p className="text-14">
          This team has run out of input characters for the month. The owner can
          upgrade through the Pricing page
          <Link
            className="ml-1 font-semibold underline"
            to={`/dashboard/billing`}
          >
            Upgrade to continue
          </Link>
        </p>
      </div>
    </div>
  );
};

export default BillinInfoBanner;
