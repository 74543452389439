export const KoreanFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_32690)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M16.1741 12.0001C16.1741 13.0435 14.3054 15.6522 12.0002 15.6522C9.69501 15.6522 7.82629 13.0435 7.82629 12.0001C7.82629 9.69484 9.69501 7.82617 12.0002 7.82617C14.3054 7.82617 16.1741 9.69484 16.1741 12.0001Z"
        fill="#D80027"
      />
      <path
        d="M16.1741 12C16.1741 14.3052 14.3054 16.1739 12.0002 16.1739C9.69501 16.1739 7.82629 14.3052 7.82629 12"
        fill="#0052B4"
      />
      <path
        d="M17.5336 14.5827L16.4268 15.6895L17.1647 16.4273L18.2714 15.3205L17.5336 14.5827Z"
        fill="black"
      />
      <path
        d="M15.6884 16.4264L14.5817 17.5332L15.3195 18.2711L16.4263 17.1643L15.6884 16.4264Z"
        fill="black"
      />
      <path
        d="M19.7471 16.7946L18.6403 17.9014L19.3782 18.6392L20.4849 17.5325L19.7471 16.7946Z"
        fill="black"
      />
      <path
        d="M17.903 18.6413L16.7963 19.748L17.5341 20.4859L18.6409 19.3791L17.903 18.6413Z"
        fill="black"
      />
      <path
        d="M18.6406 15.6901L17.5339 16.7969L18.2717 17.5347L19.3785 16.428L18.6406 15.6901Z"
        fill="black"
      />
      <path
        d="M16.796 17.5339L15.6892 18.6406L16.4271 19.3785L17.5338 18.2717L16.796 17.5339Z"
        fill="black"
      />
      <path
        d="M15.6893 5.35821L18.6407 8.30957L19.3785 7.57172L16.4272 4.62037L15.6893 5.35821Z"
        fill="black"
      />
      <path
        d="M14.5828 6.46649L15.6895 7.57324L16.4273 6.83543L15.3206 5.72867L14.5828 6.46649Z"
        fill="black"
      />
      <path
        d="M16.4274 8.31024L17.5341 9.41699L18.272 8.67918L17.1652 7.57243L16.4274 8.31024Z"
        fill="black"
      />
      <path
        d="M16.7951 4.25262L17.9019 5.35938L18.6397 4.62153L17.5329 3.51477L16.7951 4.25262Z"
        fill="black"
      />
      <path
        d="M18.6409 6.09735L19.7477 7.2041L20.4855 6.46625L19.3787 5.3595L18.6409 6.09735Z"
        fill="black"
      />
      <path
        d="M7.20343 19.7482L4.25208 16.7969L3.51423 17.5347L6.46558 20.4861L7.20343 19.7482Z"
        fill="black"
      />
      <path
        d="M8.31067 18.64L7.20392 17.5332L6.4661 18.271L7.57286 19.3778L8.31067 18.64Z"
        fill="black"
      />
      <path
        d="M6.46586 16.7962L5.3591 15.6895L4.62125 16.4273L5.72801 17.5341L6.46586 16.7962Z"
        fill="black"
      />
      <path
        d="M9.41751 17.5334L6.46616 14.582L5.72831 15.3199L8.67967 18.2712L9.41751 17.5334Z"
        fill="black"
      />
      <path
        d="M4.25265 7.20429L7.20401 4.25293L6.46616 3.51508L3.5148 6.46644L4.25265 7.20429Z"
        fill="black"
      />
      <path
        d="M5.35864 8.30976L8.31 5.3584L7.57215 4.62055L4.62079 7.57191L5.35864 8.30976Z"
        fill="black"
      />
      <path
        d="M6.46637 9.41718L9.41772 6.46582L8.67991 5.728L5.72855 8.67936L6.46637 9.41718Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_32690">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
