import classNames from "classnames";
import "rc-slider/assets/index.css";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { usePaddle } from "../../../hooks/usePaddle";
import { useSupabase } from "../../../hooks/useSupabase";
import { Switch } from "../../../shared/Library/Switch";
import { BillingDbType, UserProfileDbType } from "../../../types";
import { getCardUpdateLink } from "../../../utils/paymentv2";
import loadingAnimation from "../assets/loading-assets.json";
import BusinessPlan from "./PremiumPlan";
import PremiumPlan from "./BusinessPlan";
import { StarterPlan } from "./StarterPlan";

export const Pricing = () => {
  const supabase = useSupabase();
  const [isMonthly, setIsMonthly] = useState(false);

  const [userProfile, setUserProfile] = useState<
    UserProfileDbType & { Billing: BillingDbType }
  >();

  const [transactionId, setTransactionId] = useState<string>();

  const paddle = usePaddle();

  const [isSubscribing, setIsSubscribing] = useState(false);

  const [isYearSelected, setIsYearSelected] = useState(false);

  useEffect(() => {
    supabase.auth.getUser().then(async ({ data }) => {
      await supabase
        .from("UserProfile")
        .select("*, Billing(*)")
        .eq("id", data.user?.id as string)
        .single()
        .then((subscriptionData) => {
          setUserProfile(subscriptionData.data as typeof userProfile);
        });
    });
  }, [supabase]);

  const LoadingScreen = isSubscribing ? (
    <div className="absolute z-50 bg-gray-800/40 inset-0 flex flex-col justify-center items-center">
      <div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loadingAnimation,
          }}
        />
      </div>
    </div>
  ) : (
    <></>
  );

  const onSwitch = () => {
    setIsYearSelected(!isYearSelected);
  };

  // listen to db changes on selectedEpisode
  useEffect(() => {
    if (!userProfile?.id) return;

    const sub = supabase
      .channel(`changes`)
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "UserProfile",
          filter: `id=eq.${userProfile?.id}`,
        },
        (payload) => {
          const newProfile = {
            ...userProfile,
            ...payload.new,
          };
          setUserProfile(newProfile);

          // console.log("realtime update on user profile");

          // console.log("remainingCredits ", remainingCredits);
        }
      )
      .subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [userProfile?.id, supabase]);

  useEffect(() => {
    getCardUpdateLink({ supabase }).then((link: any) => {
      setTransactionId(link.data.url);
    });
  }, [userProfile?.id, supabase]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.paritydeals.com/banner.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!userProfile)
    return (
      <div className="absolute z-50 bg-gray-800/40 inset-0 flex flex-col justify-center items-center">
        <div>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loadingAnimation,
            }}
          />
        </div>
      </div>
    );

  return (
    <div className="overflow-x-hidden pb-10 px-4 md:px-8 animate-slide-in max-w-[1200px]">
      {LoadingScreen}
      <p className="mt-5 mb-10 text-16 text-[#202020] font-semibold">
        Pricing plans
      </p>

      {Boolean(userProfile.Billing?.paddle_transaction_id) && (
        <button
          className={classNames(
            "mb-10 border border-uf-yellow-1 rounded-lg w-fit transition-all bg-uf-yellow-1 disabled:opacity-50",
            {
              "bg-gray-200 border-gray-200 cursor-wait":
                !userProfile.Billing?.paddle_transaction_id,
            }
          )}
          disabled={!transactionId}
          onClick={() => {
            if (!!transactionId) {
              paddle?.Checkout.open({
                transactionId,
              });
            }
          }}
        >
          <p className="text-14 font-semibold text-[#202020] py-2 px-9">
            {" "}
            Manage Billing
          </p>
        </button>
      )}

      <div className="text-center flex flex-col justify-center items-center space-y-3">
        <p className="text-[#FF5698] text-14 leading-5 font-medium">
          {"You're currently on: "}
          {Boolean(userProfile.Billing?.subscription_status === "active") &&
          Boolean(userProfile.Billing?.plan_name === "SMALL")
            ? "Starter Plan"
            : Boolean(userProfile.Billing?.subscription_status === "active") &&
              Boolean(userProfile.Billing?.plan_name === "MEDIUM")
            ? "Premium Plan"
            : Boolean(userProfile.Billing?.subscription_status === "active") &&
              Boolean(userProfile.Billing?.plan_name === "LARGE")
            ? "Business Plan"
            : Boolean(
                userProfile.Billing?.ltd_id ||
                  userProfile.Billing?.appsumo_license
              )
            ? "AppSumo's Lifetime Plan"
            : "Free Plan"}
        </p>

        <p className="text-20 font-semibold">Scale with your content needs</p>
        <p className="text-[#202020] font-medium text-14 max-w-[601px]">
          Whether you’re a small creator or need content for a 100-people
          marketing team, there’s a plan for you.
        </p>
      </div>

      <div className="w-full flex justify-center items-center mt-10 mb-5 text-center">
        <div className="flex items-start justify-between space-x-4">
          <span className="text-sm leading-5 font-medium text-[#6C6C89]">
            YEARLY <br />
          </span>
          <div className="">
            <Switch
              checked={isMonthly}
              onChange={setIsMonthly}
              onColor="#5423E7"
            />
          </div>

          <span className="text-sm leading-5 font-medium text-[#6C6C89]">
            MONTHLY
          </span>
        </div>
      </div>
      <div className="w-full flex justify-center items-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {/* <div className="">
          <FreePlan
            isSubscribing={isSubscribing}
            supabase={supabase}
            setIsSubscribing={setIsSubscribing}
            me={userProfile}
          />
        </div> */}

          <StarterPlan
            isSubscribing={isSubscribing}
            supabase={supabase}
            setIsSubscribing={setIsSubscribing}
            me={userProfile}
            isMonthly={isMonthly}
            userId={userProfile.id}
          />

          <BusinessPlan
            isSubscribing={isSubscribing}
            supabase={supabase}
            setIsSubscribing={setIsSubscribing}
            me={userProfile}
            isMonthly={isMonthly}
            userId={userProfile.id}
          />

          <PremiumPlan
            isSubscribing={isSubscribing}
            supabase={supabase}
            setIsSubscribing={setIsSubscribing}
            me={userProfile}
            isMonthly={isMonthly}
            userId={userProfile.id}
          />
        </div>
      </div>
      {Boolean(userProfile?.Billing?.appsumo_license) && (
        <button
          className="bg-uf-yellow-1 text-14 font-semibold px-6 py-2 mt-7 rounded-md"
          onClick={() => {
            window.open("https://appsumo.com/products/unifireai", "_blank");
          }}
        >
          Upgrade AppSumo Plan
        </button>
      )}
    </div>
  );
};

export default Pricing;
