import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BackSquareIcon } from "../../../shared/Icons/BackSquare";
import { CircleCheckmark } from "../../../shared/Icons/CircleCheckmark";
import { HamburgerMenuIcon } from "../../../shared/Icons/HamburgerMenu";
import { PenIcon } from "../../../shared/Icons/Pen";
import { TemplateDbType } from "../../../types";
import {
  addAlpha,
  ASSET_ID_TO_TEMPLATE_PILL_LABEL,
  getPlatformColorByAssetId,
} from "../utils";
import { BinIcon } from "../../../shared/Icons/Bin";
import { SmallBinIcon } from "../../../shared/Icons/SmallBin";

interface TemplateCardProps {
  template: TemplateDbType;
  onRename: (id: string) => void;
  onDelete: (id: string) => void;
  onDeselect: (id: string) => void;
}

const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  onRename,
  onDelete,
  onDeselect,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleRename = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onRename(template.id);
    setIsMenuOpen(false);
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(template.id);
    setIsMenuOpen(false);
  };
  const handleDeselect = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDeselect(template.id);
    setIsMenuOpen(false);
  };
  return (
    <Link to={`/dashboard/template/${template.id}`} className="block">
      <div
        className={classNames(
          "relative bg-white rounded-lg shadow overflow-hidden h-[212px]",
          {
            "shadow-sm shadow-[#2DCA72]": template.is_enabled,
          }
        )}
      >
        <div className="px-5 py-8">
          <div className="flex justify-between items-center">
            <div>
              <span
                className="inline-block text-12 px-2 py-1 rounded-full border"
                style={{
                  backgroundColor: getPlatformColorByAssetId(
                    template.asset_id as string
                  ).background,
                  color: getPlatformColorByAssetId(template.asset_id as string)
                    .text,
                  borderColor: addAlpha(
                    getPlatformColorByAssetId(template.asset_id as string).text,
                    0.3
                  ),
                }}
              >
                {
                  ASSET_ID_TO_TEMPLATE_PILL_LABEL()[
                    template?.asset_id as string
                  ]
                }
              </span>
            </div>
            <div className="relative">
              <button className="h-full" onClick={toggleMenu}>
                <HamburgerMenuIcon />
              </button>
              {isMenuOpen && (
                <div className="absolute z-200 right-0 mt-2 w-48 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <button
                      onClick={handleRename}
                      className="px-4 py-2 text-14 text-[#121217] hover:bg-gray-100 hover:text-gray-900 w-full text-left flex justify-start items-center space-x-2"
                      role="menuitem"
                    >
                      <PenIcon className="text-[#8A8AA3]" />
                      <span>Rename</span>
                    </button>
                    {template.is_enabled && (
                      <button
                        onClick={handleDeselect}
                        className=" px-4 py-2 text-14 text-[#121217] hover:bg-gray-100 hover:text-gray-900 w-full text-left flex justify-start items-center space-x-2"
                        role="menuitem"
                      >
                        <BackSquareIcon className="text-[#8A8AA3]" />
                        <span>Deselect</span>
                      </button>
                    )}
                    <button
                      onClick={handleDelete}
                      className="px-4 py-2 text-sm border-t border-t-[#EBEBEF] text-red-600 hover:bg-gray-100 hover:text-red-700 w-full text-left flex justify-start items-center space-x-2"
                      role="menuitem"
                    >
                      <SmallBinIcon />
                      <span>Delete</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <h3 className="text-14 font-semibold text-[#202020] mt-6 line-clamp-2">
            {template.title}
          </h3>
          <p className="text-xs text-gray-500 leading-4 whitespace-pre-line mt-4 h-full truncate line-clamp-3 pr-3">
            {template.overview}
          </p>
        </div>
        {Boolean(template.is_enabled) && (
          <div className="absolute bottom-2 right-2 z-10">
            <div className="relative">
              <button
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <CircleCheckmark className="w-5 h-5 text-[#2DCA72] " />
              </button>
              {showTooltip && (
                <div className="absolute bottom-full right-0 mb-2">
                  <div className="w-3 h-3 bg-gray-800 transform rotate-45 absolute -bottom-[3px] right-1"></div>
                  <span className="block w-60 bg-gray-800 text-white p-3 rounded-lg text-sm">
                    {template.is_enabled
                      ? "This template will be used on your next generation"
                      : "Click to view and edit this template"}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

export default TemplateCard;
