import React, { useCallback, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { usePagination } from "../../../../hooks/usePagination";
import { FileDbType, UploadWithSocialPostsAndProject } from "../../../../types";
import { TrasncriptEditor } from "../../../EditableTranscript/components/EditableTranscriptEditor/Editor";
import { SpeakerLabelType } from "../../../EditableTranscript/components/types";
import { toHHMMSS } from "../../../UploadContent/util";
import { AssetHeader } from "../AssetHeader";
import { AssemblyTurnByTurn } from "../EpisodePage";
import { LoadingScreen } from "../FlowComponents/LoadingAsset/LoadingScreen";
import { RenderPagination } from "../RenderPagination";
import { ASSETS_IDS } from "../types";
import { ALPHABET_NUMBER_MAP } from "../../../../utils/queries";
interface TranscriptResourcesProps {
  canEdit: boolean;
  editorName: string;
  upload: UploadWithSocialPostsAndProject;
  files: FileDbType[];
}

export const TranscriptResources: React.FC<TranscriptResourcesProps> = ({
  canEdit,
  editorName,
  upload,
  files,
}) => {
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [editedText, setEditedText] = useState<AssemblyTurnByTurn>();

  const { handlePageClick, currentItems, pageCount, refreshItems } =
    usePagination({
      data: files,
      itemsPerPage: 1,
    });

  useEffect(() => {
    refreshItems(files);
  }, [files]);

  const currentFile = currentItems[0];

  useEffect(() => {
    if (currentFile) {
      setEditedText(
        (currentFile.edited_script ||
          currentFile.transcript) as AssemblyTurnByTurn
      );
    }
  }, [currentFile]);

  const handlePageChange = useCallback(
    (selectedItem: { selected: number }) => {
      setCurrentFileIndex(selectedItem.selected);
      handlePageClick(selectedItem);
    },
    [handlePageClick]
  );

  const rawSpeakers = (currentFile?.transcript as AssemblyTurnByTurn)
    ?.map((turn) => ({
      label: ALPHABET_NUMBER_MAP[turn.speaker],
      value: turn.speaker,
    }))
    .filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);

  const speakers = (
    Boolean(currentFile.speaker_mapping?.length)
      ? currentFile.speaker_mapping
      : rawSpeakers
  ) as SpeakerLabelType[];

  const DownloadableTranscript = useCallback(() => {
    if (!currentFile || !currentFile.transcript) return null;

    return (
      <div className="p-3 bg-white">
        {(
          (Boolean(currentFile.edited_script?.length)
            ? currentFile.edited_script
            : currentFile.transcript) as AssemblyTurnByTurn
        ).map((turn, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-md"
            style={{ whiteSpace: "pre-line" }}
          >
            <div className="flex items-start justify-start space-x-5">
              <p className="text-14 font-semibold min-w-[150px]">
                {`${toHHMMSS(turn.start)} - ${
                  speakers.find((speaker) => speaker.value === turn.speaker)
                    ?.label
                }`}
              </p>
              <p className="text-gray-900">{turn.text}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }, [currentFile, speakers]);

  if (!currentFile) {
    return <LoadingScreen isThemeWhite displayText="Loading transcript..." />;
  }

  if (currentFile.status === "PROCESSING") {
    return (
      <div className="relative min-h-[400px] px-4 w-full flex justify-center items-center">
        <LoadingScreen
          isThemeWhite
          displayText={"Generating your transcript. Please wait..."}
        />
      </div>
    );
  }

  return (
    <div className="w-full bg-white shadow rounded-xl transition-all duration-300 ease max-h-max self-center border-none">
      <div className="p-5">
        <AssetHeader
          post={ReactDOMServer.renderToString(<DownloadableTranscript />)}
          upload={upload}
          assetId={ASSETS_IDS.TRANSCRIPT}
        />
      </div>

      <RenderPagination
        pageCount={pageCount}
        handlePageClick={handlePageChange}
        forcedPage={currentFileIndex}
      />

      {canEdit && (
        <TrasncriptEditor
          fileId={currentFile.id}
          // content={
          //   (Boolean(currentFile?.edited_script?.length)
          //     ? currentFile?.edited_script
          //     : currentFile?.transcript) as AssemblyTurnByTurn
          // }
          content={
            (Boolean(currentFile.edited_script?.length)
              ? currentFile.edited_script
              : currentFile.transcript) as AssemblyTurnByTurn
          }
          episodeId={currentFile.id}
          onEditorReady={() => {}}
          hideGeneration
          speakers={speakers}
        />
      )}

      {!canEdit && <DownloadableTranscript />}

      <RenderPagination
        pageCount={pageCount}
        handlePageClick={handlePageChange}
        forcedPage={currentFileIndex}
      />
    </div>
  );
};
