// YoutubeUrlUploader.tsx

import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import uuid4 from "uuid4";
import * as Yup from "yup";
import { useLogsnag } from "../../../../../hooks/logsnag"; // Adjust the path as necessary
import { useAnalytics } from "../../../../../hooks/useAnalytics";
import { useSupabase } from "../../../../../hooks/useSupabase";
import { useWorkspace } from "../../../../../hooks/useWorkspace";
import { useRole } from "../../../../../hooks/userCanEdit";
import {
  convertMillisecondsToMinutes,
  embedText,
  sleep,
} from "../../../../../utils";
import { getFileTypeAnalytics } from "../../../../../utils/common";
import { UploadProgressModal } from "./ManualUploadModal";
import { uploadPastedText } from "../utils";

// Yup validation schema
const validationSchema = Yup.object().shape({
  text: Yup.string()
    .required("Paste your text to transform it")
    // should be at least 2 words
    .test("word-count", "Text too short", (val) => {
      if (!val) return false;
      return val.split(/\s+/).length >= 5;
    }),
});

const PasteText = ({
  isPublic,
  projectId,
  onUploadIdDecided,
  onUploaded,
  language,
}: {
  isPublic?: boolean;
  projectId: string;
  onUploadIdDecided: (_: string, isText: boolean) => void;
  onUploaded: () => void;
  language: string;
}) => {
  const logsnag = useLogsnag();
  const supabase = useSupabase(); // Use this context if needed for metadata or other Supabase interactions
  const [progress, setProgress] = useState(0);
  const totalDuration = 60000; // 1 min seconds in milliseconds
  const startTime = Date.now();

  const [startUpload, setStartUpload] = useState(false);

  const formikRef = useRef();

  const [show, setShow] = useState(false);

  const role = useRole();
  const workspace = useWorkspace();
  const { newUploadStarted, newUploadSuccessful, uploadFailed } =
    useAnalytics();

  const handleSubmit = ({ text }: { text: string }) => {
    if (!text) return;
    if (isPublic) handlePublicUpload(text);
    else handleAuthenticatedUpload(text);
    setProgress(100);
  };

  const handleAuthenticatedUpload = async (text: string) => {
    if (!text) return;
    setStartUpload(true);
    setShow(true);

    const session = await supabase.auth.getSession();
    const userId = session?.data?.session?.user.id ?? "";

    const startWaitduration = Date.now();
    newUploadStarted({
      mediaType: getFileTypeAnalytics("text/plain") as any,
      uploadMethod: "manual",
      userRole: role as any,
      workspaceId: workspace?.id as string,
    });

    logsnag.publish({
      channel: "content",
      description: "User uploading text",
      event: "Uploading...",
      icon: "⬆",
      notify: true,
      tags: {
        userid: userId,
        email: session?.data?.session?.user.email ?? "",
      },
    });

    const fileId = uuid4();
    const uploadId = uuid4();

    if (!projectId || !text || !workspace?.id) return;

    // first 10 words
    const firstLine = text
      .trim()
      .split("\n")[0]
      .split(" ")
      .slice(0, 10)
      .join(" ");

    // submit record
    const upload = await supabase.from("Upload").insert({
      id: uploadId,
      project_id: projectId,
      status: "edit",
      title: firstLine,
      workspace_id: workspace?.id,
      user_id: userId,
      lang_tag: language,
    });

    const insertedFile = await supabase.from("File").insert({
      id: fileId,
      duration: -1,
      mime: "text/plain",
      status: "READY",
      title: firstLine,
      upload_id: uploadId,
      workspace_id: workspace?.id,
      text_content: text,
    });

    if (upload.error || insertedFile.error) {
      alert("An error occured, please try again");
      return;
    }

    // await embedText({
    //   uploadId,
    //   supabase,
    //   langTag: language,
    // }).catch((e) => {
    //   alert("An error has occured, please try again");
    //   window.location.reload();
    // });

    onUploadIdDecided(uploadId, true);

    const endDateDuration = Date.now();
    newUploadSuccessful({
      fileType: "text/plain",
      uploadMethod: "manual",
      uploadWaitDuration: convertMillisecondsToMinutes(
        endDateDuration - startWaitduration
      ),
      userRole: role as any,
      workspaceId: workspace?.id as string,
      uploadId,
      uploadLength: -1,
      ownerId: workspace?.owner_id as string,
    });

    logsnag.publish({
      channel: "content",
      description: "User finished upload",
      event: "Upload finished",
      icon: "✅",
      notify: true,
      tags: {
        userid: userId,
        email: session?.data?.session?.user.email ?? "",
      },
    });
    await sleep(4000);

    onUploaded();
  };
  const handlePublicUpload = async (text: string) => {
    uploadPastedText({
      text,
      logsnag,
      language,
    });
    onUploaded();
  };

  // progress simulation
  useEffect(() => {
    if (!startUpload) return;
    let isCancelled = false;

    const updateProgress = () => {
      if (isCancelled) {
        return;
      }

      const elapsedTime = Date.now() - startTime;
      if (elapsedTime >= totalDuration) {
        setProgress(100);
        return;
      }

      // Calculate the current progress based on the elapsed time
      const currentProgress = (elapsedTime / totalDuration) * 100;

      // Randomly add some progress to simulate non-linear behavior
      const randomProgress = Math.random() * 5; // up to 5% random progress
      const newProgress = Math.floor(
        Math.min(currentProgress + randomProgress, 100)
      );
      setProgress(newProgress);

      // Schedule the next update
      const randomInterval = Math.random() * 1000 + 500; // between 0.5 and 1.5 seconds
      setTimeout(updateProgress, randomInterval);
    };

    // Start the loading simulation
    setTimeout(updateProgress, 0);

    return () => {
      isCancelled = true;
    };
  }, [startUpload]);

  return (
    <>
      <div className="w-full">
        <Formik
          initialValues={{ text: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formikRef as any}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-full">
                <div className="w-full border border-gray-600/20 rounded-lg">
                  <textarea
                    className="w-full py-[7px] outline-none px-3 bg-transparent text-14 h-[250px]"
                    placeholder="Paste transcripts, docs and other text here"
                    onChange={(e) => setFieldValue("text", e.target.value)}
                  />
                </div>

                <ErrorMessage
                  name="text"
                  component="p"
                  className="text-red-500 text-14"
                />
              </div>
              <div className="flex justify-between items-center w-full mt-5">
                <button
                  className="text-14 font-medium"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#FF5698] rounded-lg text-14 text-white font-semibold py-2 px-4 focus:outline-none focus:shadow-outline "
                  type="submit"
                >
                  Upload Source Material
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <UploadProgressModal
        setShow={setShow}
        show={show}
        fileUploadProgress={progress}
      />
    </>
  );
};

export default PasteText;
