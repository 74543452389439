export const MediumIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4461_11251)">
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="black"
      />
      <path
        d="M10.9733 10.0004C10.9733 12.3835 9.05465 14.3153 6.68781 14.3153C4.32102 14.3153 2.40234 12.3835 2.40234 10.0004C2.40234 7.61738 4.32102 5.68555 6.68785 5.68555C9.05465 5.68555 10.9733 7.61738 10.9733 10.0004Z"
        fill="white"
      />
      <path
        d="M15.6746 10.0002C15.6746 12.2434 14.7153 14.0619 13.5319 14.0619C12.3485 14.0619 11.3892 12.2434 11.3892 10.0002C11.3892 7.75695 12.3485 5.93848 13.5319 5.93848C14.7153 5.93848 15.6746 7.75695 15.6746 10.0002Z"
        fill="white"
      />
      <path
        d="M17.5976 10.0005C17.5976 12.0103 17.2601 13.6396 16.8439 13.6396C16.4277 13.6396 16.0903 12.0103 16.0903 10.0005C16.0903 7.99063 16.4277 6.36133 16.8439 6.36133C17.2601 6.36133 17.5976 7.99063 17.5976 10.0005Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4461_11251">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
