export const AvatarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2497 8C15.2497 9.79493 13.7947 11.25 11.9997 11.25C10.2048 11.25 8.74973 9.79493 8.74973 8C8.74973 6.20507 10.2048 4.75 11.9997 4.75C13.7947 4.75 15.2497 6.20507 15.2497 8Z"
      stroke="#8A8AA3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.84723 19.25H17.1522C18.2941 19.25 19.1738 18.2681 18.6405 17.2584C17.8561 15.7731 16.0677 14 11.9997 14C7.93174 14 6.14339 15.7731 5.35897 17.2584C4.8257 18.2681 5.70531 19.25 6.84723 19.25Z"
      stroke="#8A8AA3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
