import WaitlistPage from "./components/WaitlistPage";
import { Routes, Route } from "react-router-dom";

export const Waitlist = () => {
  return (
    <Routes>
      <Route path="/waitlist" element={<WaitlistPage />} />
    </Routes>
  );
};

export default Waitlist;
