export const ItalianFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_31013)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M23.9999 11.9987C23.9999 6.83915 20.7434 2.44063 16.1738 0.745117V23.2524C20.7434 21.5568 23.9999 17.1583 23.9999 11.9987Z"
        fill="#D80027"
      />
      <path
        d="M-0.0001297 11.9997C-0.0001297 17.1593 3.25637 21.5578 7.82598 23.2533V0.746094C3.25637 2.44161 -0.0001297 6.84013 -0.0001297 11.9997Z"
        fill="#6DA544"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_31013">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
