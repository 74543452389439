import HCaptcha from "@hcaptcha/react-hcaptcha";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useCallback, useRef } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useLogsnag } from "../../../hooks/logsnag";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { useInviteIdEmail } from "../../../hooks/useAuth";
import { useNavigateWithParams } from "../../../hooks/useNavigateWithParams";
import { useSupabase } from "../../../hooks/useSupabase";
import { isDisposable, login, socialSignin } from "../../../utils/auth";
import GoogleSigninSvg from "../assets/google.png";
import portraitImg from "../assets/portraitImg.jpeg";
import max from "../../Signup/assets/max.png";
import { ReactComponent as HeartSvg } from "../../Signup/assets/heart.svg";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Required")
    .test(
      "isDisposable",
      "Invalid email address",
      async (value) => !(await isDisposable(value))
    ),
  password: Yup.string().required("Required"),
  captchaToken: Yup.string().required("Please complete the captcha"),
});

export const AppsumoLoginPage = () => {
  // watch

  const captcha = useRef(null);

  const inviteEmail = useInviteIdEmail();
  const navigateWithParams = useNavigateWithParams();
  const supabase = useSupabase();

  const { loginSelected, loginSuccessful } = useAnalytics();

  // const logSnag = useLogsnag();

  // <p className="mt-8">
  //             New user?{" "}
  //             <a
  //               href="/signup"
  //               className="text-blue-500 hover:text-blue-700 font-semibold"
  //             >
  //               Create an account
  //             </a>
  //           </p>

  const authenticate = async ({
    email,
    password,
    captchaToken,
  }: {
    email: string;
    password: string;
    captchaToken: string;
  }) => {
    // logsnag.publish({
    //   channel: "auth",
    //   description: "User is logging in natively",
    //   event: "Native login",
    //   icon: "🎉",
    //   notify: true,
    //   tags: {
    //     email: email ?? "",
    //   },
    // });
    loginSelected("email");
    const { data, error } = await login(
      supabase,
      email ?? "",
      password ?? "",
      captchaToken,
      captcha
    );
    if (error) {
      alert(error?.cause ?? error?.message);
      return;
    }

    navigateWithParams("/onboarding");

    loginSuccessful("email");
  };

  return (
    <div className="h-screen w-full bg-white font-poppins">
      <p className="absolute top-12 right-12">
        Create a new account?{" "}
        <a
          onClick={() => navigateWithParams("/signup/appsumo")}
          className="text-teal-700 hover:text-teal-600 font-medium cursor-pointer"
        >
          Sign up
        </a>
      </p>
      <div className="flex justify-between items-center w-full h-full ">
        <div className="w-3/6 h-full relative hidden md:block">
          <div
            className="w-full h-full"
            style={{
              backgroundImage: `url(${max})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <div className="absolute top-16 text-white px-12 space-y-10">
            <p className="text-4xl leading-10 font-semibold ">Hi Sumo-lings!</p>

            <p className="text-xl leading-9 font-medium">
              Please submit your email <br />
              and password to sign into Unifire <br />
              for lifetime access. <br /> After your submission, you’ll <br />
              get immediate access to your account.
              <br /> <br />
              Welcome onboard 👋
              <br /> <br />
              Max & Yassine
            </p>
          </div>
        </div>
        {/* <p className="absolute top-12 right-12">
          Already have an account?{" "}
          <a
            onClick={() => navigateWithParams("/login")}
            className="text-teal-700 hover:text-teal-600 font-medium cursor-pointer"
          >
            Sign in
          </a>
        </p> */}
        <div className="w-full flex items-center justify-center ">
          <div className="w-[80%] md:w-[60%] lg:w-[60%] h-full">
            <div className="w-full text-[20px] sm:text-[30px] lg:text-[50px] font-bold mb-[120px] flex justify-around items-center space-x-6 font-inter">
              <span className="">Unifire</span>

              <div className="col-span-2">
                <HeartSvg className="w-full" />
              </div>
              <div className="">
                <span className="">AppSumo</span>
              </div>
            </div>

            <Formik
              initialValues={{
                email: inviteEmail ?? "",
                firstName: "",
                lastName: "",
                password: "",
                captchaToken: "",
              }}
              enableReinitialize
              validationSchema={loginValidationSchema}
              onSubmit={authenticate}
            >
              {({ errors, touched, ...props }) => (
                <Form>
                  {/* Email input */}
                  <div>
                    <div
                      className={`mt-6 relative ${
                        errors.email && touched.email && "border-red-500"
                      }`}
                    >
                      {inviteEmail ? (
                        <Field
                          type="email"
                          id="email_floating_outlined"
                          name="email"
                          className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                          placeholder=" "
                          defaultValue={inviteEmail}
                          value={inviteEmail}
                          disabled
                          focused
                        />
                      ) : (
                        <Field
                          type="email"
                          id="email_floating_outlined"
                          name="email"
                          className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                          placeholder=" "
                        />
                      )}

                      <label
                        htmlFor="email_floating_outlined"
                        className="hover:cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 bg-white peer-focus:bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Email Address
                      </label>
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Password input */}
                  <div>
                    <div
                      className={`mt-6 relative ${
                        errors.password && touched.password && "border-red-500"
                      }`}
                    >
                      <Field
                        type="password"
                        id="password_floating_outlined"
                        name="password"
                        className={`autofill:bg-white block px-3 pb-3 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-xl border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer`}
                        placeholder=" "
                      />
                      <label
                        htmlFor="password_floating_outlined"
                        className="hover:cursor-text absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 bg-white peer-focus:bg-white peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Password
                      </label>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="w-full flex justify-center items-center">
                    <div>
                      <div className="mt-6 relative">
                        <HCaptcha
                          ref={captcha}
                          sitekey={process.env.REACT_APP_CAPTCHA_KEY as string}
                          onVerify={(token) => {
                            props.setFieldValue("captchaToken", token);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="captchaToken"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>

                  <div className="w-full flex justify-between items-center mt-6">
                    <a
                      onClick={() => navigateWithParams("/send-reset")}
                      className="text-14 hover:text-blue-700 focus:text-blue-700 cursor-pointer"
                    >
                      Forgot Password?
                    </a>
                    <button
                      type="submit"
                      className="w-40 block bg-teal-600 hover:bg-teal-500 text-white font-semibold rounded-xl px-4 py-3"
                    >
                      Sign In
                    </button>
                  </div>

                  <div className="relative">
                    <p className="text-[#84818A] absolute top-16 text-12">
                      Protected by hCAPTCHA and subject to the{" "}
                      <a
                        className="text-[#009580] "
                        href="https://www.unifire.ai/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Unifire Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        className="text-[#009580]"
                        href="https://www.unifire.ai/terms-of-service"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Service.
                      </a>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
