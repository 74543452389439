"use client";

import { useEffect, useMemo, useRef, useState } from "react";
// import "../styles/globals.css";
// import "../styles/text-editor.css";
// import "./TextEditor.css";
import { parseHtmlToString } from "../../../utils/common";
import { sleep } from "../../../utils";

type EditorProps = {
  content: string;
  stream?: boolean;
};

export const ReadOnlyEditor = ({ content, stream = false }: EditorProps) => {
  const [displayText, setDisplayText] = useState(content);
  const [currentIndex, setCurrentIndex] = useState(
    content.split("").length ?? 0
  );

  const isWritingRef = useRef(false);
  const firstMountEver = useRef(true);

  const stats = useMemo(() => {
    const parsedText = parseHtmlToString(displayText);
    return {
      words: parsedText?.split(" ").length,
      characters: parsedText?.length,
    };
  }, [displayText]);

  const typeWriter = async () => {
    //  for loop for a typewriter effect
    for (let i = currentIndex; i < content.split("").length; i++) {
      await sleep(0);
      setDisplayText((prev) => prev + content.split("")[i]);
      setCurrentIndex(i + 1);
    }
    isWritingRef.current = false;
  };

  useEffect(() => {
    // display the full content on the first render, then stream the rest
    if (firstMountEver.current) {
      firstMountEver.current = false;
      return;
    }

    if (isWritingRef.current === true) {
      return;
    }
    isWritingRef.current = true;
    typeWriter();
  }, [content, currentIndex]);

  return (
    <>
      <p className="text-[#6C6C89] text-sm leading-5 font-normal px-5 mb-3">
        {stats.words} words, {stats.characters} characters
      </p>
      <div
        className="p-5 editorContainer prose max-w-full whitespace-normal prose-h1:text-24 prose-h2:text-20 prose-h3:text-18 prose-h4:text-16 prose-h5:text-14 prose-h6:text-12"
        dangerouslySetInnerHTML={{ __html: stream ? displayText : content }}
      />
    </>
  );
};
