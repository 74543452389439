export const RussianFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_32602)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 0C5.37262 0 0 5.37262 0 12C0 13.4678 0.264047 14.874 0.746391 16.1739H23.2537C23.736 14.874 24 13.4678 24 12C24 5.37262 18.6274 0 12 0Z"
        fill="#0052B4"
      />
      <path
        d="M12.0003 0C6.84077 0 2.44225 3.2565 0.746735 7.82611H23.254C21.5584 3.2565 17.1599 0 12.0003 0Z"
        fill="#D80027"
      />
      <path
        d="M3.10342 6.7832V12.7694C3.10342 16.1746 7.55191 17.218 7.55191 17.218C7.55191 17.218 12.0003 16.1746 12.0003 12.7694V6.7832H3.10342Z"
        fill="#D80027"
      />
      <path
        d="M4.94223 7.82674H10.1597V5.73977L9.11617 6.26149L7.55097 4.69629L5.98571 6.26149L4.94223 5.73977V7.82674Z"
        fill="#FFDA44"
      />
      <path
        d="M10.9561 13.6032L7.56474 10.2119L4.17343 13.6032L5.2802 14.71L7.56474 12.4254L9.84929 14.71L10.9561 13.6032Z"
        fill="#FFDA44"
      />
      <path
        d="M10.956 10.4342H9.37371C9.46268 10.2807 9.51443 10.1028 9.51443 9.91262C9.51443 9.33625 9.04723 8.86914 8.47095 8.86914C8.07274 8.86914 7.72695 9.09217 7.55103 9.42006C7.3751 9.09222 7.02931 8.86914 6.6311 8.86914C6.05482 8.86914 5.58762 9.33625 5.58762 9.91262C5.58762 10.1028 5.63937 10.2807 5.72834 10.4342H4.1734C4.1734 11.0106 4.67539 11.4777 5.25162 11.4777H5.21689C5.21689 12.0541 5.68404 12.5212 6.26037 12.5212C6.26037 13.0315 6.62684 13.4554 7.11082 13.5462L6.28864 15.4025C6.68267 15.5627 7.11326 15.6517 7.56476 15.6517C8.01626 15.6517 8.44685 15.5627 8.84088 15.4025L8.0187 13.5462C8.50268 13.4555 8.86915 13.0315 8.86915 12.5212C9.44548 12.5212 9.91263 12.0541 9.91263 11.4777H9.8779C10.4541 11.4777 10.956 11.0105 10.956 10.4342Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_32602">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
