import { Paddle, initializePaddle } from "@paddle/paddle-js";
import React, { createContext, useEffect, useState } from "react";
import { useSupabase } from "../hooks/useSupabase";

type PaddleContextType = Paddle | undefined;

export const PaddleContext = createContext<PaddleContextType>(undefined);

export const PaddleProvider = ({ children }: { children: React.ReactNode }) => {
  const [paddle, setPaddle] = useState<PaddleContextType>();
  const supabase = useSupabase();

  useEffect(() => {
    const init = async () => {
      const paddle = await initializePaddle({
        token: process.env.REACT_APP_PADDLE_CLIENT_KEY as string,
        environment:
          window.location.hostname === "app.unifire.ai"
            ? "production"
            : "sandbox",
        eventCallback: async (data) => {
          if (data.name === "checkout.completed") {
            for (let i = 0; i < 6; i++) {
              const { error } = await supabase
                .from("Billing")
                .update({ trial_confirmed: true })
                .eq("paddle_id", data.data?.customer.id as string);
              if (!error) {
                break;
              }
            }

            window.location.href = "/dashboard/overview";
          }
        },
      });
      setPaddle(paddle);
    };

    init();
  }, []);

  return (
    <PaddleContext.Provider value={paddle}>{children}</PaddleContext.Provider>
  );
};
