import { io } from "socket.io-client";

export const getSocket = (roomId: string) =>
  io((process.env.REACT_APP_TRANSCRIPTION_URL + "/streaming") as string, {
    query: { roomId },
    autoConnect: false,
    reconnection: false,
    // secure: false,
    retries: 10,
    withCredentials: true,
    transports: ["websocket"],
  });
