import axios, { AxiosError } from "axios";

export const submitAppsumoCode = async ({
  code,
  supabase,
  userId,
}: {
  code: string;
  supabase: any;
  userId: string;
}) => {
  const body = {
    code,
    userId,
  };

  const session = await supabase.auth.getSession();

  return await axios
    .post(`${process.env.REACT_APP_TRANSCRIPTION_URL}/appsumo/coupon`, body, {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    })
    .catch((error: AxiosError) => {
      console.log("error ", error);
      throw error;
    });
};
