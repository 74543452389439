import { ReadOnlyEditor } from "../../../shared/LiveEditor/components/ReadOnlyEditor";

export const AssetLoadingLiveFeed = ({ text }: { text: string }) => {
  return (
    <div className="rounded-lg h-full w-full">
      <p className="pt-5 h-full w-full align-top text-start no-scrollbar shadow rounded-lg">
        <ReadOnlyEditor content={text} stream={true} />
      </p>
    </div>
  );
};
