import { CloseIcon } from "@iconicicons/react";
import React, { useEffect, useState } from "react";
import { CircleCheckmark } from "../../../shared/Icons/CircleCheckmark";
import { PlusIcon } from "../../../shared/Icons/Plus";
import { TemplateDbType } from "../../../types";

interface TemplatePickerInterface {
  platformName: string;
  templates: TemplateDbType[];
  onSelectTemplates: (selectedTemplates: TemplateDbType[]) => void;
  onCancel: () => void;
  assetId: string;
  initialSelectedTemplates?: TemplateDbType[];
  isMultiSelect: boolean;
}

const TemplatePicker: React.FC<TemplatePickerInterface> = ({
  platformName,
  templates,
  onSelectTemplates,
  onCancel,
  initialSelectedTemplates = [],
  assetId,
  isMultiSelect,
}) => {
  const [selectedTemplates, setSelectedTemplates] = useState<TemplateDbType[]>(
    []
  );
  const [activeTemplateId, setActiveTemplateId] = useState<string | null>(null);

  useEffect(() => {
    // if the localAssetId changes, reset the selected templates

    setSelectedTemplates((prev) =>
      prev.length > 0 ? prev : initialSelectedTemplates
    );
  }, [initialSelectedTemplates]);

  const toggleTemplate = (templateId: string) => {
    const template = templates.find((t) => t.id === templateId)!;

    if (!isMultiSelect) {
      setSelectedTemplates([template]);
    } else {
      setSelectedTemplates((prev) => {
        if (prev.find((temp) => temp.id === templateId)) {
          return prev.filter((temp) => temp.id !== templateId);
        }
        return [...prev, template];
      });
    }
  };

  const handleUseTemplates = () => {
    onSelectTemplates(selectedTemplates);
    setSelectedTemplates([]);
  };

  const removeTemplate = (templateId: string) => {
    setSelectedTemplates((prev) =>
      prev.filter((temp) => temp.id !== templateId)
    );
  };

  return (
    <div className="animate-slide-in bg-white rounded-lg shadow-lg p-6 max-w-[90vw] mx-auto font-inter">
      <div className="flex justify-between items-center mt-5">
        <h2 className="text-xl leading-8 font-semibold text-gray-900">
          Select {platformName} Templates
        </h2>
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={() => onCancel()}
        >
          <CloseIcon />
        </button>
      </div>

      <p className="text-lg leading-6 font-normal text-[#6C6C89] mt-3.5">
        Choose different templates for your content.{" "}
        {isMultiSelect ? (
          <>
            You can select{" "}
            <span className="font-bold underline">as many as you want.</span>
          </>
        ) : (
          <>
            You can select{" "}
            <span className="font-bold underline">1 template maximum</span> for
            this type of asset
          </>
        )}
        <a href="#" className="text-[#3487EB] hover:underline ml-1">
          Learn how to use templates.
        </a>
      </p>

      <p className="text-sm leading-5 font-medium text-[#6C6C89] mt-11">
        {templates.length} {platformName} Templates
      </p>

      <div className="flex justify-start items-start w-full my-4">
        <div className="w-[50%] h-[50vh] overflow-y-auto">
          {templates.map((template, i) => (
            <div
              key={template.id}
              className={`p-4 cursor-pointer ${
                selectedTemplates.find((t) => t.id === template.id)
                  ? "bg-pink-100 border-r-2 border-[#FF5698]"
                  : activeTemplateId === template.id
                  ? "bg-pink-50/50 border-r-2 border-[#FF5698]/30"
                  : "bg-white"
              } ${
                i === 0
                  ? "rounded-tl-lg"
                  : i === templates.length - 1
                  ? "rounded-bl-lg"
                  : ""
              }`}
              onClick={() => setActiveTemplateId(template.id)}
            >
              <h3 className="font-bold mb-2">{template.title}</h3>
              <p className="text-gray-700 truncate">
                {template.overview?.split("\n")[0]}
              </p>
            </div>
          ))}
        </div>
        <div className="w-[50%] bg-white rounded-lg p-6 flex flex-col h-[50vh] border-l">
          <div className="flex-grow overflow-y-auto w-full">
            {activeTemplateId ? (
              <div className="whitespace-pre-line w-full">
                {templates.find((t) => t.id === activeTemplateId)?.examples}
              </div>
            ) : (
              <div className="text-gray-500 italic ">
                Select a template to view its content
              </div>
            )}
          </div>
          {activeTemplateId && (
            <div className="flex justify-end space-x-2 mt-4">
              {selectedTemplates.find((t) => t.id === activeTemplateId) ? (
                <button
                  className=" inline-flex items-center px-2 py-2 text-[#2DCA72] border border-[#2DCA72] text-sm font-medium rounded-lg space-x-2 hover:bg-[#f9fffc] transition duration-300"
                  onClick={() => toggleTemplate(activeTemplateId)}
                >
                  <CircleCheckmark className="w-5 h-5" />
                  <span>Used by default</span>
                </button>
              ) : (
                <button
                  className={`px-4 py-2 flex items-center transition duration-300 bg-white text-[#D50B3E] border-2 border-[#FBB1C4] hover:bg-pink-50 text-sm font-medium rounded-lg space-x-2`}
                  onClick={() => toggleTemplate(activeTemplateId)}
                >
                  <PlusIcon className="w-5 h-5 text-[#D50B3E]" />
                  <span>Use this template</span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="border-t mt-6 pt-4">
        <h3 className="text-sm leading-5 font-medium text-[#121217] mb-2">
          {platformName} Templates Selected
        </h3>
        <div className="flex justify-between items-center">
          <div className="flex flex-wrap gap-2 border rounded-md bg-white min-h-[40px] w-full p-1">
            {selectedTemplates.map(({ id: templateId }) => {
              const template = templates.find((t) => t.id === templateId);
              return (
                template && (
                  <div
                    key={templateId}
                    className="bg-pink-100 rounded-md px-3 py-1 text-sm flex items-center max-w-[250px]"
                  >
                    <p className="w-full truncate">{template.title}</p>
                    <button
                      onClick={() => removeTemplate(templateId)}
                      className="ml-2 text-gray-500 hover:text-gray-700"
                    >
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                )
              );
            })}
          </div>
          <button
            onClick={handleUseTemplates}
            className=" min-h-[40px] h-full bg-uf-yellow-1 rounded-md min-w-max px-6 -ml-2 disabled:bg-gray-200 disabled:text-gray-500 text-xs tleading-6 font-semibold text-gray-800"
          >
            {selectedTemplates?.length === 1 ? "Use Template" : "Use Templates"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplatePicker;
