import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { useAuthUserId } from "../../../hooks/useAuth";
import { usePaddle } from "../../../hooks/usePaddle";
import { useSupabase } from "../../../hooks/useSupabase";
import { Switch } from "../../../shared/Library/Switch";
import { BillingDbType, UserProfileDbType } from "../../../types";
import BusinessPlan from "../../Pricing/components/BusinessPlan";
import PremiumPlan from "../../Pricing/components/PremiumPlan";
import StarterPlan from "../../Pricing/components/StarterPlan";
import loadingAnimation from "../assets/loading-assets.json";
import { TrialTestimonials } from "../../Testimonial/components/TrialTestimonials";

export const TrialLoadingAnimation = () => (
  <div className="absolute z-50 bg-gray-800/40 !inset-0 flex flex-col justify-center items-center pointer-events-none">
    <div>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loadingAnimation,
        }}
      />
    </div>
  </div>
);

const TrialPage: React.FC = () => {
  const supabase = useSupabase();
  const [isMonthly, setIsMonthly] = useState(true);

  const paddle = usePaddle();

  const [isLoading, setLoading] = useState(true);

  const [isSubscribing, setIsSubscribing] = useState(false);

  const navigate = useNavigate();

  const userId = useAuthUserId();

  // me, userprofile

  const [userProfile, setUserProfile] = useState<
    UserProfileDbType & { Billing: BillingDbType }
  >();

  useEffect(() => {
    supabase.auth.getUser().then(async ({ data }) => {
      await supabase
        .from("UserProfile")
        .select("*, Billing(*)")
        .eq("id", data.user?.id as string)
        .single()
        .then((subscriptionData) => {
          setUserProfile(subscriptionData.data as typeof userProfile);

          const isConfirmed =
            subscriptionData?.data?.Billing?.trial_confirmed === true;
          if (isConfirmed) navigate("/dashboard/overview");
        });
    });
  }, [supabase]);

  // display after 1 second
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (!userId) return;

    const sub = supabase
      .channel(`changes`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "Billing",
          filter: `id=eq.${userProfile?.Billing?.id}`,
        },
        (payload) => {
          const isConfirmed =
            (payload.new as BillingDbType)?.trial_confirmed === true;
          if (isConfirmed) window.location.href = "/dashboard/overview";
        }
      )
      .subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [userId, supabase]);

  const LoadingScreen: React.FC = () => (
    <div className="absolute z-50 bg-gray-800/40 inset-0 flex flex-col justify-center items-center">
      <div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loadingAnimation,
          }}
        />
      </div>
    </div>
  );

  const showLoadingScreen = isSubscribing;

  if (isSubscribing || !Boolean(userId) || !userProfile) {
    return <LoadingScreen />;
  }

  return (
    <div className="py-8 overflow-x-hidden pb-10 px-4 md:px-8 animate-slide-in ">
      {showLoadingScreen && <LoadingScreen />}

      <div className="text-center flex flex-col justify-center items-center space-y-3">
        <p className="text-20 font-semibold">
          Unlimited Content Generation For Everyone
        </p>
        <p className="text-[#202020] font-medium text-14 max-w-[601px]">
          Whether you’re a small creator or need content for a 100-people
          marketing team, there’s a plan for you.
        </p>
      </div>

      <div className="w-full flex justify-center items-center mt-10 mb-5 text-center">
        <div className="flex items-start justify-between space-x-4">
          <span className="text-sm leading-5 font-medium text-[#6C6C89]">
            YEARLY <br />
          </span>
          <div className="">
            <Switch
              checked={isMonthly}
              onChange={setIsMonthly}
              onColor="#5423E7"
            />
          </div>

          <span className="text-sm leading-5 font-medium text-[#6C6C89]">
            MONTHLY
          </span>
        </div>
      </div>
      <div className="w-full flex justify-center items-center ">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 max-w-[1200px]">
          <StarterPlan
            isSubscribing={isSubscribing}
            supabase={supabase}
            setIsSubscribing={setIsSubscribing}
            me={userProfile as UserProfileDbType & { Billing: BillingDbType }}
            isMonthly={isMonthly}
            userId={userId as string}
            showTrial
          />

          <PremiumPlan
            isSubscribing={isSubscribing}
            supabase={supabase}
            setIsSubscribing={setIsSubscribing}
            me={userProfile as UserProfileDbType & { Billing: BillingDbType }}
            isMonthly={isMonthly}
            userId={userId as string}
            showTrial
          />

          <BusinessPlan
            isSubscribing={isSubscribing}
            supabase={supabase}
            setIsSubscribing={setIsSubscribing}
            me={userProfile as UserProfileDbType & { Billing: BillingDbType }}
            isMonthly={isMonthly}
            userId={userId as string}
            showTrial
          />
        </div>
      </div>

      <div className="w-full mt-3 flex justify-center items-center ">
        <div className="max-w-[1200px] w-full">
          <TrialTestimonials />
        </div>
      </div>
    </div>
  );
};

export default TrialPage;
