export const WarningIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.44435 1.22035L0.711065 16.3457C0.553311 16.6189 0.470244 16.9287 0.470215 17.2442C0.470186 17.5596 0.553196 17.8695 0.710899 18.1426C0.868602 18.4158 1.09544 18.6427 1.36861 18.8004C1.64178 18.9581 1.95166 19.0411 2.26708 19.0411H19.7322C20.0477 19.0411 20.3575 18.9581 20.6307 18.8004C20.9039 18.6427 21.1307 18.4158 21.2884 18.1426C21.4461 17.8695 21.5291 17.5596 21.5291 17.2442C21.5291 16.9287 21.446 16.6189 21.2883 16.3457L12.5559 1.22035C12.3982 0.947261 12.1714 0.720485 11.8984 0.562817C11.6253 0.405149 11.3155 0.322144 11.0001 0.322144C10.6848 0.322144 10.375 0.405149 10.1019 0.562817C9.82881 0.720485 9.60202 0.947261 9.44435 1.22035Z"
      fill="#D50B3E"
    />
    <path
      d="M11.114 5.88538H10.8862C10.3253 5.88538 9.87061 6.34005 9.87061 6.90092V11.7551C9.87061 12.3159 10.3253 12.7706 10.8862 12.7706H11.114C11.6748 12.7706 12.1295 12.3159 12.1295 11.7551V6.90092C12.1295 6.34005 11.6748 5.88538 11.114 5.88538Z"
      fill="#FFF7ED"
    />
    <path
      d="M11.0001 16.6205C11.6238 16.6205 12.1295 16.1148 12.1295 15.491C12.1295 14.8672 11.6238 14.3616 11.0001 14.3616C10.3763 14.3616 9.87061 14.8672 9.87061 15.491C9.87061 16.1148 10.3763 16.6205 11.0001 16.6205Z"
      fill="#FFF7ED"
    />
  </svg>
);
