import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as QuoteSvg } from "../assets/quote.svg";

export const Testimonial = ({
  testimonialObj,
}: {
  testimonialObj: {
    img: React.JSXElementConstructor<any>;
    name: string;
    testimonial: string;
    title: string;
  };
}) => {
  return (
    <div className="bg-[#F4F7F7] px-[34px] hidden md:block min-h-screen inset-y-0 transition-all">
      <div className="space-y-32 mt-14">
        <div className="w-full font-bold flex justify-start items-center space-x-2 ml-7 ">
          <Logo className="w-[32px] h-[32px] mb-1" />
          <p className="text-20 font-extrabold">Unifire</p>
        </div>
        <div className="relative w-[350px] h-[405px] flex justify-center items-center text-center">
          <testimonialObj.img className="absolute inset-0 scale-x-105" />
          <div className="z-10 max-w-[260px] ml-4 mb-12">
            <p className="font-semibold text-[#525252] text-14 mt-[40px]">
              {testimonialObj.name}
            </p>
            <div className=" flex flex-col justify-center items-center">
              <p className="text-[#84818A] font-normal text-16 tracking-[0.56px] ">
                {testimonialObj.title}
              </p>
              <QuoteSvg />
            </div>

            <p className="mt-[6px] text-[#42474B] text-14 font-medium text-center px-2">
              {testimonialObj.testimonial}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
