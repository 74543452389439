import { AxiosError } from "axios";
import Cookies from "js-cookie";
import { useCallback, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import { usePaddle } from "../../../hooks/usePaddle";
import { CircleCheckmark } from "../../../shared/Icons/CircleCheckmark";
import { BillingDbType, UserProfileDbType } from "../../../types";
import { PADDLE_MAP } from "../../../utils/constants";
import { cancelSubscription } from "../../../utils/paymentv2";
import { CancellationFeedbackModal } from "./CancelationModal";
import ConfirmationMessage from "./ConfirmationMessage";
import ConfirmationModal from "./ConfirmationModal";

export const BusinessPlan = ({
  isSubscribing,
  supabase,
  setIsSubscribing,
  me,
  isMonthly,
  userId,
  showTrial,
}: {
  isSubscribing: boolean;
  supabase: any;
  setIsSubscribing: (_: boolean) => void;
  me: UserProfileDbType & { Billing: BillingDbType };
  isMonthly: boolean;
  userId: string;
  showTrial?: boolean | undefined;
}) => {
  const [showCancellationFeedback, setShowCancellationFeedback] =
    useState(false);

  const [cancellationFeedback, setCancellationFeedback] = useState({
    reason: "",
    otherReason: "",
  });
  const paddle = usePaddle();
  const [show, setShow] = useState(false);

  const displayPrice = isMonthly ? 199 : 165;

  const [showTemporaryConfirmation, setshowTemporaryConfirmation] = useState<
    | {
        action: "TO_CANCEL" | "TO_CHANGE_PLAN";
        message: string;
        variantId: string;
      }
    | undefined
  >();

  const showConfirmationMessage = useCallback(
    (ob: {
      action: "TO_CANCEL" | "TO_CHANGE_PLAN";
      message: string;
      variantId: string;
    }) => {
      setshowTemporaryConfirmation(ob);
      setTimeout(() => {
        setshowTemporaryConfirmation(undefined);
      }, 4000);
    },
    []
  );

  return (
    <>
      <div className="h-full bg-white p-6 rounded-md border border-black">
        <div className="flex items-center">
          <h2 className="text-[#202020] font-semibold text-14 mr-2">
            Business
          </h2>
        </div>
        <p className="text-[#84818A] text-14 font-medium mt-2 h-[63px]">
          For businesses and agencies with the highest transcription and content
          quality needs and unlimited collaboration.
        </p>
        <div className="mt-7">
          <span className="text-[#000000] text-4xl leading-10 font-semibold">
            €{displayPrice}
          </span>
          <span className="text-[#84818A] font-semibold text-16">/mo</span>
        </div>
        <ul className="mt-8 space-y-2">
          {[
            "Unlimited content generation",
            "Unlimited uploads & transcriptions",
            "Unlimited team members",
            "Unlimited AI writer usage",
          ].map((feature, index) => (
            <li key={index} className="flex items-center space-x-2 ">
              <CircleCheckmark className="text-[#FF5698]" />
              <span className="relative text-sm leading-5 font-medium text-[#121217]">
                {" "}
                {feature}
                {index === 1 && <span className=" text-[#FF5698]">*</span>}
              </span>
              {/* asterics if index is 1 */}
            </li>
          ))}
        </ul>
        <button
          className="w-full bg-uf-yellow-1 text-sm leading-5 font-semibold text-[#202020] py-2 px-4 rounded-lg mt-8"
          disabled={isSubscribing}
          hidden={
            me?.Billing?.subscription_status === "active" &&
            me?.Billing?.plan_name === "LARGE"
          }
          onClick={async () => {
            setIsSubscribing(true);

            paddle?.Checkout.open({
              customer: {
                id: me?.Billing?.paddle_id ?? undefined,
                email: undefined as never,
              },
              transactionId: undefined as never,
              items: [
                {
                  priceId: isMonthly
                    ? PADDLE_MAP.month.large.planPriceId
                    : PADDLE_MAP.year.large.planPriceId,
                  quantity: 1,
                },
              ],
              customData: {
                user_id: userId as string,
                ...(Boolean(Cookies.get("affiliate")) && {
                  tolt_referral: Cookies.get("affiliate"),
                }),
              },
            });

            setIsSubscribing(false);
          }}
        >
          {showTrial ? "Start my 14-day trial" : "Buy Now"}
        </button>
        <button
          className={`w-full py-2 rounded-lg focus:outline-none focus:shadow-outline-orange bg-white text-[#42474B] font-semibold text-14 border-[#F2F2F3] border mt-8`}
          hidden={
            !(
              me?.Billing?.subscription_status === "active" &&
              me?.Billing?.plan_name === "LARGE"
            )
          }
          disabled={isSubscribing}
          onClick={async () => {
            setShowCancellationFeedback(true);
          }}
        >
          Cancel subscription
        </button>
        <hr className="h-px bg-[#DCDBDD] mt-[36px] mb-[26px]" />

        <div className="text-gray-800 space-y-5">
          <p className="text-14 text-[#202020] font-semibold">
            Everything in Premium, plus:
          </p>

          <p className="text-14 font-medium text-[#42474B]">
            5 Custom Content Templates per month
          </p>
          <p className="text-14 font-medium text-[#42474B]">Priority Support</p>
          <p className="text-14 font-medium text-[#42474B]">
            Priority AI Processing
          </p>
        </div>
      </div>

      <CancellationFeedbackModal
        isOpen={showCancellationFeedback}
        onClose={() => {
          setShowCancellationFeedback(false);
        }}
        onSubmit={(feedback) => {
          setCancellationFeedback(feedback);
          setShow(true);
        }}
      />

      <ConfirmationModal
        action={"TO_CANCEL"}
        show={show}
        onCancel={() => setShow(false)}
        onConfirm={async () => {
          setShow(false);

          // pop up triggered only on cancelation
          try {
            setIsSubscribing(true);
            const toStartUrl = await cancelSubscription({
              supabase,
              cancelationReason: `[CHOICES]:${cancellationFeedback.reason}\n[OTHER]:${cancellationFeedback.otherReason}`,
            });
            setIsSubscribing(false);
            showConfirmationMessage({
              action: "TO_CANCEL",
              message: "Your custom plan has been cancelled",
              variantId: process.env
                .REACT_APP_LEMON_VARIANT_ENTERPRISE_MONTHLY as string,
            });
            // window.location.reload();
          } catch (e) {
            const axiosError = e as AxiosError;
            alert(axiosError.response?.statusText || "Error");
            setIsSubscribing(false);
          }
        }}
        setShow={setShow}
        variantId={
          process.env.REACT_APP_LEMON_VARIANT_ENTERPRISE_MONTHLY as string
        }
      />

      {showTemporaryConfirmation && (
        <ConfirmationMessage {...showTemporaryConfirmation} />
      )}
    </>
  );
};

export default BusinessPlan;
