import { useEffect, useRef, useState } from "react";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { useAuthUserId } from "../../../hooks/useAuth";
import { useRemainingCredits } from "../../../hooks/useRemainingCredits";
import { useSupabase } from "../../../hooks/useSupabase";
import { RoleType } from "../../../hooks/userCanEdit";
import { ReadOnlyEditor } from "../../../shared/LiveEditor/components/ReadOnlyEditor";
import { AssetDbType, UploadDbType } from "../../../types";
import { CollaborativeEditor } from "../../CollaborativeEditor/src/app/[room]/page";
import { getEditorAuthTokens } from "../../CollaborativeEditor/src/app/api/auth";
import { AssetHeader } from "./AssetHeader";
import { ASSETS_IDS } from "./types";
import { useLocation, useNavigate } from "react-router-dom";

export interface AssetCardProps {
  post: { id: string; post: string };
  canEdit: boolean;
  userId?: string;
  upload: UploadDbType;
  role: RoleType;
  stream: boolean;
  asset: AssetDbType;
}

export const AssetCard = ({
  canEdit,
  upload,
  asset,
  post: { id: postId, post },
  role,
  stream,
}: AssetCardProps): JSX.Element => {
  const [editedText, setEditedText] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();

  const { userEditedAsset } = useAnalytics();
  const didCallEditedAsset = useRef(false);

  const userId = useAuthUserId();
  const supabase = useSupabase();
  const [collabToken, setCollabToken] = useState<string | null>(null);
  const [aiToken, setAiToken] = useState<string | null>(null);
  const [convertToken, setConvertToken] = useState<string | null>(null);

  const { authProfile } = useRemainingCredits();
  const isPublicPage = window.location.pathname.includes("share");

  useEffect(() => {
    // Update URL when the component mounts or when postId changes
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("post") !== postId) {
      searchParams.set("post", postId);
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [postId, navigate, location]);

  useEffect(() => {
    if (editedText === undefined || asset.asset_id === undefined) return;
    const delayDebounceFn = setTimeout(() => {
      // map through data of the asset and find the post that matches the postId
      const updatedPost = asset?.data?.map((post: any) => {
        if (post.id === postId) {
          return {
            ...post,
            post: editedText,
          };
        }
        return post;
      });

      supabase
        .from("Asset")
        .update({
          data: updatedPost,
        })
        .eq("asset_id", asset.asset_id as string)
        .eq("upload_id", upload.id)
        .then((resp) => {});

      if (didCallEditedAsset.current) return;
      didCallEditedAsset.current = true;
      userEditedAsset({
        assetType: ASSETS_IDS[asset.asset_id as keyof typeof ASSETS_IDS],
        platformType: asset?.asset_id?.includes("transcript")
          ? "transcript"
          : (asset.asset_id?.split("_")[0] as any),
        uploadId: upload.id,
        userRole: role,
        workspaceId: upload.workspace_id as string,
      });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [editedText, upload.id, postId]);

  useEffect(() => {
    // fetch data
    if (Boolean(upload?.id) === false) return;
    // if page includes "share" then its fine if userid is undefined, else return
    if (isPublicPage === false) {
      if (!userId) return;
    }

    // if is public page and editing disabled, return
    if (isPublicPage && !canEdit) return;

    // if already populated, return
    if (aiToken && collabToken) return;
    const dataFetch = async () => {
      const data = await getEditorAuthTokens({
        uploadId: upload.id,
        userId: userId ?? "public",
      });

      const castedData = data.data as {
        jwtCollabData: {
          token: string;
        };
        jwtAIData: {
          token: string;
        };
        jwtConvertData: {
          token: string;
        };
      };
      // set state when the data received
      setAiToken((prev) => (Boolean(prev) ? prev : castedData.jwtAIData.token));
      setConvertToken((prev) =>
        Boolean(prev) ? prev : castedData.jwtConvertData.token
      );
      setCollabToken((prev) =>
        Boolean(prev) ? prev : castedData.jwtCollabData.token
      );
    };

    dataFetch();
  }, [userId, isPublicPage, upload?.id, aiToken, collabToken]);

  return (
    <div className="w-full bg-white shadow rounded-xl transition-all duration-300 ease max-h-max self-center border-none">
      <div className="p-5">
        {" "}
        <AssetHeader
          post={post}
          upload={upload}
          assetId={asset.asset_id as string}
        />
      </div>

      {canEdit &&
      upload.id &&
      aiToken &&
      convertToken &&
      collabToken &&
      (isPublicPage || authProfile?.full_name) &&
      asset.asset_id ? (
        <div className="w-full h-full ">
          <CollaborativeEditor
            params={{
              uploadId: upload.id,
              userId: authProfile?.id ?? "",
              assetId: asset.asset_id as string,
              aiToken: aiToken,
              convertToken,
              collabToken: collabToken,
              postId,
              fullName: authProfile?.full_name ?? "Anonymous",
            }}
            content={post.trim()}
            onChange={(newValue) => {
              setEditedText(newValue);
            }}
          />
        </div>
      ) : !canEdit ? (
        <button
          className="overflow-y-auto h-full w-full cursor-pointer leading-7"
          disabled={!canEdit}
          onClick={() => {}}
        >
          <p className="h-full w-full align-top text-start no-scrollbar">
            <ReadOnlyEditor content={post.trim()} stream={stream} />
          </p>
        </button>
      ) : (
        <div className="relative">
          <div className="overflow-hidden h-full w-full">
            <button
              className="h-full w-full cursor-pointer leading-7"
              disabled={!canEdit}
              onClick={() => {}}
            >
              <p className="h-full w-full align-top text-start">
                <ReadOnlyEditor content={post.trim()} stream={stream} />
              </p>
            </button>
          </div>
          <div className="absolute inset-x-0 top-0 h-80 bg-gradient-to-b from-white via-white to-transparent flex flex-col items-center pt-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            <p className="mt-2 text-gray-800 font-semibold text-sm">
              Preparing the AI Writer Editor
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetCard;
