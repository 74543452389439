export const NavigationArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5019 19.1439C15.7096 19.1501 15.9141 19.0913 16.087 18.9758C16.2598 18.8604 16.3923 18.6938 16.4662 18.4995C16.54 18.3052 16.5515 18.0928 16.499 17.8917C16.4465 17.6905 16.3326 17.5109 16.1732 17.3775L10.0931 12.09L16.251 6.89523C16.3625 6.81452 16.4562 6.7117 16.5262 6.59311C16.5961 6.47452 16.6408 6.34273 16.6575 6.20606C16.6741 6.06939 16.6624 5.93079 16.623 5.79887C16.5836 5.66695 16.5174 5.54454 16.4285 5.43939C16.3396 5.33424 16.23 5.24867 16.1065 5.18786C15.983 5.12704 15.8482 5.0924 15.7107 5.08607C15.5732 5.07974 15.4358 5.1018 15.3072 5.15101C15.1786 5.20023 15.0616 5.27546 14.9634 5.372L7.91329 11.3141C7.80258 11.4071 7.71337 11.523 7.65182 11.6539C7.59027 11.7848 7.55784 11.9275 7.55676 12.0721C7.55569 12.2167 7.586 12.3599 7.6456 12.4917C7.70521 12.6235 7.79268 12.7408 7.902 12.8355L14.8631 18.8873C15.0389 19.0458 15.2653 19.1366 15.5019 19.1439Z"
      fill="#D1D1DB"
    />
  </svg>
);
