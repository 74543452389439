import "react-dropzone-uploader/dist/styles.css";
import Lottie from "react-lottie";
import Modal from "react-modal";
import SadLottie from "../assets/sad-lottie.json";
import SuccessLottie from "../assets/success-lottie.json";
import { PLAN_TITLE_MAP } from "./ConfirmationModal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "6px",
    zIndex: 100,
  },
};

const getDescription = (
  planLabel: "TO_CANCEL" | "TO_CHANGE_PLAN",
  planId: string
) => {
  const DESCRIPTION_MAP = {
    TO_CANCEL: <span>We've cancelled your subscription</span>,
    TO_CHANGE_PLAN: (
      <span>
        Your plan has been successfully updated to{" "}
        <span className="font-semibold">{PLAN_TITLE_MAP[planId]}</span>
      </span>
    ),
  };

  return DESCRIPTION_MAP[planLabel];
};

Modal.setAppElement("#root");
const ConfirmationMessage = ({
  message,
  action,
  variantId,
}: {
  message: string;
  action: "TO_CANCEL" | "TO_CHANGE_PLAN";
  variantId: string;
}) => {
  const description = getDescription(action, variantId);
  return (
    <>
      <Modal
        isOpen={true}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {}}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className="z-50 relative max-w-[300px] min-h-[300px] md:min-w-[600px] md:min-h-[350px] px-8 pb-8 pt-4 rounded-xl overflow-hidden flex justify-center items-center items-center "
          role="document"
        >
          <div className="z-50 w-full h-full space-y-24 flex flex-col justify-between items-center whitespace-pre-line">
            {action === "TO_CANCEL" && (
              <div className="w-full flex flex-col items-center justify-around">
                <Lottie
                  width={200}
                  height={200}
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: SadLottie,
                  }}
                />
                <p className="text-16 text-uf-slate-1 text-center mt-10 font-semibold">
                  We cancelled your subscription. You still have access till the
                  end of your billing cycle.
                </p>
              </div>
            )}

            {action === "TO_CHANGE_PLAN" && (
              <div className="w-full flex flex-col items-center justify-center">
                <Lottie
                  width={200}
                  height={200}
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: SuccessLottie,
                  }}
                />
                <p className="text-16 text-uf-slate-1 text-center mt-16">
                  {description}
                </p>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationMessage;
