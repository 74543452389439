export const DownloadIcon = (
  props: React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg
    {...props}
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.25 18.5V14.75C23.25 14.5014 23.1512 14.2629 22.9754 14.0871C22.7996 13.9113 22.5611 13.8125 22.3125 13.8125C22.0639 13.8125 21.8254 13.9113 21.6496 14.0871C21.4738 14.2629 21.375 14.5014 21.375 14.75V18.5C21.375 18.7486 21.2762 18.9871 21.1004 19.1629C20.9246 19.3387 20.6861 19.4375 20.4375 19.4375H3.5625C3.31386 19.4375 3.0754 19.3387 2.89959 19.1629C2.72377 18.9871 2.625 18.7486 2.625 18.5V14.75C2.625 14.5014 2.52623 14.2629 2.35041 14.0871C2.1746 13.9113 1.93614 13.8125 1.6875 13.8125C1.43886 13.8125 1.2004 13.9113 1.02459 14.0871C0.848772 14.2629 0.75 14.5014 0.75 14.75V18.5C0.75 19.2459 1.04632 19.9613 1.57376 20.4887C2.10121 21.0162 2.81658 21.3125 3.5625 21.3125H20.4375C21.1834 21.3125 21.8988 21.0162 22.4262 20.4887C22.9537 19.9613 23.25 19.2459 23.25 18.5ZM17.2688 13.6063L12.5813 17.3563C12.4157 17.487 12.211 17.5582 12 17.5582C11.789 17.5582 11.5843 17.487 11.4187 17.3563L6.73125 13.6063C6.56045 13.445 6.45639 13.2256 6.43966 12.9913C6.42292 12.757 6.49473 12.525 6.64088 12.3411C6.78702 12.1573 6.99686 12.035 7.22887 11.9984C7.46087 11.9619 7.69815 12.0137 7.89375 12.1437L11.0625 14.675V1.625C11.0625 1.37636 11.1613 1.1379 11.3371 0.962088C11.5129 0.786272 11.7514 0.6875 12 0.6875C12.2486 0.6875 12.4871 0.786272 12.6629 0.962088C12.8387 1.1379 12.9375 1.37636 12.9375 1.625V14.675L16.1062 12.1437C16.2007 12.0546 16.3125 11.9861 16.4348 11.9425C16.5571 11.8989 16.6871 11.8811 16.8166 11.8904C16.9461 11.8996 17.0722 11.9357 17.1871 11.9962C17.3019 12.0568 17.4029 12.1405 17.4837 12.2421C17.5644 12.3438 17.6232 12.4611 17.6563 12.5866C17.6894 12.7121 17.6961 12.8432 17.6758 12.9714C17.6556 13.0996 17.609 13.2223 17.539 13.3316C17.4689 13.4408 17.3769 13.5344 17.2688 13.6063Z"
      fill="#8A8AA3"
    />
  </svg>
);
