import axios, { AxiosError } from "axios";

export const UNLIMITED_CREDITS_INTEGER = 50000;

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export const sleep = async (ms: number) => {
  await timeout(ms);
};

export async function getDurationMinutes(file: File): Promise<number> {
  const url = URL.createObjectURL(file);

  return new Promise((resolve) => {
    const audio = document.createElement("audio");
    audio.muted = true;
    const source = document.createElement("source");
    source.src = url; //--> blob URL
    audio.preload = "metadata";
    audio.appendChild(source);
    audio.onloadedmetadata = function () {
      resolve(Math.floor(audio.duration / 60));
    };
  });
}

export const convertMillisecondsToMinutes = (ms: number) => {
  return Math.floor(ms / 60000);
};

export const embedText = async ({
  supabase,
  uploadId,
  langTag,
}: {
  uploadId: string;
  supabase: any;
  langTag: string;
}) => {
  const body = { uploadId: uploadId, langTag };

  const session = await supabase.auth.getSession();
  // req.setRequestHeader("x-upsert", "true");
  // random string

  const a = await axios
    .post(`${process.env.REACT_APP_TRANSCRIPTION_URL}/embed-text`, body, {
      headers: {
        "Content-Type": "application/json",
        // @ts-ignore
        ...supabase.headers,
        authorization: `Bearer ${session.data.session.access_token}`,
      },
    })
    .catch((error: AxiosError) => {
      console.log("error ", error);
      throw error;
    });

  return a;
};
