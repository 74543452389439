export const TwitterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4473_11448)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9762 7.96063C13.98 8.04875 13.9825 8.13688 13.9825 8.22625C13.9825 10.9369 11.9187 14.0631 8.145 14.0631C6.98625 14.0631 5.90812 13.7238 5 13.1419C5.16062 13.1606 5.32375 13.17 5.48938 13.17C6.45062 13.17 7.335 12.8425 8.0375 12.2919C7.13937 12.2756 6.38188 11.6825 6.12063 10.8675C6.24625 10.8913 6.37438 10.9037 6.50688 10.9037C6.69375 10.9037 6.875 10.8787 7.0475 10.8319C6.10875 10.6437 5.40187 9.81437 5.40187 8.82063V8.79438C5.67812 8.94812 5.995 9.04063 6.33125 9.05125C5.78062 8.68313 5.41812 8.055 5.41812 7.34375C5.41812 6.9675 5.51938 6.615 5.69625 6.31187C6.70813 7.55312 8.22 8.37 9.925 8.45563C9.89 8.30562 9.87187 8.14875 9.87187 7.98812C9.87187 6.855 10.7906 5.93625 11.9231 5.93625C12.5138 5.93625 13.0469 6.18563 13.4206 6.58437C13.8881 6.4925 14.3275 6.32188 14.7238 6.08687C14.5706 6.56563 14.245 6.9675 13.8219 7.22188C14.2369 7.17188 14.6319 7.06187 15 6.89875C14.725 7.31 14.3769 7.67125 13.9762 7.96063ZM10 0C4.47688 0 0 4.47688 0 10C0 15.5225 4.47688 20 10 20C15.5231 20 20 15.5225 20 10C20 4.47688 15.5231 0 10 0Z"
        fill="#41A1F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_4473_11448">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
