import { useContext } from "react";
import { PaddleContext } from "../contexts/PaddleProvider";

export const usePaddle = () => {
  const context = useContext(PaddleContext);
  // if (context === undefined) {
  //   throw new Error("paddle must be used within a PaddleProvider");
  // }
  return context;
};
