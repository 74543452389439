export const ShareIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.587 17.4549L15.6077 17.4677L15.6241 17.4497C16.3378 16.6656 17.3661 16.1728 18.5074 16.1728C20.6573 16.1728 22.4064 17.9218 22.4064 20.0718C22.4064 22.2216 20.6573 23.9707 18.5074 23.9707C16.3574 23.9707 14.6083 22.2216 14.6083 20.0718C14.6083 19.5731 14.7035 19.0966 14.8747 18.6579L14.8836 18.6352L14.8629 18.6223L8.3956 14.6167L8.3749 14.6039L8.35851 14.6219C7.64475 15.4062 6.61649 15.899 5.47521 15.899C3.32526 15.899 1.57617 14.1499 1.57617 12C1.57617 9.8502 3.32526 8.10094 5.47521 8.10094C6.61649 8.10094 7.64475 8.59394 8.35851 9.37804L8.3749 9.39605L8.3956 9.38322L14.8629 5.37762L14.8836 5.36478L14.8747 5.34206C14.7035 4.90333 14.6083 4.42683 14.6083 3.92834C14.6083 1.77838 16.3574 0.0292968 18.5074 0.0292968C20.6573 0.0292968 22.4064 1.7782 22.4064 3.92815C22.4064 6.07811 20.6573 7.82719 18.5074 7.82719C17.3661 7.82719 16.3378 7.33419 15.6241 6.55009L15.6077 6.53208L15.587 6.5449L9.11951 10.5507L9.09877 10.5635L9.10765 10.5863C9.27902 11.0248 9.37407 11.5013 9.37407 12C9.37407 12.4985 9.27903 12.975 9.10764 13.4137L9.09877 13.4364L9.11951 13.4493L15.587 17.4549ZM18.5074 1.40313C17.115 1.40313 15.9821 2.53594 15.9821 3.92834C15.9821 5.32073 17.115 6.45354 18.5074 6.45354C19.8997 6.45354 21.0326 5.32073 21.0326 3.92834C21.0326 2.53594 19.8997 1.40313 18.5074 1.40313ZM2.94983 12C2.94983 13.3924 4.08263 14.5252 5.47521 14.5252C6.86761 14.5252 8.00023 13.3924 8.00023 12C8.00023 10.6076 6.86761 9.47477 5.47521 9.47477C4.08263 9.47477 2.94983 10.6076 2.94983 12ZM18.5074 17.5464C17.115 17.5464 15.9821 18.6792 15.9821 20.0716C15.9821 21.464 17.115 22.5968 18.5074 22.5968C19.8997 22.5968 21.0326 21.464 21.0326 20.0716C21.0326 18.6792 19.8997 17.5464 18.5074 17.5464Z"
      fill="#FF5698"
      stroke="#FF5698"
      stroke-width="0.0585936"
    />
  </svg>
);
