import debounce from "lodash.debounce";
import "rc-slider/assets/index.css";
import { useEffect, useRef, useState } from "react";
import { Switch } from "../../../shared/Library/Switch";

import WPPremiumPlan from "./WPPremiumPlan";
import WPStarterPlan from "./WPStarterPlan";
import WPBusinessPlan from "./WPBusinessPlan";

export const WPPricingPage = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isMonthly, setIsMonthly] = useState(true);

  // Debounce the sendHeightToParent function to prevent it from being called too frequently
  const sendHeightToParent = debounce(() => {
    const height = (contentRef?.current?.scrollHeight ?? 0) + 10;
    window.parent.postMessage(
      { frameHeight: height },
      "https://www.unifire.ai/pricing/"
    );
  }, 100); // Adjust the debounce time (in milliseconds) as needed

  useEffect(() => {
    // Function to handle the resize event
    const handleResize = () => {
      sendHeightToParent();
    };

    // Add event listener for the resize event
    window.addEventListener("resize", handleResize);

    // Call once to send the initial height
    sendHeightToParent();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      sendHeightToParent.cancel(); // Cancel any pending debounced calls
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.paritydeals.com/banner.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div
        ref={contentRef}
        className="overflow-x-hidden py-10 px-4 md:px-8 flex flex-col justify-center items-center bg-[#FAFAFA]"
      >
        <div className="w-full max-w-[1300px] ">
          <div className="text-center flex flex-col justify-center items-center space-y-1">
            <p className="text-42 font-semibold">
              Scale with your content needs
            </p>
            <p className="text-16">
              Whether you’re a small creator or need content for a 100 people
              marketing team, there’s a <br />
              plan for you.{" "}
            </p>
          </div>

          <div className="w-full flex justify-center items-center mt-10 mb-5 text-center">
            <div className="flex items-start justify-between space-x-4">
              <span className="text-sm leading-5 font-medium text-[#6C6C89]">
                YEARLY <br />
              </span>
              <div className="">
                <Switch
                  checked={isMonthly}
                  onChange={setIsMonthly}
                  onColor="#5423E7"
                />
              </div>

              <span className="text-sm leading-5 font-medium text-[#6C6C89]">
                MONTHLY
              </span>
            </div>
          </div>

          <div className="w-full flex justify-center items-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {/* <div className="">
          <FreePlan
            isSubscribing={isSubscribing}
            supabase={supabase}
            setIsSubscribing={setIsSubscribing}
            me={userProfile}
          />
        </div> */}

              <WPStarterPlan isMonthly={isMonthly} />

              <WPPremiumPlan isMonthly={isMonthly} />
              <WPBusinessPlan isMonthly={isMonthly} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WPPricingPage;
