import { Field, Form, Formik } from "formik";
import { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";
import uuid4 from "uuid4";
import * as Yup from "yup";
import { useRemainingCredits } from "../../../hooks/useRemainingCredits";
import { useSupabase } from "../../../hooks/useSupabase";
import { useSwitchWorkspace } from "../../../hooks/useWorkspace";
import { PlusCircle } from "../../../shared/Icons/PlusCircle";
import { InviteMemberField } from "../../Team/Components/InviteMemberField";
import { useAnalytics } from "../../../hooks/useAnalytics";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
});

export const CreateWorkspace = () => {
  const [showCreateWorkspace, setShowCreateWorkspace] = useState(false);

  const [inviteOneDone, setInviteOneDone] = useState(false);
  const [inviteTwoDone, setInviteTwoDone] = useState(false);
  const [inviteThreeDone, setInviteThreeDone] = useState(false);

  const [didActuallySendEmail, setDidActuallySendEmail] = useState(1);
  const [createdWorkspaceId, setCreatedWorkspaceId] = useState<string>();

  const { createNewWorkspace } = useAnalytics();

  const ownerGeneralLimits = useRemainingCredits()?.ownerGeneralLimits;

  const switchWorkspace = useSwitchWorkspace();

  const supabase = useSupabase();

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const session = await supabase.auth.getSession();

    if (!values?.title) return;
    if (!session?.data.session?.user.id) return;

    if (ownerGeneralLimits?.remainingWorkspaces === 0) {
      alert(
        "You reached your workspaces limit, please upgrade your plan in the billing page"
      );
      return;
    }

    setSubmitting(true);

    const id = uuid4();
    const newWorkspace = await supabase.from("Workspace").insert({
      id,
      title: values.title,
      owner_id: session?.data.session?.user.id,
    });

    await supabase
      .from("Project")
      .insert({
        title: "My project",
        user_id: session?.data?.session?.user.id,
        workspace_id: id,
        lang_tag: "en",
        selected_assets: {},
      })
      .select("*")
      .single();

    if (newWorkspace.error) {
      setSubmitting(false);
      return;
    }
    setCreatedWorkspaceId(id);
  };

  const finalizeModal = () => {
    setTimeout(() => {
      if (
        inviteOneDone &&
        inviteTwoDone &&
        inviteThreeDone &&
        createdWorkspaceId
      ) {
        createNewWorkspace({
          newWorkspaceId: createdWorkspaceId,
          teamSize: didActuallySendEmail,
        });
        switchWorkspace(createdWorkspaceId as string, true).then(() =>
          setShowCreateWorkspace(false)
        );
      }
    }, 0); // force next rendering cycle since we check the state right after assigning it
  };

  return (
    <>
      <button
        className="mt-[14px] pl-[3px] h-[32px] flex justify-start items-center space-x-4 text-xs leading-4 font-normal text-[#6C6C89]"
        onClick={() => setShowCreateWorkspace(true)}
      >
        <div className="flex justify-center items-center w-8 h-8">
          <PlusCircle />
        </div>
        <span>Create Workspace</span>
      </button>
      {/* invite members */}
      {showCreateWorkspace && (
        <Modal
          isOpen={showCreateWorkspace}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => setShowCreateWorkspace(false)}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "6px",
              zIndex: 2100,
            },
          }}
          contentLabel="Example Modal"
        >
          <Formik
            initialValues={{
              title: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values: { title } }) => (
              <Form className="">
                <div
                  className="max-w-[300px] min-h-[250px] md:min-w-[909px] md:min-h-[350px] py-8 px-24"
                  role="document"
                >
                  <div className="text-center space-y-3">
                    <p className="block w-full text-xl leading-8 text-gray-900 font-semibold">
                      New Workspace
                    </p>
                    <p className="text-lg leading-6 text-[#121217]">
                      Create a workspace to collaborate on
                      <br />
                      content creation
                    </p>
                  </div>

                  <div className="mt-16 ">
                    <span className="text-14 font-medium">Name your Team</span>
                    <Field
                      type="text"
                      name="title"
                      id="title"
                      placeholder="New Superteam"
                      className="appearance-none text-14 mt-2 border placeholder:text-[#A9A9BC] border-[#D1D1DB] rounded-lg w-full p-2.5 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>

                  <div className="mt-9 space-y-5">
                    <InviteMemberField
                      shouldInvite={Boolean(createdWorkspaceId)}
                      createdWorkspaceId={createdWorkspaceId}
                      onDone={(didActuallySendEmail: boolean | undefined) => {
                        if (didActuallySendEmail) {
                          setDidActuallySendEmail((prev) => prev + 1);
                        }
                        setInviteOneDone(true);
                        finalizeModal();
                      }}
                    />

                    <InviteMemberField
                      shouldInvite={Boolean(createdWorkspaceId)}
                      createdWorkspaceId={createdWorkspaceId}
                      onDone={(didActuallySendEmail: boolean | undefined) => {
                        if (didActuallySendEmail) {
                          setDidActuallySendEmail((prev) => prev + 1);
                        }
                        setInviteTwoDone(true);
                        finalizeModal();
                      }}
                    />

                    <InviteMemberField
                      shouldInvite={Boolean(createdWorkspaceId)}
                      createdWorkspaceId={createdWorkspaceId}
                      onDone={(didActuallySendEmail: boolean | undefined) => {
                        if (didActuallySendEmail) {
                          setDidActuallySendEmail((prev) => prev + 1);
                        }
                        setInviteThreeDone(true);
                        finalizeModal();
                      }}
                    />
                  </div>

                  <div className="mt-24 w-full flex justify-end items-center">
                    <button
                      type="submit"
                      disabled={isSubmitting || !title}
                      className="transition-all max-w-[254px] disabled:bg-gray-100 bg-uf-yellow-1 text-sm leading-5 font-medium text-[#121217] py-3 px-4 rounded-xl focus:outline-none focus:shadow-outline w-full"
                    >
                      Create Workspace
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
