import classNames from "classnames";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { CopyIcon } from "../../../shared/Icons/Copy";
import { ShareIcon } from "../../../shared/Icons/Share";
import { UploadDbType } from "../../../types";
import { parseHtmlToStringWithNewLines } from "../../../utils/common";
import ExportMenu from "../../CollaborativeEditor/src/extensions/Export";
import ShareForm from "./ShareForm";
import { ASSETS_IDS } from "./types";

export const AssetHeader = ({
  upload,
  post,
  pad,
  hideCopy,
  assetId,
}: {
  upload: UploadDbType;
  post: string;
  pad?: boolean;
  hideCopy?: boolean;
  assetId: string;
}) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [showSharingForm, setShowSharingForm] = useState(false);

  const editor = window.editor;

  function download(html: string, fileName: string) {
    const content = parseHtmlToStringWithNewLines(html);
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  async function copyToClipboard(html: string): Promise<void> {
    try {
      if (navigator.clipboard && navigator.clipboard.write) {
        // Use the modern clipboard API
        const blob = new Blob([html], { type: "text/html" });
        const plainText = html.replace(/<[^>]*>/g, ""); // Strip HTML tags for plain text
        const data = [
          new ClipboardItem({
            "text/html": blob,
            "text/plain": new Blob([plainText], { type: "text/plain" }),
          }),
        ];
        await navigator.clipboard.write(data);
      } else {
        // Fallback to the older method
        const textArea = document.createElement("textarea");
        textArea.value = html;
        document.body.appendChild(textArea);
        textArea.select();
        const success = document.execCommand("copy");
        document.body.removeChild(textArea);
        if (!success) {
          throw new Error("execCommand failed");
        }
      }
    } catch (err) {
      console.error("Failed to copy content: ", err);
      throw err; // Re-throw the error for the caller to handle
    }
  }

  const TXT_ASSET_IDS = [
    ASSETS_IDS.TWITTER_TWEETS,
    ASSETS_IDS.TWITTER_THREADS,
    ASSETS_IDS.LINKEDIN_POSTS,
    ASSETS_IDS.TRANSCRIPT,
  ];

  return (
    <div
      className={classNames("flex justify-between items-center mb-11", {
        "p-5": pad,
      })}
    >
      <p className="text-14 font-medium text-[#42474B] max-w-[386px] truncate">
        {upload.title}
      </p>

      <div className="flex justify-between items-center space-x-5">
        <ExportMenu
          editor={editor}
          fileName={`${assetId}_${upload.title}`}
          backfall={TXT_ASSET_IDS.includes(assetId) ? post : undefined}
        />

        {!hideCopy && (
          <button
            data-tooltip-id="tooltipid"
            data-tooltip-content="Copied!"
            title="Copy"
            onClick={() => {
              setOpenTooltip(true);

              copyToClipboard(editor?.getHTML() ?? post);
            }}
          >
            <CopyIcon />
          </button>
        )}

        <button
          className={classNames(
            "flex justify-center items-center px-5 py-2 text-[#FF5698] border border-[#FF5698] space-x-1 rounded-lg",
            {
              hidden: window.location.pathname.includes("share"),
            }
          )}
          onClick={() => setShowSharingForm((prev) => !prev)}
        >
          <ShareIcon />
          <span className="text-14 font-medium">Share Content</span>
        </button>
      </div>
      <ShareForm
        episode={upload}
        setShowSharingForm={setShowSharingForm}
        showSharingForm={showSharingForm}
      />
      <Tooltip
        isOpen={openTooltip}
        variant="success"
        id="tooltipid"
        place="top"
        afterShow={() => {
          setTimeout(() => {
            setOpenTooltip(false);
          }, 1000);
        }}
      >
        Copied!
      </Tooltip>
    </div>
  );
};
