import classNames from "classnames";
import { useRef, useState } from "react";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { useWorkspace } from "../../../hooks/useWorkspace";
import { useRole } from "../../../hooks/userCanEdit";
import { capitalizeFirst } from "../../../utils/common";
import { UPLOAD_CATEGORIES } from "../../Episode/components/types";
import { SelectProject } from "./SelectProject";
// Define the type for an asset
interface Asset {
  id: string;
  name: string;
  platform: string;
  icon: JSX.Element;
}

// Define the type for the accumulator, which is an object where each key is a string (the platform name)
// and each value is an array of Asset objects
interface GroupedByPlatform {
  [key: string]: Asset[];
}

const ContentCreationTabs = [
  "All Content",
  ...Object.keys(UPLOAD_CATEGORIES)
    .map(
      (key) => UPLOAD_CATEGORIES[key as keyof typeof UPLOAD_CATEGORIES].value
    )
    .filter((e) => e !== UPLOAD_CATEGORIES["transcript"].value),
];

const ContentTypes = Object.keys(UPLOAD_CATEGORIES)
  .map((key: string) => {
    const assetsUI = UPLOAD_CATEGORIES[
      key as keyof typeof UPLOAD_CATEGORIES
    ].resources.map((resource) => {
      if (key === UPLOAD_CATEGORIES["transcript"].value) return undefined;
      return {
        id: resource.dbId,
        name: resource.label,
        platform: key,
        icon: UPLOAD_CATEGORIES[key as keyof typeof UPLOAD_CATEGORIES].icon,
      };
    });
    return assetsUI;
  })
  .flat()
  .filter(Boolean) as {
  id: string;
  name: string;
  platform: string;
  icon: JSX.Element;
}[];

const SelectAssets = ({
  onAssetsIntent,
  onSelectProjectId,
  bgWhite,
  isPublic,
  hideShadow,
}: {
  onAssetsIntent: (_: string[]) => void;
  onSelectProjectId: (_: string) => void;
  bgWhite?: boolean;
  isPublic?: boolean;
  hideShadow?: boolean;
}) => {
  const [selectedTab, setSelectedTab] = useState(ContentCreationTabs[0]);
  const [selectedContent, setSelectedContent] = useState(new Set());
  const selectedContentRef = useRef<HTMLDivElement>(null);
  const role = useRole();
  const workspace = useWorkspace();

  const { generateContentIntent } = useAnalytics();

  const toggleContent = (contentId: string) => {
    const newSelectedContent = new Set(selectedContent);
    if (newSelectedContent.has(contentId)) {
      newSelectedContent.delete(contentId);
    } else {
      newSelectedContent.add(contentId);
      setTimeout(() => {
        if (selectedContentRef.current) {
          const scrollElement = selectedContentRef.current;
          const scrollWidth = scrollElement.scrollWidth;
          scrollElement.scrollTo({ left: scrollWidth, behavior: "smooth" });
        }
      }, 0); // Small delay to ensure the new element is rendered
    }
    setSelectedContent(newSelectedContent);
  };

  const isSelected = (contentId: string) => selectedContent.has(contentId);

  const groupedByPlatform = ContentTypes.reduce(
    (acc: GroupedByPlatform, asset: Asset) => {
      const { platform } = asset; // Destructure platform for clearer access
      if (!acc[platform]) {
        acc[platform] = []; // Initialize an array for this platform if it doesn't exist
      }
      acc[platform].push(asset); // Add the asset to the platform array
      return acc;
    },
    {} as GroupedByPlatform
  ); // Type assertion here for the initial value

  const platformGroups = Object.keys(groupedByPlatform).map(
    (key) => groupedByPlatform[key]
  );

  return (
    <div className="w-full h-full">
      <div
        className={classNames(
          `rounded-2xl  transition-all p-6 h-full min-h-[385px] flex flex-col justify-between items-start`,
          {
            "bg-green-200": !bgWhite,
            shadow: !hideShadow,
          }
        )}
      >
        {/* section 1: for selection */}
        <div className="w-full h-full">
          <div className="flex space-x-4 mb-4 overflow-auto no-scrollbar">
            {ContentCreationTabs.map((tab) => (
              <button
                key={tab}
                className={`py-1.5 px-4 rounded-[20px] min-w-max text-xs leading-4 font-medium ${
                  selectedTab === tab
                    ? "bg-green-500 text-white"
                    : "text-gray-600 bg-green-100"
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {capitalizeFirst(tab)}
              </button>
            ))}
          </div>

          {/* section 2: Display what was selected bellow */}
          <div className="mt-5 w-full space-y-2">
            <p className="text-sm leading-5 text-[#121217] font-medium">
              Content Assets Selected
            </p>
            <div
              className={classNames(
                "flex justify-between items-center transition-all duration-300 -space-x-1",
                {
                  "cursor-not-allowed": selectedContent.size === 0,
                }
              )}
            >
              <div
                ref={selectedContentRef}
                className={classNames(
                  " w-full bg-white h-full min-h-[40px] pl-2 -mr-1 flex justify-start items-center rounded-l-lg border border-gray-300 space-x-1 overflow-auto no-scrollbar shadow",
                  {
                    "bg-[#F7F7F8]": selectedContent.size === 0,
                  }
                )}
              >
                {Array.from(selectedContent).map((contentId) => (
                  <span
                    key={contentId as string}
                    className="bg-[#FFF9EB] border border-uf-yellow-1 text-yellow-800 p-1 rounded-lg text-sm leading-5 font-regular px-3 min-w-max"
                  >
                    {ContentTypes.find((type) => type.id === contentId)?.name}
                    <button
                      onClick={() => toggleContent(contentId as string)}
                      className="text-gray-500 ml-2"
                    >
                      ✕
                    </button>
                  </span>
                ))}
                {!Boolean(Array.from(selectedContent).length) && (
                  <p className="pl-4">
                    {" "}
                    <span className="text-xs leading-6 font-medium text-[#6C6C89]">
                      Select the content assets below to get started
                    </span>
                  </p>
                )}
              </div>
              <button
                className="h-[40px] px-[22px] gray-800 leading-6 font-semibold bg-uf-yellow-1 rounded-lg min-w-max text-sm disabled:cursor-not-allowed disabled:bg-gray-100 shadow"
                disabled={selectedContent?.size === 0}
                onClick={() => {
                  onAssetsIntent(Array.from(selectedContent) as string[]);
                  generateContentIntent({
                    assetTypes: Array.from(selectedContent) as string[],
                    uploadId: undefined as any,
                    userRole: role ?? "anon",
                    workspaceId: workspace?.id as string,
                  });
                }}
              >
                Generate Content
              </button>
            </div>
            <div className="text-sm leading-5 text-[#121217] font-medium flex justify-start items-center space-x-1">
              <p> Generate in</p>{" "}
              <div>
                <SelectProject
                  isPublic={isPublic}
                  handleProjectChange={(id) => onSelectProjectId(id)}
                />
              </div>
            </div>
          </div>

          {/* section 3: Display the content types based on the selected platform */}
          <div className="mt-12 mb-4">
            {selectedTab === "All Content" ? (
              Object.entries(groupedByPlatform).map(
                ([platform, assets], index) => (
                  <div key={index} className="mb-4">
                    <h3 className="text-gray-700 text-md leading-6 font-semibold mb-2 capitalize">
                      {platform}
                    </h3>
                    <div className="grid grid-cols-2 smn:grid-cols-3 md:grid-cols-4 gap-4 mb-4 ">
                      {assets.map((type) =>
                        renderAsset(type, isSelected, toggleContent)
                      )}
                    </div>
                  </div>
                )
              )
            ) : (
              <div className="grid grid-cols-2 smn:grid-cols-3 md:grid-cols-4 gap-4 mb-4 ">
                {groupedByPlatform[selectedTab]?.map((type) =>
                  renderAsset(type, isSelected, toggleContent)
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function renderAsset(
  type: Asset,
  isSelected: (_: string) => boolean,
  toggleContent: (_: string) => void
) {
  return (
    <div className="">
      <div
        key={type.id}
        className={classNames(
          "p-4 h-[103px] bg-white rounded-[12px] cursor-pointer flex justify-between items-center ",
          {
            "border-2 border-uf-yellow-1": isSelected(type.id),
            "shadow-sm hover:bg-[#EBEBEF]": !isSelected(type.id),
          }
        )}
        onClick={() => toggleContent(type.id)}
      >
        <div className="space-y-1.5">
          {type.icon}
          <div>
            {" "}
            <p className="text-gray-700 text-xs leading-6 font-semibold">
              {type.name}
            </p>
            <p className="text-gray-400 text-xs leading-4 font-normal">
              {type.platform}
            </p>
          </div>
        </div>
        <div>
          <input
            type="checkbox"
            className="accent-[#FFE476] h-[16px] w-[16px]"
            checked={isSelected(type.id)}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectAssets;
