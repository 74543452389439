export const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.62533 0.791992C6.62533 0.446814 6.3455 0.166992 6.00033 0.166992C5.65515 0.166992 5.37533 0.446814 5.37533 0.791992V5.37533H0.791992C0.446814 5.37533 0.166992 5.65515 0.166992 6.00033C0.166992 6.3455 0.446814 6.62533 0.791992 6.62533H5.37533V11.2087C5.37533 11.5538 5.65515 11.8337 6.00033 11.8337C6.3455 11.8337 6.62533 11.5538 6.62533 11.2087V6.62533H11.2087C11.5538 6.62533 11.8337 6.3455 11.8337 6.00033C11.8337 5.65515 11.5538 5.37533 11.2087 5.37533H6.62533V0.791992Z"
      fill="#8A8AA3"
    />
  </svg>
);
