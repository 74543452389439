import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";
import * as Yup from "yup";
import { useLogsnag } from "../../../hooks/logsnag";
import { useSupabase } from "../../../hooks/useSupabase";
import { useSwitchWorkspace } from "../../../hooks/useWorkspace";
import { Database } from "../../../types/database.types";
import { LoadingScreen } from "../../Episode/components/FlowComponents/LoadingAsset/LoadingScreen";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
});

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "6px",
    zIndex: 51,
  },
};

Modal.setAppElement("#root");

type WorkSpace = Database["public"]["Tables"]["Workspace"]["Row"];

const RenameWorkspaceModal = ({
  workspace,
  setShow,
  show,
}: {
  workspace: WorkSpace;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}) => {
  const supabase = useSupabase();

  const [progressTitle, setProgressTitle] = useState("");
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  const [newName, setNewName] = useState(workspace.title);

  const switchWorkspace = useSwitchWorkspace();

  const logsnag = useLogsnag();

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const session = await supabase.auth.getSession();

    if (!workspace?.id) return;

    setSubmitting(true);

    await supabase
      .from("Workspace")
      .update({ title: values.title })
      .eq("id", workspace.id);
    await switchWorkspace(workspace.id, true);

    setSubmitting(false);
  };

  return (
    <div className="w-full h-full ">
      <Modal
        isOpen={show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setShow(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Formik
          initialValues={{
            title: workspace.title,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div
                className=" max-w-[300px] min-h-[250px] md:min-w-[750px] md:min-h-[350px] px-8 "
                role="document"
              >
                {showLoadingScreen ? (
                  <LoadingScreen displayText={progressTitle} />
                ) : (
                  <>
                    <div className="mt-4">
                      <p className="block font-semibold mb-2 w-full text-center text-20">
                        Change Team Name
                      </p>
                      {/* <p className="w-full text-center font-normal text-14 mt-2">
                        Give a descriptive name t <br />
                        content assets
                      </p> */}
                      <div className="mt-16 ">
                        <span className="text-12 font-medium">
                          New Team Name
                        </span>
                        <Field
                          type="text"
                          name="title"
                          id="title"
                          placeholder="Unicorns"
                          defaultValue={workspace.title}
                          className="appearance-none mt-2 border border-gray-400 rounded-xl text-gray-900 w-full p-3 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                    </div>

                    <div className="mt-24 w-full flex justify-center items-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className=" disabled:bg-gray-500 bg-uf-yellow-1 text-14 font-semibold text-[#202020] py-3 px-4 rounded-xl focus:outline-none focus:shadow-outline w-full"
                      >
                        Rename
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default RenameWorkspaceModal;
