import { Route, Routes } from "react-router-dom";
import React from "react";
import { ResetPwdPage } from "./components/ResetPwdPage";

export const ResetPwd = () => {
  return (
    <Routes>
      <Route path="/reset" element={<ResetPwdPage />} />
    </Routes>
  );
};

export default ResetPwd;
