import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";
import { PENDING_STATUSES } from "../../utils/queries";
import { BinIcon } from "../../shared/Icons/Bin";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "6px",
    zIndex: 51,
  },
};

Modal.setAppElement("#root");
const RemoveUpload = ({
  onConfirm,
  role,
}: {
  onConfirm: () => void;
  role: string;
}) => {
  // show useState
  const [show, setShow] = useState(false);

  const disable = role === "reader";

  return (
    <>
      <button
        className="p-1 disabled:cursor-not-allowed "
        disabled={disable}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          setShow(true);
        }}
      >
        <BinIcon className="w-4 h-4" />
      </button>
      <Modal
        isOpen={show}
        // onAfterOpen={afterOpenModal}
        onRequestClose={(e) => {
          setShow(false);
          e.stopPropagation();
          e.preventDefault();
        }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className="relative max-w-[300px] min-h-[300px] md:min-w-[600px] md:min-h-[350px] px-8 pb-8 pt-4 rounded-xl overflow-hidden flex justify-center items-center items-center "
          role="document"
        >
          <div className="w-full h-full  space-y-24 flex flex-col justify-between items-center whitespace-pre-line">
            <p className="text-20 font-semibold text-uf-slate-1 ">
              Remove Episode
            </p>

            <p className="text-16 text-uf-slate-1 text-center fon">
              This action is irreversible. Are you sure you want to continue?
            </p>

            <div className="flex justify-between w-full px-10">
              <button
                type="button"
                className="px-8 py-2 text-14  bg-white border border-black/20 text-[#202020] rounded-md font-medium"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setShow(false);
                  onConfirm();
                }}
              >
                Remove
              </button>
              <button
                type="button"
                className="px-8 py-2 text-14 rounded-md bg-uf-yellow-1 text-black font-medium"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setShow(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RemoveUpload;
