import React, { useEffect, useState } from "react";
import { CircleCheckmark } from "../../../shared/Icons/CircleCheckmark";
import { PlusIcon } from "@iconicicons/react";
import { InfoIcon } from "../../../shared/Icons/Info";
import { ASSET_ID_TO_TEMPLATE_PILL_LABEL, PLATFORM_COLORS } from "../utils";
import { addAlpha } from "../utils";
import { useSupabase } from "../../../hooks/useSupabase";
import { TemplateDbType } from "../../../types";
import { Link, useParams } from "react-router-dom";
import { BackIcon } from "../../../shared/Icons/Back";
import { usePostHog } from "posthog-js/react";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { useWorkspace } from "../../../hooks/useWorkspace";

const SkeletonLoader: React.FC = () => (
  <div className="max-w-5xl mx-auto p-6 animate-pulse">
    <div className="mt-5">
      <div className="h-10 w-32 bg-gray-200 rounded-full mb-6"></div>
    </div>
    <div className="flex justify-between items-start space-x-20 w-full">
      <div className="w-full max-w-2xl">
        <div className="h-8 w-3/4 bg-gray-200 rounded mb-4 mt-8"></div>
        <div className="h-4 w-full bg-gray-200 rounded mb-6"></div>
        <div className="h-64 w-full bg-gray-200 rounded-lg"></div>
      </div>
      <div className="h-10 w-40 bg-gray-200 rounded-lg"></div>
    </div>
  </div>
);

const TemplateDetails: React.FC = () => {
  const supabase = useSupabase();
  const { id } = useParams<{ id: string }>();
  const posthog = useAnalytics();
  const [isSelected, setIsSelected] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [template, setTemplate] = useState<TemplateDbType>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const workspace = useWorkspace();
  useEffect(() => {
    const fetchTemplate = async () => {
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from("Template")
          .select("*")
          .eq("id", id as string)
          .eq("workspace_id", workspace?.id as string)
          .single();

        if (error) throw error;
        setTemplate(data);
        setIsSelected(data.is_enabled || false);
      } catch (err) {
        console.error("Error fetching template:", err);
        setError("Failed to load template. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchTemplate();
    }
  }, [id, supabase, workspace]);

  const handleTemplateSelection = async () => {
    if (!template) return;

    setIsUpdating(true);
    try {
      const { error, data } = await supabase
        .from("Template")
        .update({ is_enabled: !isSelected })
        .eq("id", template.id)
        .eq("workspace_id", template.workspace_id as string);

      if (error) {
        alert("Failed to update template. Please try again.");
      }

      if (isSelected) {
        posthog?.addToDefaultTemplates({
          assetType: template.asset_id as string,
          isUnifireTemplate: Boolean(template.fixture_id) as boolean,
          platformType: template.asset_id
            ?.split("_")?.[0]
            ?.toLowerCase() as string,
          templateTitle: template.title as string,
        });
      }

      setIsSelected(!isSelected);
      setTemplate({ ...template, is_enabled: !isSelected });
    } catch (err) {
      console.error("Error updating template:", err);
      setError("Failed to update template. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  if (isLoading) return <SkeletonLoader />;
  if (error)
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  if (!template?.title || !template?.asset_id)
    return <div className="text-center mt-8">Template not found</div>;

  const colors = PLATFORM_COLORS[template.asset_id.split("_")[0].toLowerCase()];

  return (
    <div className="animate-slide-in max-w-5xl mx-auto p-6 flex flex-col justify-start items-start font-inter">
      <Link to="/dashboard/templates">
        <span className="mt-3">
          <BackIcon />
        </span>
      </Link>

      <div className="mt-5">
        <div className="flex justify-between items-center mb-6">
          <span
            className="px-4 py-2 border rounded-full text-sm font-medium"
            style={{
              borderColor: addAlpha(colors?.text, 0.5),
              color: colors?.text,
              backgroundColor: addAlpha(colors?.text, 0.08),
            }}
          >
            {ASSET_ID_TO_TEMPLATE_PILL_LABEL()[template.asset_id]}
          </span>
        </div>
      </div>
      <div className="flex justify-between items-start space-x-20 w-full">
        <div className="w-full max-w-2xl">
          <h1 className="text-lg leading-6 font-semibold text-[#121217] mb-4 mt-8">
            {template.title}
          </h1>

          <p className="text-14 text-[#6C6C89] mb-6">{template.overview}</p>

          <div className="bg-white border border-gray-200 rounded-lg px-8 py-10 w-full">
            <pre className="whitespace-pre-wrap text-sm leading-5 font-normal text-[#121217]">
              <div
                dangerouslySetInnerHTML={{ __html: template.examples ?? "" }}
              />
            </pre>
          </div>
        </div>

        <div>
          {isSelected ? (
            <div className="relative flex flex-row justify-start items-center min-w-max space-x-4 w-full max-w-2xl">
              <button
                className="inline-flex items-center px-2 py-2 text-[#2DCA72] border border-[#2DCA72] text-sm font-medium rounded-lg space-x-2 hover:bg-[#f9fffc] transition duration-300"
                onClick={handleTemplateSelection}
                disabled={isUpdating}
              >
                <CircleCheckmark />
                <span>Used in Generations</span>
              </button>
              <div className="relative">
                <button
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <InfoIcon className="w-5 h-5 text-[#2DCA72]" />
                </button>
                {showTooltip && (
                  <div className="absolute top-full right-0 ">
                    <div className="w-3 h-3 bg-[#2DCA72] transform rotate-45 absolute -top-[3px] right-1"></div>
                    <span className="block w-64 bg-[#2DCA72] text-white p-3 rounded-lg text-sm">
                      This template is used by default in every{" "}
                      {ASSET_ID_TO_TEMPLATE_PILL_LABEL()[template.asset_id]}{" "}
                      generation.
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="relative flex flex-row justify-start items-center min-w-max space-x-4 w-full max-w-2xl">
              <button
                className={`px-4 py-2 flex items-center transition duration-300 bg-white text-[#D50B3E] border-2 border-[#FBB1C4] hover:bg-pink-50 text-sm font-medium rounded-lg space-x-2 disabled:cursor-wait`}
                onClick={handleTemplateSelection}
                disabled={isUpdating}
              >
                <PlusIcon className="w-5 h-5 text-[#D50B3E]" />
                <span>Use this template</span>
              </button>
              <div className="relative">
                <button
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <InfoIcon className="w-5 h-5 text-[#FF5698]" />
                </button>
                {showTooltip && (
                  <div className="absolute top-full right-0 ">
                    <div className="w-3 h-3 bg-[#FF5698] transform rotate-45 absolute -top-[3px] right-1"></div>
                    <span className="block w-64 bg-[#FF5698] text-white p-3 rounded-lg text-sm">
                      Apply this template to your next{" "}
                      {ASSET_ID_TO_TEMPLATE_PILL_LABEL()[template.asset_id]}{" "}
                      generation.
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateDetails;
