export const PenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6457 13.6458H9.604M5.24984 12.7292L1.354 13.6458L2.27067 9.75001L10.7892 1.23153C11.1471 0.873548 11.7275 0.873548 12.0855 1.23153L13.7683 2.91433C14.1263 3.27231 14.1263 3.85271 13.7683 4.21069L5.24984 12.7292Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
