import { usePostHog } from "posthog-js/react";
import { useCallback } from "react";
import {
  ASSETS_IDS,
  UPLOAD_CATEGORIES,
} from "../services/Episode/components/types";
import { Database } from "../types/database.types";
import { useAuthUserId } from "./useAuth";
import { useRemainingCredits } from "./useRemainingCredits";
import { useSupabase } from "./useSupabase";
import { RoleType } from "./userCanEdit";

// Define a type for the shared input structure
type ContentGenerationEventInput = {
  assetType: ANALYTICS_TYPES["assetType"];
  platformType: ANALYTICS_TYPES["platformType"];
  numberOfAssets: number;
  creditsSpent: number;
  remainingCredits: number;
  customInstructions: string;
  userRole: ANALYTICS_TYPES["userRole"];
};

export type ANALYTICS_TYPES = {
  mediaType: "video" | "audio" | "text";
  uploadMethod: "youtube" | "rss" | "manual" | "google";
  userRole: "owner" | "admin" | "reader" | "editor" | "anon";
  signupMethod: "google" | "email";
  subscriptionType: "paid" | "free";
  platformType:
    | "linkedin"
    | "twitter"
    | "blog"
    | "podcast"
    | "youtube"
    | "newsletter"
    | "transcript"
    | "article"
    | "education"
    | "meeting";
  assetType: (typeof ASSETS_IDS)[keyof typeof ASSETS_IDS];

  shareType: "link" | "email";
};

export type SUBCATEGORY_CONSTANTS = typeof UPLOAD_CATEGORIES;

export const useAnalytics = () => {
  const posthog = usePostHog();

  const supabase = useSupabase();

  const userId = useAuthUserId();

  const { ownerCredits } = useRemainingCredits();

  // identifyUser
  const identifyUser = useCallback(
    async (userId: string) => {
      if (!userId) return;
      const { data: userProfile } = await supabase
        .from("UserProfile")
        .select("*")
        .eq("id", userId)
        .limit(1)
        .single();
      posthog?.identify(userProfile?.id as string, {
        ...userProfile,
      });
      (window as any).$crisp?.push(["set", "user:email", [userProfile?.email]]);
      (window as any).$crisp.push([
        "set",
        "user:nickname",
        [userProfile?.full_name],
      ]);

      // remove undefined and null from user object
    },
    [posthog, supabase]
  );

  const landedOnApp = () =>
    posthog?.capture("Landed On App", {
      source: document.referrer,
    });

  const loginSelected = (loginMethod: ANALYTICS_TYPES["signupMethod"]) => {
    posthog?.capture("Login Selected", {
      loginMethod,
      loginDate: new Date().toISOString(),
    });
  };

  const loginSuccessful = (loginMethod: ANALYTICS_TYPES["signupMethod"]) => {
    posthog?.capture("Login Successful", {
      loginMethod,
    });
  };
  const newUploadStarted = ({
    workspaceId,
    userRole,
    uploadMethod,
    mediaType,
  }: {
    userRole: ANALYTICS_TYPES["userRole"];
    workspaceId: string;
    uploadMethod: ANALYTICS_TYPES["uploadMethod"];
    mediaType: ANALYTICS_TYPES["mediaType"];
  }) => {
    posthog?.capture("New Upload Started", {
      workspaceId,
      userRole,
      uploadMethod,
      mediaType,
    });
  };

  const newUploadSuccessful = async ({
    ownerId,
    workspaceId,
    userRole,
    uploadMethod,
    uploadLength,
    fileType,
    uploadWaitDuration,
    uploadId,
  }: {
    ownerId: string;
    workspaceId: string;
    userRole: ANALYTICS_TYPES["userRole"];
    uploadMethod: ANALYTICS_TYPES["uploadMethod"];
    uploadLength: number;
    fileType: string;
    uploadWaitDuration: number;
    uploadId: string;
  }) => {
    const { count } = await supabase
      .from("Upload")
      .select("*", { count: "exact" })
      .eq("workspace_id", workspaceId);

    const uploadProject = await supabase
      .from("Upload")
      .select("*, Project(*), Workspace(*)")
      .eq("id", uploadId)
      .limit(1)
      .single();

    const ownerProfile = await supabase
      .from("UserProfile")
      .select("*, Billing(*)")
      .eq("id", ownerId)
      .limit(1)
      .single();

    return posthog?.capture("New Upload Successful", {
      workspaceId,
      userRole,
      uploadMethod,
      fileType,
      uploadWaitDuration,
      uploadId,
      uploadLength,
      subscriptionType:
        ownerProfile.data?.Billing?.subscription_status === "active"
          ? "paid"
          : "free",
      remainingCredits: ownerCredits,
      language: (uploadProject.data?.Project as any)[0]?.lang_tag,
      ...(count === 0 && { firstUploadSuccessful: new Date().toISOString() }),
    });
  };

  const uploadFailed = useCallback(
    ({
      uploadMethod,
      uploadLength,
      message,
    }: {
      uploadMethod: ANALYTICS_TYPES["uploadMethod"];
      uploadLength: number;
      message: string;
    }) => {
      posthog?.capture("Upload Failed", {
        uploadMethod,
        uploadLength,
        message,
      });
    },
    [posthog, userId]
  );

  const newProjectCreated = async ({
    projectId,
    role,
  }: {
    projectId: string;
    role: ANALYTICS_TYPES["userRole"];
  }) => {
    const project = await supabase
      .from("Project")
      .select("*")
      .eq("id", projectId)
      .limit(1)
      .single();

    const allProjects = await supabase
      .from("Project")
      .select("*")
      .eq("workspace_id", project.data?.workspace_id as string);

    posthog?.capture("New Project Created", {
      userRole: role,
      language: project.data?.lang_tag,
      projectTitle: project.data?.title,
      assetsSelected: [],
      numberOfProjects: allProjects.data?.length,
    });
  };

  const userEditedAsset = ({
    workspaceId,
    userRole,
    uploadId,
    assetType,
    platformType,
  }: {
    workspaceId: string;
    userRole: RoleType;
    uploadId: string;
    assetType: ANALYTICS_TYPES["assetType"];
    platformType: ANALYTICS_TYPES["platformType"];
  }) => {
    posthog?.capture("User Edited Asset", {
      workspaceId,
      userRole,
      uploadId,
      assetType,
      platformType,
    });
  };

  // New function for Transcript Edited event
  const transcriptEdited = (inp: {
    workspaceId: string;
    uploadId: string;
    userRole: ANALYTICS_TYPES["userRole"];
  }) => {
    posthog?.capture("Transcript Edited", {
      ...inp,
    });
  };

  // New function for Generate Content Intent event
  const generateContentIntent = (inp: {
    workspaceId: string;
    uploadId: string;
    userRole: ANALYTICS_TYPES["userRole"];
    assetTypes: ANALYTICS_TYPES["assetType"][];
  }) => {
    posthog?.capture("Generate Content Intent", {
      ...inp,
    });
  };

  // New function for Generate Content Started event
  const generateContentStarted = (input: ContentGenerationEventInput) => {
    posthog?.capture("Generate Content Started", {
      ...input,
    });
  };

  // New function for Content Asset Skipped event
  const contentAssetSkipped = (input: ContentGenerationEventInput) => {
    posthog?.capture("Content Asset Skipped", {
      ...input,
    });
  };

  // New function for Content Generation Successful event
  const contentGenerationSuccessful = (input: ContentGenerationEventInput) => {
    posthog?.capture("Content Generation Successful", {
      ...input,
    });
  };

  // New function for Content Generation Failed event
  const contentGenerationFailed = (input: ContentGenerationEventInput) => {
    posthog?.capture("Content Generation Failed", {
      ...input,
    });
  };

  // User clicks on Create New Workspace	New Workspace Created	userId
  // newWorkspaceId
  // teamSize	3, 2, 2004
  const createNewWorkspace = (input: {
    newWorkspaceId: string;
    teamSize: number;
  }) => {
    posthog?.capture("New Workspace Created", {
      userId,
      ...input,
    });
  };

  const addToDefaultTemplates = (input: {
    templateTitle: string;
    assetType: ANALYTICS_TYPES["assetType"];
    platformType: string;
    isUnifireTemplate: boolean;
  }) => {
    posthog?.capture("Added To Default Templates", {
      userId,
      ...input,
    });
  };

  const createdTemplate = (input: {
    templateTitle: string;
    assetType: ANALYTICS_TYPES["assetType"];
    platformType: string;
    isUnifireTemplate: boolean;
  }) => {
    posthog?.capture("Created Template", {
      userId,
      ...input,
    });
  };

  const resetAtLogout = useCallback(() => {
    posthog?.reset();
  }, [posthog]);

  return {
    identifyUser,
    landedOnApp,
    loginSelected,
    loginSuccessful,
    newUploadStarted,
    newUploadSuccessful,
    userEditedAsset,
    resetAtLogout,
    uploadFailed,
    newProjectCreated,
    transcriptEdited,
    generateContentIntent,
    generateContentStarted,
    contentAssetSkipped,
    contentGenerationSuccessful,
    contentGenerationFailed,
    createNewWorkspace,
    addToDefaultTemplates,
    createdTemplate,
  };
};
