import React, { useState } from "react";
import { useSupabase } from "../../../hooks/useSupabase";
import { PlusIcon } from "../../../shared/Icons/Plus";
import { LibraryModal } from "../../../shared/Library/Modal";
import { MinusIcon } from "../assets/MinusIcon";
import { addSeat } from "../utils";

interface SeatPaymentProps {
  currentSeats: number;
  pricePerSeat: number;
  onSuccess: (newSeats: number) => void;
  priceId: string;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const SeatPayment: React.FC<SeatPaymentProps> = ({
  currentSeats,
  pricePerSeat,
  onSuccess,
  priceId,
  setShowModal,
  showModal,
}) => {
  const [additionalSeats, setAdditionalSeats] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const supabase = useSupabase();

  const handleIncrement = () => {
    setAdditionalSeats((prev) => prev + 1);
  };

  const handleDecrement = () => {
    setAdditionalSeats((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);

    const seats = Array.from({ length: additionalSeats }, () => ({
      price_id: priceId,
    }));

    try {
      await addSeat({
        seats,
        supabase,
      });
      onSuccess(currentSeats + additionalSeats);
      window.location.reload();
      setShowModal(false);
    } catch (err) {
      setError(
        "An error occurred while processing your request. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LibraryModal setShow={setShowModal} show={showModal}>
      <div className="px-6 py-10 lg:min-w-[909px] flex flex-col justify-center items-center">
        <div className="w-full flex justify-center items-center text-center">
          <div className="max-w-[420px]">
            <p className="text-xl leading-8 font-semibold text-gray-900">
              Add Seats
            </p>
            <p className="text-lg leading-6 font-normal text-[#6C6C89] mt-2">
              You currently are using {currentSeats + 1}/{currentSeats + 1}{" "}
              seats that you are paying for, you can add more seats below.
            </p>
          </div>
        </div>

        <div className="lg:max-w-[758px] w-full mt-[61px]">
          <div className="mb-6 flex justify-between items-center w-full pl-4">
            <div>
              <h3 className="text-base leading-8 font-semibold text-gray-900">
                Add Seats
              </h3>
              <p className="text-sm leading-6 font-normal text-[#6C6C89] mb-4">
                Each additional seat is ${pricePerSeat}/mo
              </p>
            </div>

            <div className="flex items-center rounded-lg border border-gray-300 px-1.5 text-14 text-[#121217]">
              <button
                onClick={handleDecrement}
                className="h-7 w-7 text-[#8A8AA3] flex justify-center items-center"
              >
                <MinusIcon />
              </button>
              <span className="h-full font-medium w-5 text-center">
                {additionalSeats}
              </span>
              <button
                onClick={handleIncrement}
                className="h-7 w-7 text-[#8A8AA3] flex justify-center items-center"
              >
                <PlusIcon />
              </button>
            </div>
          </div>

          <div className="bg-gray-100  p-4 rounded-t-2xl">
            <div className="flex justify-between items-center">
              <h3 className="text-base leading-8 font-semibold text-gray-900">
                Summary
              </h3>
              <span className="text-base leading-8 font-medium text-gray-900">
                + €{additionalSeats * pricePerSeat}/mo
              </span>
            </div>
            <p className="text-sm leading-6 font-normal text-[#6C6C89]">
              You are adding {additionalSeats} extra seat
              {additionalSeats > 1 ? "s" : ""} today.
            </p>
            <div className="h-20" />
          </div>

          <div className="bg-gray-100 p-4 rounded-b-2xl mb-6 border-t ">
            <div className="flex justify-between items-center">
              <h3 className="text-xl leading-7 font-semibold text-black">
                Total Due Today
              </h3>
              <span className="text-xl leading-7 font-semibold text-black">
                €{(additionalSeats * pricePerSeat).toFixed(2)}
              </span>
            </div>
            <p className="text-base leading-8 font-normal text-[#6C6C89] text-right">
              Total exclusive of tax
            </p>
          </div>

          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
              role="alert"
            >
              <span className="block text-lg leading-6 font-normal">
                {error}
              </span>
            </div>
          )}

          <div className="w-full flex justify-end items-center">
            <button
              onClick={handleSubmit}
              disabled={isLoading}
              className={`py-2 px-4 rounded-lg text-xs leading-6 font-semibold w-full max-w-[254px] ${
                isLoading ? "bg-gray-400 cursor-not-allowed" : "bg-uf-yellow-1"
              } transition duration-200`}
            >
              {isLoading ? "Processing..." : "Confirm payment"}
            </button>
          </div>
        </div>
      </div>
    </LibraryModal>
  );
};

export default SeatPayment;
