import * as Sentry from "@sentry/react";
import { Editor } from "@tiptap/react";
import { usePostHog } from "posthog-js/react";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router";
import "./App.css";
import { useAnalytics } from "./hooks/useAnalytics";
import { useRedirectOnAuthEvent } from "./hooks/useAuth";
import Dashboard from "./services/Dashboard";
import { SpeakerLabelType } from "./services/EditableTranscript/components/types";
import { ASSETS_IDS } from "./services/Episode/components/types";
import Login from "./services/Login";
import Onboarding from "./services/Onboarding";
import PublicPage from "./services/PublicSharedAssets";
import ResetPwd from "./services/ResetPwd";
import SendResetPwd from "./services/SendResetPwd";
import Signup from "./services/Signup";
import TrialPage from "./services/Trial";
import WPPricing from "./services/WPPricing";
import Waitlist from "./services/Waitlist";
import { UploadDbType } from "./types";
import { initSentry } from "./utils/common";
import ServerStatusBanner from "./services/Common/ServerStatusBanner";
import axiosRetry from "axios-retry";
import axios from "axios";

axiosRetry(axios, {
  retries: 6, // number of retries
  retryDelay: (retryCount) => {
    // console.log(`retry attempt: ${retryCount}`);
    return 200; // time interval between retries
  },
});

initSentry();

process.env.REACT_APP_GA &&
  ReactGA.initialize(process.env.REACT_APP_GA as string);

function App() {
  useRedirectOnAuthEvent();

  const location = useLocation();
  const postHog = usePostHog();

  const { landedOnApp } = useAnalytics();

  React.useEffect(() => {
    // Google Analytics
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    postHog?.capture("$pageview");
  }, [location, postHog]);

  useEffect(() => {
    landedOnApp();
  }, []);

  return (
    <div className="App ">
      <Sentry.ErrorBoundary>
        <ServerStatusBanner />

        <Dashboard />
        <Login />
        <Onboarding />
        <Signup />
        <ResetPwd />
        <SendResetPwd />
        <Waitlist />
        <PublicPage />
        <TrialPage />
        <WPPricing />
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default App;
