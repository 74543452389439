import { Socket } from "socket.io-client";
import {
  AssetDbType,
  UploadWithSocialPostsAndProject,
} from "../../../../types";
import { AssetPaginator } from "../AssetPaginator";
import { ASSETS_IDS } from "../types";

export const LinkedinResources = ({
  upload,
  selectedSubCategory,
  canEdit,
  socket,
  assets,
}: {
  upload: UploadWithSocialPostsAndProject;
  selectedSubCategory: string;
  canEdit: boolean;
  socket: Socket;
  assets: AssetDbType[];
}): JSX.Element => {
  return (
    <>
      <AssetPaginator
        canEdit={canEdit ?? false}
        upload={upload}
        selectedAssetId={selectedSubCategory}
        isShortForm={true}
        itemsPerPage={6}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.LINKEDIN_POSTS
          ) as AssetDbType
        }
      />
      <AssetPaginator
        canEdit={canEdit ?? false}
        upload={upload}
        selectedAssetId={selectedSubCategory}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.LINKEDIN_CAROUSELS
          ) as AssetDbType
        }
      />
    </>
  );
};

export default LinkedinResources;
