import { Route, Routes } from "react-router-dom";
import React from "react";
import { SignupPage } from "./components/SignupPage";
import AppsumoSignupPage from "./components/AppsumoSignupPage";
import StackSocialSignupPage from "./components/StackSocialSignupPage";

export const Signup = () => {
  return (
    <Routes>
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/signup/appsumo" element={<AppsumoSignupPage />} />
      <Route path="/stacksocial" element={<StackSocialSignupPage />} />
    </Routes>
  );
};

export default Signup;
