import { Editor } from "@tiptap/react";
import React, { useEffect, useRef, useState } from "react";
import { DownloadIcon } from "../../../../../shared/Icons/Donload";
import { parseHtmlToStringWithNewLines } from "../../../../../utils/common";

interface ExportProps {
  editor: Editor | null;
  fileName: string;
  backfall?: string;
}

const Export: React.FC<ExportProps> = ({ editor, fileName, backfall }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleExport = async (format: "docx" | "txt" | "html" | "pdf") => {
    if (!editor && !backfall) return;

    try {
      switch (format) {
        case "docx":
          editor
            ?.chain()
            .focus()
            .export({
              format: "docx",
              onExport: (context) => {
                const { error, download } = context;
                if (error) {
                  console.error("Export error:", error.message);
                  return;
                }
                download();
              },
            })
            .run();
          break;

        case "txt":
          const text = backfall
            ? parseHtmlToStringWithNewLines(backfall)
            : editor?.getText() ?? "";
          downloadFile(text, `${fileName}.txt`, "text/plain");
          break;

        case "html":
          const html = backfall ? backfall : editor?.getHTML() ?? "";
          downloadFile(html, `${fileName}.html`, "text/html");
          break;

        case "pdf":
          // PDF export is handled by the PDFDownloadLink
          break;
      }
    } catch (error) {
      console.error("Export error:", error);
    }

    setIsOpen(false);
  };

  const downloadFile = (
    content: string,
    fileName: string,
    contentType: string
  ) => {
    const blob = new Blob([content], { type: contentType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        <DownloadIcon />
      </button>

      {isOpen && (
        <div className="z-10 absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {!Boolean(backfall) && (
              <button
                onClick={() => handleExport("docx")}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                role="menuitem"
              >
                Export as DOCX
              </button>
            )}

            <button
              onClick={() => handleExport("txt")}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
              role="menuitem"
            >
              Export as TXT
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Export;
