import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

export const Senja = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // check if the script is already loaded
    if ((window as any).iFrameResize) {
      return;
    }

    // Dynamically load the iFrame resizer script
    const script = document.createElement("script");
    script.src = "https://widget.senja.io/js/iframeResizer.min.js";
    script.onload = () => {
      // Once the script is loaded, initialize the iFrame resizer
      (window as any).iFrameResize(
        { log: false, checkOrigin: false },
        "#senja-collector-iframe"
      );
    };
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {loading && (
        <div className="w-full h-full flex justify-center items-start pt-10">
          <div className="h-[500px] w-[1000px] max-w-[50%]">
            <Skeleton className="w-full h-full" />
          </div>
        </div>
      )}
      <iframe
        className={loading ? "opacity-0" : ""}
        id="senja-collector-iframe"
        src="https://senja.io/p/unifireai/r/RM01si?mode=embed&nostyle=true"
        allow="camera; microphone"
        title="Senja form"
        frameBorder="0"
        scrolling="no"
        style={{ width: "100%", height: "700px" }}
        onLoad={() =>
          setTimeout(() => {
            setLoading(false);
          }, 200)
        }
      ></iframe>
    </>
  );
};
