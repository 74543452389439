import { SupabaseClient } from "@supabase/supabase-js";
import { Database } from "../types/database.types";

export const PENDING_STATUSES = ["transcribing", "edit", "writing", "_"];
export const ALPHABET_NUMBER_MAP = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "UNK",
];

const getPagination = (page: number, size: number) => {
  const limit = size ? +size : 3;
  const from = page ? page * limit : 0;
  const to = page ? from + size - 1 : size - 1;

  return { from, to };
};

const RESULTS_PER_SEARCH = 10;

// export const getPaginatedInfluencers = async (
//   supabase: SupabaseClient<Database>,
//   { query: { page, userId } }: { query: { page: number; userId: string } }
// ) => {
//   console.log("paginated userId ", userId);
//   const { from, to } = getPagination(page, RESULTS_PER_SEARCH);
//   const { data, error, count } = await supabase
//     .from("YoutubeInfluencers")
//     .select(
//       `
//     *,
//     SelectedInfluencer!inner(*)
//     `,
//       { count: "exact" }
//     )
//     // .neq("SelectedInfluencer.user_id", userId)
//     .filter("SelectedInfluencer.user_id", "not.is", userId)
//     .order("id", { ascending: true })
//     .range(from, to);

//   console.log("error ", error);

//   console.log(" count ", (count as number) / RESULTS_PER_SEARCH);
//   return {
//     props: {
//       data: data,
//       pageCount: Math.ceil(count as number) / RESULTS_PER_SEARCH,
//       page: +page,
//     },
//   };
// };

// export const fullTextSearch = async (
//   supabase: SupabaseClient<Database>,
//   {
//     query: { page, search, userId },
//   }: { query: { page: number; search: string; userId: string } }
// ) => {
//   const { from, to } = getPagination(page, RESULTS_PER_SEARCH);

//   // split the search input into an array of keywords with single quote between each word
//   const keywords = search
//     .trim()
//     .split(" ")
//     .map((word) => `'${word}'`)
//     .join(" | ");

//   const { data, error, count } = await supabase
//     .from("YoutubeInfluencers")
//     .select(
//       `
//       *,
//       SelectedInfluencer!inner(*)
//     `,
//       { count: "exact" }
//     )
//     .textSearch("keyword", keywords) // write a filter that deletes influencers already present in the SelectedInfluencer table
//     .order("id", { ascending: true })
//     .neq("SelectedInfluencer.user_id", userId)
//     .range(from, to);

//   return {
//     props: {
//       data: data,
//       pageCount: Math.ceil((count as number) / RESULTS_PER_SEARCH),
//       page: +page,
//     },
//   };
// };

// export const fullTextSearch = async (
//   supabase: SupabaseClient,
//   {
//     query: { page, search, userId },
//   }: { query: { page: number; search: string; userId: string } }
// ) => {
//   // split the search input into an array of keywords with single quote between each word
//   const keywords = search
//     .trim()
//     .split(" ")
//     .map((word) => `'${word}'`)
//     .join(" | ");

//   const { data, error } = await supabase.rpc("search_ytb_influencers", {
//     limit_records: RESULTS_PER_SEARCH,
//     offset_records: RESULTS_PER_SEARCH * page,
//     search_text: keywords,
//     user_auth_id: userId,
//   });

//   if (error || !data?.length) {
//     return {
//       props: {
//         data: [],
//         pageCount: 0,
//         page,
//       },
//     };
//   }

//   return {
//     props: {
//       data: data as Database["public"]["Functions"]["search_ytb_influencers"]["Returns"],
//       pageCount: Math.ceil(
//         (data[0].total_count as number) / RESULTS_PER_SEARCH
//       ),
//       page: +page,
//     },
//   };
// };
