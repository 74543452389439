import classNames from "classnames";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useAuthUserId } from "../../../hooks/useAuth";
import { useRemainingCredits } from "../../../hooks/useRemainingCredits";
import { useSupabase } from "../../../hooks/useSupabase";
import { useWorkspace } from "../../../hooks/useWorkspace";
import { useRole } from "../../../hooks/userCanEdit";
import { TeamMemberBinIcon } from "../../../shared/Icons/TeamMemberBin";
import { WarningIcon } from "../../../shared/Icons/Warning";
import { Database } from "../../../types/database.types";
import { ReactComponent as ArrowDownSvg } from "../../Dashboard/assets/arrow-down.svg";
import DeleteUserModal from "./DeleteUserModal";
import InviteMemeberModal from "./InviteMemeberModal";
import { MembersSkeleton } from "./MembersSkeleton";
import SeatPayment from "./SeatPayment";
import { PADDLE_MAP } from "../../../utils/constants";
import { BillingDbType, UserProfileDbType } from "../../../types";

const RoleDropDown = ({
  handleRoleChange,
  currentRole,
  isNotMember,
}: {
  handleRoleChange: (role: string) => void;
  currentRole: string;
  isNotMember?: boolean;
  member?: Database["public"]["Tables"]["Member"]["Row"];
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-32 text-start py-2">
      <div>
        <button
          type="button"
          className={classNames(
            "transition-all overflow-hidden ease flex w-full justify-start items-center gap-x-1.5 rounded-lg bg-white px-3 py-2 border border-1 border-gray-300/60 text-sm text-gray-900  ",
            {
              "delay-200 duration-100": !isOpen,
              "max-h-[1000px] border-b-0 rounded-b-none duration-100": isOpen,
            }
          )}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}
          disabled={isNotMember || currentRole === "owner"}
        >
          <div className="flex items-center justify-start space-x-3">
            <span className="text-start">{currentRole} </span>
            <ArrowDownSvg
              className={classNames({
                "transform rotate-180": isOpen,
              })}
            />
          </div>
        </button>
      </div>
      <div
        className={classNames(
          "transition-all duration-300 ease z-10 w-full origin-top-right rounded-2xl bg-white focus:outline-none",
          {
            "max-h-[0px] overflow-hidden rounded-t-none": !isOpen,
            "max-h-[1000px] overflow-auto border border-1 border-t-0 border-gray-300/60 rounded-t-none":
              isOpen,
          }
        )}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
      >
        <div className="py-1 " role="none">
          <button
            type="button"
            className="w-full text-start px-4 py-2 text-14 hover:bg-gray-100 focus:bg-gray-100"
            role="menuitem"
            onClick={() => {
              setIsOpen(false);
              handleRoleChange("admin");
            }}
          >
            admin
          </button>

          <button
            type="button"
            className="w-full text-start px-4 py-2 text-14 hover:bg-gray-100 focus:bg-gray-100"
            role="menuitem"
            onClick={() => {
              setIsOpen(false);
              handleRoleChange("reader");
            }}
          >
            reader
          </button>

          <button
            type="button"
            className="w-full text-start px-4 py-2 text-14 hover:bg-gray-100 focus:bg-gray-100"
            role="menuitem"
            onClick={() => {
              setIsOpen(false);
              handleRoleChange("editor");
            }}
          >
            editor
          </button>
        </div>
      </div>
    </div>
  );
};

export type WorkSpaceMember = Database["public"]["Tables"]["Member"]["Row"] & {
  UserProfile: UserProfileDbType;
};

export type InvitedMember = Database["public"]["Tables"]["Invite"]["Row"];

const Page = (): JSX.Element => {
  const supabase = useSupabase();

  const workspace = useWorkspace();
  const userId = useAuthUserId();
  const [showSharingForm, setShowSharingForm] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<
    WorkSpaceMember | InvitedMember | null
  >(null);

  const [members, setMembers] = useState<WorkSpaceMember[]>();

  const [invitedMembers, setInvitedMembers] = useState<InvitedMember[]>();

  const ownerGeneralLimits = useRemainingCredits()?.ownerGeneralLimits;

  const [ownerWithBilling, setOwnerWithBilling] = useState<
    UserProfileDbType & { Billing: BillingDbType }
  >();

  const role = useRole();

  useEffect(() => {
    reFetchMembers();
  }, [supabase, workspace?.id]);

  const reFetchMembers = async () => {
    if (!workspace?.id) return;
    const [joinedMembers, invitedMembers] = await Promise.all([
      supabase
        .from("Member")
        .select("*, UserProfile(*)")
        .eq("workspace_id", workspace?.id as string),

      supabase
        .from("Invite")
        .select("*")
        .eq("workspace_id", workspace?.id as string),
    ]);

    const owner = joinedMembers?.data?.find(
      (member) => member.role === "owner"
    );
    const ownerBilling = await supabase
      .from("UserProfile")
      .select("*, Billing(*)")
      .eq("id", owner?.user_id as string)
      .limit(1)
      .single();

    setInvitedMembers(invitedMembers.data as InvitedMember[]);
    setMembers(joinedMembers.data as WorkSpaceMember[]);
    setOwnerWithBilling(
      ownerBilling.data as UserProfileDbType & { Billing: BillingDbType }
    );
  };

  const updateRole = async (role: string, membership: WorkSpaceMember) => {
    if (!membership?.id) return;

    if (role === "owner") {
      alert("You can't change a member to owner");
      return;
    }

    await supabase
      .from("Member")
      .update({ role })
      .eq("id", membership.id)
      .then((data) => {
        reFetchMembers();
      });

    reFetchMembers();
  };

  const canEditMemberLogic = (
    member: WorkSpaceMember,
    myId: string | undefined
  ) => {
    // can't edit myself
    // console.log(member.role);

    // console.log(member.id);
    // console.log(myId);

    if (!myId) return false;

    if (member.user_id === myId) return false;

    // if owner change anyone but owners
    if (role === "owner") {
      return member.role !== "owner";
    }

    //  if admin change anyone but owners
    if (role === "admin") {
      return member.role !== "owner";
    }

    // if editor or reader can't change anyone
    return false;
  };

  const showInviteModal = async () => {
    if (ownerGeneralLimits.planType === "FREE") {
      alert("Your team is on a free plan, you can't add more team members");
    } else if (
      ownerGeneralLimits.planType === "LTD" &&
      ownerGeneralLimits.remainingTeamMembers <= 0
    ) {
      alert("You reached your Appsumo's team seats limits");
    }
    // check if on an old subscription
    else if (ownerGeneralLimits?.remainingTeamMembers < 0) {
      alert(
        "Your plan exceeds the number of team members allowed because your team is on an old subscription\nplease remove every team member before continuing"
      );
      return;
    }
    // Base case: Show if remaininng remaining team members are 0
    else if (ownerGeneralLimits?.remainingTeamMembers === 0) {
      // check if owner has an active subscription or not
      const status = ownerWithBilling?.Billing?.subscription_status;
      if (status === "active" || status === "trialing") {
        setShowPaymentModal(true);
      } else {
        alert(
          "You need an active subscription to add more team members, please subscribe first"
        );
      }
    } else if (ownerGeneralLimits?.remainingTeamMembers > 0) {
      setShowSharingForm(true);
    }
  };

  const handleSuccess = (newSeats: number) => {
    // Handle the success (e.g., update state, show a success message)
  };

  const onDeleted = async () => {
    // here update subscription if relevant
    // updateSubscription()

    // After successful deletion, refresh the members list
    await reFetchMembers();
    setShowDeleteModal(false);
    setDeleteTarget(null);
  };

  const isWithinFreeLimit = ownerGeneralLimits?.remainingTeamMembers >= 0;

  // YEARLY | MONTHLY | 'LTD'
  const subscriptionIntervalType = ownerGeneralLimits.subscriptionIntervalType;
  const seatAmount =
    subscriptionIntervalType === "MONTHLY"
      ? PADDLE_MAP["month"][
          ownerGeneralLimits.planType?.toLowerCase() as keyof (typeof PADDLE_MAP)["month"]
        ]?.amount ?? -1
      : PADDLE_MAP["year"][
          ownerGeneralLimits.planType?.toLowerCase() as keyof (typeof PADDLE_MAP)["year"]
        ]?.amount ?? -1;

  const userName =
    deleteTarget &&
    ("UserProfile" in deleteTarget
      ? deleteTarget.UserProfile?.full_name ?? ""
      : deleteTarget.invitee_email?.split("@")[0] ?? "");

  const totalSeats =
    (ownerWithBilling?.Billing.purchased_team_seats?.length ?? 0) +
    (ownerWithBilling?.Billing.team_members_limit ?? 0) +
    1;

  const consumedSeats = (members?.length ?? 0) + (invitedMembers?.length ?? 0);

  // priceId, choose yearly seat price if is year, or monthly seat price if is month
  let priceId = "";
  if (subscriptionIntervalType === "MONTHLY")
    priceId =
      PADDLE_MAP.month[
        ownerGeneralLimits.planType?.toLowerCase() as keyof (typeof PADDLE_MAP)["month"]
      ]?.seatPriceId;

  if (subscriptionIntervalType === "YEARLY")
    priceId =
      PADDLE_MAP.year[
        ownerGeneralLimits.planType?.toLowerCase() as keyof (typeof PADDLE_MAP)["year"]
      ]?.seatPriceId;

  return (
    <div className="w-full max-w-[880px] pl-10 h-full overflow-x-hidden py-10 px-4 animate-slide-in no-scrollbar">
      <div className="flex justify-between items-center w-full">
        <div>
          <p className="text-18 font-semibold text-[#121217]">
            {workspace?.title} members
          </p>
          <p className="mt-[14px] text-14 font-medium text-[#6C6C89]">
            Manage your workspace members here
          </p>
        </div>

        <button
          className={classNames(
            "mb-2 rounded-lg overflow-clip visible opacity-100 transition-opacity ease-in-out delay-150 duration-300 py-2 px-4 text-14 text-[#202020] font-medium bg-uf-yellow-1",
            {
              "opacity-0 ": role !== "owner" && role !== "admin",
            }
          )}
          onClick={showInviteModal}
        >
          Invite Team Member
        </button>
      </div>
      <div className=" bg-white rounded-xl w-full mt-20 space-y-4 ">
        <span className="text-sm leading-6 font-medium text-[#121217]">
          {consumedSeats}/{totalSeats} Seats
        </span>
        <table className="table-auto block overflow-x-scroll w-full no-scrollbar">
          <thead>
            <tr className="">
              <th className="w-2/3 md:w-1/3 px-4 py-2 text-start text-14 font-medium text-[#6C6C89]">
                Name
              </th>
              <th className="w-2/3 md:w-1/3 px-4 py-2 text-start text-14 font-medium text-[#6C6C89]">
                Email
              </th>

              <th className="w-2/3 md:w-1/3 px-4 py-2 text-start text-14 font-medium text-[#6C6C89]">
                Role
              </th>
              <th className="w-2/3 md:w-1/3 px-4 py-2 text-start text-14 font-medium text-[#6C6C89]">
                Status
              </th>
              <th className="w-2/3 md:w-1/3 px-4 py-2 text-start text-14 font-medium text-[#6C6C89]"></th>
            </tr>
          </thead>
          <tbody className="">
            <div className="mt-2" />
            {members === undefined && invitedMembers === undefined && (
              <MembersSkeleton />
            )}
            {members?.map((member, index) => (
              <tr key={member?.id} className="">
                <td className="border border-x-0 px-4 text-14 font-normal text-[#121217]">
                  <div className=" flex justify-start items-center h-full w-full ">
                    <Avatar
                      className="mr-2"
                      name={member.UserProfile?.full_name ?? ""}
                      round
                      size="24px"
                    />{" "}
                    <span className="line-clamp-1 text-ellipsis">
                      {member.UserProfile?.full_name}
                    </span>
                  </div>
                </td>
                <td className="border border-x-0 px-4 text-14 font-normal text-[#121217] py-5  truncate">
                  {member.UserProfile?.email}
                </td>

                <td className="border border-x-0 px-4  text-14 font-normal text-[#121217] ">
                  {canEditMemberLogic(member, userId) ? (
                    <RoleDropDown
                      currentRole={member.role}
                      handleRoleChange={(newRole) => {
                        updateRole(newRole, member);
                        if (newRole === "owner") {
                          supabase.from("Workspace").insert({
                            owner_id: userId,
                            title: "My team",
                          });
                        }
                      }}
                    />
                  ) : (
                    <span className="">{member.role}</span>
                  )}
                </td>
                <td className="border border-x-0 px-4 text-14 ">
                  <span className="text-sm leading-5 font-medium text-uf-green-1">
                    Member
                  </span>
                </td>
                <td className="border border-x-0 px-4 text-14 font-normal text-[#121217] ">
                  {member.role !== "owner" && (
                    <button
                      className="p-2"
                      onClick={() => {
                        setDeleteTarget(member);
                        setShowDeleteModal(true);
                      }}
                    >
                      <TeamMemberBinIcon />
                    </button>
                  )}
                </td>
              </tr>
            ))}

            {invitedMembers?.map((invited) => (
              <tr key={invited.id} className="">
                <td className="border border-x-0 px-4 text-14 font-normal text-[#121217] ">
                  {invited?.invitee_email?.split("@")[0]}{" "}
                </td>
                <td className="border border-x-0 px-4 text-14 font-normal text-[#121217] py-5 truncate">
                  {invited?.invitee_email}
                </td>

                <td className="border border-x-0 text-14 font-normal text-[#121217] pl-[16px]">
                  <span>{invited?.role}</span>
                </td>
                <td className="border border-x-0 px-4 text-14 font-normal text-[#121217] ">
                  <span className="text-sm leading-5 font-medium text-[#3487EB]">
                    Invited
                  </span>
                </td>
                <td className="border border-x-0 px-4 text-14 font-normal text-[#121217] ">
                  <button
                    className="p-2"
                    onClick={() => {
                      setDeleteTarget(invited);
                      setShowDeleteModal(true);
                    }}
                  >
                    <TeamMemberBinIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="space-y-7 bg-[#FBFCFC] rounded-xl px-6 py-5 mt-36 shadow">
        <div className="flex justify-start items-center">
          <WarningIcon />
          <span className="text-red-700 text-sm leading-6 font-semibold ml-2">
            Danger Zone - Project deletion
            <small className="ml-1 italic text-gray-500">(coming soon)</small>
          </span>
        </div>
        <p className="text-sm leading-5 font-medium text-[#6C6C89]">
          Once you delete your project, there is no going back. Please be
          certain.
        </p>

        <button
          className="text-14 text-white py-2 px-[22px] bg-red-700 rounded-md cursor-not-allowed"
          disabled
        >
          Delete project
        </button>
      </div>
      {showSharingForm && workspace && (
        <InviteMemeberModal
          workspace={workspace as any}
          setShowSharingForm={setShowSharingForm}
          showSharingForm={showSharingForm}
          onInviteSent={async () => {
            reFetchMembers();
          }}
          userId={userId as string}
        />
      )}

      {showPaymentModal && (
        <SeatPayment
          currentSeats={ownerGeneralLimits.teamMembers.reduce(
            (acc, curr) => acc + curr.remainingMembers,
            0
          )}
          pricePerSeat={seatAmount}
          priceId={priceId}
          onSuccess={handleSuccess}
          showModal={showPaymentModal}
          setShowModal={setShowPaymentModal}
        />
      )}
      {deleteTarget && (
        <DeleteUserModal
          userName={userName ?? ""}
          seatAmount={seatAmount}
          onDelete={onDeleted}
          onCancel={() => {
            setShowDeleteModal(false);
            setDeleteTarget(null);
          }}
          isWithinFreeLimit={isWithinFreeLimit}
          deleteTarget={deleteTarget}
          hasActiveSeats={Boolean(
            ownerWithBilling?.Billing?.purchased_team_seats?.length
          )}
          priceId={priceId}
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
        />
      )}
    </div>
  );
};

export default Page;
