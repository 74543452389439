import { AxiosError } from "axios";
import { useCallback, useState } from "react";
import { cancelSubscription } from "../../../utils/paymentv2";
import ConfirmationMessage from "./ConfirmationMessage";
import ConfirmationModal from "./ConfirmationModal";

import classNames from "classnames";
import "react-dropzone-uploader/dist/styles.css";
import { usePaddle } from "../../../hooks/usePaddle";
import { BillingDbType, UserProfileDbType } from "../../../types";

import Cookies from "js-cookie";
import { PADDLE_MAP } from "../../../utils/constants";
import SeatsPricingToolTip from "./SeatsPricingToolTip";
import { CancellationFeedbackModal } from "./CancelationModal";
import { useAuthUserId } from "../../../hooks/useAuth";

export const StarterPlan = ({
  isSubscribing,
  supabase,
  setIsSubscribing,
  me,
  isMonthly,
  userId,
  showTrial,
}: {
  isSubscribing: boolean;
  supabase: any;
  setIsSubscribing: (_: boolean) => void;
  me: UserProfileDbType & { Billing: BillingDbType };
  isMonthly: boolean;
  userId: string;
  showTrial?: boolean | undefined;
}) => {
  const [showCancellationFeedback, setShowCancellationFeedback] =
    useState(false);

  const [cancellationFeedback, setCancellationFeedback] = useState({
    reason: "",
    otherReason: "",
  });
  const paddle = usePaddle();
  const [show, setShow] = useState(false);

  const displayPrice = isMonthly ? 16 : 13;

  const [showTemporaryConfirmation, setshowTemporaryConfirmation] = useState<
    | {
        action: "TO_CANCEL" | "TO_CHANGE_PLAN";
        message: string;
        variantId: string;
      }
    | undefined
  >();

  const showConfirmationMessage = useCallback(
    (ob: {
      action: "TO_CANCEL" | "TO_CHANGE_PLAN";
      message: string;
      variantId: string;
    }) => {
      setshowTemporaryConfirmation(ob);
      setTimeout(() => {
        setshowTemporaryConfirmation(undefined);
      }, 4000);
    },
    []
  );

  return (
    <>
      <div className="w-full h-[778px] overflow-hidden bg-white rounded-md border border-black p-6 justify-self-center">
        <div className="text-16 font-inter font-semibold ">Starter</div>
        <p className="mt-2 text-14 font-medium text-[#84818A] h-[63px]">
          For content teams in need with the most advanced AI models,
          transcription quality and collaboration.
        </p>
        <div className="mt-7">
          <p className="text-[#84818A] text-16 font-semibold ">
            <span className="text-[#000000] text-4xl leading-10 font-semibold">
              €{displayPrice}
            </span>
            <span className="text-[#84818A] font-semibold text-16">/mo</span>
          </p>
          <div className="mt-4">
            <SeatsPricingToolTip price={displayPrice} />
          </div>

          <div className="h-[100px]" />

          <div className="mt-6 flex justify-center">
            <button
              disabled={isSubscribing}
              className={`w-full py-2 font-semibold text-14 rounded-lg focus:outline-none focus:shadow-outline-orange bg-uf-yellow-1 text-[#202020]`}
              hidden={
                me.Billing?.subscription_status === "active" &&
                me.Billing?.plan_name === "SMALL"
              }
              onClick={async () => {
                setIsSubscribing(true);

                paddle?.Checkout.open({
                  customer: {
                    id: me.Billing?.paddle_id ?? undefined,
                    email: undefined as never,
                  },
                  transactionId: undefined as never,
                  items: [
                    {
                      priceId: isMonthly
                        ? PADDLE_MAP.month.small.planPriceId
                        : PADDLE_MAP.year.small.planPriceId,
                      quantity: 1,
                    },
                  ],
                  customData: {
                    user_id: userId as string,
                    ...(Boolean(Cookies.get("affiliate")) && {
                      tolt_referral: Cookies.get("affiliate"),
                    }),
                  },
                });

                setIsSubscribing(false);
              }}
            >
              {showTrial ? "Start my 14-day trial" : "Buy Now"}
            </button>
          </div>
          <div
            className={classNames("flex justify-center", {
              hidden: !(
                me.Billing?.subscription_status === "active" &&
                me.Billing?.plan_name === "SMALL"
              ),
            })}
          >
            <button
              disabled={isSubscribing}
              className={`w-full py-2 rounded-lg focus:outline-none focus:shadow-outline-orange bg-white text-[#42474B] font-semibold text-14 border-[#F2F2F3] border `}
              onClick={async () => {
                setShowCancellationFeedback(true);
              }}
            >
              Cancel subscription
            </button>
          </div>
        </div>

        <hr className="h-px bg-[#DCDBDD] mt-[36px] mb-[26px]" />

        <div className="text-gray-800 space-y-5">
          <p className="text-14 text-[#202020] font-semibold">
            Scale includes:
          </p>

          <p className="text-14 font-medium text-[#42474B]">
            Unlimited Content Generation
          </p>
          <p className="text-14 font-medium text-[#42474B]">
            Unlimited AI Writer Usage
          </p>
          <p className="text-14 font-medium text-[#42474B]">
            2h Upload length / upload
          </p>
          <p className="text-14 font-medium text-[#42474B]">
            300.000 Characters uploaded / month, e.g. transcripts, documents,
            audio & video
          </p>
        </div>
      </div>

      <CancellationFeedbackModal
        isOpen={showCancellationFeedback}
        onClose={() => {
          setShowCancellationFeedback(false);
        }}
        onSubmit={(feedback) => {
          setCancellationFeedback(feedback);
          setShow(true);
        }}
      />

      <ConfirmationModal
        action={"TO_CANCEL"}
        show={show}
        onCancel={() => setShow(false)}
        onConfirm={async () => {
          setShow(false);

          // pop up triggered only on cancelation
          try {
            setIsSubscribing(true);
            const toStartUrl = await cancelSubscription({
              supabase,
              cancelationReason: `[CHOICES]:${cancellationFeedback.reason}\n[OTHER]:${cancellationFeedback.otherReason}`,
            });
            setIsSubscribing(false);
            showConfirmationMessage({
              action: "TO_CANCEL",
              message: "Your custom plan has been cancelled",
              variantId: process.env
                .REACT_APP_LEMON_VARIANT_ENTERPRISE_MONTHLY as string,
            });
            // window.location.reload();
          } catch (e) {
            const axiosError = e as AxiosError;
            alert(axiosError.response?.statusText || "Error");
            setIsSubscribing(false);
          }
        }}
        setShow={setShow}
        variantId={
          process.env.REACT_APP_LEMON_VARIANT_ENTERPRISE_MONTHLY as string
        }
      />

      {showTemporaryConfirmation && (
        <ConfirmationMessage {...showTemporaryConfirmation} />
      )}
    </>
  );
};

export default StarterPlan;
