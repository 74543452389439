import classNames from "classnames";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useAuthUserId } from "../../../hooks/useAuth";
import { useSupabase } from "../../../hooks/useSupabase";
import {
  Workspace,
  useSwitchWorkspace,
  useWorkspace,
} from "../../../hooks/useWorkspace";
import { PenIcon } from "../../../shared/Icons/Pen";
import { Database } from "../../../types/database.types";
import { ReactComponent as ArrowDownSvg } from "../assets/arrow-down.svg";
import { CreateWorkspace } from "./CreateWorkspace";
import RenameWorkspaceModal from "./RenameWorkspaceModal";

type MemberWorkspaces = Database["public"]["Tables"]["Member"]["Row"] & {
  Workspace: Database["public"]["Tables"]["Workspace"]["Row"];
};

const WorkspaceComponent = ({
  workspace,
  hidePen,
  handleDashboardClick,
  onEditWorkspace,
}: {
  workspace: MemberWorkspaces["Workspace"];
  handleDashboardClick: (id: string) => void;
  onEditWorkspace: (_: Workspace) => void;
  hidePen: boolean;
}) => {
  return (
    <div className="h-[32px] flex items-center justify-between w-full outline-none ">
      <div className="flex items-center justify-between">
        <div
          className="flex items-center gap-x-2 cursor-pointer"
          onClick={() => handleDashboardClick(workspace.id)}
        >
          <div className="w-8 h-8 mr-2">
            <Avatar name={workspace?.title ?? ""} round size="32px" />
          </div>
          <div className="flex flex-col justify-center items-start">
            <p className="w-28 text-start text-12 font-medium text-[#202020] truncate">
              {workspace.title}
            </p>
            {/* <p className="w-3/4 truncate text-12 text-[#84818A]">
            {workspace.title}
          </p> */}
          </div>
        </div>
        {!hidePen && (
          <button
            className="p-2"
            onClick={() => {
              onEditWorkspace(workspace as Workspace);
            }}
          >
            <PenIcon className="w-5 h-5" color="#8A8AA3" />
          </button>
        )}
      </div>
    </div>
  );
};

const WorkspaceDropDown = ({
  dropdownOpen,
  dropdownClose,
  disable = false,
}: {
  dropdownOpen: () => void;
  dropdownClose: () => void;
  disable?: boolean;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const supabase = useSupabase();

  const userId = useAuthUserId();

  const localWorkspace = useWorkspace();

  const switchWorkspace = useSwitchWorkspace();

  const [editWorkspace, setEditWorkspace] = useState<Workspace>();

  const [showRenameWorkspace, setShowRenameWorkspace] = useState(false);

  const [personalWorkspaces, setPersonalWorkspaces] = useState(
    [] as MemberWorkspaces["Workspace"][]
  );
  const [invitedWorkspaces, setInvitedWorkspaces] = useState(
    [] as MemberWorkspaces[]
  );
  const [subscriptionStatus, setSubscriptionStatus] = useState<
    "Free" | "Scale"
  >();

  const handleDashboardClick = async (workspaceId: string) => {
    await switchWorkspace(workspaceId, true);
    setIsOpen(false);
  };
  useEffect(() => {
    // dropdown open track
    if (isOpen) {
      dropdownOpen();
    } else {
      dropdownClose();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!userId) return;
    supabase
      .from("Member")
      .select("*, Workspace(*)")
      .eq("user_id", userId)
      .then((res) => {
        setPersonalWorkspaces(
          res.data
            ?.filter(
              (member) =>
                (member.Workspace as MemberWorkspaces["Workspace"]).owner_id ===
                userId
              // && member.workspace_id !== localWorkspace?.id
            )
            .map(
              (member) => member.Workspace as MemberWorkspaces["Workspace"]
            ) ?? []
        );

        setInvitedWorkspaces(
          res.data
            ?.filter(
              (member) =>
                (member.Workspace as MemberWorkspaces["Workspace"]).owner_id !==
                userId
            )
            .map((member) => member as MemberWorkspaces) ?? []
        );
      });
  }, [supabase, userId]);

  useEffect(() => {
    if (!localWorkspace?.owner_id) return;
    supabase
      .from("Billing")
      .select("*")
      .eq("user_profile_id", localWorkspace?.owner_id)
      .limit(1)
      .single()
      .then(({ data, error }) => {
        if (error) return;
        setSubscriptionStatus(
          data?.subscription_status === "active" ||
            Boolean(data?.appsumo_license) ||
            Boolean(data?.ltd_id)
            ? "Scale"
            : "Free"
        );
      });
  }, [localWorkspace?.owner_id, supabase]);

  return (
    <div>
      <div className="w-full text-start overflow-hidden">
        <button
          type="button"
          className={classNames(
            "transition-all overflow-hidden ease flex w-full justify-between items-center space-x-1 bg-white text-sm text-gray-900 outline-none  ",
            {
              "delay-300 duration-100": !isOpen,
              "duration-100": isOpen,
            }
          )}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex justify-start items-center space-x-3">
            <div className="relative">
              <Avatar
                name={localWorkspace?.title ?? "My team"}
                round
                size="40px"
              />
              <div className="absolute right-0 bottom-0 h-3 w-3 bg-[#2DCA72] rounded-full border border-white" />
            </div>

            <p className="w-24 text-start text-14 font-medium text-[#202020] truncate">
              {disable ? "My team" : localWorkspace?.title}
            </p>
          </div>

          <div className="flex justify-end items-start space-x-1.5 transition-all">
            <span
              className={classNames(
                "px-[10px] py-1 bg-[#FFF2EE] rounded-[360px] text-[#B82E00] text-12",
                {
                  invisible: subscriptionStatus === undefined,
                }
              )}
            >
              {subscriptionStatus}
            </span>
            <ArrowDownSvg />
          </div>
        </button>

        <div
          className={classNames(
            "transition-all ease z-10 w-full origin-top-right rounded-2xl bg-white outline-none",
            {
              "max-h-0 overflow-hidden duration-200": !isOpen,
              "max-h-[1000px] duration-500": isOpen,
            }
          )}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="" role="none">
            <p className="text-xs leading-4 font-medium text-[#6C6C89] my-[18px]">
              My teams
            </p>

            <div className="pl-[3px] space-y-[14px]">
              {personalWorkspaces.map((workspace) => (
                <WorkspaceComponent
                  key={workspace.id}
                  workspace={workspace}
                  hidePen={false}
                  handleDashboardClick={() =>
                    handleDashboardClick(workspace.id)
                  }
                  onEditWorkspace={(workspace) => {
                    setEditWorkspace(workspace);
                    setShowRenameWorkspace(true);
                  }}
                />
              ))}
            </div>

            {invitedWorkspaces.length > 0 && (
              <p className="text-xs leading-4 font-medium text-[#6C6C89] my-[18px]">
                Joined teams
              </p>
            )}
            <div className="pl-[3px] space-y-[14px]">
              {invitedWorkspaces.map((membership) => (
                <WorkspaceComponent
                  key={membership.workspace_id}
                  workspace={membership.Workspace}
                  hidePen={true}
                  handleDashboardClick={() =>
                    handleDashboardClick(membership.workspace_id as string)
                  }
                  onEditWorkspace={(workspace) => {
                    setEditWorkspace(workspace);
                    setShowRenameWorkspace(true);
                  }}
                />
              ))}
            </div>

            <CreateWorkspace />

            {/* <button className="mt-[14px] pl-[3px] h-[32px] flex justify-start items-center space-x-4 text-xs leading-4 font-normal text-[#6C6C89]">
              <div className="flex justify-center items-center w-8 h-8">
                <PlusCircle />
              </div>
              <span>Create Workspace</span>
            </button> */}
          </div>
        </div>
      </div>

      {/* rename workspace */}
      {showRenameWorkspace && editWorkspace && (
        <RenameWorkspaceModal
          workspace={editWorkspace}
          setShow={setShowRenameWorkspace}
          show={showRenameWorkspace}
        />
      )}
    </div>
  );
};

export default WorkspaceDropDown;
