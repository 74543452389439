import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

type NavProps = {
  icon?: any;
  path: string;
  title: string;
  className?: React.HTMLAttributes<HTMLButtonElement>["className"];
  blank?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
};
export const NavLink = ({
  className,
  icon,
  path,
  title,
  blank,
  disabled,
}: NavProps) => {
  const { pathname } = useLocation();

  //hacky way to open to new path
  const forceNewWindow = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (blank) {
        const win = window.open(path, "_blank", "noopener,noreferrer");
        win?.focus();
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [blank, path]
  );
  return (
    <Link
      to={
        path?.includes("discord") || path?.includes("forms") ? pathname : path
      }
      className={`
        ${className && className}
        ml-1
        flex
        justify-start 
        items-center 
        space-x-2 
        rounded-xl
        ease-in 
        duration-150 
        text-14
        font-normal
        text-[#121217]
        
        `}
      onClick={(e) => forceNewWindow(e)}
    >
      <div> {icon}</div>
      <span className="break-all">{title}</span>
    </Link>
  );
};

export default NavLink;
