import ReactPaginate from "react-paginate";
import { ReactComponent as PaginationArrow } from "../assets/pagination-arrow.svg";

export const RenderPagination = ({
  forcedPage,
  handlePageClick,
  pageCount,
}: {
  handlePageClick: (event: { selected: number }) => void;
  pageCount: number;
  forcedPage: number;
}) => (
  <div className="flex justify-end items-center w-full">
    <ReactPaginate
      className="flex justify-start items-center space-x-4 shadow py-3 px-4 rounded-xl "
      breakLabel="..."
      pageRangeDisplayed={1}
      marginPagesDisplayed={1}
      nextLabel={<PaginationArrow className="w-6 h-6 drop-shadow rotate-0" />}
      // activeClassName="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white text-white rounded-full w-7 h-7 p-0"
      activeLinkClassName="bg-gray-600 text-white text-white rounded-full px-2"
      pageLinkClassName="px-2"
      onPageChange={handlePageClick}
      pageCount={pageCount}
      previousLabel={<PaginationArrow className="w-6 h-6 drop-shadow" />}
      forcePage={forcedPage}
    />
  </div>
);
