export const HindiFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4129_32003)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#F0F0F0"
      />
      <path
        d="M12 -0.000976562C7.24248 -0.000976562 3.13173 2.7677 1.19073 6.78165H22.8092C20.8683 2.7677 16.7575 -0.000976562 12 -0.000976562Z"
        fill="#FF9811"
      />
      <path
        d="M12 23.9994C16.7575 23.9994 20.8683 21.2307 22.8093 17.2168H1.19073C3.13173 21.2307 7.24248 23.9994 12 23.9994Z"
        fill="#6DA544"
      />
      <path
        d="M12.0004 16.173C14.3055 16.173 16.1743 14.3043 16.1743 11.9991C16.1743 9.69391 14.3055 7.8252 12.0004 7.8252C9.69519 7.8252 7.82648 9.69391 7.82648 11.9991C7.82648 14.3043 9.69519 16.173 12.0004 16.173Z"
        fill="#0052B4"
      />
      <path
        d="M12.0001 14.608C13.4408 14.608 14.6088 13.44 14.6088 11.9993C14.6088 10.5586 13.4408 9.39062 12.0001 9.39062C10.5594 9.39062 9.39142 10.5586 9.39142 11.9993C9.39142 13.44 10.5594 14.608 12.0001 14.608Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.0004 8.78125L12.8052 10.6065L14.7882 10.3908L13.6099 12.0003L14.7882 13.6099L12.8052 13.3942L12.0004 15.2194L11.1956 13.3942L9.21259 13.6098L10.3908 12.0003L9.21259 10.3908L11.1956 10.6065L12.0004 8.78125Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_4129_32003">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
