import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";

Modal.setAppElement("#root");
export const LibraryModal = ({
  setShow,
  show,
  children,
}: {
  setShow: (show: boolean) => void;
  show: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Modal
      isOpen={show}
      // onAfterOpen={afterOpenModal}
      onRequestClose={(e) => {
        setShow(false);
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          borderRadius: "6px",
          zIndex: 51,
        },
      }}
      contentLabel="Example Modal"
    >
      <div
        className="max-h-[90vh] overflow-y-auto no-scrollbar relative min-h-[300px] min-w-[300px] sm:min-w-[400px] md:min-w-[600px] md:min-h-[350px] rounded-xl"
        role="document"
      >
        {children}
      </div>
    </Modal>
  );
};
