import { useEffect, useState } from "react";
import { useSupabase } from "../../hooks/useSupabase";
import { useAuthUserId } from "../../hooks/useAuth";
import { useLocation } from "react-router-dom";

function hexToRGB(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export const ServerStatusBanner = () => {
  const supabase = useSupabase();

  const [bannerId, setBannerId] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [backgroundColor, setBackgroundColor] = useState<string>();
  const [link, setLink] = useState<string>();
  const userId = useAuthUserId();
  const [show, setShow] = useState(false);

  // storage
  const [isClosed, setIsClosed] = useState(true);
  const location = useLocation();
  useEffect(() => {
    supabase
      .from("ServerStatus")
      .select("*")
      .limit(1)
      .single()
      .then(({ data }) => {
        setBannerId(data?.id as string);
        if (data?.is_appsumo_conditional === true) {
          supabase
            .from("UserProfile")
            .select("*, Billing(*)")
            .eq("id", userId as string)
            .limit(1)
            .single()
            .then(({ data: profileData }) => {
              const shouldDisplay =
                Boolean(profileData?.Billing?.appsumo_license) === false &&
                Boolean(data?.show) &&
                !location.pathname.includes("trial") &&
                !location.pathname.includes("onboarding") &&
                // remove this too when we are using frame and it's public
                (window?.location.href.includes("app.unifire.ai") ||
                  window?.location.href.includes("localhost"));

              setShow(shouldDisplay);
              setMessage(data?.message as string);
              setBackgroundColor(data?.color as string);
              setLink(data?.link as string);
              setIsClosed(localStorage.getItem(data?.id as string) === "true");
            });
        } else {
          setShow(Boolean(data?.show));
          setMessage(data?.message as string);
          setBackgroundColor(data?.color as string);
          setLink(data?.link as string);
          setIsClosed(localStorage.getItem(data?.id as string) === "true");
        }
      });
  }, [supabase, userId, location]);

  const handleClose = () => {
    setIsClosed(true);
    localStorage.setItem(bannerId ?? "", "true");
  };

  if (!show || isClosed) {
    return <></>;
  }

  return (
    <div
      className="text-14 transition-all opacity-100 w-full h-[45px] flex justify-start items-center  sticky overflow-hidden z-10"
      style={{
        backgroundColor: hexToRGB(backgroundColor as string, 0.5),
      }}
    >
      <div className="h-full w-[6px]" />

      <div className="w-full h-full flex justify-center items-center">
        <div className="flex justify-start items-center">
          <p className="text-14">{message}</p>
          {/* render link html */}
          {link && (
            <div
              className="underline mx-2"
              dangerouslySetInnerHTML={{ __html: link }}
            />
          )}
        </div>
      </div>

      <button
        onClick={() => {
          handleClose();
        }}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-18 font-bold opacity-70 hover:opacity-100 transition-opacity"
      >
        ×
      </button>
    </div>
  );
};

export default ServerStatusBanner;
