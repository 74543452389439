import { useEffect, useState } from "react";
import { ReactComponent as FranciscaImg } from "../assets/portrait-1.svg";
import { ReactComponent as CatherineImg } from "../assets/portrait-3.svg";
import { ReactComponent as JoeriImg } from "../assets/portrait-4.svg";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthUserId } from "../../../hooks/useAuth";
import { useSupabase } from "../../../hooks/useSupabase";
import { useSwitchWorkspace, useWorkspace } from "../../../hooks/useWorkspace";
import { ProjectDbType } from "../../../types";

import { TrialLoadingAnimation } from "../../Trial/components/TrialPage";
import { Testimonial } from "./Testimonial";
import { UseCases } from "./UseCases";
import { UserJob } from "./UserJob";
import axios from "axios";
import { submitAppsumoCode } from "../../../api/appsumo";

const ImgPortraits = [
  {
    img: FranciscaImg,
    name: "Francisca Umaji",
    testimonial:
      "If you record webinars, podcasts or videos Unifire is for you. It takes your unique insights and repurposes them for all major platforms. It’s a game changer for growing our social reach.",
    title: "Freelance Consultant & Coach",
  },
  {
    img: JoeriImg,
    name: "Joeri Billast ",
    testimonial:
      "It hits you differently once you upload a 45 mins podcast and receive a 6k words blog post. This took me 10h previously. It is insane.",
    title: "Fractional CMO & Coach",
  },
  {
    img: CatherineImg,
    name: "Catherine Schoendorf",
    testimonial:
      "Personally, the magic of Unifire is the LinkedIn posts. It just knows what templates and hooks fit to my content. Tbh, it writes better then I do. And I get 20 of them 🤯",
    title: "Podcast Coach & Studio Owner",
  },
];

const startEmailOnboarding = async ({
  supabase,
  onboarding,
}: {
  supabase: any;
  onboarding: Record<string, any>;
}) => {
  const session = await supabase.auth.getSession();

  return axios
    .post(
      `${process.env.REACT_APP_TRANSCRIPTION_URL}/job-onboard`,
      { onboarding },
      {
        headers: {
          "Content-Type": "application/json",
          // @ts-ignore
          ...supabase.headers,
          authorization: `Bearer ${session.data.session.access_token}`,
        },
      }
    )
    .catch((e) => {
      console.error(e);
    });
};

export const OnboardingPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(1 as number);

  const navigate = useNavigate();
  const switchSpace = useSwitchWorkspace();
  const [submitting, setSubmitting] = useState(false);
  const [onboardingObj, setOnboardingObj] = useState({
    job: "",
    use_cases: [] as string[],
  });
  const params = useSearchParams();
  const appsumoCode = params[0].get("code");

  const supabase = useSupabase();
  const userId = useAuthUserId();
  // final submit. create a new project with title, langTag and assets, ignore the job
  const submitOnboarding = async (values: typeof onboardingObj) => {
    setSubmitting(true);
    if (!userId) return;
    const latestWorksapce = await supabase
      .from("Workspace")
      .select("*")
      .eq("owner_id", userId)
      .order("created_at", { ascending: false })
      .limit(1)
      .single();

    await Promise.all([
      supabase
        .from("UserProfile")
        .update({ onboarded: true, onboarding: values })
        .eq("id", userId),
      switchSpace(latestWorksapce?.data?.id as string),
      startEmailOnboarding({ supabase, onboarding: values }),
    ]);

    // if workspace id is not the same as the latest workspace id, then user is invited, go to dashboard
    // host + /dashboard/overview
    window.location.replace(
      `${window.location.href.includes("localhost") ? "http" : "https"}://${
        window.location.host
      }/dashboard/overview`
    );

    setSubmitting(false);
  };

  useEffect(() => {
    const attachAppsumoLicenseIfAny = async (userId: string) => {
      // submit license but fail silently, this is a "make sure" mechanism
      await submitAppsumoCode({
        code: appsumoCode as string,
        supabase,
        userId,
      }).catch((error) => {
        console.log("error", error);
      });
    };

    if (!userId) return;
    if (appsumoCode) attachAppsumoLicenseIfAny(userId);
  }, [appsumoCode, userId]);

  return (
    <div className="min-h-screen bg-white flex items-stretch justify-start ">
      {submitting && <TrialLoadingAnimation />}
      <Testimonial testimonialObj={ImgPortraits[currentStep - 1]} />

      <div className="min-h-screen w-full flex justify-center items-center md:px-0 relative">
        <div className="grid grid-cols-2 gap-x-3 w-full max-w-[250px] sm:max-w-[250px] absolute top-2 sm:top-5 md:top-5">
          <div
            className={`h-[11px] rounded-lg ${
              currentStep === 1 ? "bg-[#DD4F7E]" : "bg-[#D9D9D9]"
            }`}
          />
          <div
            className={`h-[11px] rounded-lg ${
              currentStep === 2 ? "bg-[#DD4F7E]" : "bg-[#D9D9D9]"
            }`}
          />
        </div>
        <div className="space-y-10 px-5 max-w-[800px] py-10 sm:py-5">
          <div>
            {currentStep === 1 && (
              <UserJob
                onSelectedJob={(job) => {
                  setOnboardingObj((prev) => ({
                    ...prev,
                    job,
                  }));
                  setCurrentStep(2);
                }}
              />
            )}
            {currentStep === 2 && (
              <UseCases
                onSelectedUseCases={(useCases) => {
                  submitOnboarding({
                    ...onboardingObj,
                    use_cases: useCases,
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
