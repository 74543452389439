export const TeamMemberBinIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.25 4.75H1.75M14.25 4.75L13.4224 13.4396C13.3247 14.4661 12.4625 15.25 11.4314 15.25H4.56857C3.53746 15.25 2.67534 14.4661 2.57758 13.4396L1.75 4.75M14.25 4.75C14.8023 4.75 15.25 4.30228 15.25 3.75V1.75C15.25 1.19772 14.8023 0.75 14.25 0.75H1.75C1.19771 0.75 0.75 1.19772 0.75 1.75V3.75C0.75 4.30228 1.19772 4.75 1.75 4.75M5.75 9.25H10.25"
      stroke="#D50B3E"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
