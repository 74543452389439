import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";
import { ProgressBar } from "./ProgressBar";

export const UploadProgressModal = ({
  setShow,
  show,
  fileUploadProgress,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  fileUploadProgress: number;
}) => {
  // prevent the modal from closing if the upload is in progress

  const handleRequestClose = () => {
    if (fileUploadProgress !== 100) return;
    setTimeout(() => {
      setShow(false);
    }, 5000);
  };

  return (
    <Modal
      isOpen={show}
      // onAfterOpen={afterOpenModal}
      onRequestClose={handleRequestClose}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          borderRadius: "6px",
          zIndex: 51,
        },
      }}
      contentLabel="Example Modal"
    >
      <div className="w-full h-full pt-20 pb-32 text-center min-w-[775px]">
        <p className="text-xl leading-8 font-semibold text-[#121217]">
          Upload in progress
        </p>
        <p className="mt-4 text-lg leading-6 font-normal text-[#6C6C89]">
          We are working hard to upload your content
        </p>
        <div className="mt-14 flex justify-center items-center">
          <div className="w-[160px] h-[160px]">
            <ProgressBar fileUploadProgress={fileUploadProgress} />
          </div>
        </div>
      </div>
    </Modal>
  );
};
