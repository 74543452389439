export const ZoomIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4461_11337)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0C15.523 0 20 4.47695 20 10C20 15.523 15.523 20 10 20C4.47695 20 0 15.523 0 10C0 4.47695 4.47695 0 10 0Z"
        fill="url(#paint0_linear_4461_11337)"
      />
      <path
        d="M4.5874 7.35866V11.3227C4.59131 12.2192 5.32334 12.9403 6.21592 12.9368H11.9944C12.1589 12.9368 12.2909 12.8048 12.2909 12.6442V8.67976C12.2874 7.78366 11.5554 7.06218 10.6624 7.06569H4.88389C4.71982 7.06569 4.5874 7.19773 4.5874 7.35866ZM12.6589 8.90476L15.0444 7.16218C15.2515 6.99069 15.4124 7.03366 15.4124 7.34421V12.6583C15.4124 13.0118 15.2159 12.9692 15.0444 12.8407L12.6589 11.1012V8.90476Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4461_11337"
        x1="2.64961"
        y1="3.21953"
        x2="18.523"
        y2="15.2336"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4A8CFF" />
        <stop offset="1" stop-color="#23B7EC" />
      </linearGradient>
      <clipPath id="clip0_4461_11337">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
