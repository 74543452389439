import classNames from "classnames";
import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useSupabase } from "../../../hooks/useSupabase";
import { CopyIcon } from "../../../shared/Icons/Copy";
import { InfoIcon } from "../../../shared/Icons/Info";
import { Switch } from "../../../shared/Library/Switch";
import { UploadDbType, UploadWithSocialPostsAndProject } from "../../../types";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "6px",
    zIndex: 51,
  },
};

Modal.setAppElement("#root");
const ShareForm = ({
  episode,
  setShowSharingForm,
  showSharingForm,
}: {
  episode: UploadWithSocialPostsAndProject | UploadDbType;
  setShowSharingForm: React.Dispatch<React.SetStateAction<boolean>>;
  showSharingForm: boolean;
}) => {
  const supabase = useSupabase();
  const location = useLocation();

  const [localChecked, setLocalChecked] = useState(false);
  const [localPublicEditChecked, setLocalPublicEditChecked] = useState(false);

  const [openTooltip, setOpenTooltip] = useState(false);

  const url = `${window.location.origin.toString()}/share/generation/${
    episode.id
  }`;

  useEffect(() => {
    supabase
      .from("Upload")
      .select("*")
      .eq("id", episode.id)
      .limit(1)
      .single()
      .then(({ data, error }) => {
        setLocalChecked(data?.allow_share ?? false);
        setLocalPublicEditChecked(data?.allow_edit ?? false);
      });
  }, [supabase, episode.id]);

  const onSwitch = async (checked: boolean) => {
    setLocalChecked(checked);

    await supabase
      .from("Upload")
      .update({ allow_share: checked })
      .eq("id", episode.id);
  };

  const onPublicEditSwitch = async (checked: boolean) => {
    setLocalPublicEditChecked(checked);

    await supabase
      .from("Upload")
      .update({ allow_edit: checked })
      .eq("id", episode.id);
  };

  return (
    <>
      <Modal
        isOpen={showSharingForm}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setShowSharingForm(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className="relative max-w-[300px] min-h-[450px] md:min-w-[861px] md:min-h-[400px] md:px-20 pt-11 pb-20 rounded-xl overflow-hidden h-full"
          role="document"
        >
          <div className="text-center space-y-3">
            <p className="block w-full text-xl leading-8 text-gray-900 font-semibold">
              Share Content
            </p>
            <p className="text-lg leading-6 text-[#121217]">
              Share your content with collaborators
            </p>
          </div>

          <p className="mt-10 w-full text-14 font-medium text-black">
            Settings
          </p>
          <div className="mt-7">
            <div className="flex items-center justify-between w-full">
              <div className="flex justify-start items-center space-x-1">
                <p className={"text-14 font-medium text-[#121217]"}>
                  {localChecked ? "Sharing enabled" : "Sharing disabled"}
                </p>
                <InfoIcon />
              </div>

              <Switch onChange={onSwitch} checked={localChecked} />
            </div>

            <p className="mt-1 w-4/5 text-14 text-[#6C6C89]">
              Activate or deactivate sharing mode. If you deactivate users with
              the shared link will loose access.
            </p>
          </div>

          <div className="mt-7">
            <div className="flex items-center justify-between w-full">
              <div className="flex justify-start items-center space-x-1">
                <p className={"text-14 font-medium text-[#121217]"}>
                  {localPublicEditChecked
                    ? "Editing enabled"
                    : "Editing disabled"}
                </p>
                <InfoIcon />
              </div>

              <Switch
                onChange={onPublicEditSwitch}
                checked={localPublicEditChecked}
              />
            </div>

            <p className="mt-1 w-4/5 text-14 text-[#6C6C89]">
              Allow people with the link to edit the content
            </p>
          </div>

          <div className="mt-14 w-full">
            <p className="w-full text-14 font-medium text-black">Copy Link</p>
            <div
              className={classNames(
                "my-share-element flex items-center justify-between mt-4 text-[#121217] py-[18px] px-[42px] border border-[#D1D1DB] bg-[#F7F7F8] rounded-lg",
                {
                  "cursor-pointer": localChecked,
                  "cursor-not-allowed": !localChecked,
                }
              )}
              onClick={() => {
                if (localChecked) {
                  navigator.clipboard.writeText(url);
                  setOpenTooltip(true);
                }
              }}
              data-for="registerTipTwo"
            >
              <p className="max-w-[80%] truncate">{url}</p>
              <CopyIcon />
            </div>
          </div>
        </div>
        <Tooltip
          style={{ backgroundColor: "#7047EB", color: "white" }}
          place="bottom"
          isOpen={openTooltip}
          variant="success"
          anchorSelect=".my-share-element"
          id="registerTipTwo"
          afterShow={() => {
            setTimeout(() => {
              setOpenTooltip(false);
            }, 2000);
          }}
        >
          Copied!
        </Tooltip>
      </Modal>
    </>
  );
};

export default ShareForm;
