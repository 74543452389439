import cx from "clsx";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import uuid4 from "uuid4";
import { useLogsnag } from "../../../../../hooks/logsnag"; // Adjust the path as necessary
import { useAnalytics } from "../../../../../hooks/useAnalytics";
import { useAuthUserId } from "../../../../../hooks/useAuth";
import { useRemainingCredits } from "../../../../../hooks/useRemainingCredits";
import { useSupabase } from "../../../../../hooks/useSupabase";
import { useWorkspace } from "../../../../../hooks/useWorkspace";
import { useRole } from "../../../../../hooks/userCanEdit";
import {
  convertMillisecondsToMinutes,
  getDurationMinutes,
} from "../../../../../utils";
import { getFileTypeAnalytics } from "../../../../../utils/common";
import { resumableUpload, transcribe } from "../../../../UploadContent/util";
import { UploadProgressModal } from "./ManualUploadModal";

const FileDropzone = ({
  projectId,
  onUploadIdDecided,
  onUploaded,
  isPublic,
  onUploadProgress,
  language,
}: {
  projectId: string;
  onUploadIdDecided: (_: string, isText: boolean) => void;
  onUploaded: () => void;
  isPublic?: boolean;
  onUploadProgress?: (progress: number) => void;
  language: string;
}) => {
  const [fileUploadProgress, setFileUploadProgress] = useState<number>(0);
  const { newUploadStarted, newUploadSuccessful, uploadFailed } =
    useAnalytics();
  const supabase = useSupabase();
  const logsnag = useLogsnag();
  const [show, setShow] = useState(false);
  const role = useRole();
  const workspace = useWorkspace();
  const userId = useAuthUserId();
  const navigate = useNavigate();

  const { ownerGeneralLimits, remainingInpCharacters } = useRemainingCredits();

  const onDrop = (acceptedFiles: any[]) => {
    handleAuthenticatedUpload(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "audio/*": [".mp3", ".wav", ".ogg", ".flac", ".m4a"],
      "video/*": [
        ".mp4",
        ".mov",
        ".wmv",
        ".flv",
        ".webm",
        ".mpeg",
        ".mpg",
        ".m4v",
      ],
    },
    // You can add more configurations as needed
  });

  // check if has enough inp tokens else return
  useEffect(() => {
    if (ownerGeneralLimits.planType === "FREE") {
      alert(
        "You have run out of characters to be upload. Please upgrade your plan to continue."
      );
      navigate("/dashboard/billing");
    } else if (
      remainingInpCharacters <= 2000 &&
      ["SMALL", "MEDIUM", "LARGE"].includes(ownerGeneralLimits.planType as any)
    ) {
      alert(
        "You have run out of characters to be upload. Please upgrade your plan to continue."
      );
      navigate("/dashboard/billing");
    }
  }, [remainingInpCharacters]);

  const revertLoadingState = () => {
    setShow(false);
    setFileUploadProgress(0);
  };

  const handleAuthenticatedUpload = async (acceptedFile: File) => {
    if (!acceptedFile) return;
    setShow(true);

    const session = await supabase.auth.getSession();

    const startWaitduration = Date.now();
    newUploadStarted({
      mediaType: getFileTypeAnalytics(acceptedFile?.type ?? "") as any,
      uploadMethod: "manual",
      userRole: role as any,
      workspaceId: workspace?.id as string,
    });
    // authorize
    const audioDuration = await getDurationMinutes(acceptedFile);

    if (!audioDuration) {
      uploadFailed({
        uploadLength: audioDuration,
        uploadMethod: "manual",
        message: "No audio duration",
      });
      alert("Sorry, we couldn't get the duration of your file");
      setShow(false);
      return;
    }

    if (ownerGeneralLimits.audioHourLimit < audioDuration) {
      uploadFailed({
        uploadLength: audioDuration,
        uploadMethod: "manual",
        message: "Audio duration exceeds limit",
      });
      const hours = ownerGeneralLimits.audioHourLimit / 60;
      alert(
        `Your plan only allows for ${hours} hour${
          hours > 1 ? "s" : ""
        } per upload. Please upgrade to upload longer files.`
      );
      setShow(false);
      return;
    }

    logsnag.publish({
      channel: "content",
      description: "User started a new upload",
      event: "Uploading...",
      icon: "⬆",
      notify: true,
      tags: {
        userid: session?.data?.session?.user.id ?? "",
        email: session?.data?.session?.user.email ?? "",
      },
    });

    const uploadId = uuid4();
    const fileId = uuid4();

    if (!projectId || !acceptedFile || !workspace?.id) return;

    const result = await resumableUpload({
      uploadId,
      file: acceptedFile,
      supabase,
      mimeType: acceptedFile.type,
      projectId,
      fileId,
      progress: (percentage) => {
        return setFileUploadProgress(+percentage);
      },
    }).catch((e) => {
      alert("An error occured, please try again later");
      revertLoadingState();
      uploadFailed({
        uploadLength: audioDuration,
        uploadMethod: "manual",
        message: "Upload to bucket failed",
      });
    });

    // submit record
    const upload = await supabase
      .from("Upload")
      .insert({
        id: uploadId,
        project_id: projectId,
        status: "transcribing",
        title: acceptedFile?.name,
        workspace_id: workspace?.id,
        user_id: userId,
        lang_tag: language,
      })
      .select("*")
      .order("created_at", { ascending: false })
      .limit(1)
      .single();

    const insertedFile = await supabase.from("File").insert({
      id: fileId,
      duration: audioDuration,
      mime: acceptedFile.type,
      status: "PROCESSING",
      title: acceptedFile.name.split(".")[0],
      upload_id: uploadId,
      workspace_id: workspace?.id,
    });

    if (!upload.error && !insertedFile.error) {
      await transcribe({
        uploadId: uploadId as string,
        fileId,
        supabase,
      }).catch((err) => {
        alert("An error occured, please try again");
        uploadFailed({
          uploadLength: audioDuration,
          uploadMethod: "manual",
          message: "Transcription request to assembly failed",
        });
      });
    } else {
      alert("An error occured, please try again");
      revertLoadingState();
      return;
    }

    onUploadIdDecided(uploadId as string, false);

    const endDateDuration = Date.now();
    newUploadSuccessful({
      fileType: acceptedFile?.type ?? "undefined",
      uploadMethod: "manual",
      uploadWaitDuration: convertMillisecondsToMinutes(
        endDateDuration - startWaitduration
      ),
      userRole: role as any,
      workspaceId: workspace?.id as string,
      uploadId,
      uploadLength: audioDuration,
      ownerId: workspace?.owner_id as string,
    });

    revertLoadingState();
    onUploaded();
  };

  return (
    <>
      <div
        {...getRootProps()}
        className={cx(
          "w-full min-h-[250px] cursor-pointer flex flex-col justify-center items-center rounded-md border-2 border-dashed",
          { "bg-gray-100": isDragActive, "bg-[#F4F7F7]": !isDragActive }
        )}
      >
        <input {...getInputProps()} />
        <div className="text-center">
          <p className="text-14 font-semibold text-[#202020]">
            {isDragActive
              ? "Release to upload"
              : "Drag & drop your file here or click to select"}
          </p>
          <p className="text-14 text-[#42474B] font-medium">
            Supports most audio/video file types
          </p>
          <p className="mt-4 text-10 text-[#42474B] font-medium">
            Upload your best quality content (wav, 1080p, etc.) up to 5GB for
            optimal output quality
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center w-full mt-5">
        <button
          className="text-14 font-medium"
          onClick={() => {
            window.location.reload();
          }}
        >
          Cancel
        </button>
        <button
          className="bg-uf-yellow-1 rounded-lg text-14 font-medium text-[#202020] py-2 px-4 focus:outline-none focus:shadow-outline disabled:bg-gray-100"
          // onClick={() => onUploaded()}
          disabled
        >
          Upload Source Material
        </button>
      </div>

      <UploadProgressModal
        setShow={setShow}
        show={show}
        fileUploadProgress={fileUploadProgress}
      />
    </>
  );
};

export default FileDropzone;
