import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogsnag } from "../../../hooks/logsnag";
import { useAuthUserId } from "../../../hooks/useAuth";
import { useRemainingCredits } from "../../../hooks/useRemainingCredits";
import { useSupabase } from "../../../hooks/useSupabase";
import { useWorkspace } from "../../../hooks/useWorkspace";
import { useRole } from "../../../hooks/userCanEdit";
import { AssetDbType } from "../../../types";
import { Database } from "../../../types/database.types";
import BillinInfoBanner from "../../Common/BillingInfoBanner";
import { ProjectSingleEpisode } from "../../Project/components/ProjectSingleEpisode";
import { SkeletonEpisodes } from "../../Project/components/SkeletonEpisodes";
import SelectAssets from "./SelectAssets";
import { Tutorial } from "./Tutorial";
import { LoadingScreen } from "../../Episode/components/FlowComponents/LoadingAsset/LoadingScreen";
import Lottie from "react-lottie";
import LoadingIntegrationLottie from "../../Episode/assets/loading-assets.json";

export type PodcastPostType = Database["public"]["Tables"]["Project"]["Row"];
export interface GroupedData {
  monthYear: string;
  data: Array<PodcastPostType>;
}

export type EpisodeJoinAssetType =
  Database["public"]["Tables"]["Upload"]["Row"] & {
    Asset: AssetDbType[];
  };

export function groupDataByDate(data: PodcastPostType[]): GroupedData[] {
  const groupedData: { [key: string]: Array<PodcastPostType> } = {};

  // group the data by month year
  data.forEach((item) => {
    const date = new Date(item.created_at ?? "");
    const monthYear = `${date.toLocaleString("default", {
      month: "long",
    })} ${date.getFullYear()}`;
    if (!groupedData[monthYear]) {
      groupedData[monthYear] = [];
    }
    groupedData[monthYear].push(item);
  });

  // convert the grouped data to the desired format
  const result: Array<GroupedData> = [];
  for (const monthYear in groupedData) {
    result.push({ monthYear, data: groupedData[monthYear] });
  }
  return result;
}

export const DashboardOverview = ({
  onAssetsIntent,
  onSelectProjectId,
  isPublic,
}: {
  onAssetsIntent: (_: string[]) => void;
  onSelectProjectId: (_: string) => void;
  isPublic?: boolean;
}) => {
  const [openCategoryInstance, setOpenCategoryInstance] = useState(false);
  const [showNewPodcast, setShowNewPodcast] = useState(false);

  const { isLoading } = useRemainingCredits();
  const logsnag = useLogsnag();
  const supabase = useSupabase();

  const workspace = useWorkspace();

  const role = useRole();

  const navigate = useNavigate();

  const userId = useAuthUserId();

  const [remoteEpisodes, setRemoteEpisodes] = useState<EpisodeJoinAssetType[]>(
    isPublic
      ? ([
          {
            id: "1",
            title: "title",
            created_at: "2024-03-05",
            status: "ready",
          },
          {
            id: "2",
            title: "title",
            created_at: "2024-03-01",
            status: "ready",
          },
          {
            id: "3",
            title: "title",
            created_at: "2024-02-01",
            status: "ready",
          },
          {
            id: "4",
            title: "title",
            created_at: "2024-01-01",
            status: "ready",
          },
        ] as any)
      : []
  );

  const [posts, setPosts] = useState<PodcastPostType[] | []>();
  useEffect(() => {
    if (!workspace?.id) return;
    setTimeout(async () => {
      const remotePosts = await supabase
        .from("Project")
        .select("*")
        .eq("workspace_id", workspace?.id as string)
        .order("created_at", { ascending: false });

      setPosts(remotePosts?.data as PodcastPostType[]);
    }, 0);
  }, [supabase, showNewPodcast, workspace?.id, remoteEpisodes]);

  useEffect(() => {
    if (!workspace?.id) return;

    supabase
      .from("Upload")
      .select(
        `id, title, status, project_id, is_submitted, created_at, Asset(asset_id)`
      )
      .eq("workspace_id", workspace?.id as string)
      .eq("is_complete", true)
      .neq("deleted", true)
      .order("created_at", { ascending: false })
      .limit(8)
      .then((remotePosts) => {
        setRemoteEpisodes(
          (remotePosts.data ?? ([] as any)).sort(
            (a: any, b: any) =>
              new Date(b.created_at ?? "").getTime() -
              new Date(a.created_at ?? "").getTime()
          )
        );
      });
  }, [workspace?.id, supabase]);

  return (
    <>
      <BillinInfoBanner />

      <div className="w-full h-full flex justify-start overflow-x-hidden no-scrollbar animate-slide-in px-4 max-w-5xl pb-32">
        <div
          className={`w-full h-full
        ${
          openCategoryInstance
            ? "-translate-x-full duration-300"
            : "translate-x-0 duration-300"
        }`}
        >
          <div>
            <Tutorial />
          </div>
          <div className="space-y-5 mt-5">
            <p className="text-lg leading-6 font-semibold text-[#121217]">
              What will you create today?
            </p>
            <SelectAssets
              isPublic={isPublic}
              onAssetsIntent={onAssetsIntent}
              onSelectProjectId={(id) => onSelectProjectId(id)}
            />
          </div>

          {posts?.length === 0 && (
            <p className="text-14 mt-8 text-uf-slate-2">
              🎙 Here you can find all your projects. You can create a new
              project by clicking on "new project" on top
            </p>
          )}

          <div className="mt-16 relative w-full h-full">
            <p className="text-16 text-[#202020] font-semibold">
              Recent Generations
            </p>
            {remoteEpisodes.length === 0 && (
              <p className="text-14 mt-8 text-uf-slate-2">
                ✏️ Here you will see your most recent generations
              </p>
            )}

            <div
              className={classNames("w-full space-y-8 mt-8", {
                "pointer-events-none": isPublic,
              })}
            >
              {remoteEpisodes?.map((ep, idx) => {
                return (
                  <ProjectSingleEpisode
                    key={ep.id}
                    remoteEpisode={ep as any}
                    role={role}
                    reload={() => {}}
                    assetIds={ep.Asset.map((a) => a.asset_id as string)}
                  />
                );
              })}
              {remoteEpisodes === undefined && <SkeletonEpisodes />}
            </div>
          </div>
          {/* {showNewPodcast && (
            <NewPodcastForm
              setShowNewPodcast={setShowNewPodcast}
              showNewPodcast={showNewPodcast}
            />
          )} */}
        </div>
      </div>
    </>
  );
};
export default DashboardOverview;
