import axios from "axios";

export const getEditorAuthTokens = async ({
  uploadId,
  userId,
}: {
  uploadId: string;
  userId: string;
}) => {
  const body = { upload_id: uploadId, user_id: userId };

  return await axios.post(
    `${process.env.REACT_APP_TRANSCRIPTION_URL}/editor/auth`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
