import "rc-slider/assets/index.css";
import { useEffect, useState } from "react";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { useRemainingCredits } from "../../../hooks/useRemainingCredits";
import { useSupabase } from "../../../hooks/useSupabase";
import { removeWokspace } from "../../../hooks/useWorkspace";
import { WarningIcon } from "../../../shared/Icons/Warning";
import { BillingDbType, UserProfileDbType } from "../../../types";
import { UNLIMITED_CREDITS_INTEGER } from "../../../utils";
import MakeApiKey from "./MakeApiKey";
import ZapierApiKey from "./ZapierApiKey";

const LTDPlanInfo = ({
  ownerCredits,
  ownerTotalCredits,
  UNLIMITED_CREDITS_INTEGER,
}: {
  ownerCredits: number;
  ownerTotalCredits: number;
  UNLIMITED_CREDITS_INTEGER: number;
}) => (
  <>
    <div className="grid grid-cols-3 gap-4 py-5 px-6 space-x-8">
      <dt className="text-sm text-gray-500 capitalize">
        Remaining Generations for this workspace per month
      </dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-max">
        {ownerCredits + " Generations"}
      </dd>
    </div>
    <div className="grid grid-cols-3 gap-4 py-5 px-6 space-x-8">
      <dt className="text-sm text-gray-500 capitalize">
        Generations per month
      </dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-max">
        {ownerTotalCredits > -1 &&
        ownerTotalCredits >= UNLIMITED_CREDITS_INTEGER
          ? "Unlimited Generations"
          : ownerTotalCredits <= UNLIMITED_CREDITS_INTEGER
          ? ownerTotalCredits + " Generations"
          : "Loading..."}
      </dd>
    </div>
  </>
);

const OtherPlanInfo = ({
  remainingInpCharacters,
  maxInpCharacters,
}: {
  remainingInpCharacters: number;
  maxInpCharacters: number;
}) => (
  <>
    <div className="grid grid-cols-3 gap-4 py-5 px-6 space-x-8">
      <dt className="text-sm text-gray-500 capitalize">
        Remaining Input Characters for this month
      </dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-max">
        {remainingInpCharacters?.toLocaleString() + " Characters"}
      </dd>
    </div>
    <div className="grid grid-cols-3 gap-4 py-5 px-6 space-x-8">
      <dt className="text-sm text-gray-500 capitalize">
        Input Characters per month
      </dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-max">
        {maxInpCharacters?.toLocaleString() + " Characters"}
      </dd>
    </div>
  </>
);

export const SettingsPage: React.FC = () => {
  const supabase = useSupabase();
  const [userProfile, setUserProfile] = useState<
    UserProfileDbType & { Billing: BillingDbType }
  >();
  const {
    ownerCycleEndDate,
    ownerTotalCredits,
    ownerCredits,
    remainingInpCharacters,
    ownerGeneralLimits: { planType, maxInpCharacters },
  } = useRemainingCredits();

  const { resetAtLogout } = useAnalytics();

  useEffect(() => {
    supabase.auth.getUser().then(async ({ data }) => {
      await supabase
        .from("UserProfile")
        .select("*, Billing(*)")
        .eq("id", data.user?.id as string)
        .single()
        .then(async (subscriptionData) => {
          setUserProfile(subscriptionData.data as typeof userProfile);
        });
    });
  }, [supabase]);

  // listen to db changes on selectedEpisode
  useEffect(() => {
    if (!userProfile?.id) return;

    const sub = supabase
      .channel(`changes`)
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "UserProfile",
          filter: `id=eq.${userProfile?.id}`,
        },
        (payload) => {
          const newProfile = {
            ...userProfile,
            ...payload.new,
          };
          setUserProfile(newProfile);

          // console.log("realtime update on user profile");

          // console.log("remainingCredits ", remainingCredits);
        }
      )
      .subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [userProfile?.id, supabase]);

  const planName =
    planType === "LTD"
      ? "AppSumo Lifetime Plan"
      : planType === "FREE"
      ? "Free Plan"
      : planType === "SMALL"
      ? "Starter Plan"
      : planType === "MEDIUM"
      ? "Premium Plan"
      : planType === "LARGE"
      ? "Business Plan"
      : "Unknown";

  return (
    <div className="overflow-x-hidden pb-10 px-4 md:px-8 animate-slide-in max-w-[1200px]">
      <p className="mt-24 text-lg leading-6 font-semibold text-gray-900">
        Integrations
      </p>

      <div className="mt-10 space-y-16">
        <ZapierApiKey />
        <MakeApiKey />
      </div>

      <div className="mt-16 mb-8 text-lg leading-6 font-semibold text-gray-900">
        <p className="">Billing Info</p>
      </div>
      <div className="bg-white rounded-lg border border-gray-200 max-w-xl ">
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            {planType === "LTD" ? (
              <LTDPlanInfo
                ownerCredits={ownerCredits}
                ownerTotalCredits={ownerTotalCredits}
                UNLIMITED_CREDITS_INTEGER={UNLIMITED_CREDITS_INTEGER}
              />
            ) : (
              <OtherPlanInfo
                remainingInpCharacters={remainingInpCharacters}
                maxInpCharacters={maxInpCharacters}
              />
            )}
            <div className=" grid grid-cols-3 gap-4 py-5 px-6 space-x-8">
              <dt className="text-sm text-gray-500 capitalize">
                Subscription Status
              </dt>
              <div className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-max">
                {planName}
              </div>
            </div>

            <div className=" grid grid-cols-3 gap-4 py-5 px-6 space-x-8">
              <dt className="text-sm text-gray-500">Plan renews on</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-max">
                {ownerCycleEndDate}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <p className="mt-16 text-lg font-semibold text-gray-900">
        Authentication
      </p>

      <button
        className="mt-7 border border-uf-slate-1 rounded-lg w-fit cursor-pointer"
        onClick={() => {
          removeWokspace();
          resetAtLogout();
          supabase.auth.signOut();
        }}
      >
        <p className="text-14 font-bold text-uf-slate-1 py-2 px-9">Sign out</p>
      </button>

      <div className="space-y-7 bg-[#FBFCFC] rounded-xl px-6 py-5 mt-[200px] shadow">
        <div className="flex justify-start items-center">
          <WarningIcon />
          <span className="text-red-700 text-sm leading-6 font-semibold ml-2">
            Danger Zone - Workspace deletion
            <small className="ml-1 italic text-gray-500">(coming soon)</small>
          </span>
        </div>
        <p className="text-sm leading-5 font-medium text-[#6C6C89]">
          Once you delete your workspace, there is no going back. Please be
          certain.
        </p>

        <button
          className="text-14 text-white py-2 px-[22px] bg-red-700 rounded-md cursor-not-allowed"
          disabled
        >
          Delete workspace
        </button>
      </div>
    </div>
  );
};
