import { SupabaseClient } from "@supabase/supabase-js";
import { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useSupabase } from "../../../hooks/useSupabase";
import { FileDbType } from "../../../types";
import { Database } from "../../../types/database.types";
import { sleep } from "../../../utils";
import { submitTranscript } from "../../EditableTranscript/components/EditableTranscriptEditor/EditorMenu";
import { AssetProgressType } from "../../GenerateAssetsFlow/components/GenerateAssetsFlow";
import { TrialLoadingAnimation } from "../../Trial/components/TrialPage";
import DashboardOverview from "./DashboardOverview";
import { GenerateAssetsPage } from "./GenerateAssets/GenerateAssetsPage";
import UploadPage from "./Upload";

const fetchFilesWithRretries = async (
  supabase: SupabaseClient<Database>,
  uploadId: string
) => {
  let files: FileDbType[] | undefined;
  for (let i = 0; i < 4; i++) {
    const resp = await supabase
      .from("File")
      .select("*")
      .eq("upload_id", uploadId);
    if (resp.error) {
      await sleep(1000);
      continue;
    } else {
      files = resp.data;
      break;
    }
  }
  return files;
};

export const GenerationFlowManager = ({
  defaultStep,
  defaultSelectedProjectId,
  defaultSelectedAssetIds,
}: {
  defaultStep?: "overview" | "upload" | "assets";
  defaultSelectedProjectId?: string;
  defaultSelectedAssetIds?: string[];
}) => {
  const [step, setStep] = useState<
    "overview" | "upload" | "assets" | "loading"
  >(defaultStep ?? "overview");
  const supabase = useSupabase();
  const navigate = useNavigate();
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>(
    defaultSelectedAssetIds ?? []
  );
  const [selectedProjectId, setSelectedProjectId] = useState<
    string | undefined
  >(defaultSelectedProjectId);
  const [uploadId, setUploadId] = useState<string>();
  const [didUploadPastedText, setDidUploadPastedText] = useState(false);

  const onConfirmProcess = async (
    assets: AssetProgressType[],
    skipTranscript: boolean
  ) => {
    setStep("loading");
    const files = await fetchFilesWithRretries(supabase, uploadId as string);
    if (!files) {
      alert(
        "Could not submit your request, please double check you have a stable internet connection try again"
      );
      window.location.reload();
    }

    const allFilesReady = files?.every((file) => file.status === "READY");

    // redirect
    if (didUploadPastedText) {
      await submitTranscript({
        uploadId: uploadId as string,
        supabase: supabase,
      }).catch(async (e) => {
        // update the upload status to failed
        for (let i = 0; i < 3; i++) {
          const { error } = await supabase
            .from("Upload")
            .update({ status: "failed" })
            .eq("id", uploadId as string);
          if (!error) {
            break;
          }
          await sleep(1000);
        }

        alert("An error has occured, please try again");
        navigate("/dashboard/overview");
      });
      navigate(
        `/dashboard/project/${selectedProjectId}/generation/${uploadId}`
      );
    }
    // else if the script happened fast and skipTranscript was turned off after the webhook was done, then update the Db with the edit state
    else if (!skipTranscript && Boolean(allFilesReady)) {
      await supabase
        .from("Upload")
        .update({ status: "edit" })
        .eq("id", uploadId as string);
      navigate(
        `/dashboard/project/${selectedProjectId}/generation/${uploadId}`
      );
    }
    // else if asked to skip transcript
    else if (skipTranscript && Boolean(allFilesReady)) {
      // if transcriber is faster than the user finishes the flow, then the webhook didn t call the automatic submission, this means we need to call it manually
      await submitTranscript({
        uploadId: uploadId as string,
        supabase: supabase,
      }).catch(async (e: AxiosError) => {
        // update the upload status to failed
        for (let i = 0; i < 3; i++) {
          const { error } = await supabase
            .from("Upload")
            .update({ status: "failed" })
            .eq("id", uploadId as string);
          if (!error) {
            break;
          }
          await sleep(1000);
        }

        alert("Error:" + e?.message);
        navigate("/dashboard/overview");
      });
      navigate(
        `/dashboard/project/${selectedProjectId}/generation/${uploadId}`
      );
    } else {
      navigate(
        `/dashboard/project/${selectedProjectId}/generation/${uploadId}`
      );
    }
  };

  return (
    <div>
      {step === "overview" && (
        <DashboardOverview
          onSelectProjectId={(id: string) => {
            setSelectedProjectId(id);
          }}
          onAssetsIntent={(assets: string[]) => {
            setStep("upload");
            setSelectedAssetIds(assets);
          }}
        />
      )}

      {step === "upload" && (
        <UploadPage
          projectId={selectedProjectId as string}
          onUploadIdDecided={(id, isText) => {
            setUploadId(id);
            setDidUploadPastedText(isText);
          }}
          onUploaded={() => {
            setStep("assets");
          }}
        />
      )}

      {step === "assets" && (
        <GenerateAssetsPage
          onConfirmAssets={(assets, skipTranscript) =>
            onConfirmProcess(assets, skipTranscript).then(() => {})
          }
          selectedAssetIds={
            didUploadPastedText
              ? selectedAssetIds
              : ["transcript", ...selectedAssetIds]
          }
          uploadId={uploadId as string}
        />
      )}

      {step === "loading" && <TrialLoadingAnimation />}
    </div>
  );
};
