import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { useRole } from "../../../hooks/userCanEdit";
import { Switch } from "../../../shared/Library/Switch";
import { TemplateDbType } from "../../../types";
import { ASSETS_IDS } from "../../Episode/components/types";
import { ReactComponent as CoinSvg } from "../assets/coin.svg";
import { ReactComponent as WarningSvg } from "../assets/warning.svg";
import { AssetProgressType } from "./GenerateAssetsFlow";
import { TemplateSelector } from "./TemplateSelector";
import { TEMPLATE_ASSETS_IDS } from "../../Templates/utils";
import { UNLIMITED_CREDITS_INTEGER } from "../../../utils";

export const AssetInstructions = ({
  assetObject,
  onConfirm,
  onSkip,
  isLoading,
  remainingCredits,
  assetCost,
  numberOfAssets,
  isSubmitting,
  defaultSkipTranscriptEditing,
  assetTemplates,
  defaultSelectedTemplates,
}: {
  assetObject: AssetProgressType | undefined;
  onConfirm: (
    instructions: string,
    selectedTemplates: TemplateDbType[],
    skipTranscript: boolean
  ) => void;
  onSkip: (skipTranscript: boolean) => void;
  isLoading: boolean;
  remainingCredits: number | undefined;
  assetCost: number | undefined;
  numberOfAssets: number;
  isSubmitting: boolean;
  defaultSkipTranscriptEditing: boolean;
  assetTemplates: TemplateDbType[] | undefined;
  defaultSelectedTemplates?: TemplateDbType[];
}) => {
  const navigate = useNavigate();
  // instructions tracker
  const { generateContentStarted, contentAssetSkipped } = useAnalytics();
  const [instructions, setInstructions] = useState("");
  const userRole = useRole();
  const canWrite = (remainingCredits ?? -1) >= (assetCost ?? 0);
  const [shouldSkipTranscriptEditing, setShouldSkipTranscriptEditing] =
    useState(true);
  const [selectedTemplates, setSelectedTemplates] = useState<TemplateDbType[]>(
    defaultSelectedTemplates || []
  );

  const multiSelectAssetIds = [
    TEMPLATE_ASSETS_IDS().TWITTER_TWEETS,
    TEMPLATE_ASSETS_IDS().TWITTER_LONGFORM,
    // TEMPLATE_ASSETS_IDS().YOUTUBE_POSTS,
    TEMPLATE_ASSETS_IDS().LINKEDIN_POSTS,
  ];

  const isMultiSelect = multiSelectAssetIds.includes(
    assetObject?.value as string
  );

  useEffect(() => {
    setShouldSkipTranscriptEditing(defaultSkipTranscriptEditing);
  }, [defaultSkipTranscriptEditing]);

  useEffect(() => {
    if (defaultSelectedTemplates) {
      setSelectedTemplates(defaultSelectedTemplates);
    }
  }, [defaultSelectedTemplates]);

  const handleTemplateSelection = (templates: TemplateDbType[]) => {
    setSelectedTemplates(() => [...templates]);
  };

  const removeTemplate = (templateId: string) => {
    setSelectedTemplates((prevTemplates) =>
      prevTemplates.filter((template) => template.id !== templateId)
    );
  };

  return (
    <div className="w-full">
      <div className="space-y-3 mt-[61px]">
        {isLoading || !assetObject ? (
          <Skeleton className="w-full h-[40px]" />
        ) : (
          <>
            <p className="text-center text-xl leading-8 font-semibold text-[#121217]]">
              Generate {assetObject?.label}
            </p>
            <p className="text-center text-lg leading-6 font-normal text-[#6C6C89]">
              Turn your source material into {assetObject!.label.toLowerCase()}{" "}
            </p>
          </>
        )}
      </div>
      <div className="flex flex-col space-y-2 mt-[98px]">
        {isLoading && !assetObject ? (
          <Skeleton className="w-full h-[21px]" />
        ) : (
          <label
            htmlFor="instruction"
            className="text-14 font-medium text-[#121217]"
          >
            Add your instruction{" "}
            <span className="text-[#6C6C89]">(optional)</span>
          </label>
        )}

        {isLoading && !assetObject ? (
          <Skeleton className="w-full h-[114px]" />
        ) : (
          <>
            <textarea
              id="instruction"
              name="instruction"
              rows={4}
              className="outline-none shadow-sm p-2 border border-gray-300 rounded-md placeholder:text-[#84818A] placeholder:text-14"
              placeholder="Focus on a specific aspect of your input, highlight my fundamental principles of X, extract the main points of Y, ignore the introduction etc."
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
            />
            <p className="text-14 text-[#84818A]">
              Adding instruction helps the AI to focus and generate more
              accurate results
            </p>
          </>
        )}
      </div>

      {assetObject?.value === ASSETS_IDS.TRANSCRIPT && (
        <div className="mt-[47px] flex justify-between">
          <div className="sm:pr-3 md:pr-10">
            <p className="text-14 font-medium text-[#121217]">
              Edit Transcript before content generation
            </p>
            <p className="mt-1 text-14 text-[#84818A]">
              Once activated you can edit and improve your transcript before
              generating your content. Recommended for technical and scientific
              content.
            </p>
          </div>
          <div className="flex flex-col justify-start items-center">
            <div className="h-full">
              <Switch
                checked={!shouldSkipTranscriptEditing}
                onChange={() => setShouldSkipTranscriptEditing((prev) => !prev)}
              />
            </div>
          </div>
        </div>
      )}

      {Boolean(assetTemplates?.length) && (
        <TemplateSelector
          selectedTemplates={selectedTemplates}
          assetTemplates={assetTemplates}
          assetLabel={assetObject?.label}
          onSelectTemplates={handleTemplateSelection}
          onRemoveTemplate={removeTemplate}
          assetId={assetObject?.value as string}
          isMultiSelect={isMultiSelect}
        />
      )}

      <div className="mt-[47px] flex justify-start items-center space-x-4 text-14 font-medium">
        {isLoading && !assetObject ? (
          <div className="w-full">
            <Skeleton className="h-[20px] !w-[50%]" />
          </div>
        ) : (
          <>
            {(remainingCredits ?? 0) >= UNLIMITED_CREDITS_INTEGER ? (
              <></>
            ) : (
              <>
                <p className="text-[#202020]">Generations you have left:</p>
                <div className="text-[#42474B] flex items-center space-x-0.5">
                  <CoinSvg width={20} height={20} />
                  <span>
                    {(remainingCredits ?? 0) >= UNLIMITED_CREDITS_INTEGER
                      ? "Unlimited Generations"
                      : `${remainingCredits} Generations`}
                  </span>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className=" flex flex-col items-center justify-start md:flex-row md:items-center md:justify-between space-x-4 w-full mt-[50px]">
        {isLoading && !assetObject ? (
          <div className="w-full">
            <Skeleton className="h-[30px] !w-[50%]" />
          </div>
        ) : (
          <div className="flex flex-col items-center order-last md:order-first mt-5 md:mt-0">
            <button
              type="button"
              name="skip"
              className="outline-none text-[#121217] text-14 font-medium bg-transparent hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 rounded-md px-6 py-2 transition duration-150 ease-in-out disabled:bg-gray-100"
              disabled={isSubmitting}
              onClick={() => {
                onSkip(shouldSkipTranscriptEditing);
                setInstructions("");

                contentAssetSkipped({
                  assetType: assetObject!.value,
                  creditsSpent: assetCost as number,
                  customInstructions: instructions,
                  numberOfAssets,
                  platformType: assetObject!.value?.split("_")?.[0] as any,
                  remainingCredits: remainingCredits! - assetCost!,
                  userRole: userRole as any,
                });
              }}
            >
              Skip for now
            </button>

            <span className="text-10 font-medium text-black text-center">
              You can always generate later
            </span>
          </div>
        )}

        {isLoading && !assetObject ? (
          <div className="w-full">
            <Skeleton className="h-[30px] w-[75%]" />
          </div>
        ) : (
          <button
            type="button"
            className="max-w-[227px] outline-none bg-uf-yellow-1 focus:ring-2 focus:ring-offset-2 ring-uf-yellow-1 rounded-lg px-6 py-3 transition duration-150 ease-in-out flex justify-center items-center disabled:bg-gray-100"
            disabled={!canWrite || isSubmitting}
            onClick={() => {
              onConfirm(
                instructions,
                selectedTemplates,
                shouldSkipTranscriptEditing
              );
              setInstructions("");

              generateContentStarted({
                assetType: assetObject!.value,
                creditsSpent: assetCost as number,
                customInstructions: instructions,
                numberOfAssets,
                platformType: assetObject!.value?.split("_")?.[0] as any,
                remainingCredits: remainingCredits! - assetCost!,
                userRole: userRole as any,
              });
            }}
          >
            <div className="flex items-center space-x-0.5 text-14 text-[#42474B]">
              <span className="font-semibold  ">
                {" "}
                {(remainingCredits ?? 0) >= UNLIMITED_CREDITS_INTEGER
                  ? "Generate"
                  : "Generate For"}
              </span>
              {(remainingCredits ?? 0) >= UNLIMITED_CREDITS_INTEGER ? (
                <></>
              ) : (
                <>
                  <CoinSvg width={20} height={20} />
                  <span className="font-medium ">{assetCost}</span>
                </>
              )}
            </div>
          </button>
        )}
      </div>
      {!isLoading && assetObject && !canWrite && (
        <div className="mt-10 flex justify-center items-center bg-white/90 z-20  text-[#8A6100] text-14 ">
          <div className="flex justify-start items-center bg-uf-yellow-1/30 py-2 px-4 space-x-3 rounded-lg">
            <div className="space-x-2">
              <div className="inline ">
                <WarningSvg className="inline mb-0.5" />
              </div>
              <span className="font-medium">Not enough Generations</span>{" "}
              <span className="font-normal">
                {userRole === "owner"
                  ? "To create more content subscribe to Unifire"
                  : "To create more content ask the owner to subscribe to Unifire"}
              </span>
            </div>
            <button
              className="underline font-medium cursor-pointer"
              onClick={() => navigate("/dashboard/billing")}
            >
              Upgrade now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
