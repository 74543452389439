import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSupabase } from "../../../hooks/useSupabase";
import { useWorkspace } from "../../../hooks/useWorkspace";
import { LibraryModal } from "../../../shared/Library/Modal";
import { Switch } from "../../../shared/Library/Switch";
import { TemplateDbType } from "../../../types";
import { TEMPLATE_PLATFORMS } from "../utils";
import TemplateCard from "./TemplateCard";

const allTemplatesPill = "All Templates";

const groupTemplatesByPlatform = (
  templates: TemplateDbType[]
): (TemplateDbType[] | undefined)[] => {
  const platforms = TEMPLATE_PLATFORMS();

  return platforms.map((platform) => {
    const platformTemplates = templates.filter((template) =>
      template.asset_id?.toLowerCase().includes(platform.toLowerCase())
    );
    return platformTemplates.length > 0 ? platformTemplates : undefined;
  });
};

export const TemplatesOverview: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<"my" | "unifire">(
    "unifire"
  );

  const [templates, setTemplates] = useState<TemplateDbType[]>([]);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeselectModal, setShowDeselectModal] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateDbType | null>(null);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeselecting, setIsDeselecting] = useState(false);

  // const categories: ["my", "community"] = ["my", "community"];
  const categories: ["unifire", "my"] = ["unifire", "my"];

  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(
    allTemplatesPill
  );
  const [showEnabledByDefault, setShowEnabledByDefault] = useState(false);

  const supabase = useSupabase();
  const workspace = useWorkspace();
  const [isLoading, setIsLoading] = useState(true);

  const platforms = TEMPLATE_PLATFORMS();

  const filteredTemplates = templates.filter((template) => {
    // if show only using is checked, only show templates that are used, else show all available ones
    const showDefault = showEnabledByDefault ? template.is_enabled : true;
    // if selected category is "My Templates", only show templates that are of type "my", else show the unifire templates
    const isMyTemplate =
      selectedCategory === "my"
        ? template.copied_from_community_template_id === null &&
          Boolean(template.fixture_id) === false
        : selectedCategory === "unifire"
        ? Boolean(template.fixture_id)
        : false;
    // if a platform is selected, only show templates that match the selected platform, else show all templates
    const isPlatformMatch =
      selectedPlatform === allTemplatesPill ||
      (selectedPlatform
        ? template.asset_id
            ?.toLowerCase()
            .includes(selectedPlatform.toLowerCase())
        : true);

    return showDefault && isMyTemplate && isPlatformMatch;
  });

  const groupedTemplates = groupTemplatesByPlatform(filteredTemplates);

  useEffect(() => {
    supabase
      .from("Template")
      .select("*")
      .eq("workspace_id", workspace?.id as string)
      .then(({ data, error }) => {
        setIsLoading(false);
        if (error) {
          console.error(error);
          alert("Failed to fetch");
          return;
        }
        if (data) {
          setTemplates(data);
        }
      });
  }, [supabase, workspace?.id]);

  const handleRename = (id: string) => {
    const template = templates.find((t) => t.id === id);
    if (template) {
      setSelectedTemplate(template);
      setNewTemplateName(template?.title ?? "");
      setShowRenameModal(true);
    }
  };

  const handleDeselect = (id: string) => {
    const template = templates.find((t) => t.id === id);
    if (template) {
      setSelectedTemplate(template);
      setShowDeselectModal(true);
    }
  };

  const handleDelete = (id: string) => {
    const template = templates.find((t) => t.id === id);
    if (template) {
      setSelectedTemplate(template);
      setShowDeleteModal(true);
    }
  };

  const confirmRename = async () => {
    if (selectedTemplate && newTemplateName.trim()) {
      setIsRenaming(true);
      const { data, error } = await supabase
        .from("Template")
        .update({ title: newTemplateName.trim() })
        .eq("id", selectedTemplate.id)
        .eq("workspace_id", workspace?.id as string);

      if (error) {
        console.error("Error renaming template:", error);
        alert("Failed to rename template");
      } else {
        setTemplates(
          templates.map((t) =>
            t.id === selectedTemplate.id
              ? { ...t, title: newTemplateName.trim() }
              : t
          )
        );
        setShowRenameModal(false);
      }
      setIsRenaming(false);
    }
  };

  const confirmDeselect = async () => {
    if (selectedTemplate) {
      setIsDeselecting(true);
      const { data, error } = await supabase
        .from("Template")
        .update({ is_enabled: false })
        .eq("id", selectedTemplate.id)
        .eq("workspace_id", workspace?.id as string);

      if (error) {
        console.error("Error deselecting template:", error);
        alert("Failed to deselect template");
      } else {
        setTemplates(
          templates.map((t) =>
            t.id === selectedTemplate.id ? { ...t, is_enabled: false } : t
          )
        );
        setShowDeselectModal(false);
      }
      setIsDeselecting(false);
    }
  };

  const confirmDelete = async () => {
    if (selectedTemplate) {
      setIsDeleting(true);
      // delete all AssetTemplate first
      const { data: assetTemplates, error: assetTemplatesError } =
        await supabase
          .from("AssetTemplate")
          .delete()
          .eq("template_id", selectedTemplate.id);
      const { error } = await supabase
        .from("Template")
        .delete()
        .eq("id", selectedTemplate.id)
        .eq("workspace_id", workspace?.id as string);

      if (error || assetTemplatesError) {
        console.error("Error deleting template:", error);
        alert("Failed to delete template");
      } else {
        setTemplates(templates.filter((t) => t.id !== selectedTemplate.id));
        setShowDeleteModal(false);
      }
      setIsDeleting(false);
    }
  };

  return (
    <div className="animate-slide-in max-w-6xl pl-8 pr-4 py-8">
      <div className="flex justify-between items-center ">
        <h1 className="text-lg leading-6 font-semibold text-gray-900">
          Template Library
        </h1>
        <Link
          className="bg-uf-yellow-1 text-[#202020] font-medium text-14 py-2 px-4 rounded-lg"
          to={"/dashboard/template/create"}
        >
          Create new template
        </Link>
      </div>

      <div className="flex border-b border-gray-200 mt-14">
        {categories.map((category) => (
          <button
            key={category}
            className={`text-sm font-medium py-2 px-10 ${
              selectedCategory === category
                ? "border-b-2 border-[#7047EB] text-[#7047EB]"
                : "text-gray-500 hover:text-gray-700"
            }`}
            onClick={() => setSelectedCategory(category)}
          >
            {category === "my"
              ? "My Templates"
              : category === "unifire"
              ? "Unifire Templates"
              : ""}
          </button>
        ))}
      </div>

      <div className="my-6 flex flex-wrap items-center gap-2">
        <button
          key={allTemplatesPill}
          className={`px-4 py-[7px] rounded-full text-sm font-medium ${
            selectedPlatform === allTemplatesPill
              ? "bg-[#EEFBF4] text-[#17663A]"
              : "bg-[#F7F7F8] text-[#3F3F50]"
          }`}
          onClick={() => setSelectedPlatform(allTemplatesPill)}
        >
          {allTemplatesPill}
        </button>
        {platforms.map((platform) => (
          <button
            key={platform}
            className={`px-7 py-[7px] rounded-full text-sm font-medium ${
              selectedPlatform === platform
                ? "bg-[#EEFBF4] text-[#677a70]"
                : "bg-[#F7F7F8] text-[#3F3F50]"
            }`}
            onClick={() => {
              if (selectedPlatform === platform) {
                setSelectedPlatform(null);
              } else {
                setSelectedPlatform(platform);
              }
            }}
          >
            {platform}
          </button>
        ))}
      </div>

      <div className="mb-6 flex items-center">
        <Switch
          checked={showEnabledByDefault}
          onChange={setShowEnabledByDefault}
        />

        <span className="ml-3 text-sm font-medium text-gray-700">
          Show templates I’m using by default
        </span>
      </div>

      {groupedTemplates.map(
        (platformTemplates, index) =>
          platformTemplates && (
            <div
              className="space-y-3"
              key={platformTemplates[index]?.id ?? index}
            >
              <h2 className="text-lg text-[#121217] font-semibold mt-8">
                {platforms[index]}
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {platformTemplates?.map((template) => (
                  <TemplateCard
                    key={template.id}
                    template={template}
                    onRename={handleRename}
                    onDelete={handleDelete}
                    onDeselect={handleDeselect}
                  />
                ))}
              </div>
            </div>
          )
      )}

      {isLoading === true && (
        <>
          <div className="w-24 h-6 bg-gray-200 rounded-full mb-4"></div>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[...Array(8)].map((_, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow overflow-hidden animate-pulse"
              >
                <div className="px-5 py-8">
                  <div className="flex justify-between items-center">
                    <div className="w-24 h-6 bg-gray-200 rounded-full"></div>
                    <div className="w-6 h-6 bg-gray-200 rounded"></div>
                  </div>
                  <div className="w-3/4 h-4 bg-gray-200 rounded mt-6"></div>
                  <div className="space-y-2 mt-4">
                    <div className="w-full h-3 bg-gray-200 rounded"></div>
                    <div className="w-5/6 h-3 bg-gray-200 rounded"></div>
                    <div className="w-4/6 h-3 bg-gray-200 rounded"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {filteredTemplates.length === 0 && isLoading === false && (
        <div className="w-full flex justify-center items-center mt-32">
          <Link
            className="bg-uf-yellow-1 text-[#202020] font-medium text-14 py-2 px-4 rounded-lg"
            to={`/dashboard/template/create${
              selectedCategory === "unifire" ? "" : ""
            }`}
          >
            Create new template
          </Link>
        </div>
      )}

      <LibraryModal show={showRenameModal} setShow={setShowRenameModal}>
        <div className="p-8 w-full space-y-14">
          <div className="text-center">
            <h2 className="block font-semibold w-full text-center text-xl leading-8">
              Rename Template
            </h2>
            <p className=" w-full text-center font-normal mt-2 text-lg leading-6">
              Choose a new name for your template
            </p>
          </div>

          <div className="">
            <label
              htmlFor="templateName"
              className="text-14 text-[#121217] font-medium"
            >
              Template name
            </label>
            <input
              type="text"
              id="templateName"
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
              className="text-14 appearance-none mt-2 border border-gray-300/60 rounded-xl text-gray-900 w-full p-3 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter template name"
            />
          </div>
          <div className="w-full flex justify-end items-center">
            <button
              onClick={confirmRename}
              disabled={isRenaming}
              className={`py-2 px-11 w-full max-w-[254px] bg-uf-yellow-1 text-gray-800 text-xs leading-6 font-semibold rounded-md shadow-sm focus:ring-offset-2 focus:ring-yellow-400 ${
                isRenaming ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isRenaming ? "Renaming..." : "Rename"}
            </button>
          </div>
        </div>
      </LibraryModal>

      <LibraryModal show={showDeselectModal} setShow={setShowDeselectModal}>
        <div className="p-8 w-full space-y-14">
          <div className="text-center">
            <h2 className="block font-semibold w-full text-center text-xl leading-8">
              Deselect Template
            </h2>
            <p className=" w-full text-center font-normal mt-2 text-lg leading-6">
              Are you sure you want to deselect this template?
            </p>
          </div>

          <div className="text-14 text-[#121217] font-medium text-center">
            <p className="text-sm text-gray-700 mb-2 ">
              This template will no longer be used for your next generation.
            </p>
            <p className="text-sm font-medium text-gray-900">
              {selectedTemplate?.title}
            </p>
          </div>

          <div className="w-full flex justify-between items-center space-x-4">
            <button
              onClick={() => setShowDeselectModal(false)}
              disabled={isDeselecting}
              className={`py-2 px-11 w-full max-w-[254px] bg-gray-200 text-gray-800 text-xs leading-6 font-semibold rounded-md shadow-sm focus:outline-none ${
                isDeselecting ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Cancel
            </button>
            <button
              onClick={confirmDeselect}
              disabled={isDeselecting}
              className={`py-2 px-11 w-full max-w-[254px] bg-uf-yellow-1 text-gray-800 text-xs leading-6 font-semibold rounded-md shadow-sm focus:outline-none ${
                isDeselecting ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isDeselecting ? "Deselecting..." : "Deselect"}
            </button>
          </div>
        </div>
      </LibraryModal>

      <LibraryModal show={showDeleteModal} setShow={setShowDeleteModal}>
        <div className="p-8 w-full space-y-14">
          <div className="text-center">
            <h2 className="block font-semibold w-full text-center text-xl leading-8">
              Delete Template
            </h2>
            <p className=" w-full text-center font-normal mt-2 text-lg leading-6">
              Are you sure you want to delete this template?
            </p>
          </div>

          <div className="text-center">
            <p className="text-14 text-[#121217] font-medium">
              This action cannot be undone. The template will be permanently
              removed from your library.
            </p>
            <p className="text-sm font-medium text-gray-900">
              {selectedTemplate?.title}
            </p>
          </div>

          <div className="w-full flex justify-between items-center space-x-4">
            <button
              onClick={() => setShowDeleteModal(false)}
              disabled={isDeleting}
              className={`py-2 px-11 w-full max-w-[254px] bg-gray-200 text-gray-800 text-xs leading-6 font-semibold rounded-md shadow-sm focus:outline-none ${
                isDeleting ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Cancel
            </button>
            <button
              onClick={confirmDelete}
              disabled={isDeleting}
              className={`py-2 px-11 w-full max-w-[254px] bg-red-600 text-white text-xs leading-6 font-semibold rounded-md shadow-sm focus:outline-none ${
                isDeleting ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </LibraryModal>
    </div>
  );
};
