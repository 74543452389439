import { Socket } from "socket.io-client";
import {
  AssetDbType,
  UploadWithSocialPostsAndProject,
} from "../../../../types";
import { AssetPaginator } from "../AssetPaginator";
import { ASSETS_IDS } from "../types";

export const PodcastResources = ({
  upload,
  selectedAssetId,
  canEdit,
  socket,
  assets,
}: {
  upload: UploadWithSocialPostsAndProject;
  selectedAssetId: string;
  canEdit: boolean;
  socket: Socket;
  assets: AssetDbType[];
}): JSX.Element => {
  return (
    <>
      <AssetPaginator
        canEdit={canEdit ?? false}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.PODCAST_KEYTAKEAWAYS
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit ?? false}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.PODCAST_KEYWORDS
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit ?? false}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.PODCAST_MENTIONEDTOPICS
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit ?? false}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.PODCAST_QUOTES
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit ?? false}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.PODCAST_SUMMARY
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit ?? false}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.PODCAST_TIMESTAMPS
          ) as AssetDbType
        }
      />

      <AssetPaginator
        canEdit={canEdit ?? false}
        upload={upload}
        selectedAssetId={selectedAssetId}
        socket={socket}
        asset={
          assets.find(
            (asset) => asset.asset_id === ASSETS_IDS.PODCAST_TITLES
          ) as AssetDbType
        }
      />
    </>
  );
};

export default PodcastResources;
