import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

export const TrialTestimonials = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // check if the script is already loaded
    if ((window as any).iFrameResize) {
      return;
    }

    //   <script src="https://widget.senja.io/widget/3be29732-d0cf-4df0-8935-28f6a66cbbec/platform.js" type="text/javascript" async></script>
    // <div class="senja-embed" data-id="3be29732-d0cf-4df0-8935-28f6a66cbbec" data-mode="shadow" data-lazyload="false" style="display: block;"></div>
    // Dynamically load the iFrame resizer script
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/3be29732-d0cf-4df0-8935-28f6a66cbbec/platform.js";
    script.onload = () => {
      // Once the script is loaded, initialize the iFrame resizer
      (window as any).iFrameResize(
        { log: false, checkOrigin: false },
        "#senja-collector-iframe"
      );
    };
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  {
    /* <script src="https://widget.senja.io/widget/3be29732-d0cf-4df0-8935-28f6a66cbbec/platform.js" type="text/javascript" async></script>
<div class="senja-embed" data-id="3be29732-d0cf-4df0-8935-28f6a66cbbec" data-mode="shadow" data-lazyload="false" style="display: block;"></div> */
  }
  return (
    <>
      {/* {loading && (
        <div className="w-full h-full flex justify-center items-start pt-10">
          <div className="h-[500px] w-[1000px] max-w-[50%]">
            <Skeleton className="w-full h-full" />
          </div>
        </div>
      )} */}
      <div className="">
        <div
          className="senja-embed block"
          data-id="3be29732-d0cf-4df0-8935-28f6a66cbbec"
          data-mode="shadow"
          data-lazyload="false"
          // style="display: block;"
        ></div>
      </div>

      {/* <iframe
        className={loading ? "opacity-0" : ""}
        id="senja-collector-iframe"
        src="https://senja.io/widget/3be29732-d0cf-4df0-8935-28f6a66cbbec/platform.js"
        allow="camera; microphone"
        title="Senja form"
        frameBorder="0"
        scrolling="no"
        style={{ width: "100%", height: "700px" }}
        onLoad={() =>
          setTimeout(() => {
            setLoading(false);
          }, 200)
        }
      ></iframe> */}
    </>
  );
};
